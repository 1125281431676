import * as React from 'react';
import { ColumnsT } from '#interfaces/tables/benefit';
import { Badge, Icons } from '#components/commons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '#components/shadcn';
import classNames from 'classnames';

export const Columns = ({ action, config }: ColumnsT) => {
  const columns = [
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="relative p-2 hover:cursor-pointer flex-none">
                <Icons
                  type="context"
                  className="text-gray-500 hover:text-gray-900 h-4 w-4"
                />
                <span className="sr-only">Abrir menu</span>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="start">
              <DropdownMenuGroup>
                <DropdownMenuItem
                  className="hover:cursor-pointer"
                  onSelect={() => action(row.original, 'edit')}
                >
                  <Icons type="edit" className="mr-2 h-4 w-4" />
                  <span>Ver Detalles</span>
                </DropdownMenuItem>

                <DropdownMenuItem
                  className="hover:cursor-pointer text-red-500"
                  onSelect={() => action(row.original, 'delete')}
                >
                  <Icons type="delete" className="mr-2 h-4 w-4" />
                  <span>Eliminar</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: 'id_order',
      header: 'N° y estado',
      cell: ({ row }: { row: any }) => {
        const { id_order, status } = row.original;

        return (
          <div className="flex gap-x-2">
            <Badge
              className="w-auto bg-blue-50 text-blue-600 border-blue-600/10"
              text={`N°: ${id_order}`}
            />
            <Badge
              className={classNames('w-auto', status.style)}
              text={status.text}
            />
          </div>
        );
      },
    },

    {
      accessorKey: 'description',
      header: 'Descripción',
      cell: ({ row }: { row: any }) => {
        const { description } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {description}
          </p>
        );
      },
    },
    {
      accessorKey: 'date_order',
      header: 'Fecha pedido',
      cell: ({ row }: { row: any }) => {
        const { date_order } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {date_order}
          </p>
        );
      },
    },
    {
      accessorKey: 'date_received',
      header: 'Fecha recibido',
      cell: ({ row }: { row: any }) => {
        const { date_received } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {date_received || '-'}
          </p>
        );
      },
    },
    {
      accessorKey: 'is_usd',
      header: 'En USD',
      cell: ({ row }: { row: any }) => {
        const { is_usd } = row.original;

        return (
          <Icons
            type={is_usd ? 'check_clean' : 'cancel'}
            className={classNames(
              'w-5 h-5 mx-auto',
              is_usd ? 'text-green-500' : 'text-slate-500',
            )}
          />
        );
      },
      filterFn: (row: any, id: string, value: any) =>
        value.includes(row.getValue(id)),
    },
    {
      accessorKey: 'amount_laboratory',
      header: 'Importe Laboratorio',
      cell: ({ row }: { row: any }) => {
        const { amount_laboratory } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {amount_laboratory}
          </p>
        );
      },
    },

    {
      accessorKey: 'amount_total',
      header: 'Importe total',
      cell: ({ row }: { row: any }) => {
        const { amount_total } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {amount_total}
          </p>
        );
      },
    },
  ];
  !config.useUsd.params && columns.splice(5, 1);

  return columns;
};
