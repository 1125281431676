import React from 'react';
import { FormikHelpers } from 'formik';

import { ParamsT } from '#interfaces/general-values';
import { updateSistemConfig } from '../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../context';

export function useSubmit({
  params,
  onChange,
}: {
  params: ParamsT;
  onChange: () => void;
}) {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(spec, params);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Los datos se guardaron correctamente');
      onChange();
    } catch (err: any) {
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(spec: { [key: string]: any }, params: ParamsT) {
  const apiSpec = transformSpec(spec, params);

  return updateSistemConfig(apiSpec);
}

function transformSpec(spec: { [key: string]: any }, params: ParamsT) {
  interface ExtendedParamsT extends ParamsT {
    h_clinica_desde: string;
    ag_aca: string;
    ag_alerta: string;
    cod_prest_bilog: string;
  }

  const apiSpec: ExtendedParamsT = {
    ...params,
    h_clinica_desde: spec.hc_from,
    ag_aca: spec.schedule_aca,
    ag_alerta: spec.schedule_alert,
    cod_prest_bilog: spec.lender_code,
  };

  return apiSpec;
}
