import { useContext } from 'react';
import { FormikHelpers } from 'formik';
import { saveTurn } from '../../../../services';
import { SnackbarContext, FormContext } from '../../../../context';

export function useSubmit(onChange: () => void) {
  const { closeForm } = useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  return async (spec: any, formikActions: FormikHelpers<any>) => {
    try {
      await axiosPromise(spec);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Observación guardada con éxito');
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(spec: any) {
  const apiSpec = transformSpec(spec);

  return saveTurn(apiSpec);
}

function transformSpec(spec: any) {
  return {
    id_prof: spec.id_professional,
    id_usuario: spec.id_user,
    id_paciente: spec.id_patient,
    id_sucursal: spec.office,
    id_agenda: spec.id_appointment,
    opcion: 'modificarObs',
    hora: spec.time,
    fecha_dado: spec.given_date,
    fecha: spec.date,
    obs: spec.notes,
    es_sobreturno: spec.is_overturn,
    no_dar: false,
    asistencia: spec.attendance,
    paciente: spec.id_patient ? null : spec.patient_name,
    telefono: spec.phone,
    obra_social: spec.medical_insurance.name,
    plan_nombre: spec.medical_insurance.plan,
    nro_afil: spec.medical_insurance.afiliate_number,
    hora_llegada: spec.eta,
    rowversion: spec.rowversion,
  };
}
