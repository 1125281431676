import { useContext } from 'react';
import { FormikHelpers } from 'formik';
import { updateMedInsConfig } from '../../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../../context';

export function useSubmit(onChange: () => void) {
  const { closeForm } = useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  return async (spec: any, formikActions: FormikHelpers<any>) => {
    try {
      await axiosPromise(spec.medicalInsurance);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Obra social eliminada correctamente');
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}
function axiosPromise(spec: any) {
  const apiSpec = transformSpec(spec);
  return updateMedInsConfig(apiSpec);
}
function transformSpec(spec: any) {
  let apiSpec: {
    id_os: number;
    codigo_os: string;
    obra_social: string;
    domicilio: string;
    localidad: string;
    provincia: string;
    telefono: string;
    fax: string;
    e_mail: string;
    cuit: string;
    observaciones: string;
    cant_prest: number;
    iva_ob: number;
    iva_ad: number;
    cargar_por_periodo: string;
    cliente_ftp: string;
    inhabilitada: boolean;
    opcion: 'nuevo' | 'modificar' | 'eliminar';
    rowversion?: string;
  } = {
    ...spec,
    opcion: 'eliminar',
  };

  return apiSpec;
}
