import React from 'react';
import { CheckboxForm } from '#components/index';

const OtherMethod: React.FC = () => {
  return (
    <div className="col-span-3 col-start-10">
      <CheckboxForm
        name="save_register"
        label="Guardar caja"
        description="El pago se guardará con caja"
        showError={false}
      />
    </div>
  );
};

export default OtherMethod;
