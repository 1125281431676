import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarContext } from '../../../context';
import {
  startLoading,
  stopLoading,
  getManagerCode,
} from '../../../redux/slices';
import { AppDispatch } from '../../../redux';

export function useSubmit(open: () => void) {
  const dispatch = useDispatch();
  const { showErrorSnackbar } = useContext(SnackbarContext);

  return async (spec: any) => {
    try {
      dispatch(startLoading());
      await axiosPromise(spec, dispatch);

      open();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      dispatch(stopLoading());
    }
  };
}

function axiosPromise(spec: any, dispatch: AppDispatch) {
  const apiSpec = transformSpec(spec);

  return dispatch(getManagerCode(apiSpec));
}

function transformSpec(spec: any) {
  let apiSpec = {
    LenderCode: spec.lender_code,
    ManagerCode: spec.manager_code,
  };

  return apiSpec;
}
