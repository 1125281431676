import React from 'react';
import { Icons } from '../../../components';
import { Button } from '../../../components/shadcn/button';

export const TurnReminder: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <div id="schedule-view-wp">
      <Button
        onClick={onClick}
        className="h-[38px] flex px-4 bg-green-500 truncate"
      >
        <Icons type="whatsapp" className="mr-2 w-4 h-4" fill="#fff" />
        Enviar recordatorios
      </Button>
    </div>
  );
};
