import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './useSubmit';
import { Form, Copy } from '../../../../components';
import { DeleteBudgetFormT } from '#interfaces/components';

export const DeleteBudgetForm: React.FC<DeleteBudgetFormT> = ({
  selectedBudget,
  userId,
  refresh,
}) => {
  return (
    <Formik
      initialValues={{}}
      onSubmit={useSubmit({ selectedBudget, userId, refresh })}
    >
      <Form submitTitle="Aceptar" ignoreDirty>
        <Copy>
          Estás por eliminar un presupuesto y esta acción es irreversible, el
          presupuesto se eliminará definitivamente.
        </Copy>
      </Form>
    </Formik>
  );
};
