import React from 'react';
import { CheckboxForm, DropdownForm } from '../../../../../../components';
import { useFieldProps } from '../../../../../../hooks';

export const SpecialtiesCheckbox: React.FC<{
  specialties: { [key: string]: any }[];
}> = ({ specialties }) => {
  const {
    field: { value: all_specialties },
  } = useFieldProps({ name: 'all_specialties' });

  return (
    <>
      <div>
        <DropdownForm
          name="specialty"
          data={specialties}
          disabled={all_specialties}
          label="Especialidad"
          keyId="id_especialidad"
          keyData="descripcion"
        />
      </div>
      <div>
        <CheckboxForm
          label="Todas las especialidades"
          small
          name="all_specialties"
          showError={false}
        />
      </div>
    </>
  );
};
