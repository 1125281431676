import React from 'react';
import { Formik } from 'formik';
import { useForm } from './use-form';
import { useSubmit } from './use-submit';
import {
  ControllerProf,
  GroupByController,
  ControllerMedIns,
  DateManagerController,
  ControllerOffices,
} from '../Controllers';
import { SmartGraphic, Button, Title, Form } from '../../../../components';
import { useSetHeight } from '../../../../hooks';
import { ServicePerformedFormT } from '#interfaces/components';

export const ServicePerformedForm: React.FC<ServicePerformedFormT> = ({
  professionals,
  translations,
  medicalInsurances,
  data,
  offices,
  current,
}) => {
  const fieldNameAllMedIns = 'check_all_med_ins';
  const fieldNameCheckAllProf = 'check_all_prof';
  const height = useSetHeight(180);
  const { initialValues } = useForm(current);

  return (
    <div
      className="p-10 overflow-hidden overflow-y-scroll"
      style={{ height: `${height}px` }}
    >
      <Formik initialValues={initialValues} onSubmit={useSubmit()}>
        {({ submitForm }) => (
          <Form showActions={false}>
            <Title>Prestaciones realizadas</Title>
            <DateManagerController className="mt-4" />
            <ControllerMedIns
              translations={translations}
              medicalInsurances={medicalInsurances}
              fieldNameAllMedIns={fieldNameAllMedIns}
              fieldNameCheckAllProf={fieldNameCheckAllProf}
            />
            <ControllerProf
              professionals={professionals}
              fieldNameCheckAllProf={fieldNameCheckAllProf}
            />
            <ControllerOffices offices={offices} />
            <div className="grid grid-cols-12 gap-10">
              <GroupByController translations={translations} />
              <div className="col-span-6 col-start-12 self-end">
                <Button
                  className="rounded shadow"
                  fullWidth
                  onClick={submitForm}
                >
                  Generar estadisticas
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {data && (
        <div className="grid grid-cols-12 gap-10 mt-14">
          <div className="col-span-6">
            <SmartGraphic
              title={translations.medical_insurance}
              subtitle={
                translations.medical_insurance === 'Obra social'
                  ? `Importe a cargo de la ${translations.medical_insurance}`
                  : `Importe a cargo del ${translations.medical_insurance}`
              }
              label="totalAmount"
              data={data[0]?.medInsAmountDataGroup.map((e: any) => {
                return {
                  amount: e.medicalInsuranceAmount,
                  percentage: e.medicalInsurancePercentage,
                  name: e.name,
                  quantity: e.quantity,
                  type: 'money',
                };
              })}
              chartType="donut"
              color={['#40CFFF']}
            />
          </div>
          <div className="col-span-6">
            <SmartGraphic
              title="Pacientes"
              subtitle="Importe a cargo del Paciente"
              label="patientPercentage"
              data={data[0]?.patientsAmountDataGroup.map((e: any) => {
                return {
                  amount: e.patientAmount,
                  percentage: e.patientPercentage,
                  name: e.name,
                  quantity: e.quantity,
                  type: 'money',
                };
              })}
              chartType="donut"
              color={['#FBBF24']}
            />
          </div>
        </div>
      )}
    </div>
  );
};
