import React from 'react';
import { useNavigate } from 'react-router-dom';
import { updateUsersConfig } from '../../../../../../services';
import {
  SnackbarContext,
  FormContext,
  ModalContext,
} from '../../../../../../context';
import { FreemiumForm } from '../../../../../../components';
import { FormikHelpers } from 'formik';

export function useSubmit({
  permissions,
  isDemo,
  onChange,
}: {
  permissions: { [key: string]: any };
  isDemo: boolean;
  onChange: () => void;
}) {
  const { closeForm } = React.useContext(FormContext);
  const navigate = useNavigate();
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);
  const { showModal, hideModal } = React.useContext(ModalContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      if (isDemo)
        throw new Error(
          'Este es un usuario demo, por favor contactese con Bilog.',
        );

      await axiosPromise(spec, permissions);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Los datos se guardaron correctamente');
      // If the user logged is editing his
      // configs, has to login again.
      if (spec.id === spec.logged_id) {
        navigate('/');
      }
      onChange();
    } catch (err: any) {
      err.message.includes('40000')
        ? showModal(
            'Suscribirse a Premium',
            <FreemiumForm onCancel={hideModal} />,
          )
        : showErrorSnackbar(
            `${err.message} Error status code: ${err.statusCode}`,
          );
    }
  };

  function axiosPromise(
    spec: { [key: string]: any },
    permissions: { [key: string]: any },
  ) {
    const apiSpec = transformSpec(spec, permissions);

    return updateUsersConfig(apiSpec);
  }

  function transformSpec(
    spec: { [key: string]: any },
    permissions: { [key: string]: any },
  ) {
    const opcion = spec.rowversion ? 'modificar' : 'nuevo';
    let userPerm = permissions.map(
      (p: { [key: string]: any }) =>
        spec.permissions.includes(p.id_proceso) && p,
    );
    userPerm = userPerm.filter((n: { [key: string]: any }) => n);

    let apiSpec: { [key: string]: any } = {
      id_prof: spec.id_prof,
      usuario: spec.user,
      nombre: spec.name,
      clave: spec.password,
      es_odontologo: spec.dentist,
      es_odontologo_agenda: spec.dentist_schedule,
      es_odontologo_guardia: spec.dentist_guard,
      es_odontologo_datos: spec.dentist_data,
      es_supervisor: spec.supervisor,
      habilitado: spec.enabled,
      id_sucursal: spec.office,
      token: null,
      doble_moneda: spec.use_usd,
      permisos: spec.supervisor ? [] : userPerm,
      recetas_tyc: spec.recetas_tyc,
      opcion,
    };

    if (spec.rowversion) {
      apiSpec = {
        ...apiSpec,
        id_usuario: spec.id,
        rowversion: spec.rowversion,
      };
    }

    return apiSpec;
  }
}
