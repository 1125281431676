import React from 'react';
import { useDispatch } from 'react-redux';
import {
  startLoading,
  stopLoading,
  getUserParams,
  setIsLogged,
} from '../../../redux/slices';
import { AppDispatch } from '../../../redux';
import { setLogin } from '../../../redux/slices/login';
import { SnackbarContext, FormContext } from '../../../context';
import { HandlerError } from '../../../utils';

export function useSubmit(onChange: () => void) {
  const dispatch = useDispatch();
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar } = React.useContext(SnackbarContext);

  return async (spec: any) => {
    try {
      dispatch(startLoading());
      const result = await axiosPromise(spec, dispatch);

      if (result) {
        dispatch(setIsLogged({ isLogged: true }));
        onChange();
      }

      closeForm();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      dispatch(stopLoading());
    }
  };
}

async function axiosPromise(spec: any, dispatch: AppDispatch) {
  const apiSpec = transformSpec(spec);
  const data = await dispatch(setLogin(apiSpec))
    .unwrap()
    .catch((error) => {
      throw new HandlerError(
        error.message.split('-')[2],
        error.message.split('-')[0],
      );
    });
  await dispatch(getUserParams());

  return !!data;
}

function transformSpec(spec: any) {
  const apiSpec = {
    acceso: {
      webuser: spec.webuser,
      user: spec.user,
      password: spec.password,
    },
    remember: spec.remember,
  };
  return apiSpec;
}
