import React from 'react';
import { Transition } from '@headlessui/react';
import { useFieldProps } from '../../hooks';

export function Error({ name }: { name: string }) {
  const { meta } = useFieldProps({ name });
  return (
    <Transition
      show={(meta && meta.error && meta.touched) || false}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <p className="text-xs text-red-500 mt-2">
        &nbsp;{meta.error && meta.touched ? meta.error : ''}
      </p>
    </Transition>
  );
}
