import React from 'react';

interface FormContextProps {
  isOpenForm: boolean;
  openForm: () => void;
  closeForm: () => void;
  customCancel?: (fn: () => void) => () => void;
}

export const FormContext = React.createContext<FormContextProps>({
  isOpenForm: false,
  openForm: () => {},
  closeForm: () => {},
  customCancel: (fn) => () => fn(),
});

export function useFormContext() {
  return React.useContext(FormContext);
}
