import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { SelectedHisCliT } from '#interfaces/general-values';
import { FormContext, SnackbarContext } from '../../../../context';
import {
  deleteClinicHistory,
  startLoading,
  stopLoading,
} from '../../../../redux/slices';
import { AppDispatch } from '../../../../redux';

export function useSubmit({
  selectedHc,
  patientId,
}: {
  selectedHc: SelectedHisCliT | undefined;
  patientId: number;
}) {
  const dispatch: AppDispatch = useDispatch<AppDispatch>();
  const { closeForm } = useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  return async () => {
    try {
      dispatch(startLoading());
      await dispatch(deleteClinicHistory({ patientId, selectedHc }));
      showSuccessSnackbar('Historia clínica eliminada corectamente');
      closeForm();
    } catch (err: any) {
      let message = 'Unknown Error';
      err instanceof Error
        ? (message = err.message)
        : showErrorSnackbar(message);
    } finally {
      dispatch(stopLoading());
    }
  };
}
