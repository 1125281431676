import React from 'react';
import {
  ResponsiveContainer,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ComposedChart,
} from 'recharts';
import { formatDateGraphic } from '../../utils';
import { FormattedTooltip } from '../forms/Statistics/finances/tooltips';

export const NewGraphic: React.FC<{
  data: any;
  color: { original: string; comparison: string };
  compare: string;
}> = React.memo(({ data, color, compare }) => {
  const typeGraphic = data.chartType;
  const BarOrLineComponent: any = typeGraphic === 'line' ? Line : Bar;

  return (
    <div>
      <ResponsiveContainer width={500} height={300}>
        <ComposedChart
          layout={
            data.chartType !== 'horizontalBar' ? 'horizontal' : 'vertical'
          }
          width={500}
          height={300}
          data={data?.chartData || []}
          margin={{
            top: 25,
            right: 60,
            left: typeGraphic === 'horizontalBar' ? 20 : 0,
            bottom: 2,
          }}
        >
          <Tooltip
            content={
              <FormattedTooltip
                type={typeGraphic}
                color={color}
                name={data.title}
                compare={compare}
              />
            }
          />
          <CartesianGrid />
          {typeGraphic !== 'horizontalBar' ? (
            <>
              <XAxis
                dataKey={typeGraphic === 'line' ? 'period' : 'smallLabel'}
                tick={{
                  fontSize: 11,
                }}
                domain={['dataMin', 'dataMax']}
                tickFormatter={
                  typeGraphic === 'line' ? formatDateGraphic : () => ''
                }
                interval={typeGraphic === 'line' ? undefined : 0}
                textAnchor="end"
              />
              <YAxis
                tick={{
                  fontSize: 12,
                }}
              />
            </>
          ) : (
            <>
              <XAxis
                type="number"
                tick={{
                  fontSize: 12,
                }}
              />
              <YAxis
                dataKey="label"
                type="category"
                tick={{
                  fontSize: 12,
                }}
              />
            </>
          )}
          <Tooltip />
          {compare && <Legend iconType="circle" iconSize={10} />}
          <ReferenceLine y={0} stroke="#000" />
          {typeGraphic === 'line' && (
            <Brush
              dataKey="period"
              height={30}
              stroke="#333333"
              tickFormatter={formatDateGraphic}
            >
              <ComposedChart data={data}>
                <BarOrLineComponent
                  type="monotone"
                  dataKey="value"
                  fill={color.original}
                  stroke={color.original}
                />
                {compare && (
                  <BarOrLineComponent
                    type="monotone"
                    dataKey="comparationValue"
                    stroke={color.comparison}
                    fill={color.comparison}
                  />
                )}
              </ComposedChart>
            </Brush>
          )}
          <BarOrLineComponent
            name="Principal"
            type="monotone"
            dataKey="value"
            fill={color.original}
            stroke={color.original}
          />
          {compare && (
            <BarOrLineComponent
              name="Comparación"
              type="monotone"
              dataKey="comparationValue"
              stroke={color.comparison}
              fill={color.comparison}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
});
