import React from 'react';
import { DetailsPaymentForm } from './DetailsPaymentForm';
import { DeletePaymentForm } from './DeletePaymentForm';
import { CancelPaymentForm } from './CancelPaymentForm';
import { SuccesFull as Empty } from '#components/commons';
import { Modal, TabSection } from '#components/index';
import { Button } from '#components/shadcn';
import { Payments } from '#components/Tables';
import { SnackbarContext } from '../../../context';
import { useForm, useSetHeight } from '../../../hooks';
import { emptyStateNotPatient, emptyStatePayments } from '../../../mocks';
import { PaymentsFormT } from '#interfaces/components';

export const PaymentsForm: React.FC<PaymentsFormT> = ({
  userId,
  hasAdminModule,
  patient,
  offices,
  professionals,
  taxes,
  banks,
  cards,
  useUsd,
  cashDate,
  isSupervisor,
  onChange,
  handlerFocus,
  permissions,
}) => {
  const [selectedPayment, setSelectedPayment] = React.useState<any>(null);
  const { showErrorSnackbar } = React.useContext(SnackbarContext);
  const { isOpenForm, closeForm, openForm } = useForm();
  const height = useSetHeight(patient?.id_paciente ? 420 : 220);
  const patientPayments = patient.pagos_pacientes;
  const selectedPatientId = patient.id_paciente;
  const {
    isOpenForm: isOpenModalDelete,
    closeForm: closeModalDelete,
    openForm: openModalDelete,
  } = useForm();
  const {
    isOpenForm: isOpenModalCancel,
    closeForm: closeModalCancel,
    openForm: openModalCancel,
  } = useForm();

  const paymentsActions = (payment: any, action: string) => {
    const toolbarEvents: { [key: string]: () => void } = {
      edit: () => openForm(),
      delete: () => openModalDelete(),
      cancel: () => openModalCancel(),
    };

    if (payment.id_benefit) {
      return showErrorSnackbar(
        'Por favor, utilizá el módulo de prestaciones para realizar esta acción.',
      );
    }

    const date = new Date(payment.date);
    const closureDate = new Date(cashDate);

    if (
      closureDate.getDate() !== null &&
      date <= closureDate &&
      !isSupervisor
    ) {
      showErrorSnackbar(
        'Esta acción no se puede realizar ya que se realizó el cierre de caja de este período.',
      );
    } else {
      const current = patientPayments.find(
        (el: any) => el.id_pago === payment.id_payment,
      );
      if (!current)
        return showErrorSnackbar(
          'Este pago no se puede modificar. Es modificable solamente desde el software de escritorio.',
        );
      if (current.anulado)
        return showErrorSnackbar(
          'Este pago no se puede modificar, el mismo se encuentra anulado.',
        );
      if (useUsd.params && !useUsd.user && current?.es_usd)
        return showErrorSnackbar(
          'El pago seleccionado se encuentra en USD y el usuario no puede operar doble moneda.',
        );
      setSelectedPayment(current);
      toolbarEvents[action]();
    }
  };

  const openFormHandler = () => {
    setSelectedPayment(null);
    openForm();
  };

  const onChangeHandler = () => {
    onChange();
    setSelectedPayment(null);
  };
  const handlerEmpty = {
    dataMocks: !patient.id_paciente ? emptyStateNotPatient : emptyStatePayments,
    onClick: !patient.id_paciente ? handlerFocus : openFormHandler,
  };

  return (
    <section className="mt-5" style={{ height: `${height}px` }}>
      {!isOpenForm && (
        <>
          {patient?.cta_cte && patient?.cta_cte.length ? (
            <>
              <Payments
                data={patient.cta_cte}
                config={{ useUsd, permissions, isSupervisor }}
                action={paymentsActions}
              />
              {selectedPatientId &&
                (isSupervisor || permissions.hasOwnProperty('Nuevo Pago')) && (
                  <div className="flex mt-5 space-x-5 justify-end">
                    <Button
                      onClick={openFormHandler}
                      className="px-10 h-10 hover:bg-blue-600"
                    >
                      Nuevo pago
                    </Button>
                  </div>
                )}
            </>
          ) : (
            <Empty
              typeSucces="emptyState"
              dataMocks={handlerEmpty.dataMocks}
              onClick={handlerEmpty.onClick}
            />
          )}
        </>
      )}
      {selectedPatientId && (
        <>
          <TabSection
            title={selectedPayment ? 'Editar pago' : 'Nuevo pago'}
            isOpen={isOpenForm}
            close={closeForm}
            open={openForm}
          >
            <DetailsPaymentForm
              permissions={permissions}
              isSupervisor={isSupervisor}
              hasAdminModule={hasAdminModule}
              userId={userId}
              payment={selectedPayment}
              offices={offices}
              patient={patient}
              professionals={professionals}
              taxes={taxes}
              banks={banks}
              cards={cards}
              useUsd={useUsd}
              onChange={onChangeHandler}
              onCancel={closeForm}
            />
          </TabSection>
          {selectedPayment && (
            <>
              <Modal
                title="Estás por eliminar un pago"
                isOpenModal={isOpenModalDelete}
                closeModal={closeModalDelete}
                openModal={openModalDelete}
              >
                <DeletePaymentForm
                  payment={selectedPayment}
                  isSupervisor={isSupervisor}
                  onChange={onChangeHandler}
                />
              </Modal>
              <Modal
                title={
                  !selectedPayment.anulado
                    ? 'Estás por anular un pago'
                    : 'Estás por habilitar un pago'
                }
                isOpenModal={isOpenModalCancel}
                closeModal={closeModalCancel}
                openModal={openModalCancel}
              >
                <CancelPaymentForm
                  payment={selectedPayment}
                  isSupervisor={isSupervisor}
                  onChange={onChangeHandler}
                />
              </Modal>
            </>
          )}
        </>
      )}
    </section>
  );
};
