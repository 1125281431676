import classNames from 'classnames';
import { useState } from 'react';

export function useFocused() {
  const [isFocused, setIsFocused] = useState(false);
  const focus = () => setIsFocused(!isFocused);
  const focusedClass = classNames(isFocused && 'focused');

  return { isFocused, focus, focusedClass };
}
