import React from 'react';
import { updateAbsencesConfig } from '../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../context';
import { FormikHelpers } from 'formik';
import { isEqual, format } from 'date-fns';

export function useSubmit(
  selectedProf: { [key: string]: any },
  onChange: () => void,
  absences: { [key: string]: any }[] = [],
) {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      const validationDates = absences?.some((absence) =>
        isEqual(
          new Date(absence.desde?.split('T')?.[0]),
          new Date(format(spec.from, 'yyyy-MM-dd')),
        ),
      );
      if (validationDates) {
        return showErrorSnackbar(
          'Existe una ausencia en ese rango de fechas, por favor elija otro rango.',
        );
      }
      await axiosPromise(spec, selectedProf);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Ausencia guardada correctamente');
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(
  spec: { [key: string]: any },
  selectedProf: { [key: string]: any },
) {
  const apiSpec = transformSpec(spec, selectedProf);

  return updateAbsencesConfig(apiSpec);
}

function transformSpec(
  spec: { [key: string]: any },
  selectedProf: { [key: string]: any },
) {
  const { id_prof, nombre } = selectedProf;
  const opcion = spec.rowversion ? 'modificar' : 'nuevo';

  let apiSpec: { [key: string]: any } = {
    id_prof: id_prof,
    nombre: nombre,
    opcion,
    prof_ausencias: [
      {
        id_prof: id_prof,
        dia: spec.day_num === 7 ? 0 : spec.day_num,
        desde: spec.from,
        hasta: spec.to,
        rowversion: spec.rowversion,
      },
    ],
  };

  if (spec.rowversion) {
    apiSpec.prof_ausencias[0].id_feriado = spec.id;
    apiSpec.prof_ausencias[0].rowversion = spec.rowversion;
  }

  return apiSpec;
}
