import React from 'react';
import classNames from 'classnames';
import equalsImage from '../../../../static/imagesGraphics/graph_blue.svg';
import increaseImage from '../../../../static/imagesGraphics/graph_green.svg';
import decreaseImage from '../../../../static/imagesGraphics/graph_red.svg';
import notValueImage from '../../../../static/imagesGraphics/graph_grey.svg';
import { Title, Copy } from '../../../../components';
import { TranslationsT } from '#interfaces/general-values';
import { numberToCurrency } from '../../../../utils';

export const TextComponent: React.FC<{
  data: any;
  variationIcons: {
    [key: string]: { [key: string]: string };
  };
  translations: TranslationsT;
}> = ({ data, variationIcons, translations }) => {
  return (
    <div className="col-span-6 graphics">
      {Object.keys(data)?.map((item, index) => {
        const value = data[item];
        const totalMoney = numberToCurrency(value.total);
        const title = value.translations
          ? `${value.title} ${translations.medical_insurance}`
          : value.title;
        const subTitle = value.translations
          ? `${value.subtitle} ${translations.medical_insurance}.`
          : value.subtitle;
        const performanceTypeImage =
          value.performance?.type === 'increase'
            ? increaseImage
            : value.performance?.type === 'decrease'
            ? decreaseImage
            : value.performance?.type === 'equals'
            ? equalsImage
            : notValueImage;
        const styleContainer = classNames(
          'border border-slate-300 rounded-md p-8 px-6 h-auto flex flex-row gap-10 flex justify-between',
          index === 0 && 'mb-12',
          title === 'Dinero en caja' && 'mb-18',
          title === 'Facturación por obra social' && '',
        );

        return (
          <div className={styleContainer}>
            <div>
              <Title>{title}</Title>
              <Copy className="text-slate-400 pb-6">{subTitle}</Copy>
              <Title className="text-3xl">{totalMoney}</Title>
              {value.performance && value.performance.type !== 'equals' && (
                <span
                  className={variationIcons[value.performance?.type]?.className}
                >
                  {`${variationIcons[value.performance?.type]?.sumOrRest} ${
                    value.performance?.variationValue
                  } (${variationIcons[value.performance?.type]?.arrow}%${
                    value.performance?.variationPercentage
                  })`}
                </span>
              )}
            </div>
            <img className="h-auto" src={performanceTypeImage} alt="graphic" />
          </div>
        );
      })}
    </div>
  );
};
