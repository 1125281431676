import React from 'react';
import { EmptyState } from '#components/index';
import { SettingsCopyBenefits } from '#components/Tables';
import { MedInsBenefitsFormT } from '#interfaces/components';

export const CopyBenefitsForm: React.FC<MedInsBenefitsFormT> = ({
  canSelect = false,
  benefits,
  translations,
  selectedMed,
}) => {
  const groups = selectedMed?.grupos || [];

  return benefits?.length ? (
    <SettingsCopyBenefits
      data={{ benefits, groups }}
      config={{ translations, canSelect }}
    />
  ) : (
    <EmptyState
      title="Ups!"
      copy="La obra social seleccionada no tiene prestaciones definidas."
    />
  );
};
