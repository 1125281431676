export const initialPatientData: { [key: string]: any } = {
  acti_chequeos: [],
  alerta: '',
  ape_nom: '',
  cuit: '',
  alergia: false,
  alfa: false,
  cardiaco: false,
  diabetes: false,
  embarazo: false,
  es_titular: true,
  renal: false,
  hepatitis: false,
  documento: '',
  domicilio: '',
  edad: '',
  motivo: '',
  email: '',
  est_civil: '',
  f_alta: new Date().toISOString(),
  f_nacimiento: new Date().toISOString(),
  f_recitar: null,
  f_ultima_at: new Date().toISOString(),
  face: '',
  foto: '',
  hc_nro: 0,
  his_cli: [],
  id_plan: 0,
  id_socio_tit: 0,
  id_usuario: 0,
  id_paciente: null,
  instagram: '',
  localidad: '',
  nro_afil: '',
  obs_varias: '',
  ocupacion: '',
  opcion: '',
  provincia: '',
  quedatobuscar: '',
  saldo: 0,
  tel_celu: '',
  tel_off: '',
  tel_part: '',
  todos: [],
  presup: [],
  id_planNavigation: {},
  pagos_pacientes: [],
  cond_fiscal: [
    { id: 'CF', text: 'Consumidor Final' },
    { id: 'RI', text: 'Responsable Inscripto' },
    { id: 'MO', text: 'Monotributista' },
    { id: 'EX', text: 'IVA Exento' },
  ],
  condicion: [
    { id: 'NG', text: 'No gravado' },
    { id: 'GR', text: 'Gravado' },
    { id: 'PR', text: 'Privado' },
  ],
  sexo: 'F',
  titular_emp: '',
  titular_nom: '',
  twitter: '',
  odontograma: [],
};

export const typesRegistrations = [
  { type: 'Provincial', value: 'MP' },
  { type: 'Nacional', value: 'MN' },
];
