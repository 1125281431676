import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { TextFieldForm, Form } from '../../../../../../components';
import { GroupFormT } from '#interfaces/components';

export const GroupForm: React.FC<GroupFormT> = ({
  group,
  selectedMed,
  onChange,
}) => {
  const { initialValues, validate } = useForm(group, selectedMed);

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={useSubmit(selectedMed, onChange)}
      enableReinitialize
    >
      <Form ignoreDirty>
        <div className="flex gap-x-5 items-end mt-2 mb-10">
          <TextFieldForm name="code" label="Código" maxlength={4} />
          <TextFieldForm name="description" label="Descripción" />
        </div>
      </Form>
    </Formik>
  );
};
