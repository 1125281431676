import React from 'react';
import { Button, Icons } from '../../../../../components';
import { useFieldProps } from '../../../../../hooks';

import './index.scss';

export const Message: React.FC<{
  message: any;
  setAssistance: (message: string, option: string, date: string | Date) => void;
  dateFieldName: string;
}> = ({ message, setAssistance, dateFieldName }) => {
  const { pacNombre: name, mensaje, nro_tel, procesado } = message;
  const {
    field: { value: date },
  } = useFieldProps({ name: dateFieldName });

  const onClickHandler = (option: any) => setAssistance(message, option, date);

  const icon = procesado ? (
    <Icons type="check_clean" fill="green" />
  ) : (
    <Icons type="close" fill="red" />
  );

  return (
    <div className={`message${procesado ? ' sent' : ''}`}>
      <div className="message-header">
        <p>{icon}</p>
        <p className="name">{name}</p>
        <p className="phone">
          <a
            className="whatsapp"
            href={`https://wa.me/${nro_tel}`}
            target="_blank"
            rel="noreferrer"
          >
            +{nro_tel}
          </a>
        </p>
      </div>
      <div>
        <p className="subtitle">Mensaje</p>
        {mensaje.map((m: any) => (
          <p className="response">{m}</p>
        ))}
      </div>
      {!procesado && (
        <div className="presenteeism">
          <Button onClick={() => onClickHandler('Confirmo')} className="con">
            CON
          </Button>
          <Button onClick={() => onClickHandler('Ausente')} className="aca">
            ACA
          </Button>
        </div>
      )}
    </div>
  );
};
