import React from 'react';
import { AvatarT } from '#interfaces/components/commons';

export const Avatar: React.FC<AvatarT> = ({ userName }) => {
  const firstLetter = userName.charAt(0);

  return (
    <div className="w-full bg-slate-900 flex flex-col justify-center items-center">
      <p className="text-3xl font-extrabold text-white">{firstLetter}</p>
    </div>
  );
};
