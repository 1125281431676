import axios from 'axios';
import { defaultApi } from '../mocks';
import { axiosInstance, platform, setBaseUrl } from '../utils';

export function login(params: {}) {
  const body = {
    acceso: params,
    plataforma: platform,
  };

  return axiosInstance.post('/aulogin', body);
}

export async function getApiUrl(params: {}) {
  const body = {
    acceso: params,
    plataforma: platform,
  };

  const { data } = await axios.post(`${defaultApi}/api/balancer`, body, {
    headers: { 'Content-type': 'application/json' },
  });

  setBaseUrl(data.apiUrl);

  return data;
}

export function validLogin(userData: { [key: string]: any }) {
  const { habilitado = false } = userData;
  return !!habilitado;
}

export const getUpdatedVersionApp = async () => {
  return await axios.get(
    `https://api.vercel.com/v1/projects/${process.env.REACT_APP_VERCEL_PROJECT_ID}/env/${process.env.REACT_APP_VERCEL_ENV_ID}?teamId=${process.env.REACT_APP_VERCEL_TEAM_ID}`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_VERCEL_TOKEN}`,
      },
    },
  );
};
