import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { Copy, Form } from '#components/index';
import { DeleteTurnFormT } from '#interfaces/components';

const DeleteTurnForm: React.FC<DeleteTurnFormT> = ({
  appointment,
  userId,
  date,
  offices,
  onChange,
}) => {
  return (
    <Formik
      initialValues={{}}
      onSubmit={useSubmit({ appointment, userId, date, offices, onChange })}
    >
      <Form
        submitTitle={appointment.dont_give ? 'Aceptar' : 'Eliminar'}
        ignoreDirty
      >
        <section>
          {appointment.dont_give ? (
            <Copy>¿Querés volver a habilitar este turno?</Copy>
          ) : (
            <Copy>
              Estás por eliminar el turno y esta acción es irreversible, el
              turno se eliminará definitivamente.
            </Copy>
          )}
        </section>
      </Form>
    </Formik>
  );
};

export default DeleteTurnForm;
