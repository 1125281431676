import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOSPre, getPayments } from '../../services';

const initialSteps: { id: string; name: string; status: string }[] = [
  {
    id: '01',
    name: 'Datos del presupuesto',
    status: 'current',
  },
  {
    id: '02',
    name: 'Datos de O.S y prestación',
    status: 'upcoming',
  },
  {
    id: '03',
    name: 'Datos de pago',
    status: 'upcoming',
  },
  {
    id: '04',
    name: 'Resumen presupuesto',
    status: 'upcoming',
  },
];

const initialState = {
  selected: null,
  currentPosition: 0,
  lastPosition: 0,
  stepsBar: initialSteps,
  planBenefits: null,
  benefit: null,
  openSection: 'patient-data',
  payments: [],
};
export const setPlanBenefits = createAsyncThunk(
  'budgets/setPlanBenefits',
  async (idPlan: number) => {
    try {
      const { prestaciones } = await getOSPre('OSPRE', idPlan);

      return { prestaciones };
    } catch (err: any) {
      let message = 'Unknown Error';
      if (err instanceof Error) message = err.message;

      throw new Error(message);
    }
  },
);
export const getBudgetPayments = createAsyncThunk(
  'budgets/getBudgetPayments',
  async (paymentId: number) => {
    try {
      const {
        data: { budgetPayments },
      } = await getPayments(paymentId);

      return { budgetPayments };
    } catch (err: any) {
      let message = 'Unknown Error';
      if (err instanceof Error) message = err.message;

      throw new Error(message);
    }
  },
);

export const budgetsSlice = createSlice({
  name: 'budgets',
  initialState,
  reducers: {
    setBudgetSelected: (state, action) => {
      state.selected = action.payload;
    },
    openSectionInBudget: (state, action) => {
      state.openSection = action.payload;
    },
    resetBudgetSelected: (state, _action) => {
      state.selected = null;
    },
    setTabIndex(state, action) {
      state.currentPosition = action.payload;
    },
    setStepsBar(state, action) {
      state.stepsBar = action.payload;
    },
    insertBenefit(state, action) {
      state.benefit = action.payload;
    },
    goToNextStep(state) {
      state.lastPosition = state.currentPosition;
      state.currentPosition = state.currentPosition + 1;
      state.stepsBar[state.lastPosition].status = 'complete';
      state.stepsBar[state.currentPosition].status = 'current';
    },
    updateProgressBar(state, action) {
      const position = action.payload;

      if (state.currentPosition === position) return;

      state.lastPosition = state.currentPosition;
      state.currentPosition = position;
      state.stepsBar[position].status = 'current';
      state.stepsBar[state.lastPosition].status = 'complete';
    },
    completeProgressBar(state) {
      state.lastPosition = state.currentPosition;
      state.currentPosition = 3;
      state.stepsBar.forEach((step) => {
        step.status = 'complete';
      });
      state.stepsBar[state.stepsBar.length - 1].status = 'current';
    },
    resetProgressBar(state) {
      state.currentPosition = 0;
      state.stepsBar = initialSteps;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setPlanBenefits.fulfilled, (state, action) => {
      const { prestaciones } = action.payload;
      state.planBenefits = prestaciones;
    });
    builder.addCase(getBudgetPayments.fulfilled, (state, action) => {
      const { budgetPayments } = action.payload;
      state.payments = budgetPayments;
    });
  },
});

export const {
  setBudgetSelected,
  resetBudgetSelected,
  setTabIndex,
  setStepsBar,
  insertBenefit,
  goToNextStep,
  updateProgressBar,
  resetProgressBar,
  completeProgressBar,
  openSectionInBudget,
} = budgetsSlice.actions;

export default budgetsSlice.reducer;
