import React from 'react';
import { updateAbsencesConfig } from '../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../context';
import { FormikHelpers } from 'formik';

export function useSubmit(
  absence: { [key: string]: any },
  selectedProf: { [key: string]: any },
  onChange: () => void,
) {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    _spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(absence, selectedProf);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Ausencia eliminada correctamente');
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(
  absence: { [key: string]: any },
  selectedProf: { [key: string]: any },
) {
  const apiSpec = transformSpec(absence, selectedProf);

  return updateAbsencesConfig(apiSpec);
}

function transformSpec(
  absence: { [key: string]: any },
  selectedProf: { [key: string]: any },
) {
  const { id_prof, nombre } = selectedProf;

  let apiSpec = {
    id_prof: id_prof,
    nombre: nombre,
    opcion: 'eliminar',
    prof_ausencias: [
      {
        id_feriado: absence.id,
        id_prof: id_prof,
        dia: absence.day_num === 7 ? 0 : absence.day_num,
        desde: absence.from,
        hasta: absence.to,
        rowversion: absence.rowversion,
      },
    ],
  };

  return apiSpec;
}
