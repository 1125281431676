export const taxesIndentifications: { [key: string]: string } = {
  arg: 'CUIT',
  bol: 'NIT',
  chl: 'RUT',
  col: 'NIT',
  ecu: 'RUC',
  esp: 'NIF/CIF',
  pry: 'RUC',
  per: 'RUC',
  ury: 'RUT',
  ven: 'RIF',
  all: 'CUIT',
};
