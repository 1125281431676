import React from 'react';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { Error } from '../../components';
import { useFieldProps, useOnChange } from '../../hooks';
import { SwitchFormT } from '#interfaces/components';

export const SwitchForm: React.FC<SwitchFormT> = ({
  name,
  className,
  disabled,
  showError = true,
}) => {
  const { field, setFieldValue } = useFieldProps({ name });
  const {
    field: { value: dateValue },
  } = useFieldProps({ name: 'date' });
  const onChange = useOnChange({ name });

  React.useEffect(() => {
    setFieldValue(false);
  }, [dateValue]);

  const handleChange = (e: { [key: string]: any }) => {
    onChange(e.checked);
  };

  return (
    <>
      <SwitchComponent
        name={name}
        checked={field.value}
        disabled={disabled}
        cssClass={`${className}`}
        change={handleChange}
      />
      {showError && <Error name={name} />}
    </>
  );
};
