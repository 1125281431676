import React from 'react';
import { DropdownForm } from '../../../../../../components';
import { useFieldProps } from '../../../../../../hooks';
import { ControllercheckBox } from './checkbox';
import { MedicalInsurancesT } from '#interfaces/general-values';

export const Controller: React.FC<{
  name: string;
  showenabledFieldName: string;
  medicalInsurances: MedicalInsurancesT[];
  setMedical: (value: MedicalInsurancesT | undefined) => any;
  translations: { [index: string]: any };
  handleOnChange: () => any;
}> = ({
  name,
  showenabledFieldName,
  medicalInsurances,
  setMedical,
  translations,
  handleOnChange,
}) => {
  const {
    field: { value: enabled },
  } = useFieldProps({ name: showenabledFieldName });
  const {
    field: { value: medicalInsurance },
  } = useFieldProps({ name: 'medical_insurance' });

  const medInsToShow = React.useMemo(() => {
    if (enabled)
      return medicalInsurances?.filter(
        (el: { [index: string]: any }) => !el.inhabilitada,
      );
    return medicalInsurances;
  }, [enabled, medicalInsurances]);

  const current = React.useMemo(
    () =>
      medicalInsurances.find(
        (p: { [index: string]: any }) => p.id_os === medicalInsurance,
      ),
    [medicalInsurances, medicalInsurance],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => setMedical(current), [current]);

  return (
    <>
      <DropdownForm
        name={name}
        data={medInsToShow}
        label={`Seleccionar ${translations?.medical_insurance?.toLowerCase()}`}
        keyId="id_os"
        keyData="obra_social"
      />
      <ControllercheckBox
        description={`Muestra solo ${
          translations?.medical_insurances === 'Obras sociales'
            ? 'las habilitadas'
            : 'los habilitados'
        }`}
        label={translations.see_only}
        name={showenabledFieldName}
        handleOnChange={handleOnChange}
      />
    </>
  );
};
