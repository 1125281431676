import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useForm } from './use-form';
import { useSubmit } from './use-submit';
import { getMembershipHistory } from '../../../../services';
import { useForm as useFormHook } from '../../../../hooks';
import {
  Form,
  Icons,
  Action,
  TabSection,
  Grid,
  GridWrapper,
  GridRow,
} from '../../../../components';
import { membershipGridMapper } from '../../../../utils';
import { MemberVerificationT } from '#interfaces/components';

export const MemberVerificationForm: React.FC<MemberVerificationT> = ({
  patient,
  medicalInsurances,
  lenderCode,
  selectedMedIns,
}) => {
  const [history, setHistory] = useState([]);
  const medicalIsurance = medicalInsurances.find(
    (m) => m.id_os === selectedMedIns,
  );
  const { initialValues } = useForm({ patient, medicalIsurance, lenderCode });
  const {
    isOpenForm: isOpenTabAction,
    openForm: openTabAction,
    closeForm: closeTabAction,
  } = useFormHook();

  const renderItems = (onSubmit: () => void) => [
    {
      name: 'Verificación Online',
      description: 'Verificamos si el paciente cuenta con su membresía al día.',
      icon: <Icons type="globe" className="h-6 w-6" aria-hidden="true" />,
      action: 'Realizar verificación',
      onClick: onSubmit,
    },
    {
      name: 'Ver historial de chequeos',
      description: 'Te listamos el historial de los chequeos de este paciente.',
      icon: <Icons type="queue" className="h-6 w-6" aria-hidden="true" />,
      action: 'Ver historial',
      onClick: () => openTabAction(),
    },
  ];

  useEffect(() => {
    async function getHistory() {
      try {
        const {
          data: { membershipCheckList },
        } = await getMembershipHistory(patient.id_paciente);
        setHistory(membershipCheckList);
      } catch (err: any) {
        throw new Error(err);
      }
    }
    getHistory();
  }, [patient.id_paciente]);

  return (
    <Formik initialValues={initialValues} onSubmit={useSubmit()}>
      {({ submitForm }) => (
        <>
          {!isOpenTabAction && (
            <Form showActions={false} ignoreDirty>
              <div className="grid grid-cols-2 mt-10 gap-5">
                {renderItems(submitForm).map((item, index) => (
                  <Action key={index} item={item} onClick={item.onClick} />
                ))}
              </div>
            </Form>
          )}
          {isOpenTabAction && (
            <TabSection
              isOpen={isOpenTabAction}
              close={closeTabAction}
              open={openTabAction}
              className="mt-5"
            >
              {history.length > 0 ? (
                <Grid height="300px">
                  <GridWrapper data={membershipGridMapper(history)}>
                    <GridRow name="fecha" field="Fecha" />
                    <GridRow name="nro_afiliado" field="Nro. Afiliado" />
                    <GridRow name="habilitado" field="Respuesta" />
                    <GridRow name="motivo" field="Leyenda" />
                  </GridWrapper>
                </Grid>
              ) : (
                <p>No hay historial para mostrar</p>
              )}
            </TabSection>
          )}
        </>
      )}
    </Formik>
  );
};
