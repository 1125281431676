import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getDataAccount, getRegions, getDataProducts } from '../../services';

const initialState = {
  account: {},
  regions: [],
  products: [],
};

export const getAccountStats = createAsyncThunk(
  'account-manager/getAccountStats',
  async () => {
    try {
      const {
        data: { account },
      } = await getDataAccount();
      const {
        data: { listaRegionesWapp: regions },
      } = await getRegions();
      const {
        data: { products },
      } = await getDataProducts();

      return { account, regions, products };
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const accountSlice = createSlice({
  name: 'account-manager',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccountStats.fulfilled, (state, action) => {
      const { account, products, regions } = action.payload;
      state.account = account;
      state.products = products;
      state.regions = regions;
    });
  },
});

export default accountSlice.reducer;
