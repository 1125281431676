import React from 'react';
import classNames from 'classnames';
import { Title } from '../../../../components';
import { useFieldProps } from '../../../../hooks';
import { makeBudgetResumeSections } from '../../../../utils';

export const CardHeader = ({
  professionalName,
}: {
  professionalName: string;
}) => {
  const {
    field: { value: budgetTotalAmount },
  } = useFieldProps({ name: 'budget_total_amount' });
  const {
    field: { value: budgetBalance },
  } = useFieldProps({ name: 'budget_balance' });
  const {
    field: { value: idBudget },
  } = useFieldProps({ name: 'id_budget' });
  const {
    field: { value: descriptionValue },
  } = useFieldProps({ name: 'description' });
  const sections = makeBudgetResumeSections({
    budgetTotalAmount,
    professionalName,
    idBudget,
    budgetBalance,
  });
  const sectionCss = classNames(
    'p-4 py-8 border border-slate-200',
    !!idBudget ? 'col-span-3' : 'col-span-6',
  );

  return (
    <div className="grid grid-cols-12 shadow-md rounded-md bg-white">
      <Title className="col-span-12 border-b border-b-slate-300 p-5 text-2xl rounded-t-md bg-white truncate">
        {descriptionValue}
      </Title>
      {sections.map((s, i) => {
        return (
          <div key={i} className={sectionCss}>
            <h3 className="text-slate-500 text-base pb-3">{s.title}</h3>
            <span className={s.styles}>{s.value}</span>
          </div>
        );
      })}
    </div>
  );
};
