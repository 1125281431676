import React from 'react';
import { RemindersGrid } from '../../../components';
import { remindersGridMapper } from '../../../utils';
import { useFieldProps } from '../../../hooks';
import { AgendaT } from '#interfaces/general-values';

export const DailyReminders: React.FC<{ reminders: AgendaT[] }> = ({
  reminders,
}) => {
  const {
    field: { value: profId },
  } = useFieldProps({ name: 'professional' });

  return (
    <RemindersGrid
      data={remindersGridMapper(reminders, profId)}
      fieldName="turns_to_send"
      switchFieldName="send_all"
      keyId="id_schedule"
      titlesGrids={[
        { title: 'Hora', key: 'hour', className: '' },
        { title: 'Paciente', key: 'patient', className: 'xl' },
        { title: 'Profesional', key: 'professional', className: 'xl' },
        { title: 'Teléfono', key: 'phone', className: 'xl' },
      ]}
    />
  );
};
