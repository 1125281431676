import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from '#src/redux';
import { Header } from './card-header';
import { SummarySteps } from './card-summary';
import { Title, Copy, Button } from '../../../..';
import { useFieldProps } from '../../../../../hooks';
import { nameFormatter } from '../../../../../utils';
import { FinanciersT, RecipeT } from '#interfaces/components/forms';
import { OfficesT, ProfessionalT } from '#interfaces/general-values';

export const FourthStep: React.FC<{
  data: {
    financiers: FinanciersT[];
    offices: OfficesT[];
    professionals: ProfessionalT[];
    recipe: RecipeT;
  };
  onClick: () => void;
  viewLoadMedicine: {
    hiddenLoadMedicine: boolean;
    setHiddenLoadMedicine: React.Dispatch<React.SetStateAction<boolean>>;
  };
  handlerClickProgressBar: (value: number) => void;
  onSubmit: (data: FormData) => void;
}> = ({
  handlerClickProgressBar,
  data: { recipe, professionals, offices, financiers },
  viewLoadMedicine,
  onSubmit,
}) => {
  const {
    login: {
      user: { nombre },
    },
  } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const {
    field: { value: idProf },
  } = useFieldProps({ name: 'professional' });
  const {
    field: { value: idOffice },
  } = useFieldProps({ name: 'office' });
  const {
    field: { value: dateRecipe },
  } = useFieldProps({ name: 'date_recipe' });
  const {
    field: { value: plan },
  } = useFieldProps({ name: 'type_of_plan' });
  const {
    field: { value: financierId },
  } = useFieldProps({ name: 'id_financier' });
  const {
    field: { value: affiliateNumber },
  } = useFieldProps({ name: 'patient.affiliate_number' });
  const {
    field: { value: diagnosisGeneral },
  } = useFieldProps({ name: 'diagnosis_general' });
  const {
    field: { value: medicineList },
    setFieldValue: setListMedicineValue,
  } = useFieldProps({ name: 'medicines_list' });
  const { setFieldValue: setMedicine } = useFieldProps({
    name: 'medicine_from_list',
  });
  const professionalName: string =
    idProf && professionals.length > 0
      ? nameFormatter(
          professionals?.find((p: any) => p.id_prof === idProf)?.nombre || '',
        )
      : '-';
  const officeName =
    idOffice &&
    offices.length > 0 &&
    offices?.find((o: any) => o.id_sucursal === idOffice)?.sucursal;
  const medInsName =
    financierId &&
    financiers.length > 0 &&
    financiers?.find((f: any) => f.financierId === financierId)?.name;
  const dataSummary = {
    header: {
      professionalName,
      dateRecipe,
      handlerClickProgressBar,
      recipe,
      officeName,
      plan,
      affiliateNumber,
      medInsName,
    },
    summarySteps: {
      viewLoadMedicine,
      medicineList,
      setListMedicineValue,
      setMedicine,
      diagnosisGeneral,
      userName: nombre,
      recipe,
    },
  };

  return (
    <>
      <Title className={classNames('text-lg mt-6 mb-2', { hidden: recipe })}>
        Detalles de recetas
      </Title>
      <Copy className="mb-4">
        {recipe
          ? 'Vea los detalles de la prescripción. Los datos no podrán ser editados porque la receta ya fue generada.'
          : 'Vea los detalles de la prescripción y edite de ser necesario.'}
      </Copy>
      {[Header, SummarySteps].map((Component: any) => {
        return (
          <Component
            data={dataSummary}
            handlerClickProgressBar={handlerClickProgressBar}
          />
        );
      })}
      <div className="flex justify-start mt-12 mb-5 gap-5 print:hidden">
        {recipe && (
          <Button
            size="large"
            modifier="transparent"
            onClick={() => window.open(recipe.fileLink, '_blank')}
          >
            Descargar PDF
          </Button>
        )}
        {!recipe && (
          <Button
            onClick={() => navigate(-1)}
            size="large"
            className="flex justify-center w-max px-10 h-10 bg-transparent !text-red-500 hover:bg-red-500 hover:!text-white border border-red-500"
          >
            Cancelar
          </Button>
        )}
        <Button size="large" onClick={recipe ? () => navigate(-1) : onSubmit}>
          {recipe ? 'Volver' : 'Generar receta'}
        </Button>
      </div>
    </>
  );
};
