import React from 'react';
import { GeneralForm } from './GeneralForm';
import { UsersForm } from './UsersForm';
import { SmsForm } from './SmsForm';
import { ExtrasForm } from './ExtrasForm';
import { WhatsappForm } from './WhatsappForm';
import { TabSection, Icons } from '#components/index';
import {
  Card,
  CardFooter,
  CardDescription,
  CardHeader,
  CardTitle,
  Button,
} from '#components/shadcn';
import { useForm, useSetHeight } from '../../../../hooks';
import { ParamsT } from '#interfaces/components';

export const Params: React.FC<ParamsT> = ({
  params,
  templates,
  users,
  offices,
  permissions,
  mappedPermissions,
  userName,
  userId,
  professionals,
  regions,
  planVersion,
  onChange,
  region,
}) => {
  const {
    isOpenForm: isOpenGeneral,
    closeForm: closeGeneral,
    openForm: openGeneral,
  } = useForm();
  const {
    isOpenForm: isOpenSms,
    closeForm: closeSms,
    openForm: openSms,
  } = useForm();
  const {
    isOpenForm: isOpenExtras,
    closeForm: closeExtras,
    openForm: openExtras,
  } = useForm();
  const {
    isOpenForm: isOpenWhatsapp,
    closeForm: closeWhatsapp,
    openForm: openWhatsapp,
  } = useForm();
  const {
    isOpenForm: isOpenUsers,
    closeForm: closeUsers,
    openForm: openUsers,
  } = useForm();

  React.useEffect(() => {
    onChange('params');
    onChange('permissions');
    onChange('users');
    onChange('regions');
  }, [onChange]);
  const height = useSetHeight(180);
  const onChangeHandler = () => onChange('params');

  const hideBlock =
    !isOpenGeneral &&
    !isOpenSms &&
    !isOpenExtras &&
    !isOpenUsers &&
    !isOpenWhatsapp;

  return (
    <section
      className="overflow-hidden overflow-y-scroll"
      style={{ height: `${height}px` }}
    >
      {hideBlock && (
        <div className="grid grid-cols-3 gap-5">
          <Card>
            <CardHeader>
              <Icons className="h-6 w-6 mb-2 text-blue-500" type="building" />
              <CardTitle className="flex items-center text-xl">
                Datos generales
              </CardTitle>
              <CardDescription>
                En esta sección vas a poder editar todos los datos de tu clínica
                o consultorio.
              </CardDescription>
            </CardHeader>
            <CardFooter>
              <Button
                onClick={openGeneral}
                className="text-sm w-full flex gap-x-2 px-4 py-1 border bg-transparent !text-blue-500 border-blue-500 hover:bg-blue-500 hover:!text-white"
              >
                Ver más
              </Button>
            </CardFooter>
          </Card>
          {region === 'arg' && (
            <Card>
              <CardHeader>
                <Icons className="h-6 w-6 mb-2 text-blue-500" type="sms" />
                <CardTitle className="flex items-center text-xl">
                  Datos SMS
                </CardTitle>
                <CardDescription>
                  Revisá el template que vas a usar para los envios de SMS en
                  esta sección.
                </CardDescription>
              </CardHeader>
              <CardFooter>
                <Button
                  onClick={openSms}
                  className="text-sm w-full flex gap-x-2 px-4 py-1 border bg-transparent !text-blue-500 border-blue-500 hover:bg-blue-500 hover:!text-white"
                >
                  Ver más
                </Button>
              </CardFooter>
            </Card>
          )}
          <Card>
            <CardHeader>
              <Icons className="h-6 w-6 mb-2 text-blue-500" type="knob" />
              <CardTitle className="flex items-center text-xl">
                Varios
              </CardTitle>
              <CardDescription>
                Numeración de historias clínicas y algunas configuraciones de la
                agenda.
              </CardDescription>
            </CardHeader>
            <CardFooter>
              <Button
                onClick={openExtras}
                className="text-sm w-full flex gap-x-2 px-4 py-1 border bg-transparent !text-blue-500 border-blue-500 hover:bg-blue-500 hover:!text-white"
              >
                Ver más
              </Button>
            </CardFooter>
          </Card>
          <Card>
            <CardHeader>
              <Icons className="h-6 w-6 mb-2 text-blue-500" type="user" />
              <CardTitle className="flex items-center text-xl">
                Usuarios
              </CardTitle>
              <CardDescription>
                Gestioná todos los usuarios que tienen acceso a la app desde
                esta sección.
              </CardDescription>
            </CardHeader>
            <CardFooter>
              <Button
                onClick={openUsers}
                className="text-sm w-full flex gap-x-2 px-4 py-1 border bg-transparent !text-blue-500 border-blue-500 hover:bg-blue-500 hover:!text-white"
              >
                Ver más
              </Button>
            </CardFooter>
          </Card>
          <Card>
            <CardHeader>
              <Icons
                className="h-6 w-6 mb-2 text-blue-500"
                fill="#4485F3"
                type="whatsapp"
              />
              <CardTitle className="flex items-center text-xl">
                Datos WhatsApp
              </CardTitle>
              <CardDescription>
                Configurá los envíos de recordatorios de WhatsApp en esta
                sección.
              </CardDescription>
            </CardHeader>
            <CardFooter>
              <Button
                onClick={openWhatsapp}
                className="text-sm w-full flex gap-x-2 px-4 py-1 border bg-transparent !text-blue-500 border-blue-500 hover:bg-blue-500 hover:!text-white"
              >
                Ver más
              </Button>
            </CardFooter>
          </Card>
        </div>
      )}
      <TabSection
        title="Datos generales"
        isOpen={isOpenGeneral}
        close={closeGeneral}
        open={openGeneral}
      >
        <GeneralForm
          params={params}
          regions={regions}
          onChange={onChangeHandler}
        />
      </TabSection>
      <TabSection
        title="Datos SMS"
        isOpen={isOpenSms}
        close={closeSms}
        open={openSms}
      >
        <SmsForm params={params} onChange={onChangeHandler} />
      </TabSection>
      <TabSection
        title="Varios"
        isOpen={isOpenExtras}
        close={closeExtras}
        open={openExtras}
      >
        <ExtrasForm params={params} onChange={onChangeHandler} />
      </TabSection>
      <TabSection
        title="Datos Whatsapp"
        isOpen={isOpenWhatsapp}
        close={closeWhatsapp}
        open={openWhatsapp}
      >
        <WhatsappForm
          params={params}
          temps={templates}
          offices={offices}
          onChange={onChangeHandler}
          getTemplates={onChange}
        />
      </TabSection>
      <TabSection
        title="Usuarios"
        isOpen={isOpenUsers}
        close={closeUsers}
        open={openUsers}
      >
        <UsersForm
          userId={userId}
          userName={userName}
          users={users}
          professionals={professionals}
          useUsd={params.doble_moneda}
          offices={offices}
          permissions={permissions}
          mappedPermissions={mappedPermissions}
          planVersion={planVersion}
          onChange={() => onChange('users')}
        />
      </TabSection>
    </section>
  );
};
