import React from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import { FacetedFilter, Filter, Visibility } from './filters';
import { renderTabs } from '../../../utils';

const tabs = [
  {
    text: 'Expandida',
    value: 'expanded',
  },
  {
    text: 'Compacta',
    value: 'compact',
  },
];

export const TableToolbar: React.FC<{ table: any; options: any }> = ({
  table,
  options,
}) => {
  return (
    <div className="flex items-center justify-between mb-4">
      <div className="flex flex-1 items-center space-x-2">
        <div id="schedule-filters-patients">
          <Filter table={table} name="patient_name" />
        </div>
        <div id="schedule-filters-pressentism">
          <FacetedFilter
            title="Ver presentismo"
            name="attendance"
            table={table}
            options={options}
          />
        </div>
      </div>
      <div>
        <div className="flex flex-1 items-center space-x-2">
          <div id="schedule-daily-layouts">
            <Tabs.List
              className="flex h-10 bg-slate-100 p-1 rounded border border-slate-200"
              aria-label="Shcedule layout tabs"
            >
              {renderTabs(tabs, 'data-[state=active]:text-slate-800')}
            </Tabs.List>
          </div>
          <div id="schedule-filters-visibility">
            <Visibility table={table} />
          </div>
        </div>
      </div>
    </div>
  );
};
