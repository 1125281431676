import React from 'react';

export const Dentist: React.FC<{ [key: string]: any }> = (props) => {
  const { dentist } = props;
  return dentist ? (
    <span className="dentist yes">Si</span>
  ) : (
    <span className="dentist no">No</span>
  );
};
