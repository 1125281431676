import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { AppointmentTimeDropdown } from './appoiment-time-dropdown';
import { FormContext } from '../../../../../context';
import { DropdownForm, Form } from '#components/index';
import { Button } from '#components/shadcn';
import { TurnsT } from '#interfaces/general-values';

export const AvailableAppointmentsForm: React.FC<{
  availableAppointments: TurnsT[];
  setAppointment: (value: TurnsT) => void;
  openFirstTimeAppointmentForm: () => void;
  openCreateAppointmentForm: () => void;
  next: any;
}> = ({
  availableAppointments,
  setAppointment,
  openFirstTimeAppointmentForm,
  openCreateAppointmentForm,
  next,
}) => {
  const { closeForm } = React.useContext(FormContext);
  const { initialValues } = useForm();
  const patientTypes = [
    { id: 'new', text: 'Paciente nuevo' },
    { id: 'old', text: 'Paciente existente' },
  ];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={useSubmit({
        availableAppointments,
        setAppointment,
        openFirstTimeAppointmentForm,
        openCreateAppointmentForm,
        closeForm,
      })}
      enableReinitialize
    >
      {({ submitForm, isSubmitting, isValid, resetForm }) => (
        <Form showActions={false}>
          <div className="flex gap-5">
            <AppointmentTimeDropdown
              availableAppointments={availableAppointments}
            />
            <DropdownForm
              name="patient_type"
              data={patientTypes}
              keyId="id"
              keyData="text"
              label="Tipo de paciente"
            />
          </div>
          <div className="mt-8 flex gap-5">
            <Button
              onClick={submitForm}
              disabled={isSubmitting || !isValid}
              className="px-10 h-10 hover:bg-blue-600"
            >
              Confirmar turno
            </Button>
            <Button
              onClick={next}
              className="px-10 h-10 !text-blue-500 hover:!text-blue-600 bg-transparent border border-blue-500 hover:border-blue-600"
              disabled={isSubmitting}
            >
              Buscar siguiente
            </Button>
            <Button
              onClick={() => {
                resetForm();
                closeForm();
              }}
              className="px-10 h-10 bg-red-500 hover:bg-red-600"
              disabled={isSubmitting}
            >
              Cancelar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
