import { axiosInstance, platform } from '../utils';

export const getFinanciers = () => {
  return axiosInstance.get('prescriptions/financiers');
};

export const getMedications = (input: string, pageNumber: number) => {
  return axiosInstance.get(
    `prescriptions/medications/${input}?page=${pageNumber}`,
  );
};

export const getDiagnosis = (input: string) => {
  return axiosInstance.get(`prescriptions/diagnoses/${input}`);
};

export const newRecipe = (data: any) => {
  const body = {
    prescription: data,
    plataforma: platform,
  };

  return axiosInstance.post('prescriptions/new', body);
};

export const acceptTermsAndConditions = () =>
  axiosInstance.post('prescriptions/tyc/W');

export const validatePack = () => axiosInstance.get('prescriptions/pack');
