import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FirstStep, SecondStep, ThirdStep, FourthStep } from './Steps';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { Form, ProgressBar } from '#components/index';
import {
  goToNextStep,
  updateProgressBar,
  resetProgressBar,
} from '../../../../redux/slices';
import { NewBudgetFormT } from '#interfaces/components';

export const NewBudgetForm: React.FC<NewBudgetFormT> = ({
  budget,
  idUser,
  professionals,
  patient,
  offices,
  medicalInsurances,
  isUsd,
  stepsBar,
  currentPosition,
  taxes,
  refresh,
  benefits,
  hiddenView,
  benefit,
  canEdit,
  config,
}) => {
  const dispatch = useDispatch();
  const patientId = patient.id_paciente;
  const idPatientMedIns = patient.id_planNavigation.id_os;
  const idPatientPlan = patient.id_plan;
  const [hiddenLoadBenefit, setHiddenLoadBenefit] = React.useState(false);
  const { initialValues, validate } = useForm({
    budget,
    idUser,
    professionals,
    medicalInsurances,
    patientId,
    offices,
    taxes,
    idPatientMedIns,
    idPatientPlan,
  });
  const handlerClickProgressBar = (value: number) => {
    if (canEdit) {
      stepsBar[value].status !== 'upcoming' &&
        dispatch(updateProgressBar(value));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={useSubmit({ refresh, taxes, budget, config })}
      enableReinitialize
      validate={validate}
    >
      {({ submitForm }) => (
        <Form showActions={false}>
          <Tabs selectedIndex={currentPosition}>
            <TabList className="grid grid-cols-12 border bg-white rounded-lg border-slate-300 mt-4">
              {stepsBar.map(
                (
                  step: {
                    id: number;
                    name: string;
                    status: string;
                  },
                  position: number,
                ) => (
                  <Tab
                    key={position}
                    className="col-span-3"
                    onClick={() => handlerClickProgressBar(position)}
                  >
                    <ProgressBar
                      canEdit={canEdit}
                      step={step}
                      position={position}
                      length={stepsBar.length}
                      data={{ professionals, medicalInsurances }}
                    />
                  </Tab>
                ),
              )}
            </TabList>
            {[FirstStep, SecondStep, ThirdStep, FourthStep].map(
              (Step, position) => (
                <TabPanel key={position}>
                  <Step
                    key={position}
                    data={{
                      professionals,
                      offices,
                      medicalInsurances,
                      taxes,
                      benefits,
                      benefit,
                      budget,
                    }}
                    onClick={() => dispatch(goToNextStep())}
                    onSubmit={submitForm}
                    isUsd={isUsd}
                    config={config}
                    setTabIndex={handlerClickProgressBar}
                    hiddenView={hiddenView}
                    canEdit={canEdit}
                    resetProgressBar={() => dispatch(resetProgressBar())}
                    viewLoadBenefit={{
                      hiddenLoadBenefit,
                      setHiddenLoadBenefit,
                    }}
                  />
                </TabPanel>
              ),
            )}
          </Tabs>
        </Form>
      )}
    </Formik>
  );
};
