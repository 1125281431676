import { useContext } from 'react';
import { FormikHelpers } from 'formik';
import { newOverTurn } from '../../../../services';
import { formatTime, validateDateOverturn } from '../../../../utils';
import { SnackbarContext, FormContext } from '../../../../context';
import { useAppointmentError } from '../../../../hooks';
import { AgendaT } from '#interfaces/general-values';

export function useSubmit(onChange: () => void, schedule: AgendaT[]) {
  const { closeForm } = useContext(FormContext);
  const { showSuccessSnackbar } = useContext(SnackbarContext);
  const { handleAppointmentError } = useAppointmentError(onChange);

  return async (spec: any, formikActions: FormikHelpers<any>) => {
    try {
      await axiosPromise(spec, schedule);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Sobreturno generado con éxito');
      onChange();
    } catch (err: any) {
      handleAppointmentError(err, formikActions);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(spec: any, schedule: AgendaT[]) {
  const apiSpec = transformSpec(spec);
  if (validateDateOverturn(apiSpec, schedule)) {
    throw new Error(
      'No puede cargar un sobreturno en el horario de un turno ya existente',
    );
  }

  return newOverTurn(apiSpec);
}

function transformSpec(spec: any) {
  const hora = `${spec.overturn.hour}:${formatTime(spec.overturn.minutes)}`;

  return {
    hora,
    id_agenda: 0,
    opcion: 'agregarSobreTurno',
    es_sobreturno: true,
    id_prof: spec.id_professional,
    id_paciente: null,
    fecha: spec.date,
    no_dar: false,
    obs: null,
    paciente: null,
    telefono: null,
    asistencia: null,
    dianumero: 0,
    id_usuario: spec.id_user,
    obra_social: null,
    plan_nombre: null,
    nro_afil: null,
    fecha_dado: new Date().toISOString(),
    hora_llegada: null,
    id_sucursal: spec.office,
    rowversion: null,
    sucursalparasms: 0,
  };
}
