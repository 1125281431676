import React from 'react';

export function useForm() {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues(),
  );

  React.useEffect(() => {
    setInitialValues(getInitialValues());
  }, []);

  const validate = React.useCallback((values: any) => {
    const errors: { [key: string]: string } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['webuser', 'user', 'password'];
    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues() {
  const initialValues = {
    webuser: '',
    user: '',
    password: '',
    remember: false,
  };

  return initialValues;
}
