import { useState } from 'react';
import { getDayOfWeek } from './days-of-week';
import { format } from 'date-fns';
import { OfficesT, ProfessionalT } from '#interfaces/general-values';

type UseFormT = {
  offices: OfficesT[];
  professionals: ProfessionalT[];
  current: any;
};

export function useForm({ offices, professionals, current }: UseFormT) {
  const [initialValues] = useState(() =>
    getInitialValues({ offices, professionals, current }),
  );

  return { initialValues };
}

function getInitialValues({ offices, professionals, current }: UseFormT) {
  const date = new Date();
  const office = offices && offices[0];
  const getProfessional = () => {
    if (offices && offices.length) {
      return professionals.find(
        (prof) =>
          prof.prof_horarios.filter((ph: any) => {
            return ph.id_sucursal === office.id_sucursal;
          }).length > 0,
      );
    }

    return professionals.filter((p) => p.habilitado === true)[0];
  };

  const professional = getProfessional();

  const initialValues = {
    date,
    office: office?.id_sucursal ?? null,
    professional: professional?.id_prof,
    last_professional_search: -1,
    filter_professional_by_day: false,
    changeLayout: false,
    layout: 'buscar',
    day_of_week: getDayOfWeek(format(date, 'yyyy/MM/dd')),
    specialty: null,
    criteria: {
      by_professional: true,
      by_specialty: false,
      by_office: true,
      by_offices: false,
    },
    search_by_criteria: false,
    next_available_appointment_counter: 0,
  };

  if (current) {
    return {
      ...initialValues,
      ...current,
      date: current.date,
    };
  }

  return initialValues;
}
