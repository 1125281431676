import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { Controller } from './controller';
import { ControllercheckBoxSelectAll } from './controller-checkbox-select-all';
import { Form, Button, CheckboxForm } from '#components/commons';
import { useSetHeight } from '../../../../../hooks';
import { tableArticlesMapper } from '../../../../../utils';
import { Articles } from '../../../../Tables';
import { ArticlesFormCopyT } from '#interfaces/components/forms';

export const ArticlesFormCopy: React.FC<ArticlesFormCopyT> = ({
  laboratories,
  onChange,
  setLabDestination,
  labOrigin,
  labDestination,
  setLabOrigin,
}) => {
  const labsOriginFieldName = 'laboratory_origin';
  const labsDestinationFieldName = 'laboratory_destination';
  const showEnabledFieldName = 'show_enabled';
  const height = useSetHeight(200);
  const { initialValues } = useForm({
    laboratories,
    labOrigin,
    labDestination,
  });

  const dataOrigin = tableArticlesMapper(
    (labOrigin?.labo_conceptos || []).slice().reverse(),
  );
  const dataDestination = tableArticlesMapper(
    labDestination?.labo_conceptos || [],
  )
    .slice()
    .reverse();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={useSubmit(onChange)}
      enableReinitialize
    >
      {({ submitForm }) => (
        <div
          className="p-10 overflow-hidden overflow-y-scroll"
          style={{ height: `${height}px` }}
        >
          <Form className="max-w-6xl mx-auto" showActions={false}>
            <div className="grid grid-cols-6 mb-10">
              <div className="col-span-3 mb-5 mr-5">
                <Controller
                  name={labsOriginFieldName}
                  showEnabledFieldName={showEnabledFieldName}
                  label="Laboratorio de origen"
                  laboratories={laboratories}
                  onLabChange={setLabOrigin}
                />
              </div>
              <div className="col-span-3 mb-5 ml-5">
                <Controller
                  name={labsDestinationFieldName}
                  showEnabledFieldName={showEnabledFieldName}
                  label="Laboratorio de destino"
                  laboratories={laboratories}
                  onLabChange={setLabDestination}
                />
              </div>
              <div className="col-span-6 grid grid-cols-12 gap-x-10">
                <div className="col-span-6">
                  <ControllercheckBoxSelectAll
                    label="Copiar todos los artículos"
                    description="Al tildar esta opción se seleccionaran todos los artículos del laboratorio de origen."
                    name="select_all"
                    articles={dataOrigin}
                  />
                </div>
                <div className="col-span-6">
                  <CheckboxForm
                    label="Reemplazar datos en destino"
                    description="En el caso que el código del artículo del laboratorio de origen ya exista en el laboratorio de destino."
                    name="replace_destination"
                  />
                </div>
              </div>
              <div className="mt-4 col-span-3 mr-5">
                <Articles data={dataOrigin} action />
              </div>
              <div className="mt-4 col-span-3 ml-5">
                <Articles data={dataDestination} />
              </div>
            </div>
            <Button onClick={submitForm}>Copiar artículos</Button>
          </Form>
        </div>
      )}
    </Formik>
  );
};
