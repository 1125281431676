import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import classNames from 'classnames';
import { Form, ProgressBar } from '#components/index';
import { FirstStep, SecondStep, ThirdStep, FourthStep } from './Steps';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import {
  goToNextStepRecipe,
  updateProgressBarRecipe,
  resetProgressBarRecipe,
} from '../../../../redux/slices';
import { NewRecipeFormT } from '#interfaces/components/forms/recipes';

export const NewRecipeForm: React.FC<NewRecipeFormT> = ({
  recipe,
  financiers,
  professionals,
  offices,
  stepsBar,
  currentPosition,
  patient,
  refresh,
  canEdit,
  params,
}) => {
  const dispatch = useDispatch();
  const [hiddenLoadMedicine, setHiddenLoadMedicine] = React.useState(false);
  const listFinanciers = [
    { financierId: -1, number: '9999', name: 'Sin cobertura' },
    ...financiers,
  ];
  const { initialValues } = useForm({
    patient,
    recipe,
    professionals,
    financiers: listFinanciers,
    offices,
    params,
  });
  const handlerClickProgressBar = (value: number) => {
    if (canEdit) {
      stepsBar[value].status !== 'upcoming' &&
        dispatch(updateProgressBarRecipe(value));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={useSubmit(refresh, professionals, listFinanciers, params)}
      enableReinitialize
    >
      {({ submitForm }) => (
        <Form showActions={false} submitOnEnter={false}>
          <Tabs selectedIndex={currentPosition}>
            <TabList
              className={classNames(
                'grid grid-cols-12 border bg-white rounded-lg border-slate-300 mt-4',
                { hidden: recipe },
              )}
            >
              {stepsBar.map(
                (
                  step: {
                    id: number;
                    name: string;
                    status: string;
                  },
                  position: number,
                ) => (
                  <Tab
                    key={position}
                    className="col-span-3"
                    onClick={() => handlerClickProgressBar(position)}
                  >
                    <ProgressBar
                      canEdit={canEdit}
                      step={step}
                      position={position}
                      length={stepsBar.length}
                      data={{ professionals }}
                    />
                  </Tab>
                ),
              )}
            </TabList>
            {[FirstStep, SecondStep, ThirdStep, FourthStep].map(
              (Step, position) => (
                <TabPanel key={position}>
                  <Step
                    key={position}
                    data={{
                      offices,
                      professionals,
                      patient,
                      recipe,
                      financiers: listFinanciers,
                    }}
                    onClick={() => dispatch(goToNextStepRecipe())}
                    resetProgressBar={() => dispatch(resetProgressBarRecipe())}
                    viewLoadMedicine={{
                      hiddenLoadMedicine,
                      setHiddenLoadMedicine,
                    }}
                    handlerClickProgressBar={handlerClickProgressBar}
                    onSubmit={submitForm}
                  />
                </TabPanel>
              ),
            )}
          </Tabs>
        </Form>
      )}
    </Formik>
  );
};
