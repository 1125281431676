import React from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { SearchPatientForm } from '../../../../components';
import {
  startLoading,
  stopLoading,
  fetchPatient,
} from '../../../../redux/slices';

import './index.scss';
import { NewTurnFormT } from '#interfaces/components';
import { AppDispatch } from '../../../../redux';

const NewTurnForm: React.FC<NewTurnFormT> = ({
  listOfPatients,
  patientData,
  appointment,
  userId,
  date,
  offices,
  onChange,
  isOpen,
  close,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { initialValues } = useForm({
    appointment,
    patientData,
    userId,
    date,
    offices,
  });

  const onSumbitHandler = async (patientId: number, submit: () => void) => {
    dispatch(startLoading());
    await dispatch(fetchPatient({ patientId }));
    submit();
    dispatch(stopLoading());
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={useSubmit({ onChange, alert: patientData.alerta })}
      enableReinitialize
    >
      {({ submitForm }) => (
        <SearchPatientForm
          onSubmit={(id) => onSumbitHandler(id, submitForm)}
          searchResults={listOfPatients}
          isOpen={isOpen}
          close={close}
        />
      )}
    </Formik>
  );
};

export default NewTurnForm;
