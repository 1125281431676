import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { TextFieldForm, Form } from '../../../../../../components';
import { SpecialtyFormT } from '#interfaces/components';

export const SpecialtyForm: React.FC<SpecialtyFormT> = ({
  specialty,
  specialties,
  onChange,
}) => {
  const { initialValues, validate } = useForm(specialty, specialties);

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={useSubmit(onChange, specialties, specialty)}
      enableReinitialize
    >
      <Form ignoreDirty>
        <p className="mb-4 text-base font-regular text-neutral-500">
          Especificá el código y descripción de la nueva especialidad.
        </p>
        <div data-cols="2">
          <TextFieldForm name="code" label="Código" />
          <TextFieldForm name="description" label="Descripción" />
        </div>
      </Form>
    </Formik>
  );
};
