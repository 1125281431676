import React from 'react';
import { DropdownForm, CheckboxForm } from '../../../../components';
import { useFieldProps } from '../../../../hooks';
import { ProfessionalT } from '#interfaces/general-values';
import classNames from 'classnames';

export const ControllerProf: React.FC<{
  professionals: ProfessionalT[];
  fieldNameCheckAllProf: string;
  fieldNameAllMedIns?: string;
  variant?: string;
}> = ({
  professionals,
  fieldNameCheckAllProf,
  fieldNameAllMedIns,
  variant = 'grid',
}) => {
  const showFieldNameProfId = 'id_prof';
  const fieldNameCheckDisabledProf = 'show_disabled_prof';
  const {
    field: { value: checkAllProf },
  } = useFieldProps({ name: fieldNameCheckAllProf });
  const { setFieldValue: setCheckAllMedIns } = useFieldProps({
    name: fieldNameAllMedIns,
  });
  const { setFieldValue: setIdProfValue } = useFieldProps({
    name: showFieldNameProfId,
  });
  const { setFieldValue: setRadioButton } = useFieldProps({
    name: 'group_by_med_ins',
  });
  const {
    field: { value: checkDisabledProf },
  } = useFieldProps({ name: fieldNameCheckDisabledProf });
  const professionalsFilter = checkDisabledProf
    ? professionals
    : professionals?.filter((m) => m.habilitado);
  React.useEffect(() => {
    setIdProfValue(professionalsFilter[0].id_prof);
    if (checkAllProf) {
      setIdProfValue(null);
      setCheckAllMedIns(true);
      setRadioButton({ medical_insurance: true, professional: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAllProf, checkDisabledProf]);
  const variants: {
    [key: string]: { container: string; subcontainer: string };
  } = {
    grid: {
      container: 'grid grid-cols-12 gap-10 mb-6',
      subcontainer: 'col-span-4 self-end',
    },
    flex: {
      container: 'flex flex-col',
      subcontainer: 'p-2.5',
    },
  };

  return (
    <div className={variants[variant].container}>
      <div className={variants[variant].subcontainer}>
        <CheckboxForm
          label="Todos los Profesionales"
          description="Se incluirán todas los profesionales al reporte"
          name={fieldNameCheckAllProf}
          showError={false}
          small
        />
      </div>
      <div className={variants[variant].subcontainer}>
        <DropdownForm
          name={showFieldNameProfId}
          data={professionalsFilter}
          label="Profesionales"
          placeholder="Profesionales"
          keyId="id_prof"
          keyData="nombre"
          disabled={checkAllProf}
        />
      </div>
      <div className={variants[variant].subcontainer}>
        <CheckboxForm
          label="Incluir profesionales deshabilitados"
          description="Se incluirán los profesionales deshabilitados"
          name={fieldNameCheckDisabledProf}
          showError={false}
          small
        />
      </div>
      <div className={classNames('p-2.5', { hidden: variant === 'grid' })}>
        <CheckboxForm
          label="Valores en USD"
          name="calculate_usd"
          showError={false}
          small
        />
        <p className="w-96 px-7 text-sm text-gray-400 font-semibold">
          Los gráficos serán realizados a partir de los datos cargados en USD.
          No se tomará en cuenta los valores en moneda local.
        </p>
      </div>
    </div>
  );
};
