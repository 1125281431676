import React from 'react';
import { NewsT } from '#interfaces/components';
import { MaterialCarousel } from './material-carousel';

export const News: React.FC<NewsT> = ({
  banners,
  planVersion,
  isSupervisor,
}) => (
  <MaterialCarousel
    items={banners}
    version={planVersion}
    isSupervisor={isSupervisor}
  />
);
