import * as React from 'react';
import classNames from 'classnames';
import { ColumnsT } from '#interfaces/tables/benefit';
import { Icons } from '#components/commons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '#components/shadcn';

export const Columns = ({ action }: ColumnsT) => {
  return [
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }: { row: any }) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="relative p-2 hover:cursor-pointer flex-none">
              <Icons
                type="context"
                className="text-gray-500 hover:text-gray-900 h-4 w-4"
              />
              <span className="sr-only">Abrir menu</span>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56" align="start">
            <DropdownMenuGroup>
              <DropdownMenuItem
                className="hover:cursor-pointer"
                onSelect={() => action(row.original, 'edit')}
              >
                <Icons type="edit" className="mr-2 h-4 w-4" />
                <span>Editar</span>
              </DropdownMenuItem>
              <DropdownMenuItem
                className="hover:cursor-pointer text-red-500"
                onSelect={() => action(row.original, 'delete')}
              >
                <Icons type="delete" className="mr-2 h-4 w-4" />
                <span>Eliminar</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
    {
      accessorKey: 'day',
      header: 'Día',
      cell: ({ row }: { row: any }) => {
        const { day } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {day}
          </p>
        );
      },
    },
    {
      accessorKey: 'format_date_from',
      header: 'Fecha desde',
      cell: ({ row }: { row: any }) => {
        const { format_date_from } = row.original;

        return (
          <p className="my-1 text-sm leading-6 text-slate-500">
            {format_date_from}
          </p>
        );
      },
    },
    {
      accessorKey: 'format_date_to',
      header: 'Fecha hasta',
      cell: ({ row }: { row: any }) => {
        const { format_date_to } = row.original;

        return (
          <p className="my-1 text-sm leading-6 text-slate-500">
            {format_date_to}
          </p>
        );
      },
    },
  ];
};
