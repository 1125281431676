import { useEffect, useState } from 'react';

export function useForm(
  codes: {
    lenderCode: string;
    managerCode: string;
  },
  dataBaseName: string,
) {
  const [initialValues, setInitialValues] = useState(
    getInitialValues(codes, dataBaseName),
  );
  useEffect(() => {
    setInitialValues(getInitialValues(codes, dataBaseName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { initialValues };
}

function getInitialValues(
  codes: {
    lenderCode: string;
    managerCode: string;
  },
  dataBaseName: string,
) {
  return {
    medical_insurance_list: [] as string[],
    lender_code: codes.lenderCode,
    manager_code: codes.managerCode,
    data_base_name: dataBaseName,
  };
}
