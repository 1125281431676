import React from 'react';
import { SpecialtiesForm } from './SpecialtiesForm';
import { HolidaysForm } from './HolidaysForm';
import { OfficesForm } from './OfficesForm';
import { useForm, useSetHeight } from '../../../../hooks';
import { TabSection, Icons } from '#components/index';
import {
  Card,
  CardFooter,
  CardDescription,
  CardHeader,
  CardTitle,
  Button,
} from '#components/shadcn';
import { TablesT } from '#interfaces/components';

const Tables: React.FC<TablesT> = ({
  data: { specialties, holidays, offices, translations, planVersion },
  useUsd,
  userUseUsd,
  onChangeRoute,
  onChange,
}) => {
  const {
    isOpenForm: isOpenSpecialties,
    closeForm: closeSpecialties,
    openForm: openSpecialties,
  } = useForm();
  const {
    isOpenForm: isOpenHolidays,
    closeForm: closeHolidays,
    openForm: openHolidays,
  } = useForm();
  const {
    isOpenForm: isOpenOffices,
    closeForm: closeOffices,
    openForm: openOffices,
  } = useForm();
  const height = useSetHeight(180);
  const hideBlock = !isOpenSpecialties && !isOpenHolidays && !isOpenOffices;
  const validationPlan = planVersion === 'small' || planVersion === 'smallprem';

  return (
    <section
      className="overflow-hidden overflow-y-scroll"
      style={{ height: `${height}px` }}
    >
      {hideBlock && (
        <div className="grid grid-cols-3 gap-5">
          <Card>
            <CardHeader>
              <Icons className="h-6 w-6 mb-2 text-blue-500" type="dentist" />
              <CardTitle className="flex items-center text-xl">
                Profesionales
              </CardTitle>
              <CardDescription>
                Dar de alta un nuevo profesional, configurar sus horarios y
                ausencias.
              </CardDescription>
            </CardHeader>
            <CardFooter>
              <Button
                onClick={() => onChangeRoute('/config-professionals')}
                className="text-sm w-full flex gap-x-2 px-4 py-1 border bg-transparent !text-blue-500 border-blue-500 hover:bg-blue-500 hover:!text-white"
              >
                Ver más
              </Button>
            </CardFooter>
          </Card>
          <Card>
            <CardHeader>
              <Icons className="h-6 w-6 mb-2 text-blue-500" type="list" />
              <CardTitle className="flex items-center text-xl">
                {translations.config.med_ins_and_benefits}
              </CardTitle>
              <CardDescription>
                {translations.config.med_ben_text}
              </CardDescription>
            </CardHeader>
            <CardFooter>
              <Button
                onClick={() => onChangeRoute('/config-medical-insurances')}
                className="text-sm w-full flex gap-x-2 px-4 py-1 border bg-transparent !text-blue-500 border-blue-500 hover:bg-blue-500 hover:!text-white"
              >
                Ver más
              </Button>
            </CardFooter>
          </Card>
          {!validationPlan && (
            <Card>
              <CardHeader>
                <Icons className="h-6 w-6 text-blue-500" type="beaker" />
                <CardTitle className="flex items-center text-xl">
                  Laboratorios
                </CardTitle>
                <CardDescription>
                  Dar de alta un nuevo laboratorio, modificar o eliminar sus
                  listas o precios.
                </CardDescription>
              </CardHeader>
              <CardFooter>
                <Button
                  onClick={() => onChangeRoute('/config-laboratories')}
                  className="text-sm w-full flex gap-x-2 px-4 py-1 border bg-transparent !text-blue-500 border-blue-500 hover:bg-blue-500 hover:!text-white"
                >
                  Ver más
                </Button>
              </CardFooter>
            </Card>
          )}
          {!validationPlan && (
            <Card>
              <CardHeader>
                <Icons className="h-6 w-6 text-blue-500" type="specialty" />
                <CardTitle className="flex items-center text-xl">
                  Especialidades
                </CardTitle>
                <CardDescription>
                  Dar de alta una nueva especialidad, modificar o eliminar una
                  especialidad.
                </CardDescription>
              </CardHeader>
              <CardFooter>
                <Button
                  onClick={openSpecialties}
                  className="text-sm w-full flex gap-x-2 px-4 py-1 border bg-transparent !text-blue-500 border-blue-500 hover:bg-blue-500 hover:!text-white"
                >
                  Ver más
                </Button>
              </CardFooter>
            </Card>
          )}
          <Card>
            <CardHeader>
              <Icons className="h-6 w-6 text-blue-500" type="holidays" />
              <CardTitle className="flex items-center text-xl">
                Feriados
              </CardTitle>
              <CardDescription>
                Especificar un nuevo feriado, modificar o eliminar feriados
                existentes.
              </CardDescription>
            </CardHeader>
            <CardFooter>
              <Button
                onClick={openHolidays}
                className="text-sm w-full flex gap-x-2 px-4 py-1 border bg-transparent !text-blue-500 border-blue-500 hover:bg-blue-500 hover:!text-white"
              >
                Ver más
              </Button>
            </CardFooter>
          </Card>
          <Card>
            <CardHeader>
              <Icons className="h-6 w-6 text-blue-500" type="building" />
              <CardTitle className="flex items-center text-xl">
                Sucursales
              </CardTitle>
              <CardDescription>
                Crear y dar de alta una nueva sucursal, modificar o eliminar
                sucursales.
              </CardDescription>
            </CardHeader>
            <CardFooter>
              <Button
                onClick={openOffices}
                className="text-sm w-full flex gap-x-2 px-4 py-1 border bg-transparent !text-blue-500 border-blue-500 hover:bg-blue-500 hover:!text-white"
              >
                Ver más
              </Button>
            </CardFooter>
          </Card>
        </div>
      )}
      <TabSection
        title="Especialidades"
        isOpen={isOpenSpecialties}
        close={closeSpecialties}
        open={openSpecialties}
      >
        <SpecialtiesForm
          specialties={specialties}
          onChange={onChange}
          onClose={closeSpecialties}
        />
      </TabSection>
      <TabSection
        title="Feriados"
        isOpen={isOpenHolidays}
        close={closeHolidays}
        open={openHolidays}
      >
        <HolidaysForm
          holidays={holidays}
          onChange={onChange}
          onClose={closeHolidays}
        />
      </TabSection>
      <TabSection
        title="Sucursales"
        isOpen={isOpenOffices}
        close={closeOffices}
        open={openOffices}
      >
        <OfficesForm
          offices={offices}
          useUsd={useUsd}
          userUseUsd={userUseUsd}
          onChange={onChange}
          onClose={closeOffices}
        />
      </TabSection>
    </section>
  );
};

export default Tables;
