import React from 'react';
import BankMethod from './bank';
import DebitCardMethod from './debit-card';
import CreditCardMethod from './credit-card';
import PaycheckMethod from './pay-check';
import OtherMethod from './other';
import { methods } from '../../../../../mocks';
import { DropdownForm } from '../../../../../components';
import { useFieldProps } from '../../../../../hooks';
import { SnackbarContext } from '../../../../../context';
import { PaymentMethodsT } from '#interfaces/components';

export const PaymentMethods: React.FC<PaymentMethodsT> = ({
  paymentCards,
  paymentBanks,
  eInvoice,
  hasAdminModule,
}) => {
  const { showErrorSnackbar } = React.useContext(SnackbarContext);
  const creditCards = paymentCards.filter((c: any) => c.es_credito);
  const deditCards = paymentCards.filter((c: any) => !c.es_credito);
  const {
    field: { value },
    setFieldValue,
  } = useFieldProps({ name: 'payment_method' });
  const {
    field: { value: bankdId },
    setFieldValue: setBankIdValue,
  } = useFieldProps({ name: 'id_bank' });

  function renderMethods(value: any) {
    const methodComponent: { [key: string]: () => React.ReactNode } = {
      Tarjeta: () => <CreditCardMethod cards={creditCards} />,
      TarjetaD: () => <DebitCardMethod cards={deditCards} />,
      'Transf.': () => <BankMethod banks={paymentBanks} />,
      Cheque: () => <PaycheckMethod />,
      Otro: () => <OtherMethod />,
    };

    if (Object.keys(methodComponent).includes(value)) {
      return methodComponent[value]();
    }
    return null;
  }

  function handleRender() {
    if (!['Efectivo', 'Otro'].includes(value) && !hasAdminModule) {
      setFieldValue('Efectivo');
      return showErrorSnackbar(
        'Ups! Necesita el módulo administración para utilizar otra forma de pago.',
      );
    } else {
      return renderMethods(value);
    }
  }

  React.useMemo(() => {
    ['Transf.'].includes(value) &&
      !bankdId &&
      setBankIdValue(paymentBanks?.[0]?.id_banco || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <div className="flex items-center h-16 col-span-3">
        <DropdownForm
          name="payment_method"
          data={methods}
          label="Forma de pago"
          keyId="id"
          keyData="text"
          disabled={eInvoice && eInvoice}
        />
      </div>
      {value && handleRender()}
    </>
  );
};
