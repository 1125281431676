import React from 'react';
import { useSelector } from 'react-redux';
import { Button, CheckboxForm, TextFieldForm } from '#components/index';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
} from '#components/shadcn';
import { RootState } from '#src/redux';
import { useFieldProps } from '../../../../../hooks';
import { SearchDiagnosis } from '../../LoadMedicine/search-diagnose';

export const ThirdStep: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const {
    recipes: { diagnosis },
  } = useSelector((state: RootState) => state);
  const {
    field: { value: addDiagnosis },
  } = useFieldProps({ name: 'add_diagnosis_general' });
  const {
    field: { value: isDiagnosisManual },
  } = useFieldProps({ name: 'is_diagnosis_manual_general' });
  const { setFieldValue: setFieldDiagnosisValue } = useFieldProps({
    name: 'diagnosis_general',
  });

  React.useMemo(() => {
    !addDiagnosis && setFieldDiagnosisValue({ description: '', code: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDiagnosis]);

  return (
    <Card className="mt-5">
      <CardHeader>
        <CardTitle>Datos adicionales</CardTitle>
        <CardDescription>
          Podrá incluir un diagnóstico general para el tratamiento del paciente.
        </CardDescription>
      </CardHeader>
      <div className="grid grid-cols-12 px-4 gap-5 pb-6">
        <div className="col-span-3">
          <CheckboxForm
            label="Agregar diagnóstico"
            description="Podrá incluir un diagnóstico general."
            name="add_diagnosis_general"
            showError={false}
          />
        </div>
        <div className="col-span-3">
          <CheckboxForm
            label="Diagnóstico manual"
            description="Cargar la información de forma manual."
            name="is_diagnosis_manual_general"
            showError={false}
            disabled={!addDiagnosis}
          />
        </div>
        {isDiagnosisManual ? (
          <TextFieldForm
            label="Diagnóstico"
            name="diagnosis_general.description"
            maxlength={255}
            className="col-span-6"
            placeholder="Escribe aquí el diagnóstico"
          />
        ) : (
          <SearchDiagnosis
            searchResults={diagnosis}
            name="diagnosis_general"
            label="Diagnóstico"
            placeholder="Escribe aquí el diagnóstico"
            disabled={!addDiagnosis}
          />
        )}
        <Button onClick={onClick} size="large">
          Siguiente
        </Button>
      </div>
    </Card>
  );
};
