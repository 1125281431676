import React from 'react';
import classNames from 'classnames';
import { PatientDataOverViewT } from '#interfaces/components';
import { Badge, Icons } from './commons';
import { formateDateToString, checkBalance } from '../utils';
import { HoverCard, HoverCardTrigger, HoverCardContent } from './shadcn';

export const PatientDataOverView: React.FC<PatientDataOverViewT> = ({
  patientData,
  translations,
  countries,
  region,
  viewContactData,
}) => {
  const {
    ape_nom,
    documento,
    hc_nro,
    edad,
    f_nacimiento,
    saldo,
    saldoUSD,
    alerta,
    id_planNavigation,
    email,
    nro_afil,
    tel_celu,
    tipo_doc,
  } = patientData;
  const documentType = translations.doc_types.find((d) => d.id === tipo_doc);
  const plan = id_planNavigation?.nombre || '';
  const medInsuranceName = id_planNavigation?.id_osNavigation.obra_social || '';

  const areaNumber =
    countries?.find((country) => country.iso3 === region?.toUpperCase())
      ?.phoneCode || 54;

  const onClickHandler = () => {
    const url = 'https://wa.me/';
    if (tel_celu) {
      const phone = tel_celu.trim().replace(/\s+/g, '').replace(/-/g, '');

      if (tel_celu.includes(`+${areaNumber}`)) {
        return window.open(`${url}${phone}`, '_blank');
      }
      return window.open(`${url}+${areaNumber}${phone}`, '_blank');
    }
  };

  return (
    <section aria-labelledby="patient-data">
      <h2 id="patient-data" className="sr-only">
        Datos del paciente
      </h2>
      <div className="space-y-8">
        <div className="px-10 py-6">
          <dt className="text-lg flex items-center font-bold text-gray-900 print:text-2xl">
            {alerta && (
              <HoverCard>
                <HoverCardTrigger>
                  <Icons
                    type="alert"
                    className="mr-2 w-6 h-6 text-red-500 hover:text-red-400 cursor-pointer"
                  />
                </HoverCardTrigger>
                <HoverCardContent align="start">
                  <span className="flex items-center">
                    <Icons
                      type="alert"
                      className="mr-1 w-6 h-6 text-red-500 hover:text-red-400 cursor-pointer"
                    />
                    <h3 className="text-md font-bold">Alerta</h3>
                  </span>
                  <p className="text-base mt-2 text-slate-600">{alerta}</p>
                </HoverCardContent>
              </HoverCard>
            )}
            {ape_nom}
            {hc_nro && (
              <Badge className="w-auto ml-3" text={`H.C: ${hc_nro}`} />
            )}
          </dt>
          <div className="mt-5 lg:grid lg:grid-cols-12 lg:gap-x-8">
            <div
              className={classNames('sm:flex border-r border-gray-200', {
                'lg:col-span-3': viewContactData,
                'lg:col-span-4': !viewContactData,
              })}
            >
              <div>
                <dd className="text-slate-700 font-bold">
                  <p className="print:hidden">
                    {`${documentType ? documentType.text : 'DNI'}: `}
                    <span className="text-slate-400 font-regular">
                      {documento}
                    </span>
                  </p>
                  <p>
                    Fecha de nacimiento:{' '}
                    <span className="text-slate-400 font-regular">
                      {formateDateToString(f_nacimiento)}
                    </span>
                  </p>
                  <p>
                    Edad:{' '}
                    <span className="text-slate-400 font-regular">{edad}</span>
                  </p>
                </dd>
              </div>
            </div>
            <div
              className={classNames(
                'sm:flex border-r border-gray-200 print:!hidden',
                {
                  'lg:col-span-3': viewContactData,
                  'lg:col-span-4': !viewContactData,
                },
              )}
            >
              <div>
                <dt className="font-medium text-gray-900">
                  {translations.medical_insurance}
                </dt>
                <dd className="mt-3 text-gray-500">
                  <p>
                    {medInsuranceName} - {plan}
                  </p>
                  <p>N° Afil. {nro_afil}</p>
                </dd>
              </div>
            </div>
            <div
              className={classNames('sm:flex print:!hidden', {
                'lg:col-span-3 border-gray-200 border-r': viewContactData,
                'lg:col-span-4': !viewContactData,
              })}
            >
              <div>
                <dt className="font-medium text-gray-900">Saldo</dt>
                <dd className="mt-3 text-gray-500">
                  <p className={checkBalance(saldo).style}>
                    {translations.money + checkBalance(saldo).text}
                  </p>
                  <p className={checkBalance(saldoUSD).style}>
                    {'U$D' + checkBalance(saldoUSD).text}
                  </p>
                </dd>
              </div>
            </div>
            {viewContactData && (
              <div className="sm:flex lg:col-span-3">
                <div>
                  <dt className="font-medium text-gray-900">Contacto</dt>
                  <dd className="mt-3 text-gray-500">
                    {email && (
                      <a
                        href={`mailto:${email}`}
                        className="block hover:underline hover:cursor-pointer text-blue-500"
                      >
                        {email}
                      </a>
                    )}
                    {tel_celu && (
                      <div className="flex items-center">
                        <p
                          className="hover:cursor-pointer hover:underline"
                          onClick={onClickHandler}
                        >
                          {tel_celu}
                        </p>
                        <Icons
                          type="whatsapp"
                          className="ml-2 w-4 h-4"
                          fill="#25D366"
                        />
                      </div>
                    )}
                  </dd>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
