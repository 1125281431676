import React from 'react';
import classNames from 'classnames';
import { Error } from '..';
import { splitDate } from '../../utils';
import { useOnChange, useFieldProps, useFocused } from '../../hooks';
import { DatePickerT } from '#interfaces/components';

export const DatePicker: React.FC<DatePickerT> = ({
  name,
  label,
  className,
  warning,
  fullWidth,
  disabled,
  minDate = undefined,
  maxDate = undefined,
  showError = true,
}) => {
  const { focus, focusedClass } = useFocused();
  const { field } = useFieldProps({ name });
  const warningClass = classNames(warning && 'warning');
  const classes = classNames(
    'block text-sm font-medium',
    fullWidth && 'full-width',
    disabled && 'disabled',
    className,
  );
  const inputCss = classNames(
    'mt-1.5 relative rounded-md focus:ring-2 focus:ring-blue-500 shadow-sm block w-full px-4 py-2 text-sm ring-1 ring-gray-300',
    disabled && 'bg-gray-100',
  );
  const fieldProps = {
    ...field,
    label: label && label,
    value: field.value && splitDate(field.value),
    onBlur: useOnChange({ name, transform: format }),
  };

  const min = minDate && splitDate(minDate);
  const max = maxDate && splitDate(maxDate);

  return (
    <>
      <label
        id={`${name}-datepicker`}
        className={classes}
        onFocus={focus}
        onBlur={focus}
      >
        <div className="flex flex-col">
          <label className="datepicker-label">{label}</label>
          <input
            tabIndex={parseInt('-1')}
            type="date"
            className={inputCss}
            min={min}
            max={max}
            disabled={disabled}
            {...fieldProps}
          />
          {showError && <Error name={name} />}
        </div>
        <div
          className={`datepicker-control__border ${focusedClass} ${warningClass}`}
        ></div>
      </label>
    </>
  );
};

function format(event: React.ChangeEvent<HTMLInputElement>) {
  const date = event.target.value;
  if (!date) {
    return null;
  }
  const [yearStr, monthStr, dayStr] = event.target.value.split('-');
  const year = parseInt(yearStr, 10);
  const month = parseInt(monthStr, 10);
  const day = parseInt(dayStr, 10);

  return new Date(year, month - 1, day, 12, 0, 0);
}
