import { axiosInstance, platform } from '../utils';

export function validateMembership(data: any) {
  const body = {
    membership: data,
    plataforma: platform,
  };

  return axiosInstance.post('/membership/check', body);
}

export function getMembershipHistory(patientId: number) {
  return axiosInstance.get(`membership/records/${patientId}`);
}
