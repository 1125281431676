import React from 'react';
import { DropdownForm } from '#components/commons';
import { LaboratoryT } from '#interfaces/components/forms';
import { useFieldProps } from '../../../../../hooks';

export const Controller: React.FC<{
  name: string;
  showEnabledFieldName: string;
  laboratories: LaboratoryT[];
  label?: string;
  onLabChange: (value: any) => void;
}> = ({ name, showEnabledFieldName, laboratories, label, onLabChange }) => {
  const {
    field: { value: enabled },
  } = useFieldProps({ name: showEnabledFieldName });
  const {
    field: { value: laboratory },
  } = useFieldProps({ name });

  const labsToShow = React.useMemo(() => {
    if (enabled)
      return laboratories?.filter(
        (el: { [index: string]: any }) => el.habilitado,
      );
    return laboratories || [];
  }, [enabled, laboratories]);

  const current = React.useMemo(
    () =>
      laboratories?.find(
        (m: { [index: string]: any }) => m.id_labo === laboratory,
      ),
    [laboratories, laboratory],
  );

  React.useEffect(() => {
    onLabChange(current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  return (
    <div className="mb-5">
      <DropdownForm
        name={name}
        data={labsToShow}
        className="col-span-4"
        keyId="id_labo"
        keyData="nombre"
        label={label}
      />
    </div>
  );
};
