import { useEffect, useState } from 'react';

export function useForm({ budget }: { budget: { [key: string]: any } }) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues({
      budget,
    }),
  );

  useEffect(() => {
    setInitialValues(
      getInitialValues({
        budget,
      }),
    );
  }, [budget]);

  return { initialValues };
}

function getInitialValues({ budget }: { [key: string]: any }) {
  const benefits =
    budget?.prest_presup?.map((b: { [key: string]: any }) => ({
      id_prestacion: b.id_prestacion,
      is_manual: !b.id_prestacion || true,
      codigo_prest_os: b.id_prestacion
        ? b.id_prestacionNavigation.codigo_prest_os
        : null,
      id_prest_presu: b.id_prest_presu,
      descripcion: b.id_prestacion
        ? b.id_prestacionNavigation.descripcion
        : b.descripcion,
      importe_pac: b.importe_pac,
      cant: b.cant,
      pieza: b.pieza,
      imp_prest: b.imp_prest,
    })) || [];

  return {
    total_amount: budget?.importe_total || 0,
    initial_payment: budget?.pago_inicial || 0,
    benefits_list: benefits,
  };
}
