import React from 'react';
import classNames from 'classnames';
import { useFieldProps, useFocused } from '../../hooks';
import { Error, Icons, Tooltip } from '../';
import { TextFieldWithIconT } from '#interfaces/components';

export const TextFieldWithIcon: React.FC<TextFieldWithIconT> = ({
  name,
  hint = false,
  password,
  placeholder,
  disabled,
  showError = true,
  maxlength,
  refTarget,
  label,
  icon,
  onClickHandler,
  tooltipText,
  actionDisabled = false,
  fill = '#fff',
}) => {
  const { field } = useFieldProps({ name });
  const { focus } = useFocused();
  const iconCss = classNames(
    actionDisabled ? 'text-slate-300' : 'text-blue-500',
  );
  const iconContainerCss = classNames(
    actionDisabled
      ? 'flex-shrink-0 hover:cursor-not-allowed'
      : 'flex-shrink-0 hover:cursor-pointer hover:transform hover:rotate-[10deg] transform-gpu hover:transition-all hover:duration-200',
  );

  return (
    <div onFocus={focus} onBlur={focus} className="text-left">
      <label htmlFor="input" className="mb-[7px] block text-sm font-medium">
        {label}
      </label>
      <div className="inline-flex w-full items-center justify-center bg-white shadow-sm rounded-md px-4 focus:ring-2  focus:ring-blue-500 ring-1 ring-gray-300">
        <input
          {...field}
          className="inline w-full text-sm focus:ring-0 focus:outline-none"
          name={name}
          ref={refTarget}
          placeholder={placeholder}
          value={field.value || ''}
          type={password ? 'password' : 'text'}
          disabled={disabled}
          maxLength={maxlength}
        />
        <Tooltip
          type="dark"
          align="bottom"
          offset={{ top: 2, right: 0 }}
          id={`${icon}-tooltip`}
          text={tooltipText}
        >
          <div className={iconContainerCss} onClick={onClickHandler}>
            <Icons
              className={iconCss}
              width={21}
              height={21}
              type={icon}
              fill={fill}
            />
          </div>
        </Tooltip>
        {hint && <p className="text-sm text-slate-400 mt-2">{hint}</p>}
        {showError && <Error name={name} />}
      </div>
    </div>
  );
};
