import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastAction } from '#components/shadcn';
import { WithAuthT } from '#interfaces/components';
import { useToast } from '../hooks';
import { getUpdatedVersionApp } from '../services';
import pkg from '../../package.json';
import { hardRefresh } from '../utils';
import { SnackbarContext } from '../context';
import { setVersion } from '../redux/slices';

export const WithAuth: React.FC<WithAuthT> = ({ children }) => {
  const {
    commons: { isLogged },
    login: { version },
  } = useSelector((state: any) => state);
  const { showErrorSnackbar } = React.useContext(SnackbarContext);
  let location = useLocation();
  const { toast } = useToast();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchData = async () => {
      if (
        version === pkg.version ||
        //This is to avoid makin the API call to vercel in development enviroment
        ['localhost'].includes(window.location.hostname)
      )
        return;
      try {
        const { data } = await getUpdatedVersionApp();
        data?.value !== pkg.version &&
          toast({
            variant: 'informative',
            title: '✨ ¡Hay una nueva versión de Bilog!',
            description:
              'Te recomendamos actualizar Bilog para contar con los beneficios de la nueva actualización.',
            className: '!w-[600px] !right-[210px]',
            action: (
              <ToastAction
                className="!border !border-blue-800 hover:!bg-blue-800"
                altText="Actualizar"
                onClick={() => {
                  hardRefresh();
                  dispatch(setVersion(data.value));
                }}
              >
                Actualizar
              </ToastAction>
            ),
          });
      } catch (error: any) {
        showErrorSnackbar(error.message);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toast, children]);

  if (!isLogged) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};
