const pry = {
  medical_insurances: 'Seguros médicos',
  medical_insurance: 'Seguro médico',
  medical_insurance_abbreviation: 'S.M.',
  medical_insurance_data: 'Datos del seguro médico',
  see_only: 'Ver solo habilitados',
  cod_mi: 'Cod. S.M.',
  amount_mi: 'Imp SM',
  money: '₲',
  doc_types: [
    { id: 'CI', text: 'Cédula de identidad' },
    { id: 'LC', text: 'Libreta Cívica' },
    { id: 'LE', text: 'Libreta Enrrolamiento' },
    { id: 'PAS', text: 'Pasaporte' },
    { id: 'OTR', text: 'Otro' },
  ],
  config: {
    med_ins_and_benefits: 'Seguros médicos y prestaciones',
    med_ben_text:
      'Dar de alta un nuevo seguro médico, modificar sus planes, grupos y prestaciones.',
    new_med_ins: 'Nuevo seguro médico',
  },
};

export default pry;
