import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
  startLoading,
  stopLoading,
  getFullStatistics,
  setCurrentStatistics,
} from '../../../../redux/slices';
import { SnackbarContext } from '../../../../context';
import { splitDate, groupByCategory } from '../../../../utils';
import { AppDispatch } from '../../../../redux';
import { FormikHelpers } from 'formik';

export function useSubmit() {
  const dispatch: AppDispatch = useDispatch();
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  return async (spec: any, formikActions: FormikHelpers<any>) => {
    try {
      dispatch(startLoading());
      await axiosPromise(spec, dispatch);
      dispatch(setCurrentStatistics({ controlPanelCurrent: spec }));
      showSuccessSnackbar('Estadísticas generadas correctamente');
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
      dispatch(stopLoading());
    }
  };
}

async function axiosPromise(spec: any, dispatch: AppDispatch) {
  const apiSpec = transformSpec(spec);

  return await dispatch(getFullStatistics(apiSpec));
}

function transformSpec(spec: any) {
  const from = splitDate(spec.date_from);
  const to = splitDate(spec.date_to);

  const apiSpec: {
    DateFrom: string;
    DateTo: string;
    GroupDateBy: string | undefined;
    GroupByMedicalInsurances: boolean | undefined;
    MedicalInsurancesDisabled: boolean;
    ProfessionalDisabled: boolean;
    ProfessionalId: number;
    MedicalInsuranceId: number;
    SubsidiaryId: number;
    BenefitCode: string;
  } = {
    DateFrom: from,
    DateTo: to,
    GroupDateBy: groupByCategory(spec.group_date_by),
    GroupByMedicalInsurances: groupByCategory(spec.group_by_med_ins),
    MedicalInsurancesDisabled: spec.show_disabled_med_ins,
    ProfessionalDisabled: spec.show_disabled_prof,
    ProfessionalId: spec.id_prof,
    MedicalInsuranceId: spec.id_med_ins,
    SubsidiaryId: spec.id_subsidiary,
    BenefitCode: spec.benefit_code,
  };
  return apiSpec;
}
