import React from 'react';
import { useFieldProps } from '../../../../../hooks';
import { RadioButton } from '../../../../../components';

export const RadioButtonsByMedIns: React.FC<{
  text: string;
  name: string;
  selfField: string;
  oppositesFields: any[];
}> = ({ text, name, selfField, oppositesFields }) => {
  const fieldName = `${name}.${selfField}`;
  const [opposite] = oppositesFields.map((f, i) => `${name}.${f}`);
  const {
    field: { value },
    setFieldValue,
  } = useFieldProps({ name: fieldName });
  const { setFieldValue: setOpposite } = useFieldProps({ name: opposite });

  const handleOnChange = (e: any) => {
    e.preventDefault();
    setFieldValue(true);
    setOpposite(false);
  };

  return (
    <RadioButton
      label={text}
      name={fieldName}
      checked={value}
      onChange={handleOnChange}
    />
  );
};
