import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useJwt } from 'react-jwt';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { FreemiumT } from '#interfaces/components';

export const Freemium: React.FC<FreemiumT> = ({ onClick }) => {
  const {
    login: { token },
  } = useSelector((state: any) => state);
  const [userData, setUserData] = useState({ user: '', webUser: '' });
  const { decodedToken }: { decodedToken: Record<string, any> | null } =
    useJwt(token);

  useEffect(() => {
    decodedToken &&
      setUserData({ user: decodedToken.User, webUser: decodedToken.WebUser });
  }, [decodedToken]);

  return (
    <div className="background-animate before:absolute before:left-0 before:right-0 before:-z-50 before:h-full before:bg-black transform-gpu before:bg-gradient-to-r from-blue-500 via-purple-500 to-fuchsia-600">
      <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
        <div className="pr-16 sm:px-16 sm:text-center">
          <p className="font-medium text-white">
            <span className="md:hidden">Contratá Premium!</span>
            <span className="hidden md:inline">
              ¡Disfrutá de todas las funcionalidades de Bilog Premium!
            </span>
            <span className="block sm:ml-2 sm:inline-block">
              <a
                href={`https://signup.bilog.com.ar/checkout?email=${userData.user}&webuser=${userData.webUser}`}
                className="font-bold text-white underline"
              >
                Contratar ahora
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </span>
          </p>
        </div>
        <div className="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:items-start sm:pt-1 sm:pr-2">
          <button
            type="button"
            className="flex rounded-md p-2 hover:ring-2 hover:ring-white focus:outline-none focus:ring-2 focus:ring-white"
            onClick={onClick}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};
