import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { Form, Copy } from '#components/index';
import { LaboratoryT } from '#interfaces/components/forms';

export const DeleteLab: React.FC<{
  laboratory: LaboratoryT | undefined;
  onChange: () => void;
}> = ({ laboratory, onChange }) => {
  return (
    <Formik initialValues={{ laboratory }} onSubmit={useSubmit(onChange)}>
      <Form submitTitle="Eliminar" ignoreDirty>
        <Copy>
          Estás por eliminar un laboratorio y esta acción es irreversible, el
          laboratorio se eliminará definitivamente.
        </Copy>
      </Form>
    </Formik>
  );
};
