import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Error } from '../';
import { useFieldProps, useOnChange } from '../../hooks';
import { CheckBoxFormT } from '#interfaces/components';

export const CheckboxForm: React.FC<CheckBoxFormT> = ({
  name,
  className,
  small,
  label,
  disabled,
  description,
  showError = true,
  classNameContainer,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const { field } = useFieldProps({ name });
  const onChange = useOnChange({ name });

  useEffect(() => {
    if (field) {
      setIsChecked(field.value);
    }
  }, [field]);

  const handleChange = (e: any) => {
    onChange(e.target.checked);
  };

  const inputClasses = classNames(
    'border-blue-500 appearance-none h-5 w-5 border rounded-md shadow-sm',
    isChecked ? 'border-blue-500' : 'border-gray-500',
    className,
  );

  const divClasses = classNames(
    'flex',
    disabled && 'opacity-50 cursor-not-allowed pointer-events-none',
    classNameContainer,
  );

  const labelClasses = classNames(
    'ml-2 block font-semibold',
    small ? 'text-sm' : 'text-base',
  );

  return (
    <div className={divClasses}>
      <label className="cursor-pointer relative">
        <input
          type="checkbox"
          className={inputClasses}
          name={name}
          onChange={handleChange}
          checked={field.value}
        />
      </label>
      {label && (
        <label htmlFor="input" className={labelClasses}>
          {label}
          {description && (
            <p className="text-sm font-regular text-gray-400">{description}</p>
          )}
        </label>
      )}
      {showError && <Error name={name} />}
    </div>
  );
};
