import React from 'react';
import { getRemindersSchedules } from '../../../../services';
import { LoadingContext } from '../../../../context';
import { dateToISO, addDefaultProf } from '../../../../utils';
import { FormikHelpers } from 'formik';
import { AgendaT, ProfessionalT } from '#interfaces/general-values';

export function useSubmit(
  onChange: (
    listaAgenda: AgendaT[] | null,
    prof: ProfessionalT[] | [],
    spec: any,
  ) => void,
) {
  const [lastSpec, setLastSpec] = React.useState({});
  const { startLoading, stopLoading } = React.useContext(LoadingContext);

  const onSubmit = async (spec: any, formikActions: FormikHelpers<any>) => {
    const { force_refresh, ...formSpec } = spec;

    if (force_refresh) {
      formikActions.setSubmitting(true);
    } else if (shouldIgnoreSubmit(spec, lastSpec, formSpec)) {
      formikActions.setSubmitting(false);
      return;
    }

    try {
      startLoading();
      const {
        data: { listaAgenda, listaProfesionales },
      }: {
        data: { listaAgenda: any; listaProfesionales: ProfessionalT[] };
      } = await axiosPromise(spec);

      setLastSpec({ ...formSpec, force_refresh: false });
      formikActions.setValues({ ...formSpec, force_refresh: false });

      onChange(listaAgenda, addDefaultProf(listaProfesionales), spec);
      formikActions.setSubmitting(false);
    } catch (err: any) {
      onChange(null, [], spec);
    } finally {
      stopLoading();
    }
  };

  return onSubmit;
}

function axiosPromise(spec: any) {
  const [date] = dateToISO(spec.date).split('T');

  return getRemindersSchedules(date, -1, spec.office);
}

function shouldIgnoreSubmit(
  spec: any,
  lastSpec: { [key: string]: any },
  formSpec: { [key: string]: any },
) {
  return (
    lastSpec.date === formSpec.date &&
    lastSpec.office === formSpec.office &&
    !spec.force_refresh
  );
}
