import React from 'react';
import { useDispatch } from 'react-redux';
import { CheckboxForm, DropdownForm } from '../../../../../components';
import { useFieldProps } from '../../../../../hooks';
import { setSelectedProf } from '../../../../../redux/slices';

export const Controller: React.FC<{
  name: string;
  showenabledFieldName: string;
  professionals: { [key: string]: any };
}> = ({ name, showenabledFieldName, professionals }) => {
  const {
    field: { value: enabled },
  } = useFieldProps({ name: showenabledFieldName });
  const {
    field: { value: professional },
  } = useFieldProps({ name });
  const dispatch = useDispatch();
  const professionalsToShow = React.useMemo(
    () =>
      enabled
        ? professionals?.filter((el: { [key: string]: any }) => el.habilitado)
        : professionals,
    [enabled, professionals],
  );

  React.useEffect(() => {
    dispatch(setSelectedProf(professional));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [professional, professionals]);

  return (
    <>
      <div>
        <DropdownForm
          name={name}
          data={professionalsToShow}
          label="Seleccionar profesional"
          placeholder="Profesionales"
          keyId="id_prof"
          keyData="nombre"
        />
      </div>
      <div>
        <CheckboxForm
          label="Ver solo habilitados"
          description="Muestra solo los profesionales habilitados"
          small
          name={showenabledFieldName}
          showError={false}
        />
      </div>
    </>
  );
};
