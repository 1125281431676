import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { initialWeeklyStats, initialDailyStats } from '../../mocks';
import {
  getNewPatient,
  getAttendans,
  getScheduled,
  getDaily,
  getBanners,
} from '../../services';
import { bannersMapper } from '../../utils';

const initialState: { stats: { [key: string]: any }; banners: any[] } = {
  stats: {
    weekly: [],
    daily: [],
  },
  banners: [],
};

export const getStatistics = createAsyncThunk(
  'home/getStatistics',
  async () => {
    const weekly: any = [];
    const daily: any = [];

    try {
      const {
        data: { listaEstadisticas: statsNewPatients },
      } = await getNewPatient();
      const {
        data: { listaEstadisticas: statsAttendans },
      } = await getAttendans();
      const {
        data: { listaEstadisticas: statsScheduled },
      } = await getScheduled();
      const {
        data: {
          estadisticasDiarias: {
            listaPacientesCumples,
            listaPacientesExistentes,
            listaPacientesNuevos,
            laboratoryStatistics,
          },
        },
      } = await getDaily();
      var today = new Date();
      today.setUTCHours(0, 0, 0, 0);
      const todayLabs = laboratoryStatistics.filter(
        (lab: any) => lab.fecha === today.toISOString(),
      );

      weekly.push(statsNewPatients, statsAttendans, statsScheduled);
      daily.push(
        listaPacientesExistentes,
        listaPacientesNuevos,
        listaPacientesCumples,
        laboratoryStatistics,
      );

      const statsWeekly = initialWeeklyStats.map((s, i) => {
        return {
          ...s,
          data: weekly[i],
        };
      });

      const statsDaily = initialDailyStats.map((s, i) => {
        return {
          ...s,
          data: daily[i],
        };
      });

      return { statsWeekly, statsDaily };
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const getBanner = createAsyncThunk(
  'home/getBanners',
  async (country: string) => {
    try {
      const {
        data: { banners },
      } = await getBanners(country);

      return { banners: bannersMapper(banners) };
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const changeWeeklyStats = createAsyncThunk(
  'home/changeWeeklyStats',
  async (params: { from: Date; to: Date }) => {
    const { from, to } = params;
    const weekly: any = [];

    try {
      const {
        data: { listaEstadisticas: statsNewPatients },
      } = await getNewPatient(from, to);
      const {
        data: { listaEstadisticas: statsAttendans },
      } = await getAttendans(from, to);
      const {
        data: { listaEstadisticas: statsScheduled },
      } = await getScheduled(from, to);

      weekly.push(statsNewPatients, statsAttendans, statsScheduled);

      const statsWeekly = initialWeeklyStats.map((s, i) => {
        return {
          ...s,
          data: weekly[i],
        };
      });

      return { statsWeekly };
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatistics.fulfilled, (state, action) => {
      const { statsWeekly, statsDaily } = action.payload;
      state.stats.weekly = statsWeekly;
      state.stats.daily = statsDaily;
    });
    builder.addCase(changeWeeklyStats.fulfilled, (state, action) => {
      const { statsWeekly } = action.payload;
      state.stats.weekly = statsWeekly;
    });
    builder.addCase(getBanner.fulfilled, (state, action) => {
      const { banners } = action.payload;
      state.banners = banners;
    });
  },
});

export default homeSlice.reducer;
