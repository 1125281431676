export const StepConfig: {
  [key: string]: { initialStep: number; steps: any[] };
} = {
  'supervisor-home-new-user': {
    initialStep: 0,
    steps: [
      {
        title: '¡Hola! 👋',
        intro:
          'Te damos la bienvenida a nuestra app web. Para familiarizarte más rapido, te invitamos a seguir esta breve inducción.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.shortcut-agenda',
        title: 'Acciones rápidas',
        intro:
          'Desde aquí podrás acceder a tu agenda y dar turnos de forma rápida y cómoda. &#8987',
        tooltipClass: 'onboarding',
      },
      {
        element: '.shortcut-new-patient',
        title: 'Acciones rápidas',
        intro:
          '¡Dar de alta a un paciente nunca fue tan fácil! Desde aquí vas a poder cargar los datos de tu paciente de forma rápida. &#8987',
        tooltipClass: 'onboarding',
      },
      {
        element: '.shortcut-search-patient',
        title: 'Acciones rápidas &#9757',
        intro:
          '¿Estás buscando un paciente? Encuéntralo en un solo click. &#8987',
        tooltipClass: 'onboarding',
      },
      {
        element: '.daily-statistics',
        title: 'Estadísticas',
        intro:
          'En la pantalla principal siempre vas a tener disponible tus estadísticas diarias: hecha un vistaso a los nuevos pacientes , turnos agendados y envía un saludo a quienes cumplen años hoy.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.banner-home',
        title: 'Novedades',
        intro: 'Enterate de las últimas novedades que tenemos para ti.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.weekly-statistics',
        title: 'Estadísticas',
        intro:
          'También vas a tener disponibles tus estadísticas semanales para que veas como viene tu negocio.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.date-range-picker',
        title: 'Estadísticas',
        intro:
          'Desde aquí selecciona otra semana para ver su estadística correspondiente.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.menu-container',
        title: 'Menú',
        intro:
          'Este es el menú, desde acá vas a poder acceder a las diferentes partes de la aplicación.',
        tooltipClass: 'onboarding',
      },
      {
        title: '¡Eso es todo!',
        intro:
          'Y de esta forma termina esta inducción, te invitamos a seguir usando la app para maximizar tu negocio. </br> Para mayor informacíon ingresar a nuestro seccíon de ayuda.</br> <a href="https://bilog-solutions.notion.site/Help-de-la-Web-85d6c1fab7444117afe28e47aaf19866" class="text-blue-400 hover:text-blue-500 ">Click Aquí</a>',
        tooltipClass: 'onboarding',
      },
    ],
  },
  'supervisor-home-old-user': {
    initialStep: 0,
    steps: [
      {
        title: '¡Nuevo inicio!',
        intro:
          'Disfruta de la nueva pantalla de inicio. Descubrí todas las novedades siguiendo esta breve introduccíon.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.shortcuts-all',
        title: 'Acciones rápidas',
        intro:
          'Desde aquí podrás acceder a tu agenda y dar turnos de forma rápida y cómoda. &#8987',
        tooltipClass: 'onboarding',
      },
      {
        element: '.daily-statistics',
        title: 'Estadísticas',
        intro:
          'En la pantalla principal siempre vas a tener disponible tus estadísticas diarias: hecha un visto a los nuevos pacientes, turnos agendados y envía un saludo a quienes cumplen años hoy.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.banner-home',
        title: 'Novedades',
        intro: 'Enterate de las últimas novedades que tenemos para ti.',
        tooltipClass: 'onboarding',
      },
      {
        title: '¡Eso es todo!',
        intro:
          'Y de esta forma termina este recorrido, te invitamos a seguir usando la app para maximizar tu negocio. ¡Gracias!',
        tooltipClass: 'onboarding',
      },
    ],
  },
  'home-new-user': {
    initialStep: 0,
    steps: [
      {
        title: '¡Hola! 👋',
        intro:
          'Te damos la bienvenida a nuestra app web. Para familiarizarte más rapido, te invitamos a seguir esta breve inducción.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.daily-statistics',
        title: 'Estadísticas',
        intro:
          'En la pantalla principal siempre vas a tener disponible tus estadísticas diarias: hecha un vistaso a los nuevos pacientes , turnos agendados y envía un saludo a quienes cumplen años hoy.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.banner-home',
        title: 'Novedades',
        intro: 'Enterate de las últimas novedades que tenemos para ti.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.weekly-statistics',
        title: 'Estadísticas',
        intro:
          'También vas a tener disponibles tus estadísticas semanales para que veas como viene tu negocio.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.date-range-picker',
        title: 'Estadísticas',
        intro:
          'Desde aquí selecciona otra semana para ver su estadística correspondiente.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.menu-container',
        title: 'Menú',
        intro:
          'Este es el menú, desde acá vas a poder acceder a las diferentes partes de la aplicación.',
        tooltipClass: 'onboarding',
      },
      {
        title: '¡Eso es todo!',
        intro:
          'Y de esta forma termina esta inducción, te invitamos a seguir usando la app para maximizar tu negocio. </br> Para mayor informacíon ingresar a nuestro seccíon de ayuda.</br> <a href="https://bilog-solutions.notion.site/Help-de-la-Web-85d6c1fab7444117afe28e47aaf19866" class="text-blue-400 hover:text-blue-500 ">Click Aquí</a>',
        tooltipClass: 'onboarding',
      },
    ],
  },
  'home-old-user': {
    initialStep: 0,
    steps: [
      {
        title: '¡Nuevo inicio!',
        intro:
          'Disfruta de la nueva pantalla de inicio. Descubrí todas las novedades siguiendo esta breve introduccíon.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.daily-statistics',
        title: 'Estadísticas',
        intro:
          'En la pantalla principal siempre vas a tener disponible tus estadísticas diarias: hecha un visto a los nuevos pacientes, turnos agendados y envía un saludo a quienes cumplen años hoy.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.banner-home',
        title: 'Novedades',
        intro: 'Enterate de las últimas novedades que tenemos para ti.',
        tooltipClass: 'onboarding',
      },
      {
        title: '¡Eso es todo!',
        intro:
          'Y de esta forma termina este recorrido, te invitamos a seguir usando la app para maximizar tu negocio. ¡Gracias!',
        tooltipClass: 'onboarding',
      },
    ],
  },
  'new-schedule-ui': {
    initialStep: 0,
    steps: [
      {
        title: '🗓️ Mirá la nueva agenda!',
        intro:
          'Rediseñamos la agenda para que puedas dar turnos y gestionar tus tiempos de forma más fácil.',
        tooltipClass: 'onboarding',
      },
      {
        element: '#schedule-view-days',
        title: 'Selección de fechas',
        intro:
          'Desde acá vas a poder cambiar la fecha de la agenda. Navegar entre los días de la semana y cambiar entre la agendia diaria y la semanal.',
        tooltipClass: 'onboarding',
      },
      {
        element: '#schedule-view-wp',
        title: 'Recordatorio de turnos masivo',
        intro:
          'Con nuestra funcionalidad de envio masivo de recordatorio de turnos vas a poder reducir el ausentismo en tu clínica. ¡Probala!',
        tooltipClass: 'onboarding',
      },
      {
        element: '#schedule-view-professionals',
        title: 'Selección de profesional',
        intro:
          'En este panel vas a poder seleccionar entre las diferentes agendas de los profesionales, las especialidades y filtrar por día o sucursal.',
        tooltipClass: 'onboarding',
      },
      {
        element: '#schedule-view-find-appointment',
        title: 'Buscar turno disponible',
        intro:
          'Este botón te va a servir para buscar los siguientes turnos disponibles.',
        tooltipClass: 'onboarding',
      },
      {
        element: '#schedule-filters-patients',
        title: 'Filtrar por paciente',
        intro:
          'Ahora podrás buscar los turnos por nombre y apellido de los pacientes.',
        tooltipClass: 'onboarding',
      },
      {
        element: '#schedule-filters-pressentism',
        title: 'Filtrar por presentismo',
        intro:
          'Y podrás ver los turnos por la asistencia y ver rápidamente los turnos sin confirmar.',
        tooltipClass: 'onboarding',
      },
      {
        element: '#schedule-daily-layouts',
        title: 'Cambiar disposición de agenda',
        intro:
          'Puedes optar por una visibilidad más cómoda con la tabla expandida o ver más información en pantalla con la vista compacta de tu agenda.',
        tooltipClass: 'onboarding',
      },
      {
        element: '#schedule-filters-visibility',
        title: 'Visibilidad de columnas',
        intro:
          'Y para una mayor comodidad, podrás elegir que columnas ver y cuales no de tu agenda.',
        tooltipClass: 'onboarding',
      },
      {
        element: '#schedule-table',
        title: 'Agenda de turnos',
        intro:
          'Y acá encontrarás los turnos del día seleccionado. En los tres puntos encontrarás todas las acciones relacionadas al turno y podrás marcar el presentismo directamente desde la grilla.',
        tooltipClass: 'onboarding',
      },
      {
        title: 'Eso es todo!',
        intro:
          'Es hora de hacer crecer tu negocio! Para mayor información ingresar a nuestro <a href="https://bilog-solutions.notion.site/Help-de-la-Web-85d6c1fab7444117afe28e47aaf19866" class="text-blue-400 hover:text-blue-500">sección de ayuda.</a>',
        tooltipClass: 'onboarding',
      },
    ],
  },
  'schedule-layouts': {
    initialStep: 0,
    steps: [
      {
        element: '#schedule-daily-layouts',
        title: 'Cambiar disposición de agenda',
        intro:
          'Ahora puedes optar por una visibilidad más cómoda con la tabla expandida o ver más información en pantalla con la vista compacta de tu agenda.',
        tooltipClass: 'onboarding',
      },
    ],
  },
  whatsapp: {
    initialStep: 0,
    steps: [
      {
        element: '#whatsapp-tooltip',
        title: 'Recordatorio de turnos 💬',
        intro:
          '¡Reducí el ausentismo con la nueva función de recordatorio de turnos con WhatsApp!',
        tooltipClass: 'onboarding',
      },
      {
        element: '#whatsapp-tooltip',
        title: 'Recordatorio de turnos 💬',
        intro:
          'Si todavía no lo tenés, contractate con Bilog para contratar el servicio.',
        tooltipClass: 'onboarding',
      },
    ],
  },
  reminders: {
    initialStep: 0,
    steps: [
      {
        element: '.reminders-grid',
        title: 'Lista de mensajes a enviar',
        intro:
          'Esta es la grilla de turnos a los que podés enviar el recordatorio de turnos. Podés enviar todos o elegir específicamente cuales mandar.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.whatsapp-list',
        title: 'Ver mensajes enviados',
        intro:
          'Acá vas a ver la lista de los mensajes enviados con sus respuestas, en caso de tenerlas. También vas a poder ver los pacientes que no contestaron y marcar el ausentismo de forma específica.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.send-reminders',
        title: 'Enviar recordatorios',
        intro:
          '¡Con un simple click en este botón, envias los mensajes y listo!',
        tooltipClass: 'onboarding',
      },
    ],
  },
  gallery: {
    initialStep: 0,
    steps: [
      {
        element: '#react-tabs-12',
        title: 'Galería de archivos 🗂️',
        intro:
          'Mirá la nueva sección de Galería. Acá vas a poder subir todos los archivos relacionados a un paciente específico. Probala y llamanos para contratar el servicio de subida de archivos a la nube!',
        tooltipClass: 'onboarding',
      },
    ],
  },
  statistics: {
    initialStep: 0,
    steps: [
      {
        title: '¡Hola!',
        intro:
          'Te damos la bienvenida a la nueva sección en estadísticas: Finanzas',
        tooltipClass: 'onboarding',
      },

      {
        element: '.statistics-tabs',
        title: 'Acceso a las vistas',
        intro:
          'Desde aquí podrás acceder a las distintas vistas y ver todas las estadísticas que necesitas.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.parameters',
        title: 'Parámetros',
        intro:
          'Aquí tendrás a la vista qué parámetros están seleccionados además de ver la fecha. Haciendo click en el botón podrás modificar los parámetros.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.graphics',
        title: 'Descubrí nuevos gráficos',
        intro:
          'Disfruta de nuevos gráficos sobre tus finanzas, facturación total, dinero en caja, facturación por forma de pago, valor de práctica promedio, y mucho más.',
        tooltipClass: 'onboarding',
      },
    ],
  },
  'statistics-parameters': {
    initialStep: 0,
    steps: [
      {
        title: 'Parámetros',
        intro:
          '“Visualiza los parámetros qué están seleccionados y la fecha. Haciendo click en el botón podrás modificar los parámetros.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.date-range-picker',
        title: 'Selector de fecha',
        intro:
          'Ahora podrás seleccionar un rango de fecha predeterminado o personalizado.',
        tooltipClass: 'onboarding',
      },
      {
        element: '.date-range-picker',
        title: 'Selector de fecha',
        intro:
          '¡Compara tus finanzas entre dos rangos de fecha! Desde el selector de fecha podrás seleccionar un rango para comparar tus resultados y verlo plasmado en tus gráficos.',
        tooltipClass: 'onboarding',
      },
    ],
  },
};
