import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { Copy, Form } from '../../../../../../components';
import { DeletePlanFormT } from '#interfaces/components';

export const DeletePlanForm: React.FC<DeletePlanFormT> = ({
  plan,
  selectedMed,
  onChange,
}) => {
  return (
    <Formik
      initialValues={{}}
      onSubmit={useSubmit(plan, selectedMed, onChange)}
    >
      <Form submitTitle="Eliminar" ignoreDirty>
        <Copy>
          Estás por eliminar este plan y esta acción es irreversible, el plan se
          eliminará definitivamente.
        </Copy>
      </Form>
    </Formik>
  );
};
