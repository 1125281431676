import React from 'react';
import { SuccesFull as Empty } from '../../components';
import { tableAppointmentsMapper } from '../../utils';
import { Appointments } from '#components/Tables';
import { emptyStateNotPatient, emptyStateTurns } from '../../mocks';
import { useSetHeight } from '../../hooks';
import { TurnsFormT } from '#interfaces/components/forms';

export const TurnsForm: React.FC<TurnsFormT> = ({
  appointments,
  selectedPatient,
  handlerFocus,
}) => {
  const data = tableAppointmentsMapper([...appointments].reverse());
  const handlerEmpty = {
    dataMocks: !selectedPatient ? emptyStateNotPatient : emptyStateTurns,
    onClick: !selectedPatient ? handlerFocus : undefined,
  };
  const height = useSetHeight(selectedPatient ? 420 : 220);

  return (
    <section className="mt-5" style={{ height: `${height}px` }}>
      {appointments && appointments.length ? (
        <Appointments data={data} />
      ) : (
        <Empty
          typeSucces="emptyState"
          dataMocks={handlerEmpty.dataMocks}
          onClick={handlerEmpty.onClick}
        />
      )}
    </section>
  );
};
