import * as React from 'react';
import classNames from 'classnames';
import { Icons } from '#components/commons';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuGroup,
} from '#components/shadcn';

export const Columns = ({ action, recipe }: any) => {
  const columns = [
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div
                className={classNames(
                  'relative p-2 hover:cursor-pointer flex-none',
                  {
                    'cursor-not-allowed pointer-events-none': recipe,
                  },
                )}
              >
                <Icons
                  type="context"
                  className="text-gray-500 hover:text-gray-900 h-4 w-4"
                />
                <span className="sr-only">Abrir menu</span>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="start">
              <DropdownMenuGroup>
                <DropdownMenuItem
                  className="hover:cursor-pointer"
                  onSelect={() => action(row.original, 'edit')}
                >
                  <Icons type="edit" className="mr-2 h-4 w-4" />
                  <span>Editar</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="hover:cursor-pointer text-red-500"
                  onSelect={() => action(row.original, 'delete')}
                >
                  <Icons type="delete" className="mr-2 h-4 w-4" />
                  <span>Eliminar</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: 'name',
      header: 'Medicamento',
      cell: ({ row }: { row: any }) => {
        const { name, presentation, drugName } = row.original;
        return (
          <div className="text-base font-semibold leading-6 text-slate-900 my-1 hover:cursor-pointer">
            <p>{name}</p>
            <p className="text-slate-500">{drugName}</p>
            <p className="text-slate-500">{presentation}</p>
          </div>
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Cantidad',
      cell: ({ row }: { row: any }) => {
        const { quantity } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {quantity}
          </p>
        );
      },
    },
    {
      accessorKey: 'diagnosis',
      header: 'Diagnostico',
      cell: ({ row }: { row: any }) => {
        const { diagnosis } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {diagnosis}
          </p>
        );
      },
    },
    {
      accessorKey: 'dosage',
      header: 'Tratamiento / Observación',
      cell: ({ row }: { row: any }) => {
        const { dosage, observations } = row.original;
        return (
          <ul className="list-disc">
            <li>
              <p className="font-bold text-sm leading-6 text-gray-500">
                Tx.: {dosage}
              </p>
            </li>
            <li>
              <p className="font-bold text-sm leading-6 text-gray-500">
                Obs.: {observations}
              </p>
            </li>
          </ul>
        );
      },
    },
    {
      accessorKey: 'prolonged_treatment',
      header: 'Tx. Prolongado',
      cell: ({ row }: { row: any }) => {
        const { prolonged_treatment } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            <Icons
              type={prolonged_treatment ? 'check_clean' : 'cancel'}
              className={classNames(
                'w-5 h-5 mx-auto',
                prolonged_treatment ? 'text-green-500' : 'text-slate-500',
              )}
            />
          </p>
        );
      },
    },
  ];

  return columns;
};
