import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ShortCut, SearchPatientForm } from '../components';
import {
  resetPatientsForm,
  fetchPatient,
  startLoading,
  stopLoading,
} from '../redux/slices';
import { useForm } from '../hooks';
import { ShorcutsT } from '#interfaces/components';
import { AppDispatch, RootState } from '../redux';

export const ShortCuts: React.FC<ShorcutsT> = ({ data }) => {
  const [_route, setRoute] = useState<any>(undefined);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpenForm, closeForm, openForm } = useForm();
  const {
    patients: { patientsList: listOfPatients, patient },
  } = useSelector((state: RootState) => state);

  const generateOnClick = (
    type: string,
    route: string,
  ): (() => void | undefined) | undefined => {
    if (type === 'route') {
      return () => {
        if (patient.ape_nom !== '') dispatch(resetPatientsForm());
        return navigate(route);
      };
    }
    if (type === 'modal') {
      return () => {
        setRoute(route);
        openForm();
      };
    }
  };

  const onSumbitHandler = async (id_paciente: number) => {
    dispatch(startLoading());
    await dispatch(fetchPatient({ patientId: id_paciente }));
    dispatch(stopLoading());
    return navigate('/patient-profile');
  };

  return (
    <>
      {data?.map((info: any, i: number) => {
        const { title, description, btnText, route, icon, type, elementStep } =
          info;
        return (
          <div className={elementStep} key={i}>
            <ShortCut
              title={title}
              description={description}
              btnText={btnText}
              onClick={generateOnClick(type, route)}
              icon={icon}
              key={icon}
            />
          </div>
        );
      })}
      <SearchPatientForm
        searchResults={listOfPatients}
        onSubmit={onSumbitHandler}
        isOpen={isOpenForm}
        close={closeForm}
      />
    </>
  );
};
