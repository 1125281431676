import { useContext } from 'react';
import { professionalsConfig } from '../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../context';
import { FormikHelpers } from 'formik';

export function useSubmit(onChange: () => void) {
  const { closeForm } = useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(spec.profesional);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Profesional eliminado correctamente');
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(spec: { [key: string]: any }) {
  const apiSpec = transformSpec(spec);

  return professionalsConfig(apiSpec);
}

function transformSpec(spec: { [key: string]: any }) {
  let apiSpec = {
    ...spec,
    opcion: 'eliminar',
  };
  return apiSpec;
}
