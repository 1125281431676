import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import classNames from 'classnames';
import { numberToCurrency, statisticFormatter } from '../../utils';
import { Title, Icons, Tooltip, EmptyState } from '../../components';
import { SmartGraphicT } from '#interfaces/components';

export const SmartGraphic: React.FC<SmartGraphicT> = ({
  data,
  chartType,
  title,
  subtitle = '100%',
  color,
  label,
}) => {
  const [chart, setChart] = useState(chartType);
  const [chartIcon, setChartIcon] = useState('trending_up');
  const hasData = data?.length > 0;
  const isNotMergedData =
    hasData && !data?.some((e) => e.data && e.data.length > 0);
  const titleCss = classNames('text-2xl max-w-xs', !isNotMergedData && 'mb-6');
  const { series, options, total } = statisticFormatter({
    data,
    chart,
    nameTable: title,
    color,
    label,
  });

  const titleGraph = () => {
    if (total && Number(total) !== 0) {
      if (chartType !== 'donut') {
        return (
          <h4 className="text-blue-500 text-xl mt-3">{`${total} totales`}</h4>
        );
      }
      const totalDonut = data?.reduce(
        (acc, d) => acc + (d.type === 'money' ? d.amount : d.quantity),
        0,
      );
      const formattedTotalDonut = `${
        data.some((d) => d.type === 'money')
          ? numberToCurrency(totalDonut)
          : totalDonut
      }`;

      return (
        <h4 className="text-blue-500 text-xl mt-3">{`${formattedTotalDonut} totales`}</h4>
      );
    }
    <h4 className="text-slate-500 text-base mb-8">{subtitle}</h4>;
  };

  const showChangeChartIcon = chartType !== 'donut' && hasData;

  const changeChartType = () => {
    if (chart === 'line') {
      setChart('bar');
      return setChartIcon('statistics');
    }
    setChart('line');

    return setChartIcon('trending_up');
  };

  return (
    <div className="rounded-md border border-gray-300 h-full p-8 bg-white">
      <div className="flex justify-between">
        <Title className={titleCss}>{title}</Title>
        {showChangeChartIcon && (
          <Tooltip
            type="dark"
            align="bottom"
            className="hover:cursor-pointer"
            text="Cambiar tipo de gráfico"
            offset={{ left: 0 }}
            delay={300}
          >
            <Icons
              className="text-slate-500 hover:text-blue-500"
              width={21}
              height={21}
              type={chartIcon}
              onClick={changeChartType}
            />
          </Tooltip>
        )}
      </div>
      {titleGraph()}
      {hasData ? (
        <Chart series={series} options={options} type={chart} />
      ) : (
        <EmptyState
          title="Ups!"
          copy="No hay datos para mostrar"
          className="my-40"
        />
      )}
      {!isNotMergedData && hasData && (
        <div className="flex align-center justify-around mt-2">
          <div className="flex items-center">
            <div className="w-4 h-4 rounded-full bg-red-500 mr-2"></div>
            <h2 className="text-base">Prestaciones realizadas</h2>
          </div>
          <div className="flex items-center">
            <div className="w-4 h-4 rounded-full bg-purple-500 mr-2"></div>
            <h2 className="text-base">Pagos realizados</h2>
          </div>
        </div>
      )}
    </div>
  );
};
