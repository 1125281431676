import React from 'react';
import { DeleteHcForm } from './DeleteHC';
import { NewClinicHistoryForm } from './NewCH';
import { Button } from '#components/shadcn/button';
import { SuccesFull as Empty, Modal } from '#components/index';
import { useForm, useSetHeight } from '../../../hooks';
import { emptyStateHC, emptyStateNotPatient } from '../../../mocks';
import { ClinicHistoryT } from '#interfaces/components';
import { ClinicRecords } from '#components/Tables';

export const ClinicHistory: React.FC<ClinicHistoryT> = ({
  patient,
  userId,
  professionals = [],
  offices = [],
  onChange,
  handlerFocus,
}) => {
  const [selectedHc, setSelectedHc] = React.useState<any>(null);
  const {
    isOpenForm: isOpenModalNewHC,
    closeForm: closeModalNewHC,
    openForm: openModalNewHC,
  } = useForm();
  const {
    isOpenForm: isOpenModalDeleteHC,
    closeForm: closeModalDeleteHC,
    openForm: openModalDeleteHC,
  } = useForm();
  const clinicRecords = patient?.his_cli;
  const height = useSetHeight(patient?.id_paciente ? 478 : 318);

  const actions = (hc: { [key: string]: string }, action: string) => {
    setSelectedHc(hc);
    action === 'delete' ? openModalDeleteHC() : openModalNewHC();
  };
  const closeModal = () => {
    setSelectedHc(null);
    closeModalNewHC();
    onChange();
  };
  const handlerEmpty = {
    dataMocks: !patient.id_paciente ? emptyStateNotPatient : emptyStateHC,
    onClick: !patient.id_paciente ? handlerFocus : openModalNewHC,
  };

  return (
    <section className="mt-5" style={{ height: `${height}px` }}>
      {patient.his_cli && patient.his_cli.length ? (
        <>
          <ClinicRecords data={clinicRecords} action={actions} />
          {patient.id_paciente && (
            <div className="flex mt-5 space-x-5 justify-end">
              <Button
                onClick={openModalNewHC}
                className="px-10 h-10 hover:bg-blue-600"
              >
                Nueva historia clínica
              </Button>
            </div>
          )}
        </>
      ) : (
        <Empty
          typeSucces="emptyState"
          dataMocks={handlerEmpty.dataMocks}
          onClick={handlerEmpty.onClick}
        />
      )}
      <Modal
        title={
          selectedHc?.id_clinic_record
            ? 'Modificar historia clínica'
            : 'Nueva historia clínica'
        }
        isOpenModal={isOpenModalNewHC}
        closeModal={closeModal}
        openModal={openModalNewHC}
        width="100%"
      >
        <NewClinicHistoryForm
          patientId={patient.id_paciente}
          userId={userId}
          professionals={professionals}
          offices={offices}
          clinicHistory={selectedHc}
          onChange={closeModal}
        />
      </Modal>
      <Modal
        title="Eliminar Historia Clínica"
        isOpenModal={isOpenModalDeleteHC}
        closeModal={closeModalDeleteHC}
        openModal={openModalDeleteHC}
        width="80%"
      >
        <DeleteHcForm selectedHc={selectedHc} patientId={patient.id_paciente} />
      </Modal>
    </section>
  );
};
