import React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';
import classNames from 'classnames';

type SwitchProps = React.HTMLAttributes<HTMLButtonElement>;

export const SwitchShadcn = React.forwardRef<HTMLButtonElement, SwitchProps>(
  function Switch(
    { className, ...props }: SwitchProps,
    ref: React.Ref<HTMLButtonElement>,
  ) {
    return (
      <SwitchPrimitives.Root
        className={classNames(
          'peer inline-flex h-4 w-8 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-white disabled:cursor-not-allowed disabled:opacity-50 bg-green-200',
          className,
        )}
        {...props}
        ref={ref}
      >
        <SwitchPrimitives.Thumb
          className={classNames(
            'pointer-events-none block h-3 w-3 rounded-full data-[state=unchecked]:bg-white data-[state=checked]:bg-green-500 shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0',
          )}
        />
      </SwitchPrimitives.Root>
    );
  },
);

SwitchShadcn.displayName = SwitchPrimitives.Root.displayName;
