import React from 'react';
import { Formik } from 'formik';
import { useForm } from './use-form';
import { Controller } from './Controller';
import { Card, CardContent } from '#components/shadcn';
import { MedicalInsurancesFormT } from '#interfaces/components';

const MedicalInsurancesForm: React.FC<MedicalInsurancesFormT> = ({
  translations,
  medicalInsurances,
  selectedMed,
  setMedical,
  children,
  handleOnChange,
  check,
}) => {
  const { initialValues } = useForm(medicalInsurances, selectedMed, check);
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={() => {}}
    >
      <Card className="mt-5">
        <CardContent className="!p-6 items-end relative z-40 grid grid-cols-4 gap-x-10">
          <Controller
            name="medical_insurance"
            showenabledFieldName="show_enabled"
            translations={translations}
            medicalInsurances={medicalInsurances}
            setMedical={setMedical}
            handleOnChange={handleOnChange}
          />
          {children}
        </CardContent>
      </Card>
    </Formik>
  );
};

export default MedicalInsurancesForm;
