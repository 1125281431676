import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { Billing } from './billing';
import { Users } from './users';
import {
  Copy,
  Subtitle,
  CheckboxForm,
  TextFieldForm,
  Form,
  Button,
} from '../../../../../../components';
import { OfficeFormT } from '#interfaces/components';

export const OfficeForm: React.FC<OfficeFormT> = ({
  office,
  offices,
  useUsd,
  onChange,
  onCancel,
}) => {
  const { initialValues, validate } = useForm(office);

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={useSubmit(onChange)}
      enableReinitialize
    >
      {({ submitForm, isSubmitting }) => (
        <Form showActions={false} ignoreDirty>
          <Copy>
            En esta sección vas a poder dar de alta una nueva sucursal.
          </Copy>
          <Subtitle>Datos de la sucursal</Subtitle>
          <div className="grid grid-cols-3 gap-10">
            <TextFieldForm name="office" label="Sucursal *" />
            <TextFieldForm name="description" label="Descripción *" />
            <CheckboxForm
              name="enabled"
              label="Sucursal habilitada"
              description="Si la sucursal se encuentra deshabilitada, no se podrá realizar ninguna operación en ella."
              showError={false}
            />
          </div>
          <Users offices={offices} />
          <span className="h-px my-9 w-full bg-gray-300 block" />
          <Billing useUsd={useUsd} />
          <p className="text-sm text-gray-600 my-4">
            * Estos campos son requeridos
          </p>
          <div className="flex gap-5">
            <Button
              onClick={submitForm}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Guardar
            </Button>
            <Button onClick={onCancel} modifier="warning">
              Cancelar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
