import React from 'react';
import { FormikHelpers } from 'formik';
import { updateTimetableConfig } from '../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../context';
import { DeleteTimeFormT } from '#interfaces/components';

export function useSubmit({ time, selectedProf, onChange }: DeleteTimeFormT) {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    _spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(time, selectedProf);
      formikActions.resetForm();
      showSuccessSnackbar('Horario eliminado correctamente');
      onChange();
      closeForm();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(
  time: { [key: string]: any },
  selectedProf: { [key: string]: any },
) {
  const apiSpec = transformSpec(time, selectedProf);

  return updateTimetableConfig(apiSpec);
}

function transformSpec(
  time: { [key: string]: any },
  selectedProf: { [key: string]: any },
) {
  const { id_prof, nombre } = selectedProf;

  let apiSpec = {
    id_prof: id_prof,
    nombre: nombre,
    opcion: 'eliminar',
    prof_horarios: [
      {
        id_horario: time.id,
        id_prof: id_prof,
        dia: time.day_num === 7 ? 0 : time.day_num,
        desde: time.from,
        hasta: time.to,
        intervalo: time.interval,
        fecha_desde: time.date_from,
        fecha_hasta: time.date_to,
        id_sucursal: time.id_sucursal,
        id_especialidad: time.id_especialidad,
        rowversion: time.rowversion,
      },
    ],
  };

  return apiSpec;
}
