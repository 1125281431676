import React from 'react';
import { useFormikContext } from 'formik';
import { compareDatesRanges } from '../../../../utils';
import { DropdownForm } from '../../../commons';
import { useFieldProps } from '../../../../hooks';
import { ProfessionalDropdownT } from '#interfaces/components';

export const ProfessionalDropdown: React.FC<ProfessionalDropdownT> = ({
  name,
  label,
  professionals,
  officeFieldName,
  dateFieldName,
  specialtyFieldName,
  filterProfessionalByDayFieldName = '',
  dayOfWeekFieldName = '',
  ignoreDateRange = false,
  disabled = false,
  className,
}) => {
  const {
    field: { value: professional },
    setFieldValue,
  } = useFieldProps({ name });
  const {
    field: { value: office },
  } = useFieldProps({ name: officeFieldName });
  const {
    field: { value: specialty },
  } = useFieldProps({ name: specialtyFieldName });
  const {
    field: { value: date },
  } = useFieldProps({ name: dateFieldName });
  const {
    values: {
      [filterProfessionalByDayFieldName]: filterProfessionalByDay,
      [dayOfWeekFieldName]: dayOfWeek,
    },
  } = useFormikContext() as { values: { [key: string]: any } };
  const dayOfWeekToFilter = React.useMemo(
    () => (filterProfessionalByDay && dayOfWeek ? dayOfWeek : null),
    [filterProfessionalByDay, dayOfWeek],
  );

  const officeProfessionals = React.useMemo(() => {
    return professionals?.filter((el) =>
      el.habilitado && office
        ? el.prof_horarios.find(
            (s: any) =>
              s.id_sucursal === office &&
              (!dayOfWeekToFilter || s.dia === dayOfWeekToFilter) &&
              (!specialty ||
                s.id_especialidad === specialty ||
                !s.id_especialidad) &&
              (ignoreDateRange ||
                compareDatesRanges({
                  from: s.fecha_desde,
                  to: s.fecha_hasta,
                  date,
                })),
          )
        : el.habilitado,
    );
  }, [
    professionals,
    office,
    dayOfWeekToFilter,
    specialty,
    date,
    ignoreDateRange,
  ]);

  React.useEffect(() => {
    if (
      (professional &&
        !officeProfessionals.find((el) => el.id_prof === professional)) ||
      (!professional && !specialty && officeProfessionals.length)
    ) {
      setFieldValue(officeProfessionals[0]?.id_prof);
    }
  }, [professional, officeProfessionals, setFieldValue, specialty]);

  return (
    <DropdownForm
      label={label}
      name={name}
      className={className}
      data={officeProfessionals}
      keyId="id_prof"
      keyData="nombre"
      disabled={disabled}
    />
  );
};
