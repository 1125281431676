import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { addDaysToDate, getDayOfWeek } from './days-of-week';
import { getSchedules } from '../../../services';
import {
  startLoading,
  stopLoading,
  setCurrentParams,
  setSchedule,
  resetSchedule,
  setScheduleEmptyError,
  setRefreshSchedule,
} from '../../../redux/slices';
import { AppDispatch } from '../../../redux';
import { FormikHelpers } from 'formik';
import { AppointmentT } from '#interfaces/general-values';

export function useSubmit({
  forceRefresh,
  findNextAvailableAppointment,
  current,
}: {
  forceRefresh: boolean;
  findNextAvailableAppointment: AppointmentT | undefined;
  current: any;
}) {
  const dispatch: AppDispatch = useDispatch();
  const onSubmit = async (spec: any, formikActions: FormikHelpers<any>) => {
    const { criteria, ...formSpec } = spec;

    if (forceRefresh) {
      formikActions.setSubmitting(true);
    } else if (
      shouldIgnoreSubmit(spec, current, formSpec, findNextAvailableAppointment)
    ) {
      return formikActions.setSubmitting(false);
    }

    dispatch(setCurrentParams(formSpec));

    try {
      dispatch(startLoading());
      const {
        data: { listaAgenda },
      } = await axiosPromise({ spec, findNextAvailableAppointment });

      if (findNextAvailableAppointment) {
        const {
          id_prof: professional,
          fecha: date,
          id_sucursal: office,
        } = listaAgenda[0];
        const dayOfWeek = getDayOfWeek(format(new Date(date), 'yyyy/MM/dd'));
        const valuesToUpdate = {
          professional,
          office,
          date: new Date(date),
          day_of_week: dayOfWeek,
          last_professional_search: criteria.by_specialty
            ? spec.professional
            : -1,
        };
        dispatch(
          setCurrentParams({
            ...formSpec,
            ...valuesToUpdate,
          }),
        );
        formikActions.setValues({
          ...spec,
          ...valuesToUpdate,
        });
      }
      dispatch(setSchedule(listaAgenda));
      formikActions.setSubmitting(false);
    } catch (err: any) {
      dispatch(resetSchedule());
      if (/^\(\d{5,6}\)\s*-?.*/gm.test(err.message)) {
        dispatch(setScheduleEmptyError(err.message));
      }

      if (findNextAvailableAppointment) {
        await onSubmit(
          {
            ...spec,
            date: addDaysToDate(format(spec.date, 'yyyy/MM/dd'), 1),
          },
          formikActions,
        );
      } else {
        formikActions.setSubmitting(false);
      }
    } finally {
      dispatch(
        setRefreshSchedule({
          forceRefresh: true,
          findNextAvailableAppointment: false,
          refresh: () => onSubmit(spec, formikActions),
        }),
      );
      dispatch(stopLoading());
    }
  };

  return onSubmit;
}

function axiosPromise({
  spec,
  findNextAvailableAppointment,
}: {
  spec: any;
  findNextAvailableAppointment: AppointmentT | undefined;
}) {
  const {
    criteria: { by_office, by_offices, by_specialty, by_professional },
  } = spec;
  const date = format(spec.date, 'yyyy-MM-dd');
  const searchType = findNextAvailableAppointment
    ? 'buscardiadisponible'
    : spec.layout;

  let body: { [key: string]: any } = {
    fecha: date,
    id_prof: spec.professional,
    id_sucursal: spec.office,
    id_especialidad: spec.specialty,
    id_prof_ultimo: spec.last_professional_search,
    opcion: searchType,
  };

  if (spec.layout === 'buscarpordiatodosprof') {
    body = {
      fecha: date,
      opcion: searchType,
    };
  }

  if (findNextAvailableAppointment) {
    return getSchedules({
      ...body,
      id_prof: by_specialty ? -1 : spec.professional,
      id_sucursal:
        by_office ||
        (!by_office && !by_offices && !by_specialty && !by_professional)
          ? spec.office
          : null,
    });
  }

  return getSchedules(body);
}

function shouldIgnoreSubmit(
  spec: any,
  current: any,
  formSpec: any,
  findNextAvailableAppointment: AppointmentT | undefined,
) {
  if (!spec.date || !spec.professional) {
    return true;
  }

  if (JSON.stringify(current) === JSON.stringify(formSpec)) {
    return true;
  }

  if (spec.search_by_criteria && !findNextAvailableAppointment) {
    return true;
  }

  if (
    findNextAvailableAppointment &&
    current.next_available_appointment_counter ===
      formSpec.next_available_appointment_counter
  ) {
    return true;
  }

  return false;
}
