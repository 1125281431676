import { initialPatientData } from '../mocks';

class Patient {
  data: Record<string, { [key: string]: any }>;
  constructor() {
    this.data = initialPatientData;
  }

  sanitizeData(data: { [key: string]: any }) {
    const propsToDelete = [
      'id_planNavigation',
      'ortodoncia1',
      'ortodoncia2',
      'ortodoncia3',
      'ortodoncia4',
      'ortodoncia5',
      'ortodoncia6',
      'pagos_pacientes',
      'periodoncia',
      'presup',
      'labo_pacientes',
      'implantes',
      'his_cli',
      'socios',
      'todos',
      'cefalometrias',
      'cirugia',
      'acti_chequeos',
    ];
    propsToDelete.forEach((prop) => delete data[prop]);
  }

  setUserData(dataAttr: string, data: any) {
    this.data[dataAttr] = data;
  }
}

const patientInstance = new Patient();

export default patientInstance;
