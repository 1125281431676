import React, { useEffect } from 'react';
import { Autocomplete } from '../../../commons';
import { useFieldProps } from '../../../../hooks';
import { BenefitTypeT } from '#interfaces/components';

export const BenefitType: React.FC<BenefitTypeT> = ({
  name,
  className,
  benefitTypes,
  label,
  usdFieldName,
  professionals,
  useUsd,
}) => {
  const {
    field: { value },
  } = useFieldProps({ name });
  const { setFieldValue } = useFieldProps({ name: usdFieldName });
  const {
    field: { value: professionalId },
  } = useFieldProps({ name: 'professional' });

  useEffect(() => {
    const [benefit] = benefitTypes.filter(
      (b: { [key: string]: any }) => b.id_prestacion === value,
    );
    if (benefit) {
      setFieldValue(benefit.es_usd);
    }
  }, [benefitTypes, setFieldValue, value]);

  const benefitsMapper = (dataSource: any[]) => {
    return dataSource.map((d) => ({
      ...d,
      label: `${d.codigo_prest_os} - ${d.descripcion}`,
      value: `${d.codigo_prest_os} - ${d.descripcion}`,
    }));
  };

  const filteredBenefits = () => {
    const selectedProf = professionals?.find(
      (p: any) => p.id_prof === professionalId,
    );
    const { params, user } = useUsd || {};

    if (!selectedProf) return [];

    let specialties: string[] = [];
    if (selectedProf.solamente) {
      selectedProf.id_especialidad1 &&
        specialties.push(selectedProf.id_especialidad1);
      selectedProf.id_especialidad2 &&
        specialties.push(selectedProf.id_especialidad2);
    }
    const filterSpecialties = benefitTypes.filter(
      (b: { [key: string]: any }) =>
        (!selectedProf.solamente || specialties.includes(b.id_especialidad)) &&
        !b.inhabilitada,
    );
    return params && !user
      ? filterSpecialties.filter((b: any) => !b.es_usd)
      : filterSpecialties;
  };

  return (
    <div className={className}>
      <label
        htmlFor="input"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <Autocomplete
        name={name}
        filterKey="id_prestacion"
        dataSource={filteredBenefits()}
        placeholder="Buscar prestación"
        noRecordsMessage="No se encontraron prestaciones"
        customMapper={benefitsMapper}
      />
    </div>
  );
};
