import { FormikHelpers } from 'formik';
import { getWhatsapp } from '../../../../services';

export function useSubmit(onChange: (value: any) => void) {
  return async (spec: any, formikActions: FormikHelpers<any>) => {
    try {
      const {
        data: { listaWhatsAppsEnviados },
      } = await axiosPromise(spec);

      const formatted = listaWhatsAppsEnviados.map((m: any) => {
        return { ...m, mensaje: formatMessage(m.mensaje) };
      });

      onChange(formatted);
    } catch (err: any) {
      formikActions.setSubmitting(false);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(spec: any) {
  const apiSpec = transformSpec(spec);

  return getWhatsapp(apiSpec);
}

function transformSpec(spec: any) {
  return {
    fecha: spec.date,
    opcion: spec.status,
  };
}

function formatMessage(m: any) {
  let message: string;
  message = m.trim();
  message = message.replace('Template:', '');
  message = message.replace('!??', '!');
  message = message.replace('\n\n??', ',');
  message = message.replace('\n??', ',');
  message = message.replace('\n?', ',');
  message = message.replace('??', ',');
  message = message.replace(' ??', '.');
  return message;
}
