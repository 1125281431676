import React from 'react';
import { NumericField } from '../../../commons';
import { useBenefitType, useFieldProps } from '../../../../hooks';
import { ValuesToChargeFromPatientT } from '#interfaces/components';

export const ValuesToChargeFromPatient: React.FC<ValuesToChargeFromPatientT> =
  ({ name, benefitTypeFieldName, benefitTypes }) => {
    const { benefitType } = useBenefitType({
      benefitTypeFieldName,
      benefitTypes,
    });
    const { setFieldValue } = useFieldProps({ name });
    const isMounted = React.useRef(false);

    React.useEffect(() => {
      if (isMounted.current) {
        setFieldValue(benefitType?.importe_pac || 0);
      } else {
        isMounted.current = true;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [benefitType, setFieldValue]);

    return (
      <NumericField
        label="Importe a cargo del paciente ($)"
        placeholder="$ 0"
        name={name}
        disabled={!benefitType}
        min={0}
      />
    );
  };
