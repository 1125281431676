import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { ValuesToChargeFromMedicalInsurance } from './values-to-charge-from-medical-insurance';
import { ValuesToChargeFromPatient } from './values-to-charge-from-patient';
import { BenefitType } from './benefit-type';
import { ToothSection } from './tooth-section';
import { ToothFaces } from './tooth-faces';
import { ValuesToPay } from './ValuesToPay/values-to-pay';
import { ProfessionalDropdown } from './professional-dropdown';
import {
  DatePicker,
  DropdownForm,
  TextFieldForm,
  Form,
  Copy,
} from '../../../commons';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  Button,
} from '../../../shadcn';
import { BenefitsFormT } from '#interfaces/components';

export const BenefitsForm: React.FC<BenefitsFormT> = ({
  userId,
  benefit = null,
  offices,
  patientId,
  medicalInsurance,
  professionals,
  benefitTypes,
  useUsd,
  onChange,
  onCancel,
  height,
  permissions: { permissions, isSupervisor },
}) => {
  const { initialValues, validate } = useForm({
    benefit,
    patientId,
    benefitTypes,
    offices,
    professionals,
  });
  const benefitTypeFieldName = 'benefit_type';
  const professionalFieldName = 'professional';
  const officeFieldName = 'office';
  const specialtyFieldName = 'specialty';
  const usdFieldName = 'is_usd';
  const dateFieldName = 'date';
  const showCardValuesBenefits =
    isSupervisor || permissions.hasOwnProperty('Ver Importes Prest.');
  const stylesCard = `col-span-12 p-5 2xl:col-span-${
    showCardValuesBenefits ? '6' : '12'
  }`;
  const typeCurrencyText = (id: string) =>
    `Los importes se cargarán en ${
      benefitTypes?.find((b) => b.id_prestacion === id)?.es_usd
        ? 'USD'
        : 'moneda local'
    }.  `;

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      validateOnBlur={false}
      onSubmit={useSubmit({ userId, benefit, onChange })}
      enableReinitialize
    >
      {({ submitForm, isSubmitting, isValid, values }) => (
        <>
          <Form
            className="w-full grid grid-cols-12 gap-10 mt-0 2xl:mt-10 overflow-hidden overflow-y-scroll"
            showActions={false}
            height={`${height - 55}px`}
          >
            <Card className={stylesCard}>
              <CardHeader>
                <CardTitle>
                  {benefit ? 'Modificar prestación' : 'Nueva prestación'}
                </CardTitle>
                <CardDescription>
                  Cargá todos los datos referidos a la prestación.
                </CardDescription>
              </CardHeader>
              <CardContent className="grid grid-cols-12 gap-6">
                <DatePicker
                  className="col-span-4"
                  label="Fecha"
                  name="date"
                  fullWidth
                />
                {offices?.length > 1 && (
                  <DropdownForm
                    className="col-span-4"
                    name={officeFieldName}
                    data={offices}
                    placeholder="Sucursal"
                    label="Sucursal"
                    keyId="id_sucursal"
                    keyData="sucursal"
                  />
                )}
                <ProfessionalDropdown
                  label="Profesional"
                  className="col-span-4"
                  name={professionalFieldName}
                  professionals={professionals}
                  specialtyFieldName={specialtyFieldName}
                  officeFieldName={officeFieldName}
                  dateFieldName={dateFieldName}
                  ignoreDateRange
                />
                {medicalInsurance?.cargar_por_periodo && (
                  <TextFieldForm
                    label="Período"
                    className="col-span-4"
                    placeholder="202201"
                    name="period"
                  />
                )}
                <BenefitType
                  className="col-span-12"
                  label="Prestación"
                  name={benefitTypeFieldName}
                  benefitTypes={benefitTypes}
                  usdFieldName={usdFieldName}
                  professionals={professionals}
                  useUsd={useUsd}
                />
                <TextFieldForm
                  multiline
                  label="Observaciones"
                  name="notes"
                  className="col-span-12"
                />
                <ToothSection
                  name="tooth_section"
                  benefitTypeFieldName={benefitTypeFieldName}
                  benefitTypes={benefitTypes}
                  className="col-span-4"
                />
                <ToothFaces
                  name="tooth_faces"
                  benefitTypeFieldName={benefitTypeFieldName}
                  benefitTypes={benefitTypes}
                  className="col-span-8"
                />
              </CardContent>
            </Card>
            {showCardValuesBenefits && (
              <Card className="col-span-12 2xl:col-span-6 p-5">
                <CardHeader>
                  <CardTitle>Valores a cobrar de la prestación</CardTitle>
                  <CardDescription>
                    Definí los valores a cobrar de la prestación
                  </CardDescription>
                </CardHeader>
                <CardContent className="grid grid-cols-12 gap-6">
                  <div className="col-span-4">
                    <ValuesToChargeFromMedicalInsurance
                      name="values_to_charge.from_medical_insurance"
                      benefitTypeFieldName={benefitTypeFieldName}
                      benefitTypes={benefitTypes}
                    />
                  </div>
                  <div className="col-span-5">
                    <ValuesToChargeFromPatient
                      name="values_to_charge.from_patient"
                      benefitTypeFieldName={benefitTypeFieldName}
                      benefitTypes={benefitTypes}
                      benefit={benefit}
                    />
                  </div>
                  {useUsd.params && useUsd.user && (
                    <Copy className="col-span-3 !font-bold !text-slate-900">
                      {typeCurrencyText(values.benefit_type)}
                    </Copy>
                  )}
                </CardContent>
                <CardHeader>
                  <CardTitle>Valores a pagar de la prestación</CardTitle>
                  <CardDescription>
                    Definí los valores a pagar de la prestación
                  </CardDescription>
                </CardHeader>
                <CardContent className="grid grid-cols-12 gap-6">
                  <ValuesToPay
                    name="values_to_pay"
                    professionalFieldName={professionalFieldName}
                    benefitTypeFieldName={benefitTypeFieldName}
                    benefitTypes={benefitTypes}
                    permissions={permissions}
                  />
                </CardContent>
              </Card>
            )}
          </Form>
          <div className="flex mt-5 space-x-5 justify-end">
            <Button
              className="px-10 h-10 bg-transparent !text-red-500 hover:bg-red-500 hover:!text-white border border-red-500"
              onClick={onCancel}
            >
              Cancelar
            </Button>
            <Button
              disabled={isSubmitting || !isValid}
              onClick={submitForm}
              type="submit"
              className="px-10 h-10 hover:bg-blue-600"
            >
              Guardar prestación
            </Button>
          </div>
        </>
      )}
    </Formik>
  );
};
