import React from 'react';
import classNames from 'classnames';

export const RadioButton: React.FC<any> = ({
  className,
  disabled,
  label,
  onChange,
  checked = false,
}) => {
  const iconClass = classNames(
    'shrink-0 mx-2 h-5 w-[21px] rounded-full p-1 ring-1 group-hover:cursor-pointer',
    checked
      ? 'text-white ring-black bg-slate-500 text-opacity-1 border-4 border-white'
      : 'text-white ring-slate-300 text-opacity-0',
  );

  const containerClass = classNames(
    'group hover:cursor-pointer',
    disabled && 'opacity-50 pointer-events-none	',
    className,
  );

  return (
    <div className={containerClass} onClick={onChange}>
      <div className="flex group-hover:cursor-pointer items-center">
        <p className={iconClass}></p>
        <p className="group-hover:cursor-pointer px-3 py-4">{label}</p>
      </div>
    </div>
  );
};
