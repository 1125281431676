import React from 'react';

type RowDataT = {
  name: string;
  id_patient: number;
  hc_number: string;
  medical_insurance_name: string;
  id_medical_insurance: number;
  medical_insurance_plan: string;
  nro_afil: string;
  document: string;
  address: string;
  birth_date: string;
  original: any;
};

type ColumnT = {
  toggleSorting: (isSorted: boolean) => void;
  getIsSorted: () => 'asc' | 'desc' | undefined;
};

type ColumnsT = {
  accessorKey: string;
  header: string | (({ column }: { column: ColumnT }) => JSX.Element);
  cell: ({ row }: { row: RowDataT }) => any;
};

export const Columns = () => {
  const columns: ColumnsT[] = [
    {
      accessorKey: 'code',
      header: 'Codigo',
      cell: ({ row }: { row: RowDataT }) => {
        const { code } = row.original;

        return (
          <p className="text-base font-semibold leading-6 text-slate-800 my-1 hover:cursor-pointer">
            {code}
          </p>
        );
      },
    },
    {
      accessorKey: 'pathology',
      header: 'Patologia',
      cell: ({ row }: { row: RowDataT }) => {
        const { description } = row.original;

        return (
          <p className="text-base font-semibold leading-6 text-slate-800 my-1 hover:cursor-pointer">
            {description}
          </p>
        );
      },
    },
  ];
  return columns;
};
