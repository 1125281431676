import React from 'react';
import { Formik } from 'formik';
import { PatientMedicalOwner } from './patient-medical-owner';
import { PatientMedicalInsurance } from './patient-medical-insurance';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { TaxCategory, Condition, Gender } from '../../../../mocks';
import { MembershipTextfield } from './membership';
import { ReciteTextfield } from './recite-text-field';
import {
  CheckboxForm,
  DatePicker,
  DropdownForm,
  TextFieldForm,
  NumericField,
  Form,
} from '#components/index';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from '#components/shadcn';
import { Button } from '#components/shadcn/button';
import { useSetHeight } from '../../../../hooks';
import { PatientDataFormT } from '#interfaces/components';

const PatientDataForm: React.FC<PatientDataFormT> = ({
  patient,
  userId,
  translations,
  medicalInsurances,
  refTarget,
  onCancel,
  viewContactData,
  openCarnetModal,
  lenderCode,
  dataTurnPatient,
}) => {
  const medicalInsurancesFieldName = 'medical_insurance';
  const documentFieldName = 'document';
  const { initialValues, validate } = useForm(
    patient,
    userId,
    medicalInsurances,
    translations.doc_types,
    dataTurnPatient,
  );
  const height = useSetHeight(patient?.id_paciente ? 478 : 318);

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={useSubmit(refTarget)}
      enableReinitialize
    >
      {({ submitForm, isSubmitting, isValid }) => (
        <>
          <Form
            className="overflow-hidden overflow-y-scroll w-full grid grid-cols-12 gap-10"
            cancelTitle="Eliminar"
            showActions={false}
            height={`${height}px`}
          >
            <Card className="col-span-12 2xl:col-span-7 p-5">
              <CardHeader>
                <CardTitle>Datos del paciente</CardTitle>
                <CardDescription>
                  Esta es la información básica acerca de tu paciente.
                </CardDescription>
              </CardHeader>
              <CardContent className="grid grid-cols-12 gap-6">
                <TextFieldForm
                  name="patient_name"
                  className="col-span-12 font-bold"
                  label="Apellido y nombre"
                  refTarget={refTarget}
                />
                <DropdownForm
                  className="col-span-2"
                  name="doc_type"
                  data={translations.doc_types}
                  label="Documento"
                  keyId="id"
                  keyData="text"
                />
                <TextFieldForm
                  className="col-span-3"
                  name={documentFieldName}
                  label="Nº de Documento"
                />
                <NumericField
                  name="clinic_story_number"
                  className="col-span-3"
                  label="Nº Historia clínica"
                />
                <DatePicker
                  className="col-start-1 col-span-4"
                  label="Fecha de nacimiento"
                  fullWidth
                  name="birth_date"
                />
                <DatePicker
                  className="col-span-4"
                  label="Fecha de alta"
                  fullWidth
                  name="discharge_date"
                />
                <DatePicker
                  className="col-span-4"
                  label="Fecha de última atención"
                  fullWidth
                  name="last_care_date"
                />
                <ReciteTextfield />
              </CardContent>
            </Card>
            <Card className="col-span-12 2xl:col-span-5 p-5">
              <CardHeader>
                <CardTitle>Datos afiliatorios</CardTitle>
                <CardDescription>
                  Acá vas a encontrar los datos sobre la obra social de tu
                  paciente.
                </CardDescription>
              </CardHeader>
              <CardContent className="grid grid-cols-12 gap-6">
                <PatientMedicalInsurance
                  translations={translations}
                  name={medicalInsurancesFieldName}
                  nameMedicalInsurancePlan="medical_insurance_plan"
                  medicalInsurances={medicalInsurances}
                  medicalInsurancePatient={patient?.id_planNavigation?.id_os}
                />
                <MembershipTextfield
                  onClick={openCarnetModal}
                  lenderCode={lenderCode}
                  medicalInsurances={medicalInsurances}
                  medicalInsurancesFieldName={medicalInsurancesFieldName}
                  documentFieldName={documentFieldName}
                  patient={patient}
                  translations={translations}
                />
                <DropdownForm
                  className="col-span-5"
                  name="condition"
                  data={Condition}
                  label="Condición"
                  keyId="id"
                  keyData="text"
                />
                <PatientMedicalOwner />
                <TextFieldForm
                  name="alert"
                  label="Alertas"
                  className="col-span-12 col-start-1"
                />
              </CardContent>
            </Card>
            <Card className="col-span-12 2xl:col-span-6 p-10">
              <CardHeader>
                <CardTitle>Antecedentes de enfermedades</CardTitle>
                <CardDescription>
                  Acá se detallan los antecedentes de enfermedades y condiciones
                  varias de tu paciente.
                </CardDescription>
              </CardHeader>
              <CardContent className="grid grid-cols-12 gap-6">
                <div className="col-span-3">
                  <CheckboxForm
                    label="Embarazo"
                    description="Este paciente estuvo o esta embarazada."
                    name="pregnant"
                    showError={false}
                  />
                </div>
                <div className="col-span-3">
                  <CheckboxForm
                    label="Hepatitis"
                    description="Tuvo hepatitis."
                    name="hepatitis"
                    showError={false}
                  />
                </div>
                <div className="col-span-3">
                  <CheckboxForm
                    label="Diabetes"
                    description="Tuvo diabetes."
                    name="diabetes"
                    showError={false}
                  />
                </div>
                <div className="col-span-3">
                  <CheckboxForm
                    label="Alergia"
                    description="Tuvo o tiene alergias."
                    name="alergy"
                    showError={false}
                  />
                </div>
                <div className="col-span-3">
                  <CheckboxForm
                    label="Cardíaco"
                    description="Es cardíaco/a."
                    name="cardiac"
                    showError={false}
                  />
                </div>
                <div className="col-span-3">
                  <CheckboxForm
                    label="Renal"
                    description="Tuvo o tiene insuficiencia renal"
                    name="renal"
                    showError={false}
                  />
                </div>
                <div className="col-span-3">
                  <CheckboxForm
                    label="HIV"
                    description="Tuvo o tiene HIV"
                    name="alpha"
                    showError={false}
                  />
                </div>
                <TextFieldForm
                  name="notes"
                  label="Observaciones"
                  className="col-span-12"
                />
              </CardContent>
              <div className="h-px bg-slate-300 my-4" />
              <CardHeader>
                <CardTitle>Datos de facturación</CardTitle>
                <CardDescription>
                  Acá vas a encontrar los datos para la facturación de tu
                  paciente junto con su condición fiscal.
                </CardDescription>
              </CardHeader>
              <CardContent className="grid grid-cols-12 gap-6">
                <TextFieldForm
                  name="cuit"
                  className="col-span-3"
                  label="CUIT"
                />
                <DropdownForm
                  name="tax_category"
                  data={TaxCategory}
                  className="col-span-3"
                  label="Condición fiscal"
                  keyId="id"
                  keyData="text"
                />
                <TextFieldForm
                  name="occupation"
                  className="col-span-6"
                  label="Ocupación"
                />
              </CardContent>
            </Card>
            <Card className="col-span-12 2xl:col-span-6 p-10">
              <CardHeader>
                <CardTitle>Datos particulares</CardTitle>
                <CardDescription>
                  Acá vas a encontrar los datos de contacto, datos de ubicación,
                  redes sociales de tu paciente.
                </CardDescription>
              </CardHeader>
              <CardContent className="grid grid-cols-12 gap-6">
                {viewContactData && (
                  <>
                    <TextFieldForm
                      name="address"
                      className="col-span-6"
                      label="Domicilio"
                    />
                    <TextFieldForm
                      name="location"
                      className="col-span-3 self-end"
                      label="Localidad"
                    />
                    <TextFieldForm
                      name="district"
                      className="col-span-3 self-end"
                      label="Provincia"
                    />
                    <TextFieldForm
                      name="cel_phone"
                      className="col-span-4"
                      label="Teléfono celular"
                    />
                    <TextFieldForm
                      name="phone"
                      className="col-span-4"
                      label="Teléfono particular"
                    />
                    <TextFieldForm
                      name="office_phone"
                      className="col-span-4"
                      label="Teléfono laboral"
                    />
                    <TextFieldForm
                      name="email"
                      className="col-span-8"
                      label="Email"
                    />
                    <DropdownForm
                      className="col-span-3 col-start-1"
                      name="gender"
                      data={Gender}
                      label="Género"
                      keyId="id"
                      keyData="text"
                    />
                    <TextFieldForm
                      name="civil_status"
                      className="col-span-4"
                      label="Estado civil"
                    />
                  </>
                )}
                <TextFieldForm
                  className="col-start-1 col-span-4"
                  name="face"
                  label="Facebook"
                />
                <TextFieldForm
                  className="col-span-4"
                  name="twitter"
                  label="Twitter"
                />
                <TextFieldForm
                  className="col-span-4"
                  name="instagram"
                  label="Instagram"
                />
              </CardContent>
            </Card>
          </Form>
          <div className="flex mt-5 space-x-5 justify-end">
            <Button
              className="px-10 h-10 bg-transparent !text-red-500 hover:bg-red-500 hover:!text-white border border-red-500"
              onClick={onCancel}
              disabled={!patient.id_paciente}
            >
              Eliminar paciente
            </Button>
            <Button
              disabled={isSubmitting || !isValid}
              onClick={submitForm}
              type="submit"
              className="px-10 h-10 hover:bg-blue-600"
            >
              Guardar paciente
            </Button>
          </div>
        </>
      )}
    </Formik>
  );
};

export default PatientDataForm;
