import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getDiagnosis,
  getFinanciers,
  getMedications,
} from '../../services/recipes';

const initialSteps: { id: string; name: string; status: string }[] = [
  {
    id: '01',
    name: 'Datos de receta',
    status: 'current',
  },
  {
    id: '02',
    name: 'Medicamentos',
    status: 'upcoming',
  },
  {
    id: '03',
    name: 'Datos adicionales',
    status: 'upcoming',
  },
  {
    id: '04',
    name: 'Resumen',
    status: 'upcoming',
  },
];

const initialState = {
  currentPosition: 0,
  lastPosition: 0,
  stepsBar: initialSteps,
  financiers: [],
  medications: [],
  medicationsInfo: {},
  diagnosis: [],
  loadedMedications: [],
  selectedRecipe: null,
};
export const getListFinanciers = createAsyncThunk(
  'recipes/getListFinanciers',
  async () => {
    try {
      const {
        data: { financiers },
      } = await getFinanciers();

      return financiers;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);
export const getListMedications = createAsyncThunk(
  'recipes/getListMedications',
  async (data: { input: string; pageNumber: number; scrolling?: boolean }) => {
    const { input, pageNumber, scrolling } = data;

    try {
      const {
        data: {
          medicationsResponse: { medications, medicationPageInfo },
        },
      } = await getMedications(input, pageNumber);

      return { medications, medicationPageInfo, scrolling };
    } catch (err: any) {
      throw new Error(err);
    }
  },
);
export const getListDiagnosis = createAsyncThunk(
  'recipes/getListDiagnosis',
  async (input: string) => {
    try {
      const {
        data: { diagnoses },
      } = await getDiagnosis(input);

      return diagnoses;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const recipesSlice = createSlice({
  name: 'recipes',
  initialState,
  reducers: {
    setLoadedMedications(state, action) {
      state.loadedMedications = action.payload;
    },
    setSelectedRecipe(state, action) {
      state.selectedRecipe = action.payload;
    },
    setTabIndexRecipe(state, action) {
      state.currentPosition = action.payload;
    },
    setStepsBarRecipe(state, action) {
      state.stepsBar = action.payload;
    },
    goToNextStepRecipe(state) {
      state.lastPosition = state.currentPosition;
      state.currentPosition = state.currentPosition + 1;
      state.stepsBar[state.lastPosition].status = 'complete';
      state.stepsBar[state.currentPosition].status = 'current';
    },
    updateProgressBarRecipe(state, action) {
      const position = action.payload;

      if (state.currentPosition === position) return;

      state.lastPosition = state.currentPosition;
      state.currentPosition = position;
      state.stepsBar[position].status = 'current';
      state.stepsBar[state.lastPosition].status = 'complete';
    },
    completeProgressBarRecipe(state) {
      state.lastPosition = state.currentPosition;
      state.currentPosition = 3;
      state.stepsBar.forEach((step) => {
        step.status = 'complete';
      });
      state.stepsBar[state.stepsBar.length - 1].status = 'current';
    },
    resetProgressBarRecipe(state) {
      state.currentPosition = 0;
      state.stepsBar = initialSteps;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListFinanciers.fulfilled, (state, action) => {
      state.financiers = action.payload;
    });
    builder.addCase(getListMedications.fulfilled, (state, action) => {
      const { scrolling, medicationPageInfo, medications } = action.payload;

      state.medicationsInfo = medicationPageInfo;
      if (scrolling) {
        state.medications = [...state.medications, ...medications] as any;
        return;
      }
      state.medications = medications;
    });
    builder.addCase(getListDiagnosis.fulfilled, (state, action) => {
      state.diagnosis = action.payload;
    });
  },
});

export const {
  setLoadedMedications,
  setSelectedRecipe,
  setTabIndexRecipe,
  setStepsBarRecipe,
  goToNextStepRecipe,
  updateProgressBarRecipe,
  completeProgressBarRecipe,
  resetProgressBarRecipe,
} = recipesSlice.actions;

export default recipesSlice.reducer;
