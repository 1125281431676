import React from 'react';
import { Icons } from './icons';
import { ShorcutT } from '#interfaces/components';

export const ShortCut: React.FC<ShorcutT> = ({
  title,
  description,
  btnText,
  onClick,
  icon,
}) => {
  return (
    <div className="shadow-lg shadow-blue-600/20 rounded bg-blue-100 grid xl:grid-cols-2 gap-5 p-5">
      <div>
        <p className="font-black text-2xl mb-3 mt-4 whitespace-nowrap">
          {title}
        </p>
        <p className="text-base xl:text-sm  ml-0 max-h-10 text-left leading-5 tracking-tight">
          {description}
        </p>
        <button
          className="font-black pt-6 w-full flex items-center cursor-pointer group transition ease-in-out delay-80 hover:translate-x-1"
          onClick={onClick}
        >
          <p className="text-blue-500 text-left text-sm font-semibold whitespace-nowrap  group-hover:text-blue-400">
            {btnText}
          </p>
          <Icons
            className="text-blue-600 h-4 group-hover:text-blue-400"
            type="arrow_right"
          />
        </button>
      </div>
      <div className="justify-center items-center hidden xl:flex">
        <div className="w-28 h-28 rounded-full bg-gradient-to-b from-blue-300 to-blue-200 flex justify-center items-center">
          <div className="w-24 h-24 rounded-full bg-gradient-to-b from-blue-400 to-blue-200 flex justify-center items-center">
            <Icons
              className=" text-blue-500 rounded-full bg-white w-20 h-20 p-5"
              type={icon}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
