import React from 'react';
import { NumericField } from '#components/index';
import { useFieldProps } from '../../../../hooks';

type PaymentDebtFieldT = {
  name: string;
  invoiceFieldName: string;
  className: string;
  label: string;
  placeholder: string;
  disabled: boolean;
};

export const PaymentDebtField: React.FC<PaymentDebtFieldT> = ({
  name,
  invoiceFieldName,
  className,
  label,
  placeholder,
  disabled,
}) => {
  const {
    field: { value },
  } = useFieldProps({ name: invoiceFieldName });

  return (
    <NumericField
      className={className}
      name={name}
      label={label}
      placeholder={placeholder}
      min={0}
      type="number"
      disabled={disabled || value === 'Nota Crédito'}
    />
  );
};
