import React from 'react';
import classNames from 'classnames';
import { TitleT } from '#interfaces/components';

export const Title: React.FC<TitleT> = ({
  children,
  className = 'text-xl',
}) => {
  const titleCss = classNames('mt-0 font-semibold', className);

  return <h3 className={titleCss}>{children}</h3>;
};
