import { useContext } from 'react';
import { FormikHelpers } from 'formik';
import { updateHolidaysConfig } from '../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../context';

export function useSubmit(onChange: () => void) {
  const { closeForm } = useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(spec);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Feriado guardado correctamente');
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(spec: { [key: string]: any }) {
  const apiSpec = transformSpec(spec);

  return updateHolidaysConfig(apiSpec);
}

function transformSpec(spec: { [key: string]: any }) {
  const opcion = spec.rowversion ? 'modificar' : 'nuevo';

  let apiSpec: { [key: string]: any } = {
    id_prof: spec.id_prof,
    desde: spec.from,
    hasta: spec.to,
    dia: null,
    opcion,
  };

  if (spec.rowversion) {
    apiSpec = {
      ...apiSpec,
      id_feriado: spec.id,
      rowversion: spec.rowversion,
    };
  }

  return apiSpec;
}
