import React from 'react';
import { TableToolbarT } from '#interfaces/tables/benefit';
import { Filter, Visibility } from './filters';

export function TableToolbar<TData>({ table }: TableToolbarT<TData>) {
  return (
    <div className="flex items-center justify-between mb-4">
      <div className="flex flex-1 items-center space-x-2">
        <Filter table={table} name="text" />
      </div>
      <Visibility table={table} />
    </div>
  );
}
