import React from 'react';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { Copy } from '#components/index';
import { Birthdays } from '#components/Tables';
import { useDemoName } from '../../../../hooks';
import { SnackbarContext } from '../../../../context';
import {
  startLoading,
  stopLoading,
  sendWhatsappBirthdays,
} from '../../../../redux/slices';

export const BirthdaysForm: React.FC<{
  data: {
    data: {
      fecha: string;
      hora: string;
      id_paciente: number;
      paciente: string;
      profesional: string;
      telefono: string | null;
    }[];
  };
  closeModal: () => void;
  userName: string;
}> = ({ data, closeModal, userName }) => {
  const messages = data?.data;
  const dispatch = useDispatch();
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);
  const { isDemo } = useDemoName(userName);

  const handleSubmit = async (messages: any) => {
    if (isDemo) {
      return showErrorSnackbar(
        'El usuario es únicamente para demostración , por favor contacte con Bilog!',
      );
    }

    const body = {
      date: format(new Date(), 'yyyy-MM-dd'),
      option: 'recordarcumple',
      messages,
    };

    dispatch(startLoading());
    await dispatch(
      sendWhatsappBirthdays({
        ...body,
        showSuccessSnackbar,
        showErrorSnackbar,
      }) as any,
    );
    dispatch(stopLoading());
  };

  return (
    <>
      <Copy>
        Estás por hacer un envío de recordatorios de cumpleaños. Una vez
        enviadas, no podrás modificarlas.
      </Copy>
      <Birthdays
        data={messages}
        onSubmit={handleSubmit}
        closeModal={closeModal}
      />
    </>
  );
};
