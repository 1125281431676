import React from 'react';
import { Error } from '../components';
import { useFieldProps, useFocused } from '../hooks';
import { TimeFieldT } from '#interfaces/components';

export const TimeField: React.FC<TimeFieldT> = ({
  name,
  className,
  hint,
  showError = true,
  refTarget,
  label,
}) => {
  const { field, setFieldValue } = useFieldProps({ name });
  // eslint-disable-next-line no-unused-vars
  const { focus } = useFocused();

  const defaultTime = `${new Date().getHours()}:${new Date().getMinutes()}`;

  return (
    <label
      className="flex flex-col text-xs -translate-y-2 text-black"
      onFocus={focus}
      onBlur={focus}
      ref={refTarget}
    >
      <label className="block text-sm font-medium mt-2 text-black">
        {label}
      </label>
      <input
        {...field}
        type="time"
        id={name}
        min="09:00"
        max="18:00"
        value={field.value || defaultTime}
        onChange={(e) => setFieldValue(e.target.value)}
        name={name}
        className={`${className} flex text-base p-2 border border-gray-300 rounded-md`}
        required
      />
      {hint && <p className="text-sm text-slate-400">{hint}</p>}
      {showError && <Error name={name} />}
    </label>
  );
};
