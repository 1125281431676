import { useState, useMemo } from 'react';
import { useSetHeightT } from '#interfaces/hooks';

export const useSetHeight = (minus: useSetHeightT) => {
  const [height, setHeight] = useState<useSetHeightT>(
    window.innerHeight - minus,
  );
  useMemo(() => {
    if (window.innerHeight < 750) {
      return setHeight(514);
    }
    return setHeight(window.innerHeight - minus);
  }, [minus]);

  return height;
};
