import React from 'react';
import { Columns } from './columns';
import { Data } from './data';
import { auditorGridMapper } from '../../../utils';

export const Table: React.FC<any> = ({ data }) => (
  <div className="mx-auto w-full mt-4">
    <Data columns={Columns()} data={auditorGridMapper(data)} />
  </div>
);
