import React from 'react';
import { Formik } from 'formik';
import { Copy, Form } from '#components/commons';
import { useSubmit } from './use-submit';

export const DeleteArticleForm: React.FC<any> = ({ article, onChange }) => {
  return (
    <Formik initialValues={{}} onSubmit={useSubmit({ article, onChange })}>
      <Form submitTitle="Eliminar" ignoreDirty>
        <Copy>
          Estás por eliminar este trabajo y esta acción es irreversible, el
          trabajo se eliminará definitivamente.
        </Copy>
      </Form>
    </Formik>
  );
};
