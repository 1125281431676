import React from 'react';
import { useFieldProps } from '../../../../../hooks';
import { CheckboxForm } from '#components/commons';

export const ControllercheckBoxSelectAll: React.FC<{
  name: string;
  label?: string;
  description?: string;
  benefits: { [index: string]: any };
}> = ({ name, label, description, benefits }) => {
  const selectedBenefitsFieldName = 'selected_benefits';
  const { setFieldValue: setFieldValueBenefits } = useFieldProps({
    name: selectedBenefitsFieldName,
  });
  const {
    field: { value: selectAll },
  } = useFieldProps({ name });

  React.useEffect(() => {
    if (selectAll) {
      setFieldValueBenefits(
        benefits.map((b: { [index: string]: any }) => b.id_prestacion),
      );
    } else {
      setFieldValueBenefits([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  return (
    <CheckboxForm
      label={label}
      description={description}
      name={name}
      showError={false}
    />
  );
};
