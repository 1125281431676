import React from 'react';
import { Button, DropdownForm } from '../../../../../../components';
import { useFieldProps } from '../../../../../../hooks';
import { CopyPermissionT } from '#interfaces/components';

export const CopyPermission: React.FC<CopyPermissionT> = ({
  profToCopyFieldName,
  permissionsFieldName,
  usersToCopy,
  closeAutoTab,
}) => {
  const {
    field: { value },
    setFieldValue,
  } = useFieldProps({ name: profToCopyFieldName });
  const { setFieldValue: setPermToCopyValue } = useFieldProps({
    name: permissionsFieldName,
  });

  React.useEffect(() => {
    const [permToCopy] = usersToCopy.filter(
      (u: { [key: string]: any }) => u.id === value,
    );
    let arr: any[] = [];
    if (permToCopy) {
      permToCopy.permissions.forEach((p: { [key: string]: any }) =>
        arr.push(p.id_proceso),
      );
      setPermToCopyValue(arr);
    } else {
      setPermToCopyValue([]);
    }
  }, [setPermToCopyValue, usersToCopy, value]);

  const handleCancel = () => {
    closeAutoTab();
    setFieldValue(null);
  };

  return (
    <>
      <p className="mb-4 text-base font-regular text-neutral-500">
        Seleccioná el profesional de quien querés copiar los permisos a este
        nuevo usuario.
      </p>
      <div className="mb-4">
        <DropdownForm
          name={profToCopyFieldName}
          data={usersToCopy}
          label="Copiar permisos de:"
          keyId="id"
          keyData="name"
        />
      </div>
      <div className="flex gap-x-4">
        <Button onClick={handleCancel} modifier="warning">
          Cancelar
        </Button>
        <Button onClick={closeAutoTab}>Seleccionar profesional</Button>
      </div>
    </>
  );
};
