import { axiosInstance, platform } from '../utils';

export function newBudget(data: { [key: string]: any }) {
  const body = {
    budget: {
      ...data,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/budgets/new', body);
}

export function updateBudget(data: { [key: string]: any }) {
  const body = {
    budget: {
      ...data,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/budgets/update', body);
}

export function deleteBudget(data: { [key: string]: any }) {
  const body = {
    budget: {
      ...data,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/budgets/delete', body);
}

export function getPayments(budgetId: number) {
  return axiosInstance.get(`/budgets/payment/${budgetId}`);
}
