import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  isLogged: false,
};

export const commonsSlice = createSlice({
  name: 'commons',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    setIsLogged: (state, action) => {
      const { isLogged } = action.payload;
      state.isLogged = isLogged;
    },
  },
});

export const { startLoading, stopLoading, setIsLogged } = commonsSlice.actions;

export default commonsSlice.reducer;
