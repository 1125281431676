import React from 'react';
import { ParamsT } from '#interfaces/general-values';

export function useForm(props: ParamsT) {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues(props),
  );

  React.useEffect(() => {
    setInitialValues(getInitialValues(props));
  }, [props]);

  return { initialValues };
}

function getInitialValues(props: ParamsT) {
  const { sms_usuario, sms_clave, sms_texto_cumple, sms_texto_turnos } = props;
  return {
    sms_user: sms_usuario,
    sms_password: sms_clave,
    sms_text_bday: sms_texto_cumple,
    sms_text_turns: sms_texto_turnos,
  };
}
