import React, { useContext, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import { Button } from '../shadcn/button';
import { FormContext } from '../../context/form';
import { useKeypress, useAutoSubmit as AutoSubmit } from '../../hooks';
import { FormT } from '#interfaces/components';

export const Form: React.FC<FormT> = ({
  children,
  className,
  height = 'auto',
  submitTitle = 'Guardar',
  cancelTitle = 'Cancelar',
  ignoreDirty = false,
  showActions = true,
  autoSubmit = false,
  submitOnEnter = true,
}) => {
  const formRef = useRef(null);
  const { isOpenForm, closeForm } = useContext(FormContext);
  const { isSubmitting, isValid, dirty, resetForm, submitForm } =
    useFormikContext();

  useEffect(() => {
    if (!isOpenForm) {
      resetForm();
    }
  }, [isOpenForm, resetForm]);

  useKeypress({
    key: 'Enter',
    action: (e: any) => {
      // This is for cross-browsing compatibility
      const path = e.path || (e.composedPath && e.composedPath());
      const isForm = path.includes(formRef.current);
      if (isValid && isForm && submitOnEnter) return submitForm();
    },
  });

  const classes = classNames(className && className);

  return (
    <div ref={formRef} className={classes} style={{ height: height || 'auto' }}>
      {children}
      {showActions && (
        <div className="col-span-full grid grid-cols-4 gap-x-4">
          <Button
            onClick={closeForm}
            className="flex justify-center w-max px-10 h-10 bg-transparent !text-red-500 hover:bg-red-500 hover:!text-white border border-red-500"
            disabled={isSubmitting}
          >
            {cancelTitle}
          </Button>
          <Button
            className="flex justify-center w-max px-10 h-10 hover:bg-blue-600"
            onClick={submitForm}
            disabled={isSubmitting || (!ignoreDirty && !dirty) || !isValid}
          >
            {submitTitle}
          </Button>
        </div>
      )}
      {autoSubmit && <AutoSubmit />}
    </div>
  );
};
