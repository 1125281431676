import React from 'react';
import { Icons } from '../commons';
import { SuscriptionActionT } from '#interfaces/components';

export const ActionCard: React.FC<SuscriptionActionT> = ({
  suscribed,
  data,
  webUser,
}) => {
  const renderParams = () => {
    const condition = suscribed && data?.plan_version === 'premium';
    const params = {
      suscribed: {
        href: `https://wa.me/+5491128504255?text=Hola%20quiero%20modificar%20mi%20suscripcion,%20mi%20email%20es:%20${data.email}%20y%20mi%20webUser%20es:%20${webUser}`,
        text: 'Modificar mi suscripción',
      },
      default: {
        href: `https://signup.bilog.com.ar/checkout?email=${data.email}&webuser=${webUser}`,
        text: 'Contratar Premium',
      },
    };

    return params[condition ? 'suscribed' : 'default'];
  };

  return (
    <a
      href={renderParams().href}
      target="_blank"
      className="flex text-base text-blue-500 py-4 px-8 justify-between border-t-2"
      rel="noreferrer"
    >
      {renderParams().text}
      <Icons fill="#fff" type="arrow_right" width={24} height={24} />
    </a>
  );
};
