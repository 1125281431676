import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { Form } from '../../..';
import { Button } from '../../../shadcn/button';
import { DeleteBenefitsFormT } from '#interfaces/components';

export const DeleteBenefitForm: React.FC<DeleteBenefitsFormT> = ({
  benefit,
  onChange,
  onCancel,
}) => {
  return (
    <Formik
      initialValues={{}}
      onSubmit={useSubmit({ benefit, onChange })}
      enableReinitialize
    >
      {({ submitForm, isSubmitting, isValid }) => (
        <>
          <Form submitTitle="Eliminar" ignoreDirty showActions={false}>
            <p className="text">
              Esta acción borrará de forma permanente la prestación
              seleccionada.
            </p>
          </Form>
          <div className="flex mt-5 space-x-5">
            <Button
              className="px-10 h-10 bg-transparent !text-red-500 hover:bg-red-500 hover:!text-white border border-red-500"
              onClick={onCancel}
            >
              Cancelar
            </Button>
            <Button
              disabled={isSubmitting || !isValid}
              onClick={submitForm}
              type="submit"
              className="px-10 h-10 hover:bg-blue-600"
            >
              Eliminar prestación
            </Button>
          </div>
        </>
      )}
    </Formik>
  );
};
