import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { Icons } from '../commons';
import classNames from 'classnames';

export const Select: React.FC<any> = SelectPrimitive.Root;

export const SelectGroup: React.FC = SelectPrimitive.Group;

export const SelectValue: React.FC<any> = SelectPrimitive.Value;

type SelectTriggerProps = {
  className?: string;
  children?: React.ReactNode;
};

export const SelectTrigger = React.forwardRef<
  HTMLButtonElement,
  SelectTriggerProps
>(
  (
    { className, children, ...props }: SelectTriggerProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => (
    <SelectPrimitive.Trigger
      ref={ref}
      className={classNames(
        'mb-4 flex h-8 w-auto items-center justify-between rounded-md border border-slate-200 bg-transparent px-2 py-2 text-xs ring-offset-white placeholder:text-slate-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
      {...props}
    >
      {children}
      <SelectPrimitive.Icon asChild>
        <Icons type="check" className="h-4 w-4 opacity-50" />
      </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
  ),
);

SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

type SelectContentProps = {
  className?: string;
  children?: React.ReactNode;
  position?: 'popper' | 'item-aligned';
};

export const SelectContent = React.forwardRef<
  HTMLDivElement,
  SelectContentProps
>(
  (
    { className, children, position = 'popper', ...props }: SelectContentProps,
    ref: React.Ref<HTMLDivElement>,
  ) => (
    <SelectPrimitive.Portal>
      <SelectPrimitive.Content
        ref={ref}
        className={classNames(
          'relative z-50 min-w-[8rem] overflow-hidden rounded-md border border-slate-200 bg-white text-slate-950 shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
          position === 'popper' &&
            'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
          className,
        )}
        position={position}
        {...props}
      >
        <SelectPrimitive.Viewport
          className={classNames(
            'p-1',
            position === 'popper' &&
              'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]',
          )}
        >
          {children}
        </SelectPrimitive.Viewport>
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  ),
);

SelectContent.displayName = SelectPrimitive.Content.displayName;

type SelectLabelProps = {
  className?: string;
};

export const SelectLabel = React.forwardRef<HTMLDivElement, SelectLabelProps>(
  (
    { className, ...props }: SelectLabelProps,
    ref: React.Ref<HTMLDivElement>,
  ) => (
    <SelectPrimitive.Label
      ref={ref}
      className={classNames(
        'py-1.5 pl-8 pr-2 text-xs font-semibold',
        className,
      )}
      {...props}
    />
  ),
);

SelectLabel.displayName = SelectPrimitive.Label.displayName;

type SelectItemProps = {
  className?: string;
  children?: React.ReactNode;
  value: string;
};

export const SelectItem = React.forwardRef<HTMLDivElement, SelectItemProps>(
  (
    { className, value, children, ...props }: SelectItemProps,
    ref: React.Ref<HTMLDivElement>,
  ) => (
    <SelectPrimitive.Item
      value={value}
      ref={ref}
      className={classNames(
        'relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-slate-100 focus:text-slate-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
        className,
      )}
      {...props}
    >
      <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
        <SelectPrimitive.ItemIndicator>
          <Icons type="check" className="h-4 w-4" />
        </SelectPrimitive.ItemIndicator>
      </span>

      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  ),
);

SelectItem.displayName = SelectPrimitive.Item.displayName;

type SelectSeparatorProps = {
  className?: string;
};

export const SelectSeparator = React.forwardRef<
  HTMLHRElement,
  SelectSeparatorProps
>(
  (
    { className, ...props }: SelectSeparatorProps,
    ref: React.Ref<HTMLHRElement>,
  ) => (
    <SelectPrimitive.Separator
      ref={ref}
      className={classNames('-mx-1 my-1 h-px bg-slate-100', className)}
      {...props}
    />
  ),
);

SelectSeparator.displayName = SelectPrimitive.Separator.displayName;
