import * as React from 'react';
import { Icons } from '#components/commons';
import { DropdownMenu, DropdownMenuTrigger } from '#components/shadcn';
import { SelectArticles } from './select-articles';

export const Columns = (action: any) => {
  const columns = [
    {
      id: 'actions',
      header: 'Seleccionar',
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="relative p-2 hover:cursor-pointer flex-none">
                <SelectArticles selectedArticle={row.original.id_concept} />
                <span className="sr-only">Abrir menu</span>
              </div>
            </DropdownMenuTrigger>
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: 'code',
      header: 'Código',
      cell: ({ row }: { row: any }) => {
        const { code } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{code}</p>
        );
      },
    },
    {
      accessorKey: 'concept',
      header: 'Concepto',
      cell: ({ row }: { row: any }) => {
        const { concept } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{concept}</p>
        );
      },
    },
    {
      accessorKey: 'amount',
      header: 'Importe',
      cell: ({ row }: { row: any }) => {
        const { amount } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{amount}</p>
        );
      },
    },
    {
      accessorKey: 'enabled',
      header: 'Habilitado',
      cell: ({ row }: { row: any }) => {
        const { enabled } = row.original;
        return (
          <Icons
            type={enabled ? 'check_clean' : 'no_simbol'}
            className="mr-2 h-4 w-4"
          />
        );
      },
    },
  ];
  !action && columns.splice(0, 1);

  return columns;
};
