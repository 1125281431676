import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { RootState } from '#src/redux';
import {
  TextFieldForm,
  NumericField,
  SwitchForm,
  CheckboxForm,
  Textarea,
} from '#components/index';
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '#components/shadcn';
import { ButtonsMedicines } from './save';
import { SearchMedicine } from './search-medicine';
import { SearchDiagnosis } from './search-diagnose';
import { useFieldProps } from '../../../../hooks';

export const LoadMedicine: React.FC<{
  setHiddenLoadMedicine: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setHiddenLoadMedicine }) => {
  const {
    recipes: { medications, medicationsInfo, diagnosis },
  } = useSelector((state: RootState) => state);
  const {
    field: { value: isDiagnosisManual },
  } = useFieldProps({ name: 'is_diagnosis_manual' });
  const {
    field: { value: notReplace },
  } = useFieldProps({ name: 'medicine_from_list.not_replace' });
  const {
    field: { value: medicineName },
  } = useFieldProps({ name: 'medicine_from_list.productName' });
  const {
    field: { value: onlyGeneric },
  } = useFieldProps({ name: 'medicine_from_list.only_generic' });
  const {
    field: { value: addDiagnosis },
  } = useFieldProps({ name: 'add_diagnosis' });
  const { setFieldValue: setFieldDiagnosisValue } = useFieldProps({
    name: 'medicine_from_list.diagnosis',
  });

  React.useMemo(() => {
    !addDiagnosis && setFieldDiagnosisValue({ description: '', code: '' });
  }, [addDiagnosis]);

  return (
    <CardContent className="grid grid-cols-12 gap-5">
      <SearchMedicine
        searchResults={{ medications, medicationsInfo }}
        name="medicine_from_list.productName"
        label="Medicamento"
        placeholder="Escribe aquí el nombre del medicamento"
      />
      <TextFieldForm
        className="col-span-4"
        label="Presentación"
        name="medicine_from_list.presentation"
        disabled
      />
      <NumericField
        className="col-span-2"
        label="Cantidad"
        name="medicine_from_list.quantity"
        min={1}
      />
      <div
        className={classNames('col-span-4 flex', {
          'cursor-not-allowed !text-gray-500': notReplace,
        })}
      >
        <SwitchForm
          className="w-full"
          name="medicine_from_list.only_generic"
          disabled={notReplace}
        />
        <label htmlFor="input" className="ml-2 block font-semibold text-base">
          Receta solo genérico
          <p className="text-sm font-regular text-gray-400 w-64">
            El medicamento puede ser una versión genérica, no de marca.
          </p>
        </label>
      </div>
      <div
        className={classNames('col-span-4 flex', {
          'cursor-not-allowed !text-gray-500': onlyGeneric,
        })}
      >
        <SwitchForm
          name="medicine_from_list.not_replace"
          disabled={onlyGeneric}
        />
        <label htmlFor="input" className="ml-2 block font-semibold text-base">
          No sustituir
          <p className="text-sm font-regular text-gray-400 w-64">
            Se debe dispensar exactamente el medicamento prescrito, sin cambios.
          </p>
        </label>
      </div>
      <div className="col-span-full border-t border-t-slate-200">
        <CardHeader className="!px-0">
          <CardTitle>Diagnóstico para el medicamento</CardTitle>
          <CardDescription>
            Puedes complementar la información de los requerimientos de tu
            paciente y el diagnóstico del medicamento.
          </CardDescription>
        </CardHeader>
        <div className="grid grid-cols-12 gap-5 mb-5">
          <div className="col-span-3">
            <CheckboxForm
              label="Agregar diagnóstico"
              description="Podrá incluir un diagnóstico para este medicamento."
              name="add_diagnosis"
              showError={false}
            />
          </div>
          <div className="col-span-3">
            <CheckboxForm
              label="Diagnóstico manual"
              description="Cargar la información de forma manual."
              name="is_diagnosis_manual"
              showError={false}
              disabled={!addDiagnosis}
            />
          </div>
          {isDiagnosisManual ? (
            <TextFieldForm
              label="Diagnóstico"
              name="medicine_from_list.diagnosis.description"
              maxlength={255}
              className="col-span-6"
              placeholder="Escribe aquí el diagnóstico"
            />
          ) : (
            <SearchDiagnosis
              searchResults={diagnosis}
              name="medicine_from_list.diagnosis"
              label="Diagnóstico"
              placeholder="Escribe aquí el diagnóstico"
              disabled={!addDiagnosis}
            />
          )}
          <Textarea
            label="Observación"
            name="medicine_from_list.observations"
            className="col-span-6"
          />
          <Textarea
            label="Tratamiento"
            name="medicine_from_list.dosage"
            className="col-span-6"
          />
          <div className="col-span-3">
            <CheckboxForm
              label="Tratamiento prolongado"
              description="El medicamento es para un tratamiento prolongado."
              name="medicine_from_list.prolonged_treatment"
              showError={false}
            />
          </div>
        </div>
        <div className="col-span-12 flex gap-5">
          <ButtonsMedicines
            enabledButton={medicineName}
            setHiddenLoadMedicine={setHiddenLoadMedicine}
          />
        </div>
      </div>
    </CardContent>
  );
};
