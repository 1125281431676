export const benefitsState: { id: string; text: string }[] = [
  {
    id: 'ALL',
    text: 'Todas',
  },
  {
    id: 'LIQ',
    text: 'Liquidadas',
  },
  {
    id: 'NOLIQ',
    text: 'No liquidadas',
  },
];

export const defaultStats = ({
  totalAmount,
  totalServices,
  period,
}: {
  totalAmount: number;
  totalServices: number;
  period: string;
}) => {
  const formatAmount = () => {
    return new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 2,
    }).format(totalAmount);
  };
  formatAmount();
  return [
    {
      name: 'Total facturado',
      stat: `${formatAmount()}`,
    },
    {
      name: 'Prestaciones realizadas',
      stat: totalServices,
    },
    {
      name: 'Período',
      stat: period,
    },
  ];
};
