import React from 'react';
import { Title } from '#components/index';
import { AttendanceStats } from '#components/Tables';

export const NewPatientsFrom: React.FC<{
  data: {
    data: {
      fecha: string;
      hora: string;
      id_paciente: number;
      paciente: string;
      profesional: string;
      telefono: string | null;
    }[];
  };
}> = ({ data }) => (
  <div className="max-w-6xl w-full m-auto mt-10">
    <Title className="text-2xl mb-6">Turnos de pacientes nuevos</Title>
    <AttendanceStats data={data.data} />
  </div>
);
