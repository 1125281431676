import { useEffect, useState } from 'react';

export function useForm(props: { [key: string]: any }) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues(props),
  );

  useEffect(() => {
    setInitialValues(getInitialValues(props));
  }, [props]);

  return { initialValues };
}

function getInitialValues(props: { [key: string]: any }) {
  const { h_clinica_desde, ag_aca, ag_alerta, cod_prest_bilog } = props;

  return {
    hc_from: h_clinica_desde,
    schedule_aca: ag_aca,
    schedule_alert: ag_alerta,
    lender_code: cod_prest_bilog,
  };
}
