import React from 'react';
import classNames from 'classnames';
import { Icons } from './icons';
import { usePagination } from '../../hooks/index';
import { PaginationT } from '#interfaces/components';

export const Pagination: React.FC<PaginationT> = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  totalPages,
}) => {
  const DOTS = '...';
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    totalPages,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () =>
    currentPage !== totalPages && onPageChange(currentPage + 1);

  const onPrevious = () => currentPage > 1 && onPageChange(currentPage - 1);

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white py-3">
      <div className="flex flex-1 items-center justify-center">
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <span
              onClick={onPrevious}
              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 hover:cursor-pointer"
            >
              <span className="sr-only">Previous</span>
              <Icons type="arrow_back" width={18} height={18} />
            </span>
            {paginationRange.map((pageNumber) => {
              if (pageNumber === DOTS) {
                return (
                  <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">
                    &#8230;
                  </span>
                );
              }

              const pillCss = classNames(
                currentPage === lastPage &&
                  'relative z-10 inline-flex items-center border border-slate-100 bg-slate-50 px-4 py-2 text-sm font-medium text-slate-100 focus:z-20 hover:cursor-pointer hover:bg-gray-50',
                currentPage === pageNumber
                  ? 'relative z-10 inline-flex items-center border border-blue-500 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-500 focus:z-20 hover:cursor-pointer hover:bg-gray-50'
                  : 'relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm text-slate-500 font-medium hover:cursor-pointer hover:bg-gray-50',
              );

              return (
                <span
                  onClick={() => onPageChange(pageNumber)}
                  aria-current="page"
                  className={pillCss}
                >
                  {pageNumber}
                </span>
              );
            })}
            <span
              onClick={onNext}
              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 hover:cursor-pointer"
            >
              <span className="sr-only">Next</span>
              <Icons type="arrow_right" width={18} height={18} />
            </span>
          </nav>
        </div>
      </div>
    </div>
  );
};
