import React from 'react';
import classNames from 'classnames';
import { Error } from './error';
import { useFieldProps, useFocused } from '../../hooks';
import { TextareaT } from '#interfaces/components';

export const Textarea: React.FC<TextareaT> = ({
  name,
  className,
  hint = false,
  placeholder,
  disabled,
  showError = true,
  maxlength,
  refTarget,
  label,
  rows,
}) => {
  const { field } = useFieldProps({ name });
  const { focus } = useFocused();

  const inputCss = classNames(
    'mt-1.5 relative focus:ring-2 focus:ring-blue-500 shadow-sm block w-full px-4 py-2 text-sm ring-1 ring-gray-300 rounded-md',
    disabled && 'bg-gray-100 text-gray-500 cursor-not-allowed',
  );

  return (
    <div onFocus={focus} onBlur={focus} className={`text-left ${className}`}>
      <label htmlFor="input" className="mb-2 block text-sm font-medium">
        {label}
      </label>
      <textarea
        {...field}
        className={inputCss}
        name={name}
        ref={refTarget}
        placeholder={placeholder}
        value={field.value || ''}
        disabled={disabled}
        maxLength={maxlength}
        rows={rows}
      />
      {hint && <p className="text-sm text-slate-400 mt-2">{hint}</p>}
      {showError && <Error name={name} />}
    </div>
  );
};
