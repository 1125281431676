import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import {
  CheckboxForm,
  NumericField,
  Form,
  TextFieldForm,
} from '../../../../../components';
import { ExtraFormtT } from '#interfaces/components';
import './index.scss';

export const ExtrasForm: React.FC<ExtraFormtT> = ({ params, onChange }) => {
  const { initialValues } = useForm(params);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={useSubmit({ params, onChange })}
      enableReinitialize
    >
      <Form>
        <div>
          <div className="form-content">
            <h4 className="mt-2 text-base font-semibold">
              Numeración de Historias Clínicas en Pacientes
            </h4>
            <p className="mb-4 text-base font-regular text-neutral-500">
              Las historias clínicas serán numeradas automáticamente por el
              sistema a partir:
            </p>
            <div className="grid grid-cols-4 gap-10">
              <NumericField
                name="hc_from"
                placeholder="Número (0 = Automatico)"
              />
            </div>
          </div>
          <span className="h-px my-9 w-full bg-gray-300 block" />
          <h4 className="mt-2 text-base font-semibold">Agenda de Turnos</h4>
          <p className="mb-4 text-base font-regular text-neutral-500">
            Configuraciones sobre la agenda de turnos.
          </p>
          <div className="pb-8 grid grid-cols-2 gap-10">
            <CheckboxForm
              label="Al marcar un turno como ACA, moverlo al próximo minuto disponible."
              description="Cuando se marque un turno como ausente con aviso, se replicará con un minuto adelantado de diferencia."
              name="schedule_aca"
              small
              showError={false}
            />
            <CheckboxForm
              label="Al dar un nuevo turno mostrar la alerta del paciente."
              description="Si el paciente tiene una alerta, se mostrará cuando se agende un nuevo turno."
              name="schedule_alert"
              small
              showError={false}
            />
          </div>
          <span className="h-px my-9 w-full bg-gray-300 block" />
          <h4 className=" text-base font-semibold">Auditor Bilog</h4>
          <div className="pb-8 grid grid-cols-4 gap-10">
            <TextFieldForm
              className="py-3 w-full mb-4"
              multiline
              label="Código Prestador"
              name="lender_code"
              maxlength={15}
              placeholder="BIL1234"
            />
          </div>
        </div>
      </Form>
    </Formik>
  );
};
