import React from 'react';
import { FormikHelpers } from 'formik';
import { updatePlansConfig } from '../../../../../../services';
import {
  SnackbarContext,
  FormContext,
  LoadingContext,
} from '../../../../../../context';

export function useSubmit(
  plan: { [key: string]: any },
  selectedMed: { [key: string]: any },
  onChange: () => void,
) {
  const { closeForm } = React.useContext(FormContext);
  const { startLoading, stopLoading } = React.useContext(LoadingContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    _spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      startLoading();
      await axiosPromise(plan, selectedMed);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Plan eliminado correctamente');
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      stopLoading();
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(
  plan: { [key: string]: any },
  selectedMed: { [key: string]: any },
) {
  const apiSpec = transformSpec(plan, selectedMed);

  return updatePlansConfig(apiSpec);
}

function transformSpec(
  plan: { [key: string]: any },
  selectedMed: { [key: string]: any },
) {
  const { rowversion } = plan;

  let apiSpec = {
    id_os: selectedMed.id_os,
    codigo_os: selectedMed.codigo_os,
    obra_social: selectedMed.obra_social,
    opcion: 'eliminar',
    planes: [
      {
        id_plan: plan.id,
        codigo_plan: plan.code,
        nombre: plan.name,
        id_os: plan.id_os,
        inhabilitada: !plan.enabled,
        rowversion,
      },
    ],
  };

  return apiSpec;
}
