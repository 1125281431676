import React from 'react';
import { useFieldProps } from '../../../../../../hooks';
import {
  Subtitle,
  DropdownForm,
  RadioButton,
} from '../../../../../../components';

export const Users: React.FC<{ offices: { [key: string]: any } }> = ({
  offices,
}) => {
  const [officesToShow, setOfficesToShow] = React.useState([]);
  const {
    field: { value: enabled },
  } = useFieldProps({ name: 'enabled' });
  const {
    field: { value: all },
    setFieldValue: setAllValue,
  } = useFieldProps({ name: 'users_all' });
  const {
    field: { value: one },
    setFieldValue: setOneValue,
  } = useFieldProps({ name: 'users_one' });

  React.useEffect(
    () =>
      setOfficesToShow(
        offices?.filter((o: { [key: string]: any }) => o.habilitada === true),
      ),
    [offices],
  );

  const handleChange = (name: string) => {
    const ev: { [key: string]: any } = {
      users_all: () => {
        setAllValue(true);
        setOneValue(false);
      },
      users_one: () => {
        setAllValue(false);
        setOneValue(true);
      },
    };
    return ev[name]();
  };

  return (
    !enabled && (
      <>
        <span className="h-px my-9 w-full bg-gray-300 block" />
        <Subtitle>Reasignar acceso de los usuarios</Subtitle>
        <div className="items-end grid grid-cols-3">
          <DropdownForm
            name="id_new_office"
            data={officesToShow}
            label="Sucursal"
            keyId="id_sucursal"
            keyData="sucursal"
            disabled={!one}
          />
          <RadioButton
            showError={false}
            name="all"
            label="Todas las sucursales"
            checked={all}
            onChange={() => handleChange('users_all')}
          />
          <RadioButton
            showError={false}
            name="one"
            label="Una sucursal"
            checked={one}
            onChange={() => handleChange('users_one')}
          />
        </div>
      </>
    )
  );
};
