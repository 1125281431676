import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  messagesFormatter,
  prepareMessageToProcess,
  dateToISO,
} from '../../utils';
import { processWhatsapp, sendWhatsapp } from '../../services';

const initialState = {
  response: [],
};

export const sendWhatsappReminders = createAsyncThunk(
  'reminders/sendWhatsapp',
  async (data: any) => {
    const { date, messages, option, successSnk, errSnk } = data;
    const messagesToSend =
      messages.length > 0 ? messagesFormatter(messages) : [];

    try {
      const {
        data: { listaWhatsApps },
      } = await sendWhatsapp(date, messagesToSend, option);
      const whatsappResponses =
        listaWhatsApps.length &&
        listaWhatsApps.map((r: any) => {
          return { id_agenda: r.idAgenda, response: r.mensajeRespuesta };
        });
      successSnk('Mensajes enviados correctamente');

      return { whatsappResponses };
    } catch (err: any) {
      errSnk(err.message);
    }
  },
);

export const sendWhatsappBirthdays = createAsyncThunk(
  'reminders/sendWhatsappBirthdays',
  async (data: any) => {
    const { date, messages, option, showSuccessSnackbar, showErrorSnackbar } =
      data;
    try {
      await sendWhatsapp(date, messages, option);
      showSuccessSnackbar('Mensajes enviados correctamente');
    } catch (error: any) {
      showErrorSnackbar(error.message);
    }
  },
);

export const processMessage = createAsyncThunk(
  'reminders/processMessage',
  async (data: any) => {
    const { date, message, option, successSnk, errSnk } = data;
    try {
      await processWhatsapp(
        prepareMessageToProcess(message),
        option,
        dateToISO(date),
      );
      successSnk('Asistencia guardada correctamente');
    } catch (err: any) {
      errSnk(err.message);
    }
  },
);

export const reminderSlice = createSlice({
  name: 'reminders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendWhatsappReminders.fulfilled, (state, action) => {
      if (action.payload) {
        const { whatsappResponses } = action.payload;
        state.response = whatsappResponses;
      }
    });
  },
});

export default reminderSlice.reducer;
