import React from 'react';
import classNames from 'classnames';
import { RadioButtonsByDate } from '../Controllers';
import { Subtitle, DatePicker, Tooltip } from '../../../../components';

export const DateManagerController: React.FC<{
  className?: string;
  groupByDate?: boolean;
}> = ({ className, groupByDate = false }) => {
  const controllerCss = classNames('grid grid-cols-12 gap-10 mb-6', className);
  const group = [
    {
      text: 'Día',
      field_names: { self: 'day', opposites: ['month', 'year'] },
      hint: 'Hasta 31 días',
    },
    {
      text: 'Mes',
      field_names: { self: 'month', opposites: ['day', 'year'] },
      hint: 'Hasta 24 meses',
    },
    {
      text: 'Año',
      field_names: { self: 'year', opposites: ['month', 'day'] },
      hint: 'Hasta 10 años',
    },
  ];

  return (
    <div className={controllerCss}>
      {groupByDate && (
        <div className="col-span-4 self-end">
          <Subtitle>Agrupar por</Subtitle>
          <div className="grid grid-cols-3 gap-4">
            {group.map((item, i) => (
              <Tooltip
                key={i}
                align="bottom"
                className="hover:cursor-pointer"
                text={item.hint}
              >
                <RadioButtonsByDate
                  text={item.text}
                  name="group_date_by"
                  selfField={item.field_names.self}
                  oppositesFields={item.field_names.opposites}
                />
              </Tooltip>
            ))}
          </div>
        </div>
      )}
      <div className="col-span-4 self-end">
        <DatePicker name="date_from" label="Fecha desde" />
      </div>
      <div className="col-span-4 self-end">
        <DatePicker name="date_to" label="Fecha hasta" />
      </div>
    </div>
  );
};
