import { axiosInstance, platform } from '../utils';

export function getSchedules(params: any) {
  const body = {
    agenda: {
      ...params,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/auagenda', body);
}

export function getRemindersSchedules(
  date: string | Date,
  profId?: number,
  officeId?: number,
  specialty?: any,
  lastProf: number = -1,
  option: string = 'buscar',
) {
  const body = {
    agenda: {
      fecha: date,
      id_prof: profId,
      id_sucursal: officeId,
      id_especialidad: specialty,
      id_prof_ultimo: lastProf,
      sucursalparasms: officeId,
      opcion: option,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/auagenda', body);
}

export function deleteTurn(turn: any) {
  const body = {
    agenda: {
      ...turn,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/auagenda', body);
}

export function newOverTurn(overturn: any) {
  const body = {
    agenda: {
      ...overturn,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/auagenda', body);
}

export function saveTurn(params: any) {
  const body = {
    agenda: {
      ...params,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/auagenda', body);
}
