import React from 'react';
import { Columns } from './columns';
import { Data } from './data';

export const Table: React.FC<any> = ({ data, action, recipe }) => {
  return (
    <div className="mx-auto w-full">
      <Data columns={Columns({ action, recipe })} data={data} />
    </div>
  );
};
