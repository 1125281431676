import React from 'react';
import { CheckboxForm, DropdownForm } from '../../../../../../components';
import { useFieldProps } from '../../../../../../hooks';
import { IsDentis_UserOfficeT } from '#interfaces/components';

export const IsDentis: React.FC<IsDentis_UserOfficeT> = ({
  data,
  supervisorFieldName,
}) => {
  const checkboxName = 'dentist';
  const {
    field: { value },
  } = useFieldProps({ name: checkboxName });
  const {
    field: { value: supValue },
  } = useFieldProps({ name: supervisorFieldName });

  return (
    <>
      <CheckboxForm
        label="Es odontólogo/a"
        description="Indica si este usuario es odontólogo/a."
        name={checkboxName}
        showError={false}
        disabled={supValue}
      />
      <DropdownForm
        name="id_prof"
        data={data}
        disabled={supValue || !value}
        label="Profesional"
        keyId="id_prof"
        keyData="nombre"
      />
      <CheckboxForm
        label="Accede a la guardia"
        description="Indica si este usuario puede ver la agenda de la guardia."
        name="dentist_guard"
        showError={false}
        disabled={supValue || !value}
      />
      <CheckboxForm
        label="Visualiza solo su agenda"
        description="Indica si este usuario puede ver solamente su agenda."
        name="dentist_schedule"
        disabled={supValue || !value}
        showError={false}
      />
    </>
  );
};
