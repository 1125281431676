import React from 'react';
import { TableToolbarT } from '#interfaces/tables/benefit';
import { Visibility, FacetedFilter } from './filters';

export function TableToolbar<TData>({
  table,
  options,
  config: { useUsd },
}: TableToolbarT<TData> & {
  config: {
    isSupervisor: boolean;
    permissions: any;
    useUsd: any;
  };
}) {
  return (
    <div className="flex items-center justify-between mb-4">
      {useUsd.params && (
        <FacetedFilter
          title="Filtrar por moneda"
          name="is_usd"
          table={table}
          options={options}
        />
      )}
      <Visibility table={table} />
    </div>
  );
}
