import React from 'react';
import { FormikHelpers } from 'formik';
import { updateTimetableConfig } from '../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../context';

export const useSubmit = ({
  selectedProf,
  hasSchedule,
  onChange,
}: {
  selectedProf: { [key: string]: any };
  hasSchedule: { [key: string]: any }[];
  onChange: () => void;
}) => {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(spec, selectedProf, hasSchedule);
      formikActions.resetForm();
      showSuccessSnackbar('Horario guardado correctamente');
      closeForm();
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
};

function axiosPromise(
  spec: { [key: string]: any },
  selectedProf: { [key: string]: any },
  hasSchedule: { [key: string]: any }[],
) {
  const apiSpec = transformSpec(spec, selectedProf, hasSchedule);

  return updateTimetableConfig(apiSpec);
}

function transformSpec(
  spec: { [key: string]: any },
  selectedProf: { [key: string]: any },
  hasSchedule: { [key: string]: any }[],
) {
  const { id_prof, nombre } = selectedProf;
  const [intervalo] = spec.interval.split(' ');
  const opcion = spec.rowversion ? 'modificar' : 'nuevo';

  let apiSpec = {
    id_prof: id_prof,
    nombre: nombre,
    no_tiene_agenda: hasSchedule,
    opcion,
    prof_horarios: [
      {
        id_horario: spec.id,
        id_prof: id_prof,
        dia: spec.day_num === 7 ? 0 : spec.day_num,
        desde: spec.from,
        hasta: spec.to,
        intervalo,
        fecha_desde: spec.date_from,
        fecha_hasta: spec.date_to,
        id_sucursal: spec.office,
        id_especialidad: spec.all_specialties ? null : spec.specialty,
        rowversion: spec.rowversion,
      },
    ],
  };

  return apiSpec;
}
