import { useEffect } from 'react';
import { useFormikContext } from 'formik';

export const useAutoSubmit = () => {
  const { submitForm, values } = useFormikContext();
  useEffect(() => {
    submitForm();
  }, [submitForm, values]);

  return null;
};
