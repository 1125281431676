import React from 'react';
import classNames from 'classnames';
import { Tooltip } from './tooltip';
import { Icons } from './icons';
import { WithIconT } from '#interfaces/components';

export const WithIcon: React.FC<WithIconT> = ({
  className,
  size,
  modifier = 'filled',
  type = 'with-icon',
  disabled,
  loading,
  onClick,
  text,
  icon,
  fill = 'transparent',
}) => {
  const isDisabled = disabled || loading;
  const variants: { [key: string]: any } = {
    warning: 'bg-[#f80a5a] text-white ml-4',
    filled: 'bg-[#007aff] text-white',
    transparent: 'bg-transparent text-[#007aff] border border-[#007aff]',
  };
  const sizes: { [key: string]: any } = {
    small: 'text-xs h-6',
    medium: 'text-xs h-8',
  };
  const classes = classNames(
    'flex justify-center items-center p-2 rounded-full hover:cursor-pointer transition-transform hover:translate-y-[-8px]',
    modifier && variants[modifier],
    size && sizes[size],

    isDisabled && 'bg-gray-400 text-white pointer-events-none',
  );

  const sharedProps: { [key: string]: any } = {
    className: classes,
    disabled,
    onClick,
  };
  const iconCss = classNames('relative text-white', className);

  return (
    <Tooltip
      id={`${icon}-tooltip`}
      text={text}
      offset={{ top: 2, right: 0 }}
      type="dark"
    >
      <div {...sharedProps} onClick={onClick}>
        <Icons className={iconCss} type={type} fill={fill} />
      </div>
    </Tooltip>
  );
};
