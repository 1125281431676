import React from 'react';
import { Formik } from 'formik';
import classNames from 'classnames';
import { PeriodFormat } from './period';
import { useForm } from './use-form';
import { DeleteOsForm } from './DeleteOS';
import { useSubmit } from './use-submit';
import {
  CheckboxForm,
  TextFieldForm,
  NumericField,
  Form,
  Modal,
} from '#components/index';
import { useForm as useFormHook, useSetHeight } from '../../../../../../hooks';
import './index.scss';
import { MedInsDataFormT } from '#interfaces/components';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
  Button,
} from '#components/shadcn';

const DataForm: React.FC<MedInsDataFormT> = ({
  medicalInsurances,
  selectedMed,
  refTarget,
  newMedHandler,
  focusElement,
  onChange,
  modifying,
  modifyingHandler,
  translations,
}) => {
  const current = React.useMemo(() => {
    if (selectedMed)
      return medicalInsurances.find((p) => p.id_os === selectedMed.id_os);
    return medicalInsurances?.[0];
  }, [medicalInsurances, selectedMed]);
  const height = useSetHeight(290);
  const { initialValues, validate } = useForm(current);

  React.useEffect(() => {
    newMedHandler(false);
    modifyingHandler(false);
  }, [newMedHandler, modifyingHandler, selectedMed]);

  async function handleOnSubmit(submitForm: () => void) {
    if (modifying) {
      await submitForm();
      return modifyingHandler(false);
    }
    modifyingHandler(true);
    focusElement();
  }
  const { isOpenForm, closeForm, openForm } = useFormHook();
  const formOpen = (modifying: boolean | undefined) => {
    !modifying ? openForm() : modifyingHandler(false);
  };
  const stylesForm = classNames(
    'grid grid-cols-4 gap-5 mb-5',
    !modifying &&
      'pointer-events-none cursor-not-allowed filter grayscale-100 opacity-60',
  );

  return (
    <div
      className="overflow-hidden overflow-y-scroll"
      style={{ height: `${height}px` }}
    >
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={useSubmit(current, onChange)}
        enableReinitialize
      >
        {({ submitForm, isSubmitting, isValid }) => (
          <>
            <Form showActions={false} className="relative">
              <Card className="mt-5">
                <CardHeader>
                  <CardTitle>{translations.medical_insurance_data}</CardTitle>
                </CardHeader>
                <CardContent className={stylesForm}>
                  <TextFieldForm
                    multiline
                    label="Código"
                    name="code"
                    className="small"
                    refTarget={refTarget}
                  />
                  <TextFieldForm
                    multiline
                    label={translations.medical_insurance}
                    name="name"
                    className="small"
                  />
                  <TextFieldForm
                    multiline
                    label="Dirección"
                    name="address"
                    className="small"
                  />
                  <TextFieldForm
                    multiline
                    label="Localidad"
                    name="location"
                    className="small"
                  />
                  <TextFieldForm
                    multiline
                    label="Provincia"
                    name="state"
                    className="small"
                  />
                  <TextFieldForm
                    multiline
                    label="Teléfono"
                    name="phone"
                    className="small"
                  />
                  <TextFieldForm
                    multiline
                    label="Fax"
                    name="fax"
                    className="small"
                  />
                  <TextFieldForm
                    multiline
                    label="Email"
                    name="email"
                    className="small"
                  />
                  <TextFieldForm
                    multiline
                    label={translations.taxes}
                    name="cuit"
                    className="small"
                  />
                  <TextFieldForm
                    multiline
                    label="Cliente FTP"
                    name="ftp"
                    className="small"
                  />
                  <PeriodFormat />
                  <div>
                    <h4 className="pb-4">IVA sobre del paciente</h4>
                    <NumericField
                      label="Obligatorios / No gravados (%)"
                      name="iva_ob"
                    />
                  </div>
                  <NumericField
                    label="Adherentes / Gravados (%)"
                    name="iva_ad"
                    className="mt-10"
                  />
                  <div className="col-start-1 col-span-4">
                    <h4>General</h4>
                    <TextFieldForm
                      multiline
                      label="Observaciones"
                      name="observations"
                    />
                  </div>
                  <CheckboxForm
                    label="Habilitada"
                    name="enabled"
                    showError={false}
                  />
                </CardContent>
                <CardFooter className="gap-5">
                  <Button
                    onClick={() => formOpen(modifying)}
                    className="flex justify-center w-max px-10 h-10 bg-red-500 hover:bg-red-600 text-white"
                  >
                    {modifying ? 'Cancelar' : 'Eliminar'}
                  </Button>
                  <Button
                    onClick={() => handleOnSubmit(submitForm)}
                    disabled={isSubmitting || !isValid}
                    className="flex justify-center w-max px-10 h-10 bg-blue-500 hover:bg-blue-600 text-white"
                  >
                    {modifying ? 'Guardar' : 'Editar'}
                  </Button>
                </CardFooter>
              </Card>
            </Form>
            <Modal
              title="Eliminar Obra Social"
              isOpenModal={isOpenForm}
              closeModal={closeForm}
              openModal={openForm}
            >
              <DeleteOsForm medicalInsurance={current} onChange={onChange} />
            </Modal>
          </>
        )}
      </Formik>
    </div>
  );
};

export default DataForm;
