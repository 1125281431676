import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BenefitField } from './field-benefits';
import { ButtonsBenefit } from './save-benefits';
import { AppDispatch, RootState } from '../../../../../redux';
import { useFieldProps } from '../../../../../hooks';
import { CheckboxForm, TextFieldForm, NumericField } from '#components/index';
import {
  Card,
  CardContent,
  CardTitle,
  CardHeader,
  CardDescription,
} from '#components/shadcn';
import {
  setPlanBenefits,
  startLoading,
  stopLoading,
} from '../../../../../redux/slices';

export const LoadBenefit: React.FC<{
  setHiddenLoadBenefit: (value: boolean) => any;
  config: { params: boolean; user: boolean };
}> = ({ setHiddenLoadBenefit, config }) => {
  const dispatch: AppDispatch = useDispatch<AppDispatch>();
  const showFieldNameImportBenefit = 'benefit_from_list.amount';
  const showFieldNameCheckManualBenefit = 'benefit_from_list.is_manual';
  const {
    budgets: { planBenefits },
  } = useSelector((state: RootState) => state);
  const {
    field: { value: benefitValue },
  } = useFieldProps({ name: 'benefit_from_list' });
  const {
    field: { value: checkManualBenefit },
  } = useFieldProps({ name: showFieldNameCheckManualBenefit });
  const {
    field: { value: idPlan },
  } = useFieldProps({ name: 'id_plan' });
  const enabledButtonSave =
    (benefitValue.description || benefitValue.id_benefit) &&
    benefitValue.quantity;
  const enabledInputPart =
    ['DIE', 'ALG'].includes(benefitValue.needs_thoot) || checkManualBenefit;

  React.useEffect(() => {
    dispatch(startLoading());
    dispatch(setPlanBenefits(idPlan));
    dispatch(stopLoading());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idPlan]);

  return (
    <Card className="mt-5">
      <CardHeader>
        <CardTitle className="text-lg mt-5">Selección de prestación</CardTitle>
        <CardDescription>
          Cargá todos los datos referidos a la prestación.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid grid-cols-12 gap-5">
        <div className="col-span-3 mt-6">
          <CheckboxForm
            label="Prestación manual"
            name={showFieldNameCheckManualBenefit}
            showError={false}
            description="Cargá la información de forma manual."
          />
        </div>
        <BenefitField benefits={planBenefits} config={config} />
        <NumericField
          className="col-span-3 col-start-1"
          label="Cantidad *"
          name="benefit_from_list.quantity"
          min={1}
        />
        <TextFieldForm
          className="col-span-3"
          label="Diente / Sector"
          name="benefit_from_list.tooth_section"
          disabled={!enabledInputPart}
        />
        <NumericField
          className="col-span-3"
          label="Importe unitario *"
          name={showFieldNameImportBenefit}
          min={0}
        />
        <NumericField
          className="col-span-3"
          label="Importe total"
          name="benefit_from_list.total_amount"
          disabled
        />
        <div className="col-span-12 flex gap-5">
          <ButtonsBenefit
            enabledButton={enabledButtonSave}
            hiddenBenefit={setHiddenLoadBenefit}
          />
        </div>
      </CardContent>
    </Card>
  );
};
