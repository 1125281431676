import { axiosInstance, platform } from '../utils';
import { PatientT, SelectedHisCliT } from '#interfaces/general-values';

export function searchPatient(patientId: number) {
  const body = {
    paciente: {
      id_paciente: patientId,
      ape_nom: '',
      opcion: 'buscar',
      quedatobuscar: 'todo',
    },
    plataforma: platform,
  };

  return axiosInstance.post('/aupaciente', body);
}

export function searchPatientsList(patientName: string, isContained: boolean) {
  const body = {
    paciente: {
      id_paciente: 0,
      ape_nom: patientName,
      opcion: isContained ? 'buscarcontenido' : 'buscar',
      quedatobuscar: 'todo',
    },
    plataforma: platform,
  };

  return axiosInstance.post('/aupaciente', body);
}

export function savePatient(patient: {
  id_paciente?: number;
  ape_nom: string;
  f_nacimiento: string;
  domicilio: string;
  localidad: string;
  provincia: string;
  tel_part: string;
  tel_off: string;
  tel_celu: string;
  email: string;
  documento: string;
  sexo: string;
  id_plan: string;
  nro_afil: string;
  hepatitis: boolean;
  embarazo: boolean;
  diabetes: boolean;
  alergia: boolean;
  cardiaco: boolean;
  renal: boolean;
  alfa: boolean;
  f_alta: string;
  f_ultima_at: string;
  es_titular: boolean;
  hc_nro: number;
  condicion: any;
  titular_nom: string;
  titular_emp: string;
  obs_varias: string;
  foto: string;
  id_socio_tit: number;
  alerta: string;
  id_usuario: number;
  face: string;
  twitter: string;
  f_recitar: string | null;
  est_civil: string;
  ocupacion: string;
  cuit: string;
  cond_fiscal: any;
  tipo_doc: string;
  opcion: string;
  instagram: string;
  quedatobuscar: string;
  edad: string;
  saldo: number;
  cta_cte: any[];
  rowversion?: string;
}) {
  const body = {
    paciente: {
      ...patient,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/aupaciente', body);
}

export function deletePatient(patientData: {
  id_paciente: number;
  rowversion: string;
  opcion: string;
}) {
  const body = {
    paciente: {
      ...patientData,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/aupaciente', body);
}

export function getPatientHc(patientId: number) {
  const body = {
    paciente: {
      id_paciente: patientId,
      opcion: 'buscar',
      quedatobuscar: 'hcli',
    },
    plataforma: platform,
  };

  return axiosInstance.post('/aupaciente', body);
}

export function deleteHc(hc: SelectedHisCliT | undefined) {
  const { id_clinic_record, id_patient, rowversion } = hc || {};

  const body = {
    paciente: {
      opcion: 'eliminarhc',
      his_cli: [
        {
          id_his_cli: id_clinic_record,
          id_paciente: id_patient,
          rowversion,
        },
      ],
    },
    plataforma: platform,
  };

  return axiosInstance.post('/aupaciente', body);
}

export function saveHc(newHc: {
  his_cli: [
    {
      fecha: string;
      hist_cli: string;
      id_prof: string;
      id_sucursal: string;
      id_paciente: number;
      id_usuario: string;
      id_his_cli?: number;
      rowversion?: string;
    },
  ];
  opcion: string;
}) {
  let body = {
    paciente: { ...newHc },
    plataforma: platform,
  };

  return axiosInstance.post('/aupaciente', body);
}
