import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { deletePayment } from '../../../../services';
import { SnackbarContext, FormContext } from '../../../../context';
import { startLoading, stopLoading } from '../../../../redux/slices';
import { PaymentsT } from '#interfaces/components/forms/payments';

export function useSubmit(payment: PaymentsT, onChange: () => void) {
  const dispatch = useDispatch();
  const { closeForm } = useContext(FormContext);
  const { showSuccessSnackbar, showErrorSnackbar } =
    useContext(SnackbarContext);

  return async () => {
    try {
      dispatch(startLoading());
      await axiosPromise(payment);
      showSuccessSnackbar('Pago eliminado con éxito');
      closeForm();
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      dispatch(stopLoading());
    }
  };
}

function axiosPromise(payment: PaymentsT) {
  return deletePayment(payment);
}
