import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { Copy, Form } from '../../../../../../components';
import { DeleteOfficeFormT } from '#interfaces/components';

export const DeleteOfficeForm: React.FC<DeleteOfficeFormT> = ({
  office,
  onChange,
}) => {
  return (
    <Formik initialValues={{}} onSubmit={useSubmit(office, onChange)}>
      <Form submitTitle="Eliminar" ignoreDirty>
        <Copy>
          Al eliminar una sucursal se eliminarán los horarios de los
          profesionales asignados, y los usuarios que trabajan en la misma se
          quedarán sin sucursal.
        </Copy>
        <p className="mt-0 mb-6 text-base font-bold text-neutral-900">
          ¿Desea eliminar la sucursal seleccionada?
        </p>
      </Form>
    </Formik>
  );
};
