import React from 'react';
import classNames from 'classnames';
import { EmptyStateT } from '#interfaces/components';

export const EmptyState: React.FC<EmptyStateT> = ({
  title,
  copy,
  className = 'm-auto mt-56',
}) => {
  const containerCss = classNames('text-center', className);

  return (
    <div className={containerCss}>
      <p className="text-lg text-slate-900">{title}</p>
      <p className="text-base text-slate-400">{copy}</p>
    </div>
  );
};
