import { useCallback, useEffect, useState } from 'react';
import { MedicalInsurancesT } from '#interfaces/general-values';

export function useForm(props: MedicalInsurancesT | undefined) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues(props),
  );

  useEffect(() => {
    setInitialValues(getInitialValues(props));
  }, [props]);

  const validate = useCallback((values: any) => {
    const errors: { [key: string]: string } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['name', 'code'];
    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues(medIns: MedicalInsurancesT | undefined) {
  return {
    id: medIns?.id_os || 0,
    code: medIns?.codigo_os || '',
    name: medIns?.obra_social || '',
    address: medIns?.domicilio || '',
    location: medIns?.localidad || '',
    state: medIns?.provincia || '',
    phone: medIns?.telefono || '',
    fax: medIns?.fax || '',
    email: medIns?.e_mail || '',
    cuit: medIns?.cuit || '',
    ftp: medIns?.cliente_ftp || null,
    period_format: medIns?.cargar_por_periodo || false,
    benefits_amount: medIns?.cant_prest || 0,
    iva_ob: medIns?.iva_ob || 0,
    iva_ad: medIns?.iva_ad || 0,
    observations: medIns?.observaciones || '',
    enabled: !medIns?.inhabilitada || false,
  };
}
