import React from 'react';
import { DropdownForm, CheckboxForm } from '../../../../components';
import { useFieldProps } from '../../../../hooks';

export const ControllerOffice: React.FC<{
  offices: { [key: string]: any };
  idBudget: string;
}> = ({ offices, idBudget }) => {
  const showFieldNameNotOffice = 'not_include_office';
  const showFieldNameOfficeId = 'id_office';
  const {
    field: { value: checkNotOffices },
  } = useFieldProps({ name: showFieldNameNotOffice });
  const {
    field: { value: idOffice },
    setFieldValue: setFieldOfficeValue,
  } = useFieldProps({ name: showFieldNameOfficeId });
  React.useMemo(() => {
    !!checkNotOffices && setFieldOfficeValue(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkNotOffices]);

  return (
    <>
      <DropdownForm
        name={showFieldNameOfficeId}
        data={offices}
        disabled={checkNotOffices}
        className="col-span-3"
        label="Sucursal"
        keyId="id_sucursal"
        keyData="sucursal"
      />
      {!idOffice && !!idBudget && (
        <div className="col-span-3 mt-6">
          <CheckboxForm
            label="No incluye sucursal"
            name={showFieldNameNotOffice}
            showError={false}
            description="El presupuesto no posee una sucursal."
          />
        </div>
      )}
    </>
  );
};
