import React from 'react';
import { Params } from './Params';
import { SistemT } from '#interfaces/components';

const Sistem: React.FC<SistemT> = ({ data, onChange }) => {
  const {
    params,
    users,
    templates,
    permissions,
    mappedPermissions,
    professionals,
    offices,
    userId,
    regions,
    userName,
    planVersion,
    region,
  } = data;

  return (
    <Params
      params={params}
      users={users}
      templates={templates}
      offices={offices}
      professionals={professionals}
      permissions={permissions}
      userId={userId}
      userName={userName}
      mappedPermissions={mappedPermissions}
      regions={regions}
      planVersion={planVersion}
      onChange={onChange}
      region={region}
    />
  );
};

export default Sistem;
