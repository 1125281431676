import React from 'react';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Resize,
  Toolbar,
  Sort,
  Filter,
  AggregatesDirective,
  AggregateDirective,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  Aggregate,
  Page,
} from '@syncfusion/ej2-react-grids';
import { SnackbarContext } from '../../../../../context';
import { useFieldProps } from '../../../../../hooks';
import {
  BenefitsLoadGridMapper,
  budgetBenefitListTotalValue,
} from '../../../../../utils';

export const GridBenefits: React.FC<{ onclick: (value: boolean) => void }> = ({
  onclick,
}) => {
  const { showErrorSnackbar } = React.useContext(SnackbarContext);
  const grid = React.useRef<any>(null);
  const {
    field: { value: listBenefitValue },
    setFieldValue: setListBenefitValue,
  } = useFieldProps({ name: 'benefits_list' });
  const { setFieldValue: setBenefitValueSelected } = useFieldProps({
    name: 'benefit_from_list',
  });
  const customizeCell = (args: any) => {
    const field = args.column.field;
    const isImpColumn = field === 'Imp Unit' || field === 'Imp Total';
    args.cell.innerHTML =
      `<span class=${isImpColumn && 'text-green-700 text-base'}>` +
      args.cell.textContent +
      '</span>';
  };
  const footerTotalQuantity = () => {
    const totalQuantity = listBenefitValue
      ?.map((b: { [key: string]: number }) => b.quantity * 1)
      .reduce((acc: number, curr: number) => acc + curr, 0);

    return <span className="text-base">{totalQuantity}</span>;
  };
  const footerTotalAmount = () => (
    <span className="text-green-700 text-base">
      ${budgetBenefitListTotalValue(listBenefitValue)}
    </span>
  );
  const toolbar = [
    {
      text: 'Modificar',
      tooltipText: 'Modificar',
      prefixIcon: 'e-edit',
      id: 'edit',
    },
    {
      text: 'Eliminar',
      tooltipText: 'Eliminar',
      prefixIcon: 'e-delete',
      id: 'delete',
    },
  ];
  const filterBenefit = (
    selectedRow: { [key: string]: any },
    action: string,
  ) => {
    const actions: { [key: string]: () => any } = {
      edit: () =>
        listBenefitValue?.filter(
          (b: { [key: string]: any }) => b.id_budget_benefit === selectedRow.id,
        ),
      delete: () =>
        listBenefitValue?.filter(
          (b: { [key: string]: any }) => b.id_budget_benefit !== selectedRow.id,
        ),
    };

    return actions[action]();
  };
  const toolbarEvents: { [key: string]: any } = {
    edit: (selectedRow: { [key: string]: any }) => {
      const [benefitsSelected] = filterBenefit(selectedRow, 'edit');

      onclick(true);
      setBenefitValueSelected(benefitsSelected);
    },
    delete: (selectedRow: { [key: string]: any }) => {
      const benefitsSelected = filterBenefit(selectedRow, 'delete');

      setListBenefitValue(benefitsSelected);
    },
  };
  const handleToolbarClick = (e: { [key: string]: any }) => {
    const option: string = e.item.id;
    if (!option.includes('filter')) {
      const [selectedRow] = grid?.current?.getSelectedRecords();
      if (!selectedRow) {
        return showErrorSnackbar(
          'Por favor, primero seleccioná una prestación.',
        );
      }

      return toolbarEvents[option](selectedRow);
    }
  };

  return (
    <GridComponent
      ref={grid}
      dataSource={BenefitsLoadGridMapper(listBenefitValue).reverse()}
      pageSettings={{ pageSize: 50 }}
      allowResizing={true}
      allowSorting={true}
      allowFiltering={true}
      height={window.innerHeight - 850}
      queryCellInfo={customizeCell}
      toolbar={toolbar}
      toolbarClick={handleToolbarClick}
    >
      <Inject services={[Resize, Toolbar, Sort, Filter, Page, Aggregate]} />
      <ColumnsDirective>
        <ColumnDirective field="needs_thoot" visible={false} />
        <ColumnDirective field="thoot_face" visible={false} />
        <ColumnDirective field="id_benefit" visible={false} />
        <ColumnDirective field="id_budget" visible={false} />
        <ColumnDirective field="id" visible={false} />
        <ColumnDirective field="is_manual" visible={false} />
        <ColumnDirective
          field="benefit_code"
          headerText="Cod. Prest"
          width="100"
          allowResizing={false}
        />
        <ColumnDirective
          field="description"
          headerText="Descripción"
          width="225"
          allowResizing={false}
        />
        <ColumnDirective
          field="tooth_section"
          headerText="Pieza/s"
          width="50"
          allowResizing={false}
        />
        <ColumnDirective
          field="quantity"
          headerText="Cantidad"
          width="50"
          textAlign="Center"
          allowResizing={false}
        />
        <ColumnDirective
          field="amount"
          headerText="Imp Unit"
          width="50"
          allowResizing={false}
        />
        <ColumnDirective
          field="total_amount"
          headerText="Imp Total"
          width="50"
          allowResizing={false}
        />
      </ColumnsDirective>
      <AggregatesDirective>
        <AggregateDirective>
          <AggregateColumnsDirective>
            <AggregateColumnDirective
              field="benefit_code"
              type="Sum"
              footerTemplate="TOTAL"
            />
            <AggregateColumnDirective
              field="quantity"
              type="Sum"
              footerTemplate={footerTotalQuantity}
            />
            <AggregateColumnDirective
              field="total_amount"
              type="Sum"
              footerTemplate={footerTotalAmount}
            />
          </AggregateColumnsDirective>
        </AggregateDirective>
      </AggregatesDirective>
    </GridComponent>
  );
};
