import React from 'react';
import { orderBenefitsByCode } from '../../../../../utils';
import { useFieldProps } from '../../../../../hooks';
import { Autocomplete, TextFieldForm, Tooltip, Icons } from '#components/index';

export const BenefitField: React.FC<{
  benefits: [{ [key: string]: any }];
  config: { params: boolean; user: boolean };
}> = ({ benefits, config }) => {
  const {
    field: { value: benefitValue },
    setFieldValue: setFieldValueBenefit,
  } = useFieldProps({ name: 'benefit_from_list' });
  const {
    field: { value: checkIsUSD },
  } = useFieldProps({ name: 'is_usd' });

  const filterBenefitsByEndString = benefits?.filter(
    (b) =>
      !b.dibujo ||
      (!b.dibujo.trim().toUpperCase().endsWith('_R') &&
        !b.dibujo.trim().toUpperCase().endsWith('_X') &&
        b.dibujo.trim().toUpperCase() !== 'TA' &&
        b.dibujo.trim().toUpperCase() !== 'OA'),
  );

  const filterBenefitByUsd = () => {
    if (!config.params)
      return filterBenefitsByEndString?.filter(
        (b) => b.importe_os === 0 || b.importe_pac > 0,
      );
    return checkIsUSD
      ? filterBenefitsByEndString?.filter(
          (b) => (b.importe_os === 0 || b.importe_pac > 0) && !!b.es_usd,
        )
      : filterBenefitsByEndString?.filter(
          (b) => (b.importe_os === 0 || b.importe_pac > 0) && !b.es_usd,
        );
  };
  const orderedBenefit = orderBenefitsByCode(filterBenefitByUsd()) || [];
  const benefitsMapper = (dataSource: { [key: string]: any }[]) =>
    dataSource?.map((b) => ({
      ...b,
      label: `${b.codigo_prest_os} - ${b.descripcion}`,
      value: `${b.codigo_prest_os} - ${b.descripcion}`,
    }));

  React.useMemo(() => {
    if (benefitValue.is_manual) {
      return setFieldValueBenefit({
        amount: benefitValue.amount ?? 0,
        benefit_code: benefitValue.benefit_code ?? null,
        description: benefitValue.description ?? '',
        id_benefit: benefitValue.id_benefit ?? null,
        id_budget: benefitValue.id_budget ?? 0,
        id_budget_benefit: benefitValue.id_budget_benefit || Math.random(),
        is_manual: benefitValue.is_manual,
        needs_thoot: benefitValue.needs_thoot ?? 'ALG',
        quantity: benefitValue.quantity ?? 1,
        thoot_face: benefitValue.thoot_face ?? false,
        tooth_section: benefitValue.tooth_section ?? '',
        total_amount: benefitValue.total_amount ?? 0,
      });
    }

    if (benefitValue.id_benefit) {
      const benefitFromAutocomplete = orderedBenefit?.find(
        (b: { [key: string]: any }) =>
          b.id_prestacion === benefitValue.id_benefit,
      );

      if (benefitFromAutocomplete) {
        setFieldValueBenefit({
          ...benefitValue,
          amount: benefitFromAutocomplete.importe_pac,
          benefit_code: benefitFromAutocomplete.codigo_prest_os,
          description: benefitFromAutocomplete.descripcion,
          id_benefit: benefitFromAutocomplete.id_prestacion,
          id_budget: benefitValue.id_budget ?? 0,
          id_budget_benefit: benefitValue.id_budget_benefit || Math.random(),
          is_manual: false,
          needs_thoot: benefitFromAutocomplete.diente,
          thoot_face: benefitFromAutocomplete.pide_cara,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benefitValue.id_benefit, benefitValue.is_manual]);

  React.useMemo(() => {
    setFieldValueBenefit({
      ...benefitValue,
      total_amount: benefitValue.amount * Number(benefitValue.quantity),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benefitValue.quantity, benefitValue.amount]);

  return (
    <div className="col-span-6">
      {benefitValue.is_manual ? (
        <TextFieldForm
          label="Prestación"
          name="benefit_from_list.description"
        />
      ) : (
        <>
          <label className="block text-sm font-medium">Prestación</label>
          <div className="flex items-center">
            <Autocomplete
              name="benefit_from_list.id_benefit"
              filterKey="id_prestacion"
              placeholder="Buscar prestación"
              customMapper={benefitsMapper}
              noRecordsMessage="No se encontraron prestaciones"
              dataSource={orderedBenefit}
              className="w-full"
            />
            <Tooltip
              type="dark"
              align="right"
              id="autocomplete-tooltip"
              className="ml-2"
              text="Las prestaciones acá mostradas son solamente las que tienen cargo para el paciente."
            >
              <Icons
                className="text-gray-800"
                width={21}
                height={21}
                type="question"
              />
            </Tooltip>
          </div>
        </>
      )}
    </div>
  );
};
