import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import Uploader from './uploader';
import { ArchivesPreview } from './ArchivesPreview';
import CategoryPreview from './category-preview';
import { Icons, Modal, SuccesFull as Empty, Copy } from '#components/index';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Button,
} from '#components/shadcn';
import { startLoading, stopLoading } from '../../../../redux/slices';
import { AppDispatch } from '../../../../redux';
import { categories, emptyStateNotPatient } from '../../../../mocks';
import { SnackbarContext } from '../../../../context';
import { checkMapper } from '../../../../utils';
import { useDemoName, useSetHeight } from '../../../../hooks';
import {
  checkArchives,
  deleteArchives,
  uploadArchives,
  editDescription,
} from '../../../../services';
import { ArchivesFormT } from '#interfaces/components';

const ArchivesForm: React.FC<ArchivesFormT> = ({
  patient,
  onChange,
  userName,
  handlerFocus,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [selectedArchives, setSelectedArchives] = React.useState<any>([]);
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);
  const { isDemo } = useDemoName(userName);
  const gallery: any = patient.galeria;
  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  const selectedHandler = (archive: any) => {
    if (selectedArchives.includes(archive)) {
      const filtered =
        selectedArchives.filter((e: any) => e.id !== archive.id) || [];
      return setSelectedArchives(filtered);
    }
    setSelectedArchives([...selectedArchives, archive]);
  };

  const deleteArchivesHandler = async (archives: any) => {
    try {
      dispatch(startLoading());
      if (isDemo)
        throw new Error(
          'Este es un usuario demo, por favor contactese con Bilog.',
        );
      await deleteArchives(archives);
      showSuccessSnackbar(
        'Los archivos seleccionados se han eliminado con éxito',
      );
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      dispatch(stopLoading());
      closeModal();
    }
  };

  const uploadArchivesHandler = async (archives: any) => {
    const toCheck = checkMapper(archives);
    try {
      dispatch(startLoading());
      if (isDemo)
        throw new Error(
          'Este es un usuario demo, por favor contactese con Bilog.',
        );
      await checkArchives(toCheck);
      await uploadArchives(archives);
      showSuccessSnackbar('Los archivos se han cargado exitosamente');
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      dispatch(stopLoading());
    }
  };

  const editHandler = async (archive: any) => {
    try {
      dispatch(startLoading());
      if (isDemo)
        throw new Error(
          'Este es un usuario demo, por favor contactese con Bilog.',
        );
      await editDescription(archive);
      showSuccessSnackbar('La descripción se ha modificado exitosamente');
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      dispatch(stopLoading());
    }
  };
  const height = useSetHeight(patient?.id_paciente ? 420 : 220);

  return (
    <section
      className="my-5 overflow-hidden overflow-y-scroll"
      style={{ height: `${height}px` }}
    >
      {patient.id_paciente ? (
        <Card>
          <CardHeader>
            <div className="flex justify-between">
              <CardTitle>Listado de archivos</CardTitle>
              <Button
                onClick={openModal}
                className={classNames(
                  'flex px-4 py-2 gap-x-2 items-center',
                  !(selectedArchives.length > 0)
                    ? 'cursor-not-allowed pointer-events-none bg-slate-100 !text-slate-500'
                    : 'cursor-pointer bg-red-500 text-white',
                )}
              >
                <Icons type="delete" className="w-5 h-5" />
                <p>Eliminar archivos</p>
              </Button>
            </div>
          </CardHeader>
          <CardContent>
            {gallery &&
              categories.map(
                (c: any, i: number) =>
                  gallery[c.id] && (
                    <CategoryPreview subtitle={c.subtitle} index={i}>
                      <ArchivesPreview
                        archives={gallery[c.id]}
                        selectedHandler={selectedHandler}
                        editHandler={editHandler}
                        deleteHandler={deleteArchivesHandler}
                      />
                    </CategoryPreview>
                  ),
              )}
            <Uploader
              onClick={uploadArchivesHandler}
              patientId={patient.id_paciente}
            />
          </CardContent>
          <Modal
            title="Eliminar archivo/s"
            isOpenModal={isOpenModal}
            closeModal={closeModal}
            openModal={openModal}
          >
            <Copy className="mb-0">
              ¿Desea eliminar los archivos seleccionados?
            </Copy>
            <Copy className="mb-6 text-red-500 font-bold">
              Esta acción es irreversible y los archivos se perderán.
            </Copy>
            <div className="flex gap-x-4">
              <Button
                className="px-4 py-2 border bg-transparent !text-blue-500 border-blue-500"
                onClick={closeModal}
              >
                Cancelar
              </Button>
              <Button
                className="px-4 py-2 border border-blue-500"
                onClick={() => deleteArchivesHandler(selectedArchives)}
              >
                Aceptar
              </Button>
            </div>
          </Modal>
        </Card>
      ) : (
        <Empty
          typeSucces="emptyState"
          dataMocks={emptyStateNotPatient}
          onClick={handlerFocus}
        />
      )}
    </section>
  );
};

export default ArchivesForm;
