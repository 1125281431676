import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { SpecialtiesCheckbox } from './specialty-checkbox';
import { TimeDatePicker } from './time-date-picker';
import {
  TimeField,
  DropdownForm,
  Form,
  NumericField,
} from '../../../../../../components';
import { days } from '../../../../../../mocks';
import './index.scss';
import { TimeFormT } from '#interfaces/components';

export const TimeForm: React.FC<TimeFormT> = ({
  time,
  specialties,
  offices,
  selectedProf: { currentProfessional, hasSchedule },
  onChange,
}) => {
  const { initialValues, validate } = useForm({
    time,
    selectedProf: currentProfessional,
    offices,
  });

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={useSubmit({
        selectedProf: currentProfessional,
        hasSchedule,
        onChange,
      })}
      enableReinitialize
    >
      <Form ignoreDirty>
        <div className="selected-professional">
          <div className="selected-professional_data">
            <label>Profesional seleccionado</label>
            <p className="name">{currentProfessional.nombre}</p>
          </div>
        </div>
        <div
          className={`grid grid-cols-${
            offices?.length ? '2' : '1'
          } gap-10 pb-5`}
        >
          <DropdownForm
            name="day_num"
            data={days}
            label="Día"
            keyId="id"
            keyData="text"
          />
          {offices?.length ? (
            <DropdownForm
              name="office"
              data={offices}
              label="Sucursal"
              keyId="id_sucursal"
              keyData="sucursal"
            />
          ) : null}
        </div>
        <div className="items-end grid grid-cols-2 gap-10">
          {specialties && <SpecialtiesCheckbox specialties={specialties} />}
        </div>
        <div className="grid grid-cols-3 gap-10 mt-5">
          <TimeField label="Hora inicial" name="from" className="mt-2" />
          <TimeField label="Hora final" name="to" className="mt-2" />
          <NumericField label="Intérvalo" placeholder="30" name="interval" />
        </div>
        <div className="grid grid-cols-2 pb-10">
          <TimeDatePicker
            placeholder="Fecha desde"
            dateName="date_from"
            checkboxName="check_from"
          />
          <TimeDatePicker
            placeholder="Fecha hasta"
            dateName="date_to"
            checkboxName="check_to"
          />
        </div>
      </Form>
    </Formik>
  );
};
