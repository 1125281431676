import React from 'react';
import { GridRow } from './grid-row';
import { GridWrapperT } from '#interfaces/components';

export const GridWrapper: React.FC<GridWrapperT> = ({ data, children }) => {
  function renderColumns() {
    const rawColumns = Object.keys(data[0]);
    const renamedFields = children?.map((child) => {
      const isVisible =
        child.props.visible !== undefined ? child.props.visible : true;

      if (isVisible) {
        return { [child.props.name]: child.props.field };
      }
      return {
        no_visible: 'no_visible',
      };
    });
    const columns = renamedFields.map((field: any) => {
      if (rawColumns.includes(Object.keys(field)?.[0])) {
        return Object.values(field)?.[0];
      }
      return undefined;
    });

    return columns.filter((col: any) => col !== undefined);
  }

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          {renderColumns().map((col: any, index: number) => (
            <th
              key={index}
              scope="col"
              className="whitespace-nowrap px-4 text-left text-sm font-semibold text-gray-900"
            >
              {col}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white overflow-y-scroll">
        {data.map((item, i) => (
          <tr key={i}>
            {children?.map((child: any) => (
              <GridRow
                item={item}
                prop={child.props.name}
                visible={child.props.visible}
                template={child.props.template}
                className={child.props.className || ''}
              />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
