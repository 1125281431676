import React from 'react';
import { DropdownForm, CheckboxForm } from '../../../components';
import { useFieldProps } from '../../../hooks';
import { MedicalInsurancesT } from '#interfaces/general-values';

const defaultGroups = [
  {
    id_grupo: 1,
    descripcion: 'General',
  },
];

export const GroupsDropdown: React.FC<{
  medicalInsurances: MedicalInsurancesT[];
  medInsFieldName: string;
}> = ({ medicalInsurances, medInsFieldName }) => {
  const [groups, setGroups] = React.useState(defaultGroups);
  const groupFieldName = 'medical_insurance_group';
  const checkboxFieldName = 'groups_all';
  const {
    field: { value: medInsValue },
  } = useFieldProps({ name: medInsFieldName });
  const {
    field: { value: checkboxValue },
  } = useFieldProps({ name: checkboxFieldName });

  const {
    field: { value: groupValue },
    setFieldValue,
  } = useFieldProps({ name: groupFieldName });

  React.useEffect(() => {
    if (groupValue) {
      setFieldValue(groupValue);
    }
  }, [groupValue, setFieldValue]);

  React.useEffect(() => {
    const medInsGroups =
      medicalInsurances.filter(
        (m: { [key: string]: any }) => m.id_os === medInsValue,
      )[0]?.grupos || [];

    if (medInsGroups.length > 0) {
      setGroups(medInsGroups);
    } else {
      setGroups(defaultGroups);
    }
  }, [medInsValue, medicalInsurances]);

  return (
    <>
      <DropdownForm
        name={groupFieldName}
        label="Grupos"
        data={groups}
        keyId="id_grupo"
        keyData="descripcion"
        disabled={checkboxValue || groups.length < 2}
      />
      <div className="self-end">
        <CheckboxForm
          label="Todos los grupos"
          description="Se incluirán todos los grupos al reporte."
          disabled={groups.length < 2}
          name={checkboxFieldName}
        />
      </div>
    </>
  );
};
