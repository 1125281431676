import React from 'react';
import { LaboratoryT } from '#interfaces/components/forms';

export function useForm(current: LaboratoryT | undefined) {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues(current),
  );

  React.useEffect(() => {
    setInitialValues(getInitialValues(current));
  }, [current]);

  const validate = React.useCallback((values: any) => {
    const errors: { [key: string]: string } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['name'];
    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues(current: LaboratoryT | undefined) {
  return {
    id_labo: current?.id_labo || 0,
    name: current?.nombre || '',
    contact: current?.contacto || '',
    address: current?.direccion || '',
    location: current?.localidad || '',
    city: current?.provincia || '',
    phone: current?.telefono || '',
    e_mail: current?.e_mail || '',
    balance: current?.saldo || 0,
    balance_usd: current?.saldo_usd || 0,
    cuit: current?.cuit || '',
    observations: current?.observaciones || '',
    enabled: current?.habilitado || true,
    labo_concepts: current?.labo_conceptos || [],
    labo_patients: current?.labo_pacientes || [],
  };
}
