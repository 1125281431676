import React from 'react';
import { formatDateGraphic } from '../../../../utils';

export const FormattedTooltip: React.FC<{
  name: string;
  type: string;
  payload?: any[];
  color: { original: string; comparison: string };
  compare: string;
}> = ({ name, type, payload, color, compare }) => {
  const periodEntries = payload?.filter((entry) => entry.name === 'Principal');

  return (
    <div className="bg-white p-2 border border-slate-200">
      {periodEntries?.map((periodEntry, index) => (
        <div key={index}>
          {!periodEntry.payload?.label ? (
            <h4>
              {formatDateGraphic(periodEntry.payload?.period)}
              {compare &&
                `vs
              ${formatDateGraphic(
                periodEntries[index]?.payload?.comparationPeriod,
              )}`}
            </h4>
          ) : (
            <h4 className="text-sm">
              {periodEntry.payload?.label}
              {name === 'Rentabilidad de paciente por pago en caja' &&
                compare &&
                ` vs ${periodEntry.payload?.comparationLabel}`}
            </h4>
          )}
          <div style={{ color: color.original }}>
            {`${compare ? 'Principal' : 'Valor'}: ${periodEntry.value}`}
          </div>
          {compare && (
            <div style={{ color: color.comparison }}>
              {`Comparacion: ${periodEntries[index]?.payload?.comparationValue}`}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
