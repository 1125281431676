import React from 'react';

export function useForm() {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues(),
  );

  React.useEffect(() => {
    setInitialValues(getInitialValues());
  }, []);

  const validate = (values: any) => {
    const errors: { [key: string]: string } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['appointment_time', 'patient_type'];
    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  };

  return { initialValues, validate };
}

function getInitialValues() {
  return {
    patient_type: 'new',
  };
}
