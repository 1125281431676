import { axiosInstance } from '../utils';

export function importMedins(data: any) {
  const body = {
    ImportableMedicalInsurances: {
      ...data,
      DatabaseName: null,
      MedicalInsuranceList: null,
    },
  };

  return axiosInstance.post('/import/medicalinsurance/available', body);
}

export function sendListMedins(data: any) {
  const body = {
    ImportableMedicalInsurances: {
      ...data,
    },
  };

  return axiosInstance.post('/import/medicalinsurance', body);
}
