import React, { useEffect } from 'react';
import { DropdownForm, TextFieldForm, CheckboxForm } from '../../../commons';
import { useFieldProps } from '../../../../hooks';
import { OfficesT } from '#interfaces/general-values';

export const ControllerOffices: React.FC<{
  offices: OfficesT[];
  variant?: string;
  financesStatitics?: boolean;
}> = ({ offices, variant = 'grid', financesStatitics = false }) => {
  const showFieldNameAllOffices = 'check_all_offices';
  const showFieldNameOfficeId = 'id_subsidiary';
  const showFildNameBenefit = 'benefit_code';
  const {
    field: { value: checkAllOffices },
  } = useFieldProps({ name: showFieldNameAllOffices });
  const { setFieldValue } = useFieldProps({ name: showFieldNameOfficeId });
  const variants: {
    [key: string]: { container: string; subcontainer: string };
  } = {
    grid: {
      container: 'grid grid-cols-12 gap-10 mb-6',
      subcontainer: 'col-span-4 self-end',
    },
    flex: {
      container: 'flex flex-col',
      subcontainer: 'p-2.5',
    },
  };
  useEffect(() => {
    if (checkAllOffices) {
      setFieldValue(null);
    } else {
      setFieldValue(offices && offices[0]?.id_sucursal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAllOffices]);

  return (
    <div className={variants[variant].container}>
      <div className={variants[variant].subcontainer}>
        <CheckboxForm
          label="Todos las sucursales"
          small
          name={showFieldNameAllOffices}
          showError={false}
        />
        <p className="text-sm font-semibold text-gray-400 ml-7">
          Se incluirán todas las sucursales al reporte
        </p>
      </div>
      <div className={variants[variant].subcontainer}>
        <DropdownForm
          name={showFieldNameOfficeId}
          data={offices}
          label="Sucursal"
          placeholder="Sucursales"
          keyId="id_sucursal"
          keyData="sucursal"
          disabled={checkAllOffices}
        />
      </div>
      {!financesStatitics && (
        <div className={variants[variant].subcontainer}>
          <TextFieldForm
            name={showFildNameBenefit}
            label="Prestación"
            placeholder="Ingresa una prestación..."
          />
        </div>
      )}
    </div>
  );
};
