import React from 'react';
import { ValueToPay } from './value-to-pay';
import { CheckboxForm, Icons } from '../../../..';
import {
  useBenefitType,
  useFieldProps,
  useDefaultValuesToPay,
} from '../../../../../hooks';
import { ValuesToPayT } from '#interfaces/components';
import './index.scss';

export const ValuesToPay: React.FC<ValuesToPayT> = ({
  name,
  professionalFieldName,
  benefitTypeFieldName,
  benefitTypes,
  permissions,
}) => {
  const {
    field: { value: professionalId },
  } = useFieldProps({ name: professionalFieldName });
  const { benefitType } = useBenefitType({
    benefitTypeFieldName,
    benefitTypes,
  });
  const canEditFieldName = `${name}.can_edit`;
  const { setFieldValue: setCanEditFieldValue } = useFieldProps({
    name: canEditFieldName,
  });
  const { isSupervisor, mappedPermissions } = permissions;
  const modifyBenefitPermission = mappedPermissions?.pacientes?.filter(
    (p: any) => p.opcion === 'Modificar Prestación',
  );

  const disabled = React.useMemo(
    () =>
      !isSupervisor &&
      (!professionalId || !benefitType || !modifyBenefitPermission),
    [professionalId, benefitType],
  );
  const {
    defaultValuesToPay,
    updateDefaultValuesToPay,
    loadingDefaultValuesToPay,
  } = useDefaultValuesToPay({
    professionalId,
    benefitType,
  });

  React.useEffect(() => {
    if (disabled || loadingDefaultValuesToPay) {
      setCanEditFieldValue(false);
    }
  }, [disabled, loadingDefaultValuesToPay, setCanEditFieldValue]);

  return (
    <>
      <div className="col-span-12">
        <CheckboxForm
          label="Modificar valores a pagar al profesional"
          description="Estos son los valores que se usaran para la liquidación del profesional"
          name={canEditFieldName}
          disabled={disabled}
          showError={false}
        />
        {!disabled && (
          <div onClick={updateDefaultValuesToPay}>
            <Icons
              className="w-6 h-6 text-blue-600 hover:cursor-pointer hover:rotate-45 transition-all"
              type="refresh"
            />
          </div>
        )}
      </div>
      <div className="col-span-6">
        <ValueToPay
          name={name}
          label="Importe a pagar (O.S.) ($):"
          entity="medical_insurance"
          paymentOption="amount"
          defaultValue={defaultValuesToPay?.medicalInsurance?.amount}
        />
        <ValueToPay
          name={name}
          label="Porcentaje a pagar (O.S.) (%):"
          entity="medical_insurance"
          paymentOption="percentage"
          defaultValue={defaultValuesToPay?.medicalInsurance?.percentage}
        />
      </div>
      <div className="col-span-6">
        <ValueToPay
          name={name}
          label="Importe a pagar (Pac.) ($):"
          entity="patient"
          paymentOption="amount"
          defaultValue={defaultValuesToPay?.patient?.amount}
        />
        <ValueToPay
          name={name}
          label="Porcentaje a pagar (Pac.) (%):"
          entity="patient"
          paymentOption="percentage"
          defaultValue={defaultValuesToPay?.patient?.percentage}
        />
      </div>
    </>
  );
};
