import { useState, useEffect, useCallback } from 'react';

export function useForm(
  specialty: { [key: string]: any },
  specialties: { [key: string]: any }[],
) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues(specialty),
  );

  useEffect(() => {
    setInitialValues(getInitialValues(specialty));
  }, [specialty]);

  const validate = useCallback((values: { [key: string]: any }) => {
    const errors: { [key: string]: any } = {};
    const requiredMsg = 'Campo requerido';
    const repeatedCode = 'Codigo en uso';
    const requiredFields = ['code', 'description'];

    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    if (!values.id) {
      const codeFound = specialties?.find(
        (s) => s.cod_especialidad === values.code,
      );
      if (values.code === codeFound?.cod_especialidad) {
        errors['code'] = repeatedCode;
      }
    }

    return errors;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { initialValues, validate };
}

function getInitialValues(specialty: { [key: string]: any }) {
  const { rowversion } = specialty;
  return {
    id: specialty?.id_especialidad || null,
    code: specialty?.cod_especialidad || '',
    description: specialty?.descripcion || '',
    rowversion,
  };
}
