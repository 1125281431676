import React from 'react';
import { format, parseISO } from 'date-fns';
import { Badge } from '../../commons';
import { ArrowUpDown } from 'lucide-react';
import { TranslationsT } from '#interfaces/general-values';

type RowDataT = {
  name: string;
  id_patient: number;
  hc_number: string;
  medical_insurance_name: string;
  id_medical_insurance: number;
  medical_insurance_plan: string;
  nro_afil: string;
  document: string;
  address: string;
  birth_date: string;
  original: any;
};

type ColumnT = {
  toggleSorting: (isSorted: boolean) => void;
  getIsSorted: () => 'asc' | 'desc' | undefined;
};
type ColumnsPropsT = {
  translations: TranslationsT;
};
type ColumnsT = {
  accessorKey: string;
  header: string | (({ column }: { column: ColumnT }) => JSX.Element);
  cell: ({ row }: { row: RowDataT }) => any;
};

export const Columns = ({ translations }: ColumnsPropsT) => {
  const columns: ColumnsT[] = [
    {
      accessorKey: 'name',
      header: 'Apellido y Nombre',
      cell: ({ row }: { row: RowDataT }) => {
        const { name, id_patient } = row.original;

        return (
          name && (
            <p
              id={id_patient}
              className="text-base font-semibold leading-6 text-slate-800 my-1 hover:cursor-pointer"
            >
              {name}
            </p>
          )
        );
      },
    },
    {
      accessorKey: 'hc_number',
      header: 'Historia clínica',
      cell: ({ row }: { row: RowDataT }) => {
        const { hc_number } = row.original;

        return hc_number && <Badge className="w-auto" text={hc_number} />;
      },
    },
    {
      accessorKey: 'medical_insurance_name',
      header: ({ column }: { column: ColumnT }) => {
        return (
          <p
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
            className="flex items-center text-left align-middle font-medium text-slate-400 hover:cursor-pointer hover:text-slate-600"
          >
            {translations.medical_insurance}
            <ArrowUpDown className="ml-2 h-4 w-4 text-slate-400" />
          </p>
        );
      },
      cell: ({ row }: { row: RowDataT }) => {
        const { medical_insurance_name, id_medical_insurance } = row.original;

        return (
          medical_insurance_name && (
            <p
              id={id_medical_insurance}
              className="text-sm leading-6 text-gray-500 my-1"
            >
              {medical_insurance_name}
            </p>
          )
        );
      },
    },
    {
      accessorKey: 'medical_insurance_plan',
      header: ({ column }: { column: ColumnT }) => {
        return (
          <p
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
            className="flex items-center text-left align-middle font-medium text-slate-400 hover:cursor-pointer hover:text-slate-600"
          >
            Plan
            <ArrowUpDown className="ml-2 h-4 w-4 text-slate-400" />
          </p>
        );
      },
      cell: ({ row }: { row: RowDataT }) => {
        const { medical_insurance_plan } = row.original;

        return (
          medical_insurance_plan && (
            <p className="text-sm leading-6 text-gray-500 my-1">
              {medical_insurance_plan}
            </p>
          )
        );
      },
    },
    {
      accessorKey: 'nro_afil',
      header: 'Nro. Afiliado',
      cell: ({ row }: { row: RowDataT }) => {
        const { affiliate_number } = row.original;

        return (
          affiliate_number && (
            <p className="text-sm leading-6 text-gray-500 my-1">
              {affiliate_number}
            </p>
          )
        );
      },
    },
    {
      accessorKey: 'document',
      header: 'DNI',
      cell: ({ row }: { row: RowDataT }) => {
        const { document } = row.original;

        return (
          document && (
            <p className="text-sm leading-6 text-gray-500 my-1">{document}</p>
          )
        );
      },
    },
    {
      accessorKey: 'address',
      header: 'Domicilio',
      cell: ({ row }: { row: RowDataT }) => {
        const { address } = row.original;

        return (
          address && (
            <p className="text-sm leading-6 text-gray-500 my-1">{address}</p>
          )
        );
      },
    },
    {
      accessorKey: 'birth_date',
      header: 'Fecha de Nacimiento',
      cell: ({ row }: { row: RowDataT }) => {
        const { birth_date } = row.original;

        return (
          birth_date && (
            <p className="text-sm leading-6 text-gray-500 my-1">
              {format(parseISO(birth_date), 'dd/MM/yyyy')}
            </p>
          )
        );
      },
    },
  ];
  return columns;
};
