import React from 'react';

export const Supervisor: React.FC<{ [key: string]: any }> = (props) => {
  const { supervisor } = props;
  return supervisor ? (
    <span className="supervisor yes">Si</span>
  ) : (
    <span className="supervisor no">No</span>
  );
};
