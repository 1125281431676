import React from 'react';
import { isMajorOrMinor } from '../../../../../../utils';
import { benefitFormFields } from '../../../../../../mocks';
export function useForm(benefit: { [key: string]: any }) {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues(benefit),
  );

  React.useEffect(() => {
    setInitialValues(getInitialValues(benefit));
  }, [benefit]);

  const validate = React.useCallback((values: { [key: string]: any }) => {
    const errors: { [key: string]: any } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = [
      'code_medical_insurance',
      'code_medical_insurance_bill',
      'description',
    ];

    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues(benefit: { [key: string]: any }) {
  const { rowversion } = benefit;
  const { unitField } = benefitFormFields;
  const unit = unitField.find((b: any) => b.text === benefit.unit);

  return {
    id_plan: benefit?.id_plan,
    id_benefit: benefit?.id_benefit || 0,
    id_benefit_to_do: benefit?.id_benefit_to_do,
    id_group: benefit?.id_group,
    id_specialty: benefit?.id_specialty,
    enable_specialty: !benefit?.id_specialty,
    code_medical_insurance: benefit?.code_medical_insurance,
    code_medical_insurance_bill: benefit?.code_medical_insurance_bill,
    description: benefit?.description,
    billing_medical_insurance: benefit?.billing_medical_insurance || 0,
    billing_patient: benefit?.billing_patient || 0,
    tooth: benefit?.tooth || 'NIN',
    tooth_face: benefit?.tooth_face || false,
    catastral: benefit?.catastral || false,
    intern: benefit?.intern || false,
    enabled: benefit?.enabled || true,
    enabled_draw: !benefit?.draw,
    draw: benefit?.draw || null,
    unit: unit?.id || 'none',
    amount: benefit?.amount || 0,
    enable_group_belongs: !benefit?.group_belongs,
    group_belongs_name: benefit?.group_belongs_name,
    amount_patient: benefit?.amount_patient || 9999,
    amount_unit: benefit?.amount_unit || 9999,
    age_text: benefit.age ? isMajorOrMinor(benefit.age) : 'none',
    age: benefit?.age || 0,
    is_usd: benefit?.is_usd || false,
    rowversion,
  };
}
