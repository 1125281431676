import React from 'react';
import {
  CheckboxForm,
  DropdownForm,
  TextFieldForm,
} from '../../../../components';
import { useFieldProps } from '../../../../hooks';
import { MedicalInsurancesT, TranslationsT } from '#interfaces/general-values';

export const ControllerMedicalInsurance: React.FC<{
  translations: TranslationsT;
  medicalInsurances: MedicalInsurancesT[];
}> = ({ translations, medicalInsurances }) => {
  const isInitialMount = React.useRef(true);
  const {
    field: { value: medicalInsuranceValue },
  } = useFieldProps({ name: 'medical_insurance' });
  const { setFieldValue: setValueMedInsPlan } = useFieldProps({
    name: 'medical_insurance_plan',
  });
  const {
    field: { value: allowInputValue },
  } = useFieldProps({ name: 'allow_input' });
  const plans = React.useMemo(() => {
    const medicalInsurance = medicalInsurances?.find(
      (m) => m.id_os === medicalInsuranceValue,
    );

    return medicalInsurance?.planes ?? [];
  }, [medicalInsuranceValue, medicalInsurances]);
  const medicalInsurancesEnabled = medicalInsurances?.filter(
    (m) => !m.inhabilitada,
  );
  React.useMemo(() => {
    isInitialMount.current
      ? (isInitialMount.current = false)
      : setValueMedInsPlan(plans[0]?.id_plan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicalInsuranceValue]);

  return (
    <>
      <span className="col-span-2">
        <CheckboxForm
          name="allow_input"
          label={`Ingresar ${translations.medical_insurance.toLowerCase()} manualmente`}
        />
      </span>
      {!allowInputValue ? (
        <>
          <DropdownForm
            name="medical_insurance"
            data={medicalInsurancesEnabled}
            label={translations.medical_insurances}
            keyId="id_os"
            keyData="obra_social"
            className="col-span-1"
          />
          <DropdownForm
            name="medical_insurance_plan"
            data={plans}
            label="Plan"
            keyId="id_plan"
            keyData="nombre"
            className="col-span-1"
          />
        </>
      ) : (
        <>
          <TextFieldForm
            label={translations.medical_insurances}
            name="medical_insurance"
            className="col-span-1"
            placeholder={`Ingrese ${translations.medical_insurance.toLowerCase()}`}
          />
          <TextFieldForm
            label="Plan"
            name="medical_insurance_plan"
            className="col-span-1"
            placeholder="Ingrese plan"
          />
        </>
      )}
    </>
  );
};
