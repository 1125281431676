import React from 'react';
import { useDispatch } from 'react-redux';
import { createBenefit, updateBenefit } from '../../../../services';
import { SnackbarContext, FormContext } from '../../../../context';
import { dateToISO, checkToothFaces } from '../../../../utils';
import { startLoading, stopLoading } from '../../../../redux/slices';

export const useSubmit = ({
  userId,
  benefit,
  onChange,
}: {
  userId: string;
  benefit: any;
  onChange: () => void;
}) => {
  const dispatch = useDispatch();
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (spec: any) => {
    try {
      dispatch(startLoading());
      await axiosPromise({ userId, benefit, spec });
      showSuccessSnackbar(
        benefit
          ? 'La prestación se ha actualizado con éxito'
          : 'La prestación se ha creado con éxito',
      );
      closeForm();
      onChange();
    } catch (err: any) {
      showErrorSnackbar(err.message);
    } finally {
      dispatch(stopLoading());
    }
  };
};

function axiosPromise({
  userId,
  benefit,
  spec,
}: {
  userId: string;
  benefit: any;
  spec: any;
}) {
  const apiSpec = transformSpec({ spec, userId, benefit });

  return benefit ? updateBenefit(apiSpec) : createBenefit(apiSpec);
}

function transformSpec({
  spec,
  userId,
  benefit,
}: {
  userId: string;
  benefit: any;
  spec: any;
}) {
  const apiSpec = {
    periodo: spec.period,
    fecha: dateToISO(spec.date),
    id_prof: spec.professional,
    id_paciente: spec.id_patient,
    id_prestacion: spec.benefit_type,
    diente_sector: spec.tooth_section,
    cara:
      spec.tooth_faces &&
      Object.keys(checkToothFaces(spec.tooth_faces)).join('').toUpperCase(),
    es_usd: spec.is_usd,
    importe_os: spec.values_to_charge.from_medical_insurance,
    importe_pac: spec.values_to_charge.from_patient,
    obs: spec.notes,
    comi_os: spec.values_to_pay.medical_insurance_percentage,
    comi_pac: spec.values_to_pay.patient_percentage,
    comi_os_imp: spec.values_to_pay.medical_insurance_amount,
    comi_pac_imp: spec.values_to_pay.patient_amount,
    pagada: benefit?.pagada ?? false,
    solp: benefit?.solp ?? false,
    fecha_solp: benefit?.fecha_solp ?? null,
    liquidada: benefit?.liquidada ?? false,
    rechazada: benefit?.rechazada ?? false,
    id_usuario: userId,
    fecha_liqui: benefit?.fecha_liqui ?? null,
    fecha_recha: benefit?.fecha_recha ?? null,
    fecha_pago: benefit?.fecha_pago ?? null,
    id_liq_prof: benefit?.id_liq_prof || null,
    id_liq_solp: benefit?.id_liq_solp || null,
    id_sucursal: spec.office || null,
    ignorarAuditoria: benefit?.ignorarAuditoria ?? true,
  };

  return benefit
    ? [
        {
          ...apiSpec,
          id_todos: benefit.id_todos,
          rowversion: benefit.rowversion,
        },
      ]
    : [apiSpec];
}
