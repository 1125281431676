import * as React from 'react';

export const Columns = () => {
  return [
    {
      accessorKey: 'date',
      header: 'Fecha',
      cell: ({ row }: { row: any }) => {
        const { date } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {date}
          </p>
        );
      },
    },
    {
      accessorKey: 'affiliate',
      header: 'N° de Afiliado',
      cell: ({ row }: { row: any }) => {
        const { affiliate } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {affiliate}
          </p>
        );
      },
    },
    {
      accessorKey: 'med_ins_code',
      header: 'Cod. de Prestación',
      cell: ({ row }: { row: any }) => {
        const { med_ins_code } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {med_ins_code}
          </p>
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Descripción',
      cell: ({ row }: { row: any }) => {
        const { description } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {description}
          </p>
        );
      },
    },
    {
      accessorKey: 'thoot_section',
      header: 'Diente/s',
      cell: ({ row }: { row: any }) => {
        const { thoot_section } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {thoot_section}
          </p>
        );
      },
    },
    {
      accessorKey: 'thoot_face',
      header: 'Caras',
      cell: ({ row }: { row: any }) => {
        const { thoot_face } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {thoot_face}
          </p>
        );
      },
    },
    {
      accessorKey: 'med_ins_amount',
      header: 'Importe',
      cell: ({ row }: { row: any }) => {
        const { med_ins_amount } = row.original;

        return (
          <p className="text-base font-semibold leading-6 text-green-500 my-1">
            {med_ins_amount}
          </p>
        );
      },
    },
  ];
};
