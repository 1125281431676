import React from 'react';
import { FormikHelpers } from 'formik';
import { copyArticles } from '../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../context';

export const useSubmit = (onChange: () => void) => {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      const {
        request: { response },
      } = await axiosPromise(spec);
      const msg = JSON.parse(response);
      onChange();
      showSuccessSnackbar(msg.successMessage);
      formikActions.resetForm();
      closeForm();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} ${err.status}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
};

function axiosPromise(spec: { [key: string]: any }) {
  const apiSpec = transformSpec(spec);

  return copyArticles(apiSpec);
}

function transformSpec(spec: { [key: string]: any }) {
  let apiSpec = {
    OriginId: spec.laboratory_origin,
    DestinationId: spec.laboratory_destination,
    ItemsIds: spec.selected_articles,
    ReplaceItems: spec.replace_destination,
  };

  return apiSpec;
}
