import { useCallback, useEffect, useState } from 'react';
import { RegionsT } from '#interfaces/general-values';

export function useForm({
  props,
  regions,
}: {
  props: any;
  regions: RegionsT[];
}) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues({ props, regions }),
  );
  useEffect(() => {
    setInitialValues(getInitialValues({ props, regions }));
  }, [props, regions]);

  const validate = useCallback((values: any) => {
    const errors: { [key: string]: string } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['last_name', 'first_name', 'phone', 'country'];
    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues({
  props,
  regions,
}: {
  props: any;
  regions: RegionsT[];
}) {
  const country =
    props.country &&
    regions?.find((e: any) => e.iso3 === props.country_iso3.toUpperCase());
  const birthday = props?.birthday?.split('T')[0] || '';

  return {
    first_name: props?.first_name || '',
    last_name: props?.last_name || '',
    country: country?.name || '',
    country_iso3: props?.country_iso3?.toUpperCase() || '',
    birthday,
    phone: props?.phone || '',
    is_whatsapp: props?.is_whatsapp || false,
  };
}
