import { axiosInstance, platform } from '../utils';

export const newOrder = (data: { [key: string]: any }) => {
  const body = {
    patientLaboratory: {
      ...data,
    },
    plataforma: platform,
  };
  return axiosInstance.post('patient/laboratory/new', body);
};

export const updateOrder = (data: { [key: string]: any }) => {
  const body = {
    patientLaboratory: {
      ...data,
    },
    plataforma: platform,
  };
  return axiosInstance.post('patient/laboratory/update', body);
};

export const deleteOrder = (data: { [key: string]: any }) => {
  const body = {
    patientLaboratory: {
      ...data,
    },
    plataforma: platform,
  };
  return axiosInstance.post('patient/laboratory/delete', body);
};
