import React from 'react';
import { RadioButton } from '../../../../../components';
import { useFieldProps } from '../../../../../hooks';

export const SelectCriteria: React.FC<{
  name: string;
  criteria: string;
  oppositFieldName: string;
  label: string;
}> = ({ name, criteria, oppositFieldName, label }) => {
  const fieldName = `${name}.${criteria}`;
  const oppositeFieldName = `${name}.${oppositFieldName}`;
  const { setFieldValue } = useFieldProps({ name: fieldName });
  const { setFieldValue: setOppositeFieldValue } = useFieldProps({
    name: oppositeFieldName,
  });

  const handleOnChange = () => {
    setFieldValue(true);
    setOppositeFieldValue(false);
  };

  return (
    <RadioButton name={fieldName} label={label} onChange={handleOnChange} />
  );
};
