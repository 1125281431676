import * as React from 'react';

export const columns = [
  {
    accessorKey: 'fecha',
    header: 'Fecha',
    cell: ({ row }: { row: any }) => {
      const { fecha } = row.original;

      return (
        <p className="font-bold text-sm leading-6 text-gray-500 my-1">
          {fecha}
        </p>
      );
    },
  },
  {
    accessorKey: 'hora',
    header: 'Hora',
    cell: ({ row }: { row: any }) => {
      const { hora } = row.original;

      return (
        <p className="text-base font-semibold leading-6 text-slate-800 my-1">
          {hora}
        </p>
      );
    },
  },
  {
    accessorKey: 'paciente',
    header: 'Paciente',
    cell: ({ row }: { row: any }) => {
      const { paciente } = row.original;

      return (
        <p className="text-base font-semibold leading-6 text-slate-800 my-1">
          {paciente}
        </p>
      );
    },
  },
  {
    accessorKey: 'telefono',
    header: 'Teléfono',
    cell: ({ row }: { row: any }) => {
      const { telefono } = row.original;

      return (
        <p className="text-base font-semibold leading-6 text-slate-800 my-1">
          {telefono}
        </p>
      );
    },
  },
  {
    accessorKey: 'profesional',
    header: 'Profesional',
    cell: ({ row }: { row: any }) => {
      const { profesional } = row.original;

      return (
        <p className="text-base font-semibold leading-6 text-slate-800 my-1">
          {profesional}
        </p>
      );
    },
  },
];
