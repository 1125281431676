import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from './redux';
import './styles/index.scss';
import App from './App';
import { SnackbarProvider, LoadingProvider, ModalProvider } from './context';

const persistor = persistStore(store);

ReactDOM.render(
  <StrictMode>
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <ModalProvider>
          <SnackbarProvider>
            <LoadingProvider>
              <App />
            </LoadingProvider>
          </SnackbarProvider>
        </ModalProvider>
      </Provider>
    </PersistGate>
  </StrictMode>,
  document.getElementById('main'),
);
