import React from 'react';
import {
  MedicalInsurancesT,
  OfficesT,
  TurnsCombinationT,
} from '#interfaces/general-values';

type DataForm = {
  appointment: TurnsCombinationT;
  userId: number;
  date: Date | string;
  offices: OfficesT[];
  medicalInsurances: MedicalInsurancesT[];
};
export function useForm({
  appointment,
  userId,
  date,
  offices,
  medicalInsurances,
}: DataForm) {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues({ appointment, userId, date, offices, medicalInsurances }),
  );
  React.useEffect(() => {
    setInitialValues(
      getInitialValues({
        appointment,
        userId,
        date,
        offices,
        medicalInsurances,
      }),
    );
  }, [appointment, userId, date, offices, medicalInsurances]);

  const validate = React.useCallback((values: any) => {
    const errors: { [key: string]: string } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['patient'];
    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues({
  appointment,
  userId,
  date,
  offices,
  medicalInsurances,
}: DataForm) {
  const office = offices && offices.length > 1 ? offices[0].id_sucursal : null;
  const allowInputValue =
    appointment?.appointment_type?.status === 'free' ||
    medicalInsurances?.find(
      (m) => m.obra_social === appointment?.medical_insurance?.name,
    );

  const medicalInsurance = medicalInsurances?.find(
    (m) => m.obra_social === appointment?.medical_insurance?.name,
  );
  const medicalInsurancePlan = medicalInsurance?.planes?.find(
    (m: any) => m.nombre === appointment?.medical_insurance?.plan,
  );

  return {
    id_professional: appointment?.id_professional || appointment?.id_prof,
    date: appointment?.date ?? new Date(date).toISOString(),
    id_user: userId,
    office: appointment?.id_office || office,
    hour: appointment?.time || appointment?.hora,
    afiliate_number: appointment?.medical_insurance?.affiliate_number ?? null,
    medical_insurance:
      medicalInsurance?.id_os ||
      appointment?.medical_insurance?.name ||
      medicalInsurances[0]?.id_os,
    medical_insurance_plan:
      medicalInsurancePlan?.id_plan ||
      appointment?.medical_insurance?.plan ||
      medicalInsurance?.planes[0]?.id_plan,
    notes: appointment?.observation ?? null,
    patient: appointment?.patient_name ?? null,
    phone: appointment?.patient_phone ?? null,
    allow_input: !allowInputValue,
  };
}
