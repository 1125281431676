import React from 'react';
import {
  OfficesT,
  ProfessionalT,
  SelectedHisCliT,
} from '#interfaces/general-values';

export function useForm({
  clinicHistory,
  offices,
  professionals,
}: {
  clinicHistory: SelectedHisCliT;
  offices: OfficesT[];
  professionals: ProfessionalT[];
}) {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues({ clinicHistory, offices, professionals }),
  );

  React.useEffect(() => {
    setInitialValues(
      getInitialValues({ clinicHistory, offices, professionals }),
    );
  }, [clinicHistory, offices, professionals]);

  const validate = React.useCallback(
    (values: { [key: string]: any }) => {
      const errors: { [key: string]: string | null } | null = {};
      const requiredMsg = 'Campo requerido';
      const requiredFields: string[] = ['hist_cli', 'professional'];
      if (offices?.length) requiredFields.push('office');
      requiredFields.forEach((requiredField) => {
        if (!values[requiredField]) {
          errors[requiredField] = requiredMsg;
        }
      });

      return errors;
    },
    [offices?.length],
  );

  return { initialValues, validate };
}

function getInitialValues({
  clinicHistory,
  offices,
  professionals,
}: {
  clinicHistory: SelectedHisCliT;
  offices: OfficesT[];
  professionals: ProfessionalT[];
}) {
  const office = offices && offices[0];
  const getProfessional = () => {
    if (offices && offices.length) {
      return professionals.find(
        (prof) =>
          prof.prof_horarios.filter((ph: { [key: string]: any }) => {
            return ph.id_sucursal === office.id_sucursal;
          })?.length > 0,
      );
    }

    return professionals.filter((p) => p.habilitado === true)?.[0];
  };
  const professional = getProfessional();

  const initialValues = {
    date: clinicHistory?.date || new Date().toISOString(),
    hist_cli: clinicHistory?.text,
    professional:
      clinicHistory?.id_professional || professional?.id_prof || null,
  };

  if (offices && offices.length) {
    return {
      ...initialValues,
      office: clinicHistory?.id_office || office?.id_sucursal,
    };
  }

  return initialValues;
}
