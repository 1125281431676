import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import {
  startLoading,
  stopLoading,
  fetchPatientsList,
  resetPatientsForm,
  resetPatientList,
} from '../../redux/slices';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import { useKeypress } from '../../hooks';
import { SearchPatientFormT } from '#interfaces/components';
import { AppDispatch } from '../../redux';
import { SnackbarContext } from '../../context';
import { PatientT } from '#interfaces/general-values';

export const SearchPatientForm: React.FC<SearchPatientFormT> = ({
  searchResults,
  isOpen,
  close,
  onSubmit,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [query, setQuery] = React.useState('');
  const { showErrorSnackbar } = React.useContext(SnackbarContext);

  const filteredPeople =
    query === ''
      ? []
      : searchResults.filter((person) => {
          return person.ape_nom.toLowerCase().includes(query.toLowerCase());
        });

  useKeypress({
    key: 'Enter',
    action: async () => {
      // This is for cross-browsing compatibility
      dispatch(resetPatientsForm());
      dispatch(startLoading());
      await dispatch(
        fetchPatientsList({
          input: query,
          isContained: true,
          errSnk: showErrorSnackbar,
        }),
      );
      dispatch(stopLoading());
    },
  });

  const handleLeave = () => {
    setQuery('');
    dispatch(resetPatientList());
  };

  return (
    <Transition.Root
      show={isOpen}
      as={React.Fragment}
      afterLeave={handleLeave}
      appear
    >
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-4xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox
                onChange={(person: PatientT) => {
                  if (person && person.id_paciente) {
                    onSubmit(person.id_paciente);
                    close();
                  }
                }}
              >
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Buscar paciente..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                {searchResults.length > 0 && (
                  <Combobox.Options
                    static
                    className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
                  >
                    <Combobox.Option
                      value
                      className="px-4 py-2 bg-slate-50 border-b-2 border-slate-200"
                    >
                      <div className="grid grid-cols-5 gap-5 font-bold">
                        <p>Nombre y Apellido</p>
                        <p>Nro. Hist Clinica</p>
                        <p>Fecha de nacimiento</p>
                        <p>Obra Social</p>
                        <p>Plan</p>
                      </div>
                    </Combobox.Option>
                    {searchResults.map((person) => (
                      <Combobox.Option
                        key={person.id_paciente}
                        value={person}
                        className={({ active }) =>
                          classNames(
                            'cursor-pointer select-none px-4 py-2 group',
                            active && 'bg-blue-600 text-white',
                          )
                        }
                      >
                        <div className="grid grid-cols-5 gap-5">
                          <p className="text-slate-900 font-bold group-hover:text-white">
                            {person.ape_nom}
                          </p>
                          <p className="text-slate-600 group-hover:text-white">
                            {person.hc_nro}
                          </p>
                          <p className="text-slate-600 group-hover:text-white">
                            {new Date(person.f_nacimiento).toLocaleDateString()}
                          </p>
                          <p className="text-slate-600 group-hover:text-white">
                            {person.id_planNavigation.id_osNavigation.codigo_os}
                          </p>
                          <p className="text-slate-600 group-hover:text-white">
                            {person.id_planNavigation.codigo_plan}
                          </p>
                        </div>
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}

                {query !== '' && filteredPeople.length === 0 && (
                  <p className="p-4 text-sm text-gray-500">
                    No se encontraron pacientes.
                  </p>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
