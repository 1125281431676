import React from 'react';
import classNames from 'classnames';
import { Tooltip, Icons } from '..';
import { ItemT } from '#interfaces/components';

export const Item: React.FC<ItemT> = ({
  item,
  icon,
  selected,
  onClickHandler,
}) => {
  const itemCss = classNames(
    'hover:cursor-pointer',
    selected ? 'text-white' : 'text-slate-400',
  );

  return (
    <div
      className="flex flex-col items-center w-[40px] first:mt-0 mt-6 mx-auto px-6 py-4 hover:bg-slate-900 hover:cursor-pointer rounded-md print:hidden"
      onClick={onClickHandler}
    >
      <Tooltip type="dark" align="right" id={`${icon}-tooltip`} text={item}>
        <div onClick={onClickHandler}>
          <Icons className={itemCss} width={21} height={21} type={icon} />
        </div>
      </Tooltip>
    </div>
  );
};
