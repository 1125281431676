import React from 'react';
import { formateDateToString } from '../../../utils';
import './index.scss';
import { PatientResultsT } from '#interfaces/components';

const PatientResults: React.FC<PatientResultsT> = ({
  results,
  onClickHandler,
  translations,
}) => {
  return (
    <>
      <div className="searchbar--search-for__labels flex items-center py-2">
        <p className="row-label label-mobile">Nombre y apellido</p>
        <p className="row-label label-mobile">Nº de HC</p>
        <p className="row-label">Fecha de nacimiento</p>
        <p className="row-label">{translations.medical_insurance}</p>
        <p className="row-label">Plan</p>
        <p className="row-label">Nº de afiliado</p>
        <p className="row-label">D.N.I</p>
        <p className="row-label">Domicilio</p>
      </div>
      <div>
        {results.map((item) => {
          const {
            id_paciente,
            nro_afil,
            documento,
            domicilio,
            ape_nom,
            hc_nro,
            id_planNavigation: { nombre, id_osNavigation },
            f_nacimiento,
          } = item;

          return (
            <div
              className="searchbar--search-for__patient-row py-3 flex"
              onClick={() => onClickHandler(id_paciente)}
              key={id_paciente}
            >
              <p className="patient-row--item label-mobile patient-name">
                {ape_nom}
              </p>
              <p className="patient-row--item label-mobile ch-number">
                {hc_nro}
              </p>
              <p className="patient-row--item">
                {formateDateToString(f_nacimiento)}
              </p>
              <p className="patient-row--item">{id_osNavigation.obra_social}</p>
              <p className="patient-row--item">{nombre}</p>
              <p className="patient-row--item">{nro_afil}</p>
              <p className="patient-row--item">{documento}</p>
              <p className="patient-row--item">{domicilio}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PatientResults;
