import * as React from 'react';
import classNames from 'classnames';
import { ColumnsT } from '#interfaces/tables/benefit';
import { Icons } from '#components/commons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '#components/shadcn';

export const Columns = ({ action }: ColumnsT) => {
  return [
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="relative p-2 hover:cursor-pointer flex-none">
                <Icons
                  type="context"
                  className="text-gray-500 hover:text-gray-900 h-4 w-4"
                />
                <span className="sr-only">Abrir menu</span>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="start">
              <DropdownMenuGroup>
                <DropdownMenuItem
                  className="hover:cursor-pointer"
                  onSelect={() => action(row.original, 'edit')}
                >
                  <Icons type="edit" className="mr-2 h-4 w-4" />
                  <span>Editar</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="hover:cursor-pointer text-red-500"
                  onSelect={() => action(row.original, 'delete')}
                >
                  <Icons type="delete" className="mr-2 h-4 w-4" />
                  <span>Eliminar</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: 'code',
      header: 'Código',
      cell: ({ row }: { row: any }) => {
        const { code } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{code}</p>
        );
      },
    },
    {
      accessorKey: 'concept',
      header: 'Concepto',
      cell: ({ row }: { row: any }) => {
        const { concept } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{concept}</p>
        );
      },
    },
    {
      accessorKey: 'import',
      header: 'Importe',
      cell: ({ row }: { row: any }) => {
        const { amount } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{amount}</p>
        );
      },
    },
    {
      accessorKey: 'is_usd',
      header: 'En usd',
      cell: ({ row }: { row: any }) => {
        const { is_usd } = row.original;

        return (
          <Icons
            type={is_usd ? 'check_clean' : 'no_simbol'}
            className={classNames(
              'h-4 w-4',
              is_usd ? 'text-green-500' : 'text-slate-500',
            )}
          />
        );
      },
    },
    {
      accessorKey: 'enabled',
      header: 'Habilitado',
      cell: ({ row }: { row: any }) => {
        const { enabled } = row.original;
        return (
          <Icons
            type={enabled ? 'check_clean' : 'no_simbol'}
            className={classNames(
              'h-4 w-4 mr-2',
              enabled ? 'text-green-500' : 'text-slate-500',
            )}
          />
        );
      },
    },
  ];
};
