import React from 'react';
import { Formik } from 'formik';
import {
  CheckboxForm,
  DropdownForm,
  TextFieldForm,
  Form,
} from '../../../../../components';
import { TaxCategory } from '../../../../../mocks';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { GeneralFormtT } from '#interfaces/components';

export const GeneralForm: React.FC<GeneralFormtT> = ({
  params,
  regions,
  onChange,
}) => {
  const { initialValues, validate } = useForm({ params, regions });

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={useSubmit({ params, onChange, regions })}
      enableReinitialize
    >
      <Form>
        <div className="grid grid-cols-4 gap-10">
          <TextFieldForm multiline label="Nombre" name="name" />
          <TextFieldForm multiline label="Dirección" name="address" />
          <TextFieldForm multiline label="Teléfono" name="phone" />
          <TextFieldForm multiline label="Email" name="email" />
        </div>
        <div className="grid grid-cols-4 gap-10 pb-8 mt-6 items-end">
          <TextFieldForm multiline label="CUIT" name="cuit" />
          <DropdownForm
            name="tax_category"
            data={TaxCategory}
            label="Condición Fiscal"
            keyId="id"
            keyData="text"
          />
          <DropdownForm
            name="what_region"
            data={regions}
            label="Región"
            keyId="idCountry"
            keyData="name"
          />
          <CheckboxForm
            name="use_usd"
            label="Usar doble moneda"
            description="Permite a los usuarios cargar prestaciones y pagos en USD."
            showError={false}
            small
          />
        </div>
      </Form>
    </Formik>
  );
};
