import React from 'react';
import classNames from 'classnames';
import { TemplatesT } from '#interfaces/components';

export const Templates: React.FC<TemplatesT> = (props) => {
  const { name, currentValue, onClick } = props;
  const [selected, setSelected] = React.useState(currentValue === name);

  React.useEffect(() => {
    currentValue === name ? setSelected(true) : setSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const imgCss = classNames(
    'w-full h-auto rounded-md border-2 relative hover:border-blue-500 hover:cursor-pointer transition-all',
    selected ? 'border-blue-500 shadow-md' : 'shadow border-transparent',
  );

  return (
    <img
      className={imgCss}
      onClick={() => onClick(name)}
      src={`https://app.bilog.com.ar/resources/templates/${name}.png`}
      alt="Whatsapp template"
    />
  );
};
