import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { Controller } from './controller';
import { GroupsDropdown } from './groups-dropdown';
import { CopyBenefitsForm } from './copy-benefits-form';
import { ControllercheckBoxSelectAll } from './controller-checkbox-select-all';
import { Form, Button, CheckboxForm } from '#components/index';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '#components/shadcn';
import { MedicalInsurancesFormCopyT } from '#interfaces/components';

const MedicalInsurancesFormCopy: React.FC<MedicalInsurancesFormCopyT> = ({
  medicalInsurances,
  selectedMedOrigin,
  selectedMedDestination,
  setMedicalDestination,
  setMedicalOrigin,
  translations,
  isOpenBenefits,
  benefits,
  benefitsOrigin,
  onChangeOrigin,
  onChangeDestination,
}) => {
  const medInsOriginFieldName = 'medical_insurance_origin';
  const medInsDestinationFieldName = 'medical_insurance_destination';
  const showEnabledFieldName = 'show_enabled';
  const { initialValues } = useForm({
    medicalInsurances,
    selectedMedOrigin,
    selectedMedDestination,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={useSubmit()}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <div className="pt-5 overflow-hidden overflow-y-scroll pb-10">
          <Form showActions={false}>
            <div className="grid grid-cols-6 gap-5 mb-5">
              <Card className="col-span-3">
                <CardHeader>
                  <CardTitle>
                    {`${translations.medical_insurance} de origen`}
                  </CardTitle>
                  <CardDescription>
                    {`Selección de ${translations.medical_insurance.toLowerCase()} de origen a copiar.`}
                  </CardDescription>
                </CardHeader>
                <CardContent className="grid grid-cols-6 gap-5">
                  <Controller
                    name={medInsOriginFieldName}
                    showEnabledFieldName={showEnabledFieldName}
                    label={`Seleccionar ${translations.medical_insurance}`}
                    medicalInsurances={medicalInsurances}
                    onMedInsChange={setMedicalOrigin}
                  />
                  <GroupsDropdown
                    medIns={medicalInsurances}
                    medInsFieldName={medInsOriginFieldName}
                    planFieldName="plan_origin"
                    onChange={onChangeOrigin}
                  />
                  <div className="col-span-6">
                    <ControllercheckBoxSelectAll
                      label="Copiar todas las prestaciones"
                      description="Al tildar esta opción se copiarán todas las prestaciones de la obra social de origen a la obra social de destino."
                      name="selectAll"
                      benefits={benefitsOrigin}
                    />
                  </div>
                </CardContent>
              </Card>
              <Card className="col-span-3">
                <CardHeader>
                  <CardTitle>
                    {`${translations.medical_insurance} de destino`}
                  </CardTitle>
                  <CardDescription>
                    {`Selección de ${translations.medical_insurance.toLowerCase()} a donde se copiaran las prestaciones.`}
                  </CardDescription>
                </CardHeader>
                <CardContent className="grid grid-cols-6 gap-5">
                  <Controller
                    name={medInsDestinationFieldName}
                    showEnabledFieldName={showEnabledFieldName}
                    label={`Seleccionar ${translations.medical_insurance}`}
                    medicalInsurances={medicalInsurances}
                    onMedInsChange={setMedicalDestination}
                  />
                  <GroupsDropdown
                    medIns={medicalInsurances}
                    medInsFieldName={medInsDestinationFieldName}
                    planFieldName="plan_destination"
                    onChange={onChangeDestination}
                  />
                  <div className="col-span-6">
                    <CheckboxForm
                      label="Reemplazar datos en destino"
                      description="Reemplazar datos en el caso que el código de la prestación ya exista en destino."
                      name="replace_destination"
                    />
                  </div>
                </CardContent>
              </Card>
              <div className="col-span-3">
                <CopyBenefitsForm
                  canSelect
                  benefits={benefitsOrigin}
                  translations={translations}
                  selectedMed={selectedMedOrigin}
                  isOpenTabAction={isOpenBenefits}
                />
              </div>
              <div className="col-span-3">
                <CopyBenefitsForm
                  benefits={benefits}
                  translations={translations}
                  selectedMed={selectedMedDestination}
                  isOpenTabAction={isOpenBenefits}
                />
              </div>
            </div>
            <Button onClick={(e) => handleSubmit(e)}>
              Copiar prestaciones
            </Button>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default MedicalInsurancesFormCopy;
