import React from 'react';
import { Columns } from './columns';
import { Data } from './data';
import { benefitsGridMapper } from '../../../../utils';

export const Table: React.FC<any> = ({
  data: { benefits, groups },
  config,
  action,
}) => {
  return (
    <div className="mt-5 mx-auto w-full">
      <Data
        columns={Columns({ action, config })}
        data={benefitsGridMapper(benefits, groups)}
        config={config}
      />
    </div>
  );
};
