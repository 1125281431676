import React from 'react';
import { Formik } from 'formik';
import { useForm } from './use-form';
import { useSubmit } from './use-submit';
import { TextFieldForm, Form } from '../../../../components';

import './index.scss';
import { ObservationTurnFormT } from '#interfaces/components';

const ObservationForm: React.FC<ObservationTurnFormT> = ({
  appointment,
  userId,
  showAlert,
  onChange,
  date,
  offices,
}) => {
  const { initialValues } = useForm({ appointment, userId, date, offices });
  const focused = React.useRef<any>(null);

  React.useEffect(() => {
    focused.current.focus();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={useSubmit(onChange)}
      enableReinitialize
    >
      <Form ignoreDirty>
        {showAlert && appointment.alert && (
          <p className="m-0 mb-10">
            <span>Alerta</span> {appointment.alert}
          </p>
        )}
        <TextFieldForm
          refTarget={focused}
          label="Nueva observación"
          name="notes"
          className="my-4"
        />
      </Form>
    </Formik>
  );
};

export default ObservationForm;
