import React from 'react';
import { CheckboxForm, DropdownForm } from '../../../../../../components';
import { useFieldProps } from '../../../../../../hooks';
import { IsDentis_UserOfficeT } from '#interfaces/components';

export const UserOffice: React.FC<IsDentis_UserOfficeT> = ({
  data,
  supervisorFieldName,
}) => {
  const checkboxName = 'enable_office';
  const {
    field: { value },
  } = useFieldProps({ name: checkboxName });
  const {
    field: { value: supValue },
  } = useFieldProps({ name: supervisorFieldName });

  return (
    <>
      <CheckboxForm
        label="Modifica solo sus datos"
        description="Este usuario puede modificar solamente sus datos."
        name="dentist_data"
        showError={false}
        disabled={supValue}
      />
      <CheckboxForm
        label="Trabaja en todas las sucursales"
        name={checkboxName}
        showError={false}
      />
      <DropdownForm
        name="office"
        data={data}
        disabled={value}
        label="Sucursales"
        keyId="id_sucursal"
        keyData="sucursal"
      />
    </>
  );
};
