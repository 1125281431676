import React from 'react';
import { Columns } from './columns';
import { Data } from './data';
import { tableBenefitsMapper } from '../../../utils';

export const Table: React.FC<any> = ({
  data,
  translations,
  action,
  sorting,
  setSorting,
  permissions,
  config,
}) => {
  return (
    <div className="mx-auto w-full">
      <Data
        columns={Columns({
          translations,
          action,
          config: { permissions, useUsd: config },
        })}
        data={tableBenefitsMapper(data).reverse()}
        sorting={sorting}
        setSorting={setSorting}
        config={config}
      />
    </div>
  );
};
