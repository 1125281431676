import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox } from '#components/shadcn';
import { Loading } from '#components/commons';
import { acceptTermsAndConditions } from '../../../services';
import { startLoading, stopLoading, updateUser } from '../../../redux/slices';
import { RootState } from '../../../redux';
import { terms_and_conditions } from '../../../mocks/terms_conditions';

export const TermsAndConditions = ({
  onCancel,
  onChange,
}: {
  onCancel: () => void;
  onChange: () => void;
}) => {
  const [isAccepted, setIsAccepted] = React.useState(false);
  const {
    commons: { loading },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const onSubmit = async () => {
    if (isAccepted) {
      dispatch(startLoading());
      const {
        data: { usuario },
      } = await acceptTermsAndConditions();
      dispatch(updateUser(usuario));
      onCancel();
      onChange();
      dispatch(stopLoading());
    }
  };

  return (
    <>
      {loading && <Loading />}
      <div className="h-64 overflow-y-auto my-5 bg-slate-100">
        {terms_and_conditions}
      </div>
      <div className="space-x-2 flex items-center">
        <Checkbox onClick={() => setIsAccepted(!isAccepted)} />
        <label className="text-base font-medium leading-none text-slate-600">
          He leído y estoy de acuerdo con los{' '}
          <strong>Términos y Condiciones</strong> de Bilog
        </label>
      </div>
      <div className="flex mt-5 space-x-4 justify-end">
        <Button
          onClick={onCancel}
          className="flex justify-center w-max px-10 h-10 bg-transparent !text-red-500 hover:bg-red-500 hover:!text-white border border-red-500"
        >
          Cancelar
        </Button>
        <Button
          className="flex justify-center w-max px-10 h-10 hover:bg-blue-600"
          onClick={onSubmit}
          disabled={!isAccepted}
        >
          Guardar
        </Button>
      </div>
    </>
  );
};
