import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { Copy, Form } from '../../../../components';
import { DeleteTurnFormT } from '#interfaces/components';

const DisableTurnForm: React.FC<DeleteTurnFormT> = ({
  appointment,
  userId,
  date,
  offices,
  onChange,
}) => {
  const messageDisabledTurn = appointment?.is_overturn
    ? 'Este sobreturno se eliminará de su lista.'
    : 'Este turno se marcará como "NO CITAR".';

  return (
    <Formik
      initialValues={{}}
      onSubmit={useSubmit({ appointment, userId, date, offices, onChange })}
    >
      <Form ignoreDirty>
        <section>
          <Copy>{messageDisabledTurn}</Copy>
        </section>
      </Form>
    </Formik>
  );
};

export default DisableTurnForm;
