import React from 'react';
import { TextFieldWithIcon } from '../../../commons';
import { useFieldProps } from '../../../../hooks';
import { SnackbarContext } from '../../../../context';
import { MembershipT } from '#interfaces/components';

export const MembershipTextfield: React.FC<MembershipT> = ({
  onClick,
  lenderCode,
  medicalInsurances,
  medicalInsurancesFieldName,
  documentFieldName,
  patient,
  translations,
}) => {
  const textfieldFieldName = 'affiliate_number';
  const { showErrorSnackbar } = React.useContext(SnackbarContext);
  const {
    field: { value: affiliateValue },
  } = useFieldProps({ name: textfieldFieldName });
  const {
    field: { value: medicalInsurancesValue },
  } = useFieldProps({ name: medicalInsurancesFieldName });
  const {
    field: { value: documentValue },
  } = useFieldProps({ name: documentFieldName });
  const currentMedIns = medicalInsurances.find(
    (mi) => mi.id_os === medicalInsurancesValue,
  );

  const actionDisabled =
    !lenderCode ||
    (!affiliateValue && !documentValue) ||
    !currentMedIns?.cliente_ftp;
  const typeErrorMessage = !lenderCode
    ? 'Falta el codigo de prestador'
    : !documentValue
    ? 'Falta DNI del paciente'
    : `Falta cliente FTP en el/la ${translations.medical_insurance} del paciente`;
  const onClickHandler = () => {
    if (!patient.id_paciente) {
      return showErrorSnackbar(
        'Error: No puede validar el carnet de un paciente inexistente',
      );
    }
    const isMatch =
      patient.nro_afil === affiliateValue &&
      patient.id_planNavigation?.id_os === medicalInsurancesValue;
    if (!actionDisabled) {
      return isMatch
        ? onClick()
        : showErrorSnackbar('Error: debe guardar el paciente primero');
    }
    showErrorSnackbar(` Error: ${typeErrorMessage}`);
  };

  return (
    <div className="col-span-5">
      <TextFieldWithIcon
        name={textfieldFieldName}
        label="Número de afiliado"
        icon="identification"
        tooltipText="Verificar carnet"
        onClickHandler={onClickHandler}
      />
    </div>
  );
};
