import React from 'react';
import { Formik } from 'formik';
import {
  Subtitle,
  Templates,
  WhatsappCarousel,
  Form,
} from '../../../../../components';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { WhatsappFormT } from '#interfaces/components';
import './index.scss';

export const WhatsappForm: React.FC<WhatsappFormT> = ({
  params,
  temps,
  offices = [],
  onChange,
  getTemplates,
}) => {
  const [formHeight, setFormHeight] = React.useState('705px');
  const { initialValues } = useForm(params);

  React.useEffect(() => {
    async function handleWappTemplates() {
      await getTemplates('templates');
    }
    handleWappTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (window) {
      const height = `${window.innerHeight - 205}px`;
      setFormHeight(height);
    }
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={useSubmit(params, onChange)}
      enableReinitialize
    >
      <Form
        ignoreDirty
        height={formHeight}
        className="permissions-form overflow-hidden overflow-y-scroll h-[718px] px-3 pb-8"
      >
        <div className="templates-container">
          <Subtitle>Plantillas de turnos</Subtitle>
          <p className="mb-4 text-base font-regular text-neutral-500">
            Estas son todas las plantillas para el envío de recordatorio de
            turnos.
          </p>
          {temps.turns && (
            <WhatsappCarousel
              data={temps.turns}
              name="what_template_turns"
              component={Templates}
            />
          )}
          {offices?.length && temps.turns_office ? (
            <>
              <Subtitle>Plantillas con sucursales</Subtitle>
              <p className="mb-4 text-base font-regular text-neutral-500">
                Estas son todas las plantillas para el envío de recordatorio de
                turnos con sucursales.
              </p>
              <WhatsappCarousel
                data={temps.turns_office}
                name="what_template_turns"
                component={Templates}
              />
            </>
          ) : null}
        </div>
        <div className="templates-container">
          <Subtitle>Plantillas de cumpleaños</Subtitle>
          <p className="mb-4 text-base font-regular text-neutral-500">
            Estas son todas las plantillas para el envío de felicitaciones de
            cumpleaños.
          </p>
          {temps.birthday && (
            <WhatsappCarousel
              data={temps.birthday}
              name="what_template_birthday"
              component={Templates}
            />
          )}
        </div>
      </Form>
    </Formik>
  );
};
