import React from 'react';
import { Formik } from 'formik';
import { useForm } from './use-form';
import { useSubmit } from './use-submit';
import {
  ControllerProf,
  GroupByController,
  ControllerMedIns,
  DateManagerController,
} from '../Controllers';
import { Title, Form, Button, SmartGraphic } from '../../../../components';
import { useSetHeight } from '../../../../hooks';
import { BudgetsIssuedT } from '#interfaces/components/forms/statistics';

export const BudgetsIssuedForm: React.FC<BudgetsIssuedT> = ({
  professionals,
  translations,
  medicalInsurances,
  data,
  current,
}) => {
  const fieldNameAllMedIns = 'check_all_med_ins';
  const fieldNameCheckAllProf = 'check_all_prof';
  const height = useSetHeight(180);
  const { initialValues } = useForm(current);

  const dataFilter =
    Object.keys(data).length > 0
      ? data.grouped[0]?.budgetsAmountDataGroup
      : data.grouped;

  function getDataForChart(data: any) {
    if (data && data[0]?.groupBy === 'none') {
      return {
        data: data.map((e: any) => ({
          amount: e.quantity,
          percentage: e.percentageBudget,
          name: e.period,
          quantity: e.quantity,
        })),
        chart: 'donut',
      };
    } else {
      return {
        data: data?.map((e: any) => ({
          amount: 0,
          percentage: 0,
          name: e.period,
          quantity: e.quantity,
        })),
        chart: 'line',
      };
    }
  }

  return (
    <div
      className="p-10 overflow-hidden overflow-y-scroll"
      style={{ height: `${height}px` }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={useSubmit()}
        enableReinitialize
      >
        {({ submitForm }) => (
          <Form showActions={false}>
            <Title>Presupuestos emitidos</Title>
            <DateManagerController groupByDate />
            <ControllerMedIns
              translations={translations}
              medicalInsurances={medicalInsurances}
              fieldNameAllMedIns={fieldNameAllMedIns}
              fieldNameCheckAllProf={fieldNameCheckAllProf}
            />
            <ControllerProf
              professionals={professionals}
              fieldNameCheckAllProf={fieldNameCheckAllProf}
            />
            <div className="grid grid-cols-12 gap-10">
              <GroupByController translations={translations} />
              <div className="col-span-6 col-start-12 self-end">
                <Button fullWidth onClick={submitForm}>
                  Generar estadisticas
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="grid grid-cols-12 gap-10 mt-14">
        <div className="col-span-6">
          <SmartGraphic
            title="% Presupuesto emitido"
            data={getDataForChart(data.percentageByUse).data}
            chartType="donut"
            color={['#40CFFF']}
          />
        </div>
        <div className="col-span-6">
          <SmartGraphic
            title="Presupuesto emitido"
            data={getDataForChart(data.groupedByTime).data}
            chartType="line"
            color={['#A78BFA']}
          />
        </div>
        <div className="col-span-6">
          <SmartGraphic
            title="Presupuesto emitido"
            data={getDataForChart(data.percentageByStatus).data}
            chartType="donut"
            color={['#40CFFF']}
          />
        </div>
        <div className="col-span-6">
          <SmartGraphic
            title="Presupuesto por obra social"
            data={dataFilter}
            chartType="donut"
            color={['#40CFFF']}
          />
        </div>
      </div>
    </div>
  );
};
