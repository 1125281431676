import React from 'react';
import {
  TextFieldForm,
  DropdownForm,
  Button,
  NumericField,
} from '#components/index';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from '#components/shadcn';
import { useFieldProps } from '../../../../../hooks';
import { methods } from '../../../../../mocks';
import { SelectDiscountType } from '../select-discount-type';
import { InitialPayment } from '../initial-payment';
import { Quota } from '../controller-quota';
import { updateTotalAmount } from '../../../../../utils';
import { ThirdStepT } from '#interfaces/components';

export const ThirdStep: React.FC<ThirdStepT> = ({
  onClick,
  data: { taxes },
}) => {
  const initialPaymentFieldName = 'initial_payment';
  const interstRateFieldName = 'interest_rate';
  const ivaFieldName = 'iva';
  const {
    field: { value: benefitsListTotalAmountValue },
  } = useFieldProps({ name: 'benefits_list_total_amount' });
  const {
    field: { value: iva },
  } = useFieldProps({ name: ivaFieldName });
  const {
    field: { value: interestRateValue },
  } = useFieldProps({ name: interstRateFieldName });
  const {
    field: {
      value: { percentage, percentage_value, amount_value },
    },
  } = useFieldProps({ name: 'discount' });
  const {
    field: { value: budgetTotalAmount },
    setFieldValue: setTotalAmount,
  } = useFieldProps({ name: 'budget_total_amount' });

  React.useMemo(() => {
    const IVAAmount = taxes.find(
      (t: { [key: string]: any }) => t.id === iva,
    ).tax;
    setTotalAmount(
      updateTotalAmount({
        totalValueBenefits: benefitsListTotalAmountValue,
        IVAAmount,
        percentage,
        percentage_value,
        amount_value,
        interestRateValue,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentage_value, interestRateValue, iva, amount_value]);

  return (
    <Card className="mt-5">
      <CardHeader>
        <CardTitle>Datos de pago</CardTitle>
        <CardDescription>
          Cargá los datos de tiempo estimado, pagos y observaciones.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid grid-cols-12 gap-5">
        <div className="col-span-3">
          <label className="mb-2 block text-sm font-medium leading-5">
            Valor total
          </label>
          <p className="text-2xl font-bold text-green-700 leading-7">
            $ {budgetTotalAmount}
          </p>
        </div>
        <TextFieldForm
          className="col-span-3"
          label="Tiempo estimado"
          name="estimated_time"
          maxlength={20}
          placeholder="Ej: 3 meses"
          multiline
        />
        <NumericField
          className="col-span-3"
          label="Cantidad de sesiones"
          name="sessions"
          max={99}
          placeholder="Ej: 3"
        />
        <DropdownForm
          className="col-span-3"
          data={methods}
          label="Forma de pago"
          name="payment_method"
          keyId="id"
          keyData="text"
        />
        <SelectDiscountType
          label="Porcentaje de descuento"
          textfieldLabel="% de descuento"
          name="discount"
          discountType="percentage"
          maxAmount={100}
        />
        <SelectDiscountType
          label="Importe de descuento"
          textfieldLabel="$ de descuento"
          name="discount"
          discountType="amount"
          maxAmount={benefitsListTotalAmountValue}
        />
        <DropdownForm
          className="col-span-3"
          data={taxes}
          label="% IVA"
          name={ivaFieldName}
          keyId="id"
          keyData="tax"
          placeholder="0.0"
        />
        <NumericField
          className="col-span-3"
          label="% Interés"
          placeholder="0.0%"
          name={interstRateFieldName}
        />
        <InitialPayment name={initialPaymentFieldName} />
        <Quota name={initialPaymentFieldName} />
        <TextFieldForm
          className="col-span-6"
          multiline
          label="Observaciones"
          name="observation"
          placeholder="Escriba una observación"
        />
        <Button className="uppercase" size="large" onClick={onClick}>
          siguiente
        </Button>
      </CardContent>
    </Card>
  );
};
