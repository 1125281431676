import ImageMissingTooth from '../static/imagesSuccefull/Imagen006.png';
import ImageNotSelectedPatient from '../static/imagesSuccefull/Imagen009.png';

export const emptyStateHC = {
  emptyState: {
    title: 'El paciente no tiene historia clínica para mostrar.',
    button: 'NUEVA HISTORIA CLÍNICA',
    img: ImageMissingTooth,
  },
};

export const emptyStateBudget = {
  emptyState: {
    title: 'El paciente no tiene presupuestos para mostrar',
    button: 'NUEVO PRESUPUESTO',
    img: ImageMissingTooth,
    route: '/budgets',
  },
};
export const emptyStateLaboratory = {
  emptyState: {
    title: 'El paciente no tiene pedidos de laboratorios para mostrar',
    button: 'NUEVO PEDIDO',
    img: ImageMissingTooth,
  },
};
export const emptyStateRecipe = {
  emptyState: {
    title: 'El paciente no tiene recetas para mostrar',
    button: 'NUEVA RECETA',
    img: ImageMissingTooth,
  },
};
export const emptyStateBenefits = {
  emptyState: {
    title: 'El paciente no tiene prestaciones para mostrar.',
    button: 'NUEVA PRESTACIÓN',
    img: ImageMissingTooth,
  },
};

export const emptyStateNotPatient = {
  emptyState: {
    title: 'No existe paciente seleccionado',
    description:
      'Todavía no has seleccionado un paciente para mostrar, busca un paciente para ver toda su información.',
    img: ImageMissingTooth,
    button: 'BUSCAR PACIENTE',
  },
};

export const emptyStatePayments = {
  emptyState: {
    title: 'El paciente no tiene pagos para mostrar.',
    button: 'NUEVO PAGO',
    img: ImageNotSelectedPatient,
  },
};

export const emptyStateTurns = {
  emptyState: {
    title: 'El paciente no tiene historial de turnos.',
    img: ImageMissingTooth,
  },
};

export const emptyStateBudgetPayments = {
  emptyState: {
    title: 'El presupuesto no tiene pagos asociados para mostrar.',
    img: ImageMissingTooth,
    route: '/budgets',
  },
};
