import React from 'react';
import { Formik } from 'formik';
import { useForm } from './use-form';
import { useSubmit } from './use-submit';
import {
  ControllerProf,
  GroupByController,
  ControllerMedIns,
  DateManagerController,
} from '../Controllers';
import { Title, Form, SmartGraphic, Button } from '../../../../components';
import { useSetHeight } from '../../../../hooks';
import {
  MedicalInsurancesT,
  ProfessionalT,
  TranslationsT,
} from '#interfaces/general-values';

export const ControlPanelForm: React.FC<{
  professionals: ProfessionalT[];
  translations: TranslationsT;
  medicalInsurances: MedicalInsurancesT[];
  data: any;
  current: any;
}> = ({ professionals, translations, medicalInsurances, data, current }) => {
  const fieldNameCheckAllProf = 'check_all_prof';
  const fieldNameAllMedIns = 'check_all_med_ins';
  const { initialValues } = useForm({ current });
  const [showGraphic, setShowGraphic] = React.useState(true);
  const height = useSetHeight(180);

  function mergeData() {
    if (data.paymentsEntered.length > 0 || data.benefitAmounts.length > 0)
      return [
        {
          data: formatData()?.payments.sort(dateCompare),
          title: 'Pagos ingresados',
          color: '#8000FF',
        },
        {
          data: formatData()?.benefits.sort(dateCompare),
          title: 'Prestaciones realizadas',
          color: '#DD524C',
        },
      ];

    return [];
  }
  function formatData() {
    if (data.paymentsEntered.length > 0 || data.benefitAmounts.length > 0) {
      const dataPayments = getDataForChart(data.paymentsEntered).data;
      const dataBenefits = getDataForChart(data.benefitAmounts).data;

      const namesFromPayments = dataPayments.map(
        (payment: any) => payment.name,
      );

      const namesFromBenefits = dataBenefits.map(
        (benefit: any) => benefit.name,
      );

      const combinedNamesArray = namesFromPayments.concat(namesFromBenefits);
      const uniqueSortedNames = combinedNamesArray
        .filter(
          (value: any, index: number, self: any) =>
            self.indexOf(value) === index,
        )
        .sort();
      const defaultValues = uniqueSortedNames.reduce((acc: any, name: any) => {
        acc[name] = { amount: 0, percentage: 0, name, quantity: 0 };
        return acc;
      }, {});
      const payments = mergeObjects(dataPayments, defaultValues);
      const benefits = mergeObjects(dataBenefits, defaultValues);

      return { payments, benefits };
    }
  }
  function mergeObjects(obj1: any, obj2: any) {
    const obj1Map: { [key: string]: any } = obj1.reduce(
      (acc: any, curr: any) => {
        acc[curr.name] = curr;
        return acc;
      },
      {},
    );

    for (const key in obj2) {
      if (!obj1Map.hasOwnProperty(key)) {
        obj1.push(obj2[key]);
      }
    }

    return obj1;
  }
  function dateCompare(a: any, b: any) {
    const dayA = Number(a.name.split('/')[0]);
    const dayB = Number(b.name.split('/')[0]);
    const monthA = Number(a.name.split('/')[1]);
    const monthB = Number(b.name.split('/')[1]);
    if (monthA !== monthB) {
      return monthA - monthB;
    }
    return dayA - dayB;
  }

  function getDataForChart(data: any) {
    if (data && data[0]?.groupBy === 'none') {
      return {
        data: data[0].dataGroups.map((e: any) => ({
          amount: e.quantity,
          percentage: ((e.quantity / data[0].quantity) * 100).toFixed(2),
          name: e.name,
          quantity: e.quantity,
        })),
        chart: 'donut',
      };
    } else {
      return {
        data: data.map((e: any) => ({
          amount: 0,
          percentage: 0,
          name: e.period,
          quantity: e.quantity,
        })),
        chart: 'line',
      };
    }
  }

  React.useEffect(() => {
    setShowGraphic(false);
    const timer = setTimeout(() => {
      setShowGraphic(true);
    }, 1);

    return () => clearTimeout(timer);
  }, [data]);

  return (
    <div
      className="p-10 overflow-hidden overflow-y-scroll"
      style={{ height: `${height}px` }}
    >
      <Formik initialValues={initialValues} onSubmit={useSubmit()}>
        {({ submitForm }) => (
          <Form showActions={false}>
            <Title>Panel de Control</Title>
            <DateManagerController groupByDate />
            <ControllerMedIns
              translations={translations}
              medicalInsurances={medicalInsurances}
              fieldNameAllMedIns={fieldNameAllMedIns}
              fieldNameCheckAllProf={fieldNameCheckAllProf}
            />
            <ControllerProf
              professionals={professionals}
              fieldNameCheckAllProf={fieldNameCheckAllProf}
            />
            <div className="grid grid-cols-12 gap-10">
              <GroupByController translations={translations} />
              <div className="col-span-6 col-start-12 self-end">
                <Button
                  className="rounded shadow"
                  fullWidth
                  onClick={submitForm}
                >
                  Generar estadisticas
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="grid grid-cols-12 gap-10 mt-14">
        <div className="col-span-6">
          {showGraphic && (
            <SmartGraphic
              title="Pacientes Nuevos"
              data={getDataForChart(data.newPatients).data}
              chartType={getDataForChart(data.newPatients).chart}
              color={['#FBBF24']}
            />
          )}
        </div>
        <div className="col-span-6">
          {showGraphic && (
            <SmartGraphic
              title="Pacientes Atendidos"
              data={getDataForChart(data.patientAttended).data}
              chartType={getDataForChart(data.patientAttended).chart}
              color={['#FBBF24']}
            />
          )}
        </div>
        <div className="col-span-6">
          {showGraphic && (
            <SmartGraphic
              title="Turnos Agendados"
              data={getDataForChart(data.patientScheduled).data}
              chartType={getDataForChart(data.patientScheduled).chart}
              color={['#22C55E']}
            />
          )}
        </div>
        <div className="col-span-6">
          <SmartGraphic
            title="Pagos ingresados y prestaciones realizadas"
            data={mergeData()}
            chartType="line"
            color={['#DD524C']}
          />
        </div>
      </div>
    </div>
  );
};
