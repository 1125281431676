import { useContext } from 'react';
import { FormikHelpers } from 'formik';
import { SnackbarContext, FormContext } from '../context';
import { HandlerError } from '../utils';

export function useAppointmentError(onChange: () => void) {
  const { closeForm } = useContext(FormContext);
  const { showErrorSnackbar } = useContext(SnackbarContext);

  const handleAppointmentError = (
    err: HandlerError,
    formikActions: FormikHelpers<any>,
  ) => {
    showErrorSnackbar(err.message);
    if (
      err.message?.includes(
        '(00035) - Turno existente en base de datos. Refresque la agenda.',
      ) ||
      err.message?.includes(
        '(99998) - Error de Concurrencia, refresque la agenda.',
      )
    ) {
      closeForm();
      formikActions.resetForm();
      onChange();
    }
  };

  return { handleAppointmentError };
}
