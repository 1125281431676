import React from 'react';
import { Icons } from './icons';
import { Title } from './title';
import { Onboarding } from '../onboarding';
import classNames from 'classnames';
import { SidebarT } from '#interfaces/components';

export const Sidebar: React.FC<SidebarT> = ({
  setShowSidebar,
  showSidebar,
  children,
  title,
  handleOnExit,
  onboarding,
}) => {
  const stylesSidebar = classNames(
    'overflow-scroll top-0 right-0 w-auto py-8 bg-slate-50 fixed h-full z-40 ease-in-out duration-300 sidebar',
    showSidebar ? 'translate-x-0 ' : 'translate-x-full',
  );
  const stylesScreen = classNames(
    'top-0 left-20 w-screen bg-black pl-20 fixed h-full z-30 ease-in-out duration-300',
    showSidebar ? 'opacity-50' : 'opacity-0 hidden',
  );

  return (
    <>
      <div className={stylesSidebar}>
        {showSidebar && !onboarding && (
          <Onboarding
            show={true}
            onExit={() => handleOnExit('statisticsOnboarding')}
            section="statistics-parameters"
          />
        )}
        <div className="border-b border-b-slate-400 w-full flex flex-row justify-between px-4 pb-2 items-center relative">
          <Title>{title}</Title>
          <button
            className="text-2xl text-slate-600 cursor-pointer"
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <Icons width={21} height={21} type="close" />
          </button>
        </div>
        <div className="mt-6">{children}</div>
      </div>
      <div className={stylesScreen} />
    </>
  );
};
