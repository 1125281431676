import React from 'react';
import { CheckboxForm, TextFieldForm } from '../../../../../../components';
import { useFieldProps } from '../../../../../../hooks';

export const UserPassword: React.FC = () => {
  const checkboxName = 'enable_password';
  const {
    field: { value: checkBoxValue },
  } = useFieldProps({ name: checkboxName });
  const {
    field: { value: userId },
  } = useFieldProps({ name: 'id' });
  const disabledInputs = userId && !checkBoxValue;

  return (
    <>
      {userId && (
        <CheckboxForm
          label="Cambiar contraseña"
          description="Habilita los campos para cambiar la contraseña"
          name={checkboxName}
          showError={false}
        />
      )}
      <TextFieldForm
        label="Contraseña"
        disabled={disabledInputs}
        name="password"
        type="password"
      />
      <TextFieldForm
        label="Confirmar contraseña"
        disabled={disabledInputs}
        name="pass_verification"
        type="password"
      />
    </>
  );
};
