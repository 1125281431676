import { useCallback, useState, useEffect } from 'react';
import { splitDate } from '../../../../utils';
import { OfficesT, TurnsCombinationT } from '#interfaces/general-values';

type DataFormT = {
  appointment: TurnsCombinationT;
  userId: number;
  date: string | Date;
  offices: OfficesT[];
};

export function useForm({ appointment, userId, date, offices }: DataFormT) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues({ appointment, userId, date, offices }),
  );

  useEffect(() => {
    setInitialValues(getInitialValues({ appointment, userId, date, offices }));
  }, [appointment, userId, date, offices]);

  const validate = useCallback((values: any) => {
    const errors: { [key: string]: string } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['notes'];
    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues({ appointment, userId, date, offices }: DataFormT) {
  const office = offices && offices.length > 1 ? offices[0].id_sucursal : null;

  return {
    id_professional: appointment?.id_professional || appointment?.id_prof,
    id_user: userId,
    id_appointment: appointment.id_appointment ?? appointment.id_agenda,
    id_patient: appointment.id_patient ?? appointment.id_paciente,
    date: appointment?.date || splitDate(date),
    given_date: appointment?.given_date || new Date().toISOString(),
    office: appointment?.id_office || appointment?.id_sucursal || office,
    time: appointment.time ?? appointment.hora,
    afiliate_number:
      appointment?.affiliate_number || appointment?.nro_afil || null,
    attendance: appointment?.attendance || null,
    medical_insurance:
      appointment?.medical_insurance ?? appointment.obra_social,
    notes: appointment.observation ?? '',
    patient_name: appointment?.patient_name || null,
    eta: appointment?.eta || '',
    medical_insurance_plan:
      appointment?.medical_insurance_plan ?? appointment.plan_nombre,
    phone: appointment?.patient_phone || null,
    is_overturn: appointment.is_overturn ?? appointment.es_sobreturno,
    rowversion: appointment.rowversion,
  };
}
