import React from 'react';
import { Button } from '../../../../../components';
import { useFieldProps, useKeypress } from '../../../../../hooks';

export const ButtonsBenefit: React.FC<{
  enabledButton: boolean;
  hiddenBenefit: (value: boolean) => any;
}> = ({ enabledButton, hiddenBenefit }) => {
  const {
    field: { value: benefitListTotalValue },
    setFieldValue: setValueTotal,
  } = useFieldProps({ name: 'benefits_list' });
  const { setFieldValue: setBenefitListChangeValue } = useFieldProps({
    name: 'benefit_list_has_change',
  });
  const {
    field: { value: benefitValue },
  } = useFieldProps({ name: 'benefit_from_list' });
  const updateAndSaveBenefit = () => {
    setBenefitListChangeValue(true);
    const indexBenefit = benefitListTotalValue.findIndex(
      (b: { [key: string]: string }) =>
        b.id_budget_benefit === benefitValue.id_budget_benefit,
    );

    indexBenefit !== -1
      ? (benefitListTotalValue[indexBenefit] = benefitValue)
      : benefitListTotalValue.push(benefitValue);

    setValueTotal(benefitListTotalValue);
    hiddenBenefit(false);
  };
  const cancelButton = () => hiddenBenefit(false);
  useKeypress({
    key: 'Enter',
    action: (e: any) => {
      e.key === 'Enter' && enabledButton && e.preventDefault();
      updateAndSaveBenefit();
    },
  });

  return (
    <>
      <Button modifier="warning" onClick={cancelButton}>
        Cancelar
      </Button>
      <Button onClick={updateAndSaveBenefit} disabled={!enabledButton}>
        Guardar prestación
      </Button>
    </>
  );
};
