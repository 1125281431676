import { axiosInstance, platform } from '../utils';

export function checkArchives(archives: any) {
  const body = {
    archivos: archives,
    plataforma: platform,
  };

  return axiosInstance.post('/cloudstorage/check', body);
}

export function uploadArchives(archives: any) {
  const body = {
    archivos: archives,
    plataforma: platform,
  };

  return axiosInstance.post('/cloudstorage/upload', body);
}

export function editDescription(archive: any) {
  const body = {
    archivo: archive,
    plataforma: platform,
  };

  return axiosInstance.post('/cloudstorage/edit', body);
}

export function deleteArchives(archives: any) {
  const body = {
    archivos: archives,
    plataforma: platform,
  };

  return axiosInstance.post('/cloudstorage/delete', body);
}
