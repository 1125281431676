import React from 'react';
import {
  MedicalInsurancesT,
  PatientT,
  TurnsT,
} from '#interfaces/general-values';

export function useForm(
  patient: PatientT,
  userId: number,
  medicalInsurances: MedicalInsurancesT[],
  docTypes: any,
  dataTurnPatient: TurnsT,
) {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues(
      patient,
      userId,
      medicalInsurances,
      docTypes,
      dataTurnPatient,
    ),
  );

  React.useEffect(() => {
    setInitialValues(
      getInitialValues(
        patient,
        userId,
        medicalInsurances,
        docTypes,
        dataTurnPatient,
      ),
    );
  }, [patient, userId, medicalInsurances, docTypes, dataTurnPatient]);

  const validate = React.useCallback((values: any) => {
    const errors: { [key: string]: string } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['patient_name'];
    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues(
  patient: PatientT,
  userId: number,
  medicalInsurances: MedicalInsurancesT[],
  docTypes: any,
  dataTurnPatient: TurnsT,
) {
  const taxCategory =
    patient.cond_fiscal && patient.cond_fiscal.length > 1
      ? patient.cond_fiscal[0].id
      : patient.cond_fiscal;
  const medInsDataTurn = medicalInsurances?.find(
    (m) => m.obra_social === dataTurnPatient?.obra_social,
  );
  const planDataTurn = medInsDataTurn?.planes?.find(
    (p: any) => p.nombre === dataTurnPatient?.plan_nombre,
  );

  const specs = {
    alergy: patient.alergia,
    cardiac: patient.cardiaco,
    alpha: patient.alfa,
    diabetes: patient.diabetes,
    pregnant: patient.embarazo,
    renal: patient.renal,
    hepatitis: patient.hepatitis,
    holder: patient.es_titular,
    doc_type: patient.tipo_doc || docTypes[0].id,
    gender: patient.sexo || 'F',
    condition: patient.condicion[0]?.id ?? patient.condicion,
    tax_category: taxCategory,
    medical_insurance:
      medInsDataTurn?.id_os ||
      planDataTurn?.id_os ||
      medicalInsurances[0]?.id_os,
    medical_insurance_plan:
      patient.id_plan ||
      planDataTurn?.id_plan ||
      medicalInsurances[0]?.planes[0]?.id_plan,
    discharge_date: patient.f_alta,
    birth_date: patient.f_nacimiento,
    last_care_date: patient.f_ultima_at,
    recite_date: patient.f_recitar || null,
    recite: !!patient.f_recitar || false,
    cuit: patient.cuit,
    notes: patient.obs_varias,
    alert: patient.alerta,
    patient_name: patient.ape_nom || dataTurnPatient?.paciente,
    email: patient.email,
    address: patient.domicilio,
    location: patient.localidad,
    district: patient.provincia,
    age: patient.edad,
    civil_status: patient.est_civil,
    face: patient.face,
    instagram: patient.instagram,
    twitter: patient.twitter,
    affiliate_number: patient.nro_afil || dataTurnPatient?.nro_afil,
    occupation: patient.ocupacion,
    cel_phone: patient.tel_celu || dataTurnPatient?.telefono,
    office_phone: patient.tel_off,
    phone: patient.tel_part,
    company_owner: patient.titular_emp,
    name_owner: patient.titular_nom,
    document: patient.documento,
    clinic_story_number: patient.hc_nro,
    motive: patient.motivos || '',
    option: 'agregar',
    data_to_find: '',
    balance: 0,
    id_user: 0,
  };

  if (patient.id_paciente) {
    return {
      ...specs,
      id_user: userId,
      doc_type: patient.tipo_doc || docTypes[0].id,
      medical_insurance: patient.id_planNavigation.id_os,
      medical_insurance_plan: patient.id_plan,
      rowversion: patient.rowversion,
      id_patient: patient.id_paciente,
      checking_account: patient.cta_cte,
      option: 'modificar',
    };
  }

  return specs;
}
