import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { ProductActionT } from '#interfaces/components';

export const ActionCard: React.FC<ProductActionT> = ({
  suscribed,
  href,
  webUser,
}) => {
  const renderParams = () => {
    const params = {
      suscribed: {
        href: href.modify,
        text: 'Modificar mi suscripción',
      },
      default: {
        href: href.hire,
        text: 'Contratar ahora',
      },
    };
    return params[suscribed ? 'suscribed' : 'default'];
  };

  return (
    <a
      href={`${renderParams().href}. Mi usuario Bilog es: ${webUser}`}
      target="_blank"
      className="flex text-base text-blue-500 py-4 px-8 justify-between border-t-2"
      rel="noreferrer"
    >
      {renderParams().text}
      <ChevronRightIcon className="h-5 text-sm text-blue-500 transition" />
    </a>
  );
};
