import * as React from 'react';
import { ColumnsT } from '#interfaces/tables/benefit';
import { Icons } from '#components/commons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '#components/shadcn';

export const Columns = ({ action }: ColumnsT) => {
  return [
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        const { quantity, services_performed } = row.original;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="relative p-2 hover:cursor-pointer flex-none">
                <Icons
                  type="context"
                  className="text-gray-500 hover:text-gray-900 h-4 w-4"
                />
                <span className="sr-only">Abrir menu</span>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="start">
              <DropdownMenuGroup>
                <DropdownMenuItem
                  className="hover:cursor-pointer"
                  onSelect={() => action(row.original, 'edit')}
                >
                  <Icons type="edit" className="mr-2 h-4 w-4" />
                  <span>Modificar</span>
                </DropdownMenuItem>
                {row.original.id_budget !== 0 && (
                  <DropdownMenuItem
                    disabled={quantity <= services_performed}
                    className="hover:cursor-pointer text-green-600"
                    onSelect={() => action(row.original, 'mark_done')}
                  >
                    <Icons type="check_clean" className="mr-2 h-4 w-4" />
                    <span>Marcar como realizada</span>
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem
                  className="hover:cursor-pointer text-red-500"
                  onSelect={() => action(row.original, 'delete')}
                >
                  <Icons type="delete" className="mr-2 h-4 w-4" />
                  <span>Eliminar</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Descripción',
      cell: ({ row }: { row: any }) => {
        const { description } = row.original;

        return (
          <p className="font-bold text-base leading-6 text-slate-600">
            {description}
          </p>
        );
      },
    },
    {
      accessorKey: 'tooth_section',
      header: 'Diente/Sector',
      cell: ({ row }: { row: any }) => {
        const { tooth_section } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {tooth_section}
          </p>
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Cantidad',
      cell: ({ row }: { row: any }) => {
        const { quantity } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {quantity}
          </p>
        );
      },
    },
    {
      accessorKey: 'services_performed',
      header: 'Realizadas',
      cell: ({ row }: { row: any }) => {
        const { services_performed, quantity } = row.original;

        return (
          <p className="font-bold text-sm leading-6">
            {services_performed || 0} / {quantity}
          </p>
        );
      },
    },
    {
      accessorKey: 'total',
      header: 'Importe total',
      cell: ({ row }: { row: any }) => {
        const { total_amount } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-green-500">
            {`$ ${total_amount}`}
          </p>
        );
      },
    },
  ];
};
