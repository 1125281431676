import React from 'react';
import { FormikHelpers } from 'formik';

import { ParamsT } from '#interfaces/general-values';
import { updateSistemConfig } from '../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../context';

export function useSubmit({
  params,
  onChange,
}: {
  params: ParamsT;

  onChange: () => void;
}) {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    spec: {
      [key: string]: any;
    },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(spec, params);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Los datos se guardaron correctamente');
      onChange();
    } catch (err: any) {
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(
  spec: {
    [key: string]: any;
  },
  params: ParamsT,
) {
  const apiSpec = transformSpec(spec, params);

  return updateSistemConfig(apiSpec);
}

function transformSpec(
  spec: {
    [key: string]: any;
  },
  params: ParamsT,
) {
  const apiSpec = {
    ...params,
    sms_usuario: spec.sms_user,
    sms_clave: spec.sms_password,
    sms_texto_cumple: spec.sms_text_bday,
    sms_texto_turnos: spec.sms_text_turns,
  };

  return apiSpec;
}
