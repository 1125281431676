import React from 'react';
import classNames from 'classnames';
import { NormalBadgeT } from '#interfaces/components';

export const Normal: React.FC<NormalBadgeT> = ({
  text,
  className,
  onClick,
}) => {
  const badgeCss = classNames(
    'inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-bold text-blue-600 ring-1 ring-inset ring-blue-600/10',
    className,
  );

  return (
    <span className={badgeCss} onClick={onClick}>
      {text}
    </span>
  );
};
