import { axiosInstance, platform } from '../utils';

export function getDataAccount() {
  return axiosInstance.get(`/account/WEB`);
}

export function updateDataAccount(data: { [key: string]: string }) {
  const body = {
    account: data,
    plataforma: platform,
  };

  return axiosInstance.post('/account/edit', body);
}
export function setLender(data: { [key: string]: string }) {
  const body = {
    SubscribeToLender: {
      ...data,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/account/subscription/lender', body);
}
