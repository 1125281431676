import React from 'react';
import classNames from 'classnames';
import { CopyT } from '#interfaces/components';

export const Copy: React.FC<CopyT> = ({
  children,
  className = 'mt-0 mb-6',
}) => {
  const css = classNames('text-sm font-regular text-neutral-500', className);
  return <p className={css}>{children}</p>;
};
