import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import {
  Copy,
  Subtitle,
  Tooltip,
  TextFieldForm,
  Form,
  Icons,
} from '../../../../../components';
import './index.scss';
import { SmsFormT } from '#interfaces/components';

export const SmsForm: React.FC<SmsFormT> = ({ params, onChange }) => {
  const { initialValues } = useForm(params);
  const Content = `
    <h2>Lista de parámetros SMS</h2>
    <h4>Parámetros para reemplazar valores en el mensaje:</h4>
    <p><< PACIENTE >> Nombre del paciente del turno que se visualiza en el listado.</p>
    <p><< DIA >> Fecha del día seleccionado.</p>
    <p><< HORA >> Hora del turno que se visualiza en el listado.</p>
    <p><< CLINICA >> Nombre de su clínica.</p>
    <p><< PROFESIONAL >> Nombre del profesional del turno que se visualiza en el listado.</p>
  `;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={useSubmit({ params, onChange })}
      enableReinitialize
    >
      <Form ignoreDirty>
        <Subtitle>Credenciales SMS</Subtitle>
        <Copy>
          Ingresá tu usuario y contraseña de SMS proporcionados por Bilog.
        </Copy>
        <div className="grid grid-cols-4 gap-10">
          <TextFieldForm name="sms_user" label="Usuario" className="small" />
          <TextFieldForm
            name="sms_password"
            label="Contraseña"
            className="small"
            type="password"
          />
        </div>
        <span className="h-px my-9 w-full bg-gray-300 block" />
        <div className="flex items-center">
          <Subtitle>Mensajes</Subtitle>
          <div className="ml-2 form-tooltip">
            <Tooltip html align="right" id="help-tooltip" text={Content}>
              <Icons width={21} height={21} type="help" />
            </Tooltip>
          </div>
        </div>
        <Copy>
          En esta sección vas a poder definir mensajes personalizados de
          cumpleaños y avisos de turnos.
        </Copy>
        <div className="pb-8 grid grid-cols-4 gap-10">
          <TextFieldForm
            multiline
            name="sms_text_bday"
            label="Saludo de cumpleaños"
            className="col-span-2"
          />
          <TextFieldForm
            multiline
            name="sms_text_turns"
            label="Aviso de turno"
            className="col-span-2"
          />
        </div>
      </Form>
    </Formik>
  );
};
