import React from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import { Undo } from 'lucide-react';
import classNames from 'classnames';
import { Modal, Icons, Button } from '#components/index';
import { CardDescription, CardHeader, CardTitle } from '#components/shadcn';
import { useFieldProps, useForm } from '../../../../../hooks';

export const Signature = ({
  name,
  modifying,
}: {
  name: string;
  modifying?: boolean;
}) => {
  const sigPad = React.useRef<any>(null);
  const {
    field: { value },
    setFieldValue,
  } = useFieldProps({ name });
  const {
    isOpenForm: isOpenModalSignature,
    closeForm: closeModalSignature,
    openForm: openModalSignature,
  } = useForm();

  const clear = () => {
    sigPad.current.clear();
  };
  const undo = () => {
    const data = sigPad.current.toData();
    data && data.pop();
    sigPad.current.fromData(data);
  };
  const save = (setFieldValue: any) => {
    if (!sigPad) return;
    else if (sigPad.current?.toData().length <= 0) {
      closeModalSignature();
      return setFieldValue(null);
    }
    closeModalSignature();
    setFieldValue(sigPad.current.toDataURL().split(',')[1]);
  };

  return (
    <div
      className={classNames(
        !modifying &&
          'pointer-events-none cursor-not-allowed filter grayscale-100 opacity-60',
      )}
    >
      <CardHeader className="m-5 !p-0 border-t border-slate-200">
        <CardTitle className="mt-5">Configuración de sello</CardTitle>
        <CardDescription>
          Utilice el campo de firma para registrar su firma la cual será
          utilizada para recetas y otras funcionalidades.
        </CardDescription>
      </CardHeader>
      <div className="grid grid-cols-12 m-5 gap-5">
        <div className="col-span-3">
          <label htmlFor="input" className="mb-2 block text-sm font-medium">
            Firma del profesional **
          </label>
          {value ? (
            <img
              alt="signature"
              src={`data:image/png;base64,${value}`}
              className="h-24 mb-5"
            />
          ) : (
            <textarea
              name="signature"
              className="w-full h-24 mt-2 mb-5  shadow-sm px-4 py-2 text-sm ring-1 ring-gray-300 rounded-md cursor-not-allowed"
              disabled
            />
          )}
          <span
            className="cursor-pointer flex items-center text-blue-500 transition-transform hover:translate-x-[10px]"
            onClick={openModalSignature}
          >
            <small>{value ? 'Rehacer firma' : 'Crear Firma'}</small>
            <Icons className="w-4 h-4 mr-px" type="arrow_right" />
          </span>
          <Modal
            title="Firma del profesional"
            isOpenModal={isOpenModalSignature}
            closeModal={closeModalSignature}
            openModal={openModalSignature}
            width="700px"
          >
            <div className="w-auto">
              <SignaturePad
                ref={sigPad}
                options={{
                  minWidth: 1,
                  maxWidth: 3,
                  penColor: '#000',
                }}
                canvasProps={{
                  style: {
                    border: '1px solid rgb(209, 213, 219)',
                    borderRadius: '6px',
                  },
                }}
              />
            </div>
            <div
              onClick={undo}
              className="text-blue-500 mt-2 flex gap-x-2 cursor-pointer hover:text-blue-600"
            >
              Deshacer
              <Undo />
            </div>
            <div className="flex w-full justify-end gap-x-5 mt-5">
              <Button modifier="transparent" size="large" onClick={clear}>
                Limpiar
              </Button>
              <Button onClick={() => save(setFieldValue)} size="large">
                Guardar
              </Button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};
