import React from 'react';
import { TextFieldForm } from '../../../commons';
import { useFieldProps, useBenefitType } from '../../../../hooks';
import { ToothSectionT } from '#interfaces/components';

export const ToothSection: React.FC<ToothSectionT> = ({
  name,
  benefitTypeFieldName,
  benefitTypes,
  className,
}) => {
  const { benefitType } = useBenefitType({
    benefitTypeFieldName,
    benefitTypes,
  });
  const [disabled, setDisabled] = React.useState(true);
  const { setFieldValue } = useFieldProps({ name });

  React.useEffect(() => {
    if (!benefitType || !['DIE', 'ALG'].includes(benefitType?.diente)) {
      setDisabled(true);
      setFieldValue(null);
    } else {
      setDisabled(!['DIE', 'ALG'].includes(benefitType?.diente));
    }
  }, [benefitType, setFieldValue]);

  return (
    <TextFieldForm
      label="Diente/Sector"
      name={name}
      disabled={disabled}
      maxlength={85}
      className={className}
    />
  );
};
