import React from 'react';
import { DatePicker, TextFieldForm } from '#components/index';

const PaycheckMethod: React.FC = () => {
  return (
    <div className="col-span-12 grid grid-cols-12 gap-6">
      <DatePicker
        name="paychecks.paycheck_date"
        label="Fecha Diferida"
        className="col-span-3"
        fullWidth
      />
      <TextFieldForm
        name="paychecks.bank_emitter"
        label="Banco emisor"
        className="col-span-3"
        placeholder="Nombre del banco"
        maxlength={100}
      />
      <TextFieldForm
        name="paychecks.paycheck_number"
        label="Nro. cheque"
        className="col-span-3"
        maxlength={20}
      />
    </div>
  );
};

export default PaycheckMethod;
