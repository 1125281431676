import React from 'react';
import {
  OfficesT,
  PatientT,
  TurnsCombinationT,
} from '#interfaces/general-values';

type DataFormT = {
  appointment: TurnsCombinationT;
  patientData: PatientT;
  userId: number;
  date: string | Date;
  offices: OfficesT[];
};

export function useForm({
  appointment,
  patientData,
  userId,
  date,
  offices,
}: DataFormT) {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues({ appointment, patientData, userId, date, offices }),
  );

  React.useEffect(() => {
    setInitialValues(
      getInitialValues({ appointment, patientData, userId, date, offices }),
    );
  }, [appointment, patientData, userId, date, offices]);

  return { initialValues };
}

function getInitialValues({
  appointment,
  patientData,
  userId,
  date,
  offices,
}: DataFormT) {
  const office = offices && offices?.length > 1 ? offices[0].id_sucursal : null;

  return {
    id_professional: appointment.id_professional,
    id_user: userId,
    id_appointment: appointment.id_appointment,
    id_patient: patientData.id_paciente,
    id_office: appointment?.id_office || office,
    date:
      appointment?.date || date
        ? new Date(date).toISOString()
        : new Date().toISOString(),
    time: appointment.time,
    afiliate_number: null,
    observation: null,
    is_overturn: appointment.is_overturn || false,
    medical_insurance:
      patientData?.id_planNavigation?.id_osNavigation?.obra_social || null,
    given_date: appointment.given_date || new Date().toISOString(),
    attendance: null,
    patient_name: null,
    medical_insurance_plan: patientData?.id_planNavigation?.nombre || null,
    phone: null,
    rowversion: appointment.rowversion,
  };
}
