import React from 'react';
import { useDispatch } from 'react-redux';
import { FormikHelpers } from 'formik';

import { updateSistemConfig } from '../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../context';
import { startLoading, stopLoading } from '../../../../../redux/slices';
import { ParamsT, RegionsT } from '#interfaces/general-values';

export function useSubmit({
  params,
  onChange,
  regions,
}: {
  params: ParamsT;
  onChange: () => void;
  regions: RegionsT[];
}) {
  const { closeForm } = React.useContext(FormContext);
  const dispatch = useDispatch();
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      dispatch(startLoading());
      await axiosPromise({ spec, params, regions });
      showSuccessSnackbar('Los datos se guardaron correctamente');
      formikActions.resetForm();
      closeForm();
      onChange();
    } catch (err: any) {
    } finally {
      // If the user changes his region, we have to
      // force a login to change translations.
      if (spec.what_region !== params.what_region) {
        const history: {
          length: number;
          state: any;
          pushState(data: any, title: string, url?: string | null): void;
          replaceState(data: any, title: string, url?: string | null): void;
          go(delta: any): void;
          back(): void;
          forward(): void;
        } = window.history;
        return history.go('/');
      }
      formikActions.setSubmitting(false);
      dispatch(stopLoading());
    }
  };
}

function axiosPromise({
  spec,
  params,
  regions,
}: {
  spec: { [key: string]: any };
  params: ParamsT;
  regions: RegionsT[];
}) {
  const apiSpec = transformSpec({ spec, params, regions });

  return updateSistemConfig(apiSpec);
}

function transformSpec({
  spec,
  params,
  regions,
}: {
  spec: { [key: string]: any };
  params: ParamsT;
  regions: RegionsT[];
}) {
  const [region] = regions?.filter(
    (r: { [key: string]: any }) => r.idCountry === spec.what_region,
  );
  const apiSpec = {
    ...params,
    nombre: spec.name,
    direccion: spec.address,
    cuit: spec.cuit,
    email: spec.email,
    telefono: spec.phone,
    cond_fiscal: spec.tax_category,
    doble_moneda: spec.use_usd,
    what_region: region.iso3.toLowerCase(),
  };

  return apiSpec;
}
