import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, SuccesFull as Empty, Modal } from '#components/index';
import { OrderLaboratories } from '#components/Tables';
import { DeleteOrderForm } from './DeleteOrder';
import {
  completeProgressBarLab,
  openSectionInBudget,
  resetProgressBarLab,
  setSelectedOrder,
} from '../../../redux/slices';
import { useForm, useSetHeight } from '../../../hooks';
import { emptyStateLaboratory, emptyStateNotPatient } from '../../../mocks';

export const OrderLaboratoryForm: React.FC<any> = ({
  patient,
  handlerFocus,
  selectedOrder,
  onChange,
  useUsd,
  isSupervisor,
  permissions,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNewLabOnClick = () => {
    dispatch(resetProgressBarLab());
    dispatch(setSelectedOrder(null));
    dispatch(openSectionInBudget('patient-laboratories'));
    navigate('/laboratories');
  };
  const handleEditLabOnClick = () => {
    dispatch(completeProgressBarLab());
    dispatch(openSectionInBudget('patient-laboratories'));
    navigate('/laboratories');
  };
  const {
    isOpenForm: isOpenModalDelete,
    closeForm: closeModalDelete,
    openForm: openModalDelete,
  } = useForm();
  const handlerEmpty = {
    dataMocks: !patient.id_paciente
      ? emptyStateNotPatient
      : emptyStateLaboratory,
    onClick: !patient.id_paciente ? handlerFocus : handleNewLabOnClick,
  };
  const orders = patient?.labo_pacientes;
  const height = useSetHeight(patient?.id_paciente ? 420 : 220);
  const actions = (order: { [key: string]: string }, action: string) => {
    const findOrder = patient?.labo_pacientes.find(
      (lab: any) => order.id_order === lab.id_pedido,
    );

    dispatch(setSelectedOrder(findOrder));
    action === 'delete' ? openModalDelete() : handleEditLabOnClick();
  };

  return (
    <section className="mt-5" style={{ height: `${height}px` }}>
      {patient.labo_pacientes && patient.labo_pacientes.length ? (
        <>
          <OrderLaboratories
            data={orders}
            action={actions}
            config={{ useUsd, permissions, isSupervisor }}
          />
          {patient.id_paciente && (
            <div className="flex mt-5 space-x-5 justify-end">
              <Button
                onClick={handleNewLabOnClick}
                className="px-10 h-10 hover:bg-blue-600"
              >
                Nuevo pedido
              </Button>
            </div>
          )}
        </>
      ) : (
        <Empty
          typeSucces="emptyState"
          dataMocks={handlerEmpty.dataMocks}
          onClick={handlerEmpty.onClick}
        />
      )}
      <Modal
        title="Eliminar Pedido"
        isOpenModal={isOpenModalDelete}
        closeModal={closeModalDelete}
        openModal={openModalDelete}
      >
        <DeleteOrderForm selectedOrder={selectedOrder} refresh={onChange} />
      </Modal>
    </section>
  );
};
