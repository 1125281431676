import React from 'react';
import { FormikHelpers } from 'formik';
import { deleteArticle } from '../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../context';

export const useSubmit = ({
  article,
  onChange,
}: {
  article: any;
  onChange: () => void;
}) => {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    _spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(article);
      onChange();
      formikActions.resetForm();
      closeForm();
      showSuccessSnackbar('Artículo eliminado correctamente');
    } catch (err: any) {
      showErrorSnackbar(`${err.message} ${err.status}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
};

const axiosPromise = (article: { [key: string]: any }) => {
  const apiSpec = transformSpec(article);

  return deleteArticle(apiSpec);
};

const transformSpec = (article: { [key: string]: any }) => {
  const apiSpec = {
    id_concepto: article?.id_concept,
    concepto: article?.concept,
    id_labo: article?.id_labo,
    codigo: article?.code,
    importe: article?.amount,
    habilitado: article?.enabled,
    rowversion: article?.rowversion,
  };
  return apiSpec;
};
