import React from 'react';
import { DropdownForm } from '../../../components';

export const LayoutDropdown: React.FC<{
  name: string;
  layouts: any;
  label?: string;
}> = ({ name, layouts, label }) => {
  return (
    <DropdownForm
      name={name}
      data={layouts}
      label={label}
      keyId="id"
      keyData="description"
    />
  );
};
