import { PaymentsT } from '#interfaces/components/forms';
import { axiosInstance, platform } from '../utils';

export function createPayment(payment: PaymentsT, patientName: string) {
  const body = {
    Paciente: {
      ape_nom: patientName,
      opcion: 'agregarpago',
      pagos_pacientes: [payment],
    },
    plataforma: platform,
  };

  return axiosInstance.post('/aupaciente', body);
}

export function editPayment(payment: PaymentsT, patientName: string) {
  const body = {
    Paciente: {
      ape_nom: patientName,
      opcion: 'modificarpago',
      pagos_pacientes: [payment],
    },
    plataforma: platform,
  };

  return axiosInstance.post('/aupaciente', body);
}

export function deletePayment(payment: PaymentsT) {
  const body = {
    Paciente: {
      opcion: 'eliminarpago',
      pagos_pacientes: [payment],
    },
    plataforma: platform,
  };

  return axiosInstance.post('/aupaciente', body);
}

export function cancelPayment(payment: PaymentsT) {
  const body = {
    Paciente: {
      opcion: 'anularpago',
      pagos_pacientes: [payment],
    },
    plataforma: platform,
  };

  return axiosInstance.post('/aupaciente', body);
}

export function getCardsAndBanks() {
  const body = {
    Tablas: {
      opcionbuscar: 'tarjetas, bancos',
    },
    plataforma: platform,
  };

  return axiosInstance.post('/autablas', body);
}
