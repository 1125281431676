import React from 'react';
import { Button } from '#components/index';
import { useFieldProps, useKeypress } from '../../../../hooks';
import { SnackbarContext } from '../../../../context';

export const ButtonsMedicines: React.FC<{
  enabledButton: boolean;
  setHiddenLoadMedicine: (value: boolean) => any;
}> = ({ enabledButton, setHiddenLoadMedicine }) => {
  const { showWarningSnackbar } = React.useContext(SnackbarContext);
  const {
    field: { value: listTotalValue },
    setFieldValue: setValueTotal,
  } = useFieldProps({ name: 'medicines_list' });
  const {
    field: { value: medicineValue },
  } = useFieldProps({ name: 'medicine_from_list' });
  const {
    field: { value: addDiagnosis },
    setFieldValue: setaddDiagnosis,
  } = useFieldProps({ name: 'add_diagnosis' });
  const {
    field: { value: isDiagnosisManual },
    setFieldValue: setIsManualDiagnosis,
  } = useFieldProps({ name: 'is_diagnosis_manual' });
  const {
    field: { value: diagnosis },
  } = useFieldProps({ name: 'medicine_from_list.diagnosis' });

  const updateAndSaveMedicine = () => {
    const indexMedicine = listTotalValue.findIndex(
      (b: { [key: string]: string }) =>
        b.id_medicine_recipe === medicineValue.id_medicine_recipe,
    );

    if (
      listTotalValue[0] &&
      listTotalValue[0].isForHIV !== medicineValue?.isForHIV
    ) {
      const message = listTotalValue[0].isForHIV
        ? 'El primer medicamento es para HIV; no se podrán incluir otros tipos de medicamentos.'
        : 'El primer medicamento NO es para HIV; no se podrán incluir otros tipos de medicamentos.';
      return showWarningSnackbar(message);
    }

    if (addDiagnosis && !isDiagnosisManual && !diagnosis.code) {
      return showWarningSnackbar(
        'El diagnostico no es valido, por favor busca un diagnostico o escríbelo de forma manual.',
      );
    }

    indexMedicine !== -1
      ? (listTotalValue[indexMedicine] = medicineValue)
      : listTotalValue.push(medicineValue);
    setValueTotal(listTotalValue);
    setHiddenLoadMedicine(false);
  };

  const cancelButton = () => {
    setaddDiagnosis(false);
    setIsManualDiagnosis(false);
    setHiddenLoadMedicine(false);
  };

  useKeypress({
    key: 'Enter',
    action: (e: any) => {
      e.key === 'Enter' && enabledButton && e.preventDefault();
      return;
    },
  });

  return (
    <>
      <Button modifier="warning" onClick={cancelButton}>
        Cancelar
      </Button>
      <Button onClick={updateAndSaveMedicine} disabled={!enabledButton}>
        Guardar medicamento
      </Button>
    </>
  );
};
