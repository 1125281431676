import { useEffect, useState, useCallback } from 'react';
import { UserT } from '#interfaces/general-values';

export function useForm({
  current,
  loggedUserId,
}: {
  current: UserT | null;
  loggedUserId: string;
}) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues({ current, loggedUserId }),
  );

  useEffect(() => {
    setInitialValues(getInitialValues({ current, loggedUserId }));
  }, [current, loggedUserId]);

  const validate = useCallback((values: { [key: string]: any }) => {
    const errors: { [key: string]: any } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['name'];
    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues({
  current,
  loggedUserId,
}: {
  current: UserT | null;
  loggedUserId: string;
}) {
  return {
    logged_id: loggedUserId,
    id: current?.id || null,
    name: current?.name || '',
    user: current?.user || '',
    password: '',
    pass_verification: current?.pass || '',
    supervisor: current?.supervisor || false,
    enabled: current?.enabled || true,
    dentist: current?.dentist || false,
    dentist_data: current?.dentist_data || false,
    dentist_guard: current?.dentist_guard || false,
    dentist_schedule: current?.dentist_schedule || false,
    id_prof: current?.id_prof || null,
    id_prof_to_copy: current?.id_prof || null,
    enable_office: !current?.id_office || true,
    office: current?.id_office || null,
    permissions: current
      ? current.permissions?.map((p: { [key: string]: any }) => p.id_proceso)
      : [],
    use_usd: current?.use_usd || false,
    rowversion: current?.rowversion || null,
    guard: false,
    recetas_tyc: current?.recetas_tyc || null,
  };
}
