export const shortCutData: {
  title: string;
  description: string;
  route: string;
  btnText: string;
  icon: string;
  type: string;
  elementStep: string;
}[] = [
  {
    title: 'Ver agenda',
    description: 'Accede a la agenda de manera directa y rápida',
    route: '/schedule',
    btnText: 'Ir a mi agenda',
    icon: 'schedule',
    type: 'route',
    elementStep: 'shortcut-agenda',
  },
  {
    title: 'Nuevo paciente',
    description: 'Da de alta un nuevo paciente',
    route: '/patient-profile',
    btnText: 'Nuevo paciente',
    icon: 'user_plus',
    type: 'route',
    elementStep: 'shortcut-new-patient',
  },
  {
    title: 'Buscar paciente',
    description: 'Encuentra al paciente que necesitas',
    route: '/patient-profile',
    btnText: 'Buscar un paciente',
    icon: 'search',
    type: 'modal',
    elementStep: 'shortcut-search-patient',
  },
];
