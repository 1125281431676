import React from 'react';
import { Formik } from 'formik';
import { Copy, Form } from '../../../../../../components';
import { useSubmit } from './use-submit';
import { DeleteBenefitFormT } from '#interfaces/components';

export const DeleteBenefitForm: React.FC<DeleteBenefitFormT> = ({
  benefit,
  selectedMed,
  selectedPlan,
  onChange,
}) => {
  return (
    <Formik
      initialValues={{}}
      onSubmit={useSubmit({ benefit, selectedMed, selectedPlan, onChange })}
    >
      <Form submitTitle="Eliminar" ignoreDirty>
        <Copy>
          Estás por eliminar esta prestación y esta acción es irreversible, la
          prestación se eliminará definitivamente.
        </Copy>
      </Form>
    </Formik>
  );
};
