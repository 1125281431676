import React from 'react';
import Dropzone from 'react-dropzone-uploader';
import { Icons } from '#components/index';
import { archivesMapper } from '../../../../utils';

const Uploader: React.FC<{ onClick: (value: any) => void; patientId: number }> =
  ({ onClick, patientId }) => {
    const allowExtensions =
      'image/jpeg,image/jpg,image/png,image/webp,text/*,video/mp4,application/pdf';
    let base64strings: string[] = [];

    const handleChangeStatus = (file: any) => {
      const reader: any = new FileReader();
      reader.onload = () => {
        const string = reader.result.replace('data:', '').replace(/^.+,/, '');
        if (!base64strings.includes(string)) base64strings.push(string);
      };
      reader.readAsDataURL(file.file);
    };

    const handleSubmit = (files: any, allFiles: any[]) => {
      const mappedFiles = archivesMapper(files, base64strings, patientId);
      onClick(mappedFiles);
      allFiles.forEach((f) => f.remove());
    };

    const inputContent = () => (
      <div className="py-10 flex-col items-center text-center">
        <Icons type="upload" className="mx-auto h-12 w-12 text-slate-500" />
        <div>
          <span className="text-blue-500 hover:underline">Seleccioná</span> o
          arrastá el archivo
        </div>
      </div>
    );

    return (
      <div className="uploader w-full mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
        <Dropzone
          onChangeStatus={handleChangeStatus}
          onSubmit={handleSubmit}
          accept={allowExtensions}
          inputContent={inputContent}
          inputWithFilesContent="Agregar archivos"
          submitButtonContent="Subir archivos"
        />
      </div>
    );
  };

export default Uploader;
