import React from 'react';
import { Pagination, Stats, Title } from '#components/index';
import { Reports } from '#components/Tables';
import { BenefitT } from '#interfaces/general-values';
import { defaultStats } from '../../../mocks';

export const Auditor: React.FC<{
  data: {
    professional: string;
    clinic: string;
    address: string;
    phone: string;
    mail: string;
    medicalInsuranceCode: string;
    medicalInsurance: string;
    lender: string;
    period: string;
    rebilling: string;
    groupCode: string;
    group: string;
    totalAmount: number;
    totalServices: number;
    totalPages: number;
    services: BenefitT[];
  };
  currentPage: number;
  changePage: (page: number) => void;
}> = ({ data, currentPage, changePage }) => {
  const {
    clinic,
    address,
    phone,
    mail,
    medicalInsurance,
    lender,
    period,
    rebilling,
    group,
    services,
    totalPages,
    totalAmount,
    totalServices,
  } = data;

  const info: {
    title: string;
    data: string;
  }[] = [
    {
      title: 'Dirección',
      data: address,
    },
    {
      title: 'Teléfono',
      data: phone,
    },
    {
      title: 'Email',
      data: mail,
    },
    {
      title: 'Obra Social',
      data: medicalInsurance,
    },
    {
      title: 'Prestador',
      data: lender,
    },
    {
      title: 'Grupo',
      data: group,
    },
    {
      title: 'Tipo',
      data: rebilling,
    },
  ];

  return (
    <div className="w-full my-10">
      <Title className="mb-5 text-lg">{clinic}</Title>
      <div className="bg-white rounded-lg shadow p-4 flex items-center justify-between">
        <dl className="flex-auto text-sm text-gray-600 grid grid-cols-4 gap-x-6 gap-y-6">
          {info.map((item, index) => (
            <div key={index} className="flex justify-between md:block">
              <dt className="font-medium text-gray-900">{item.title}</dt>
              <dd className="mt-1">{item.data}</dd>
            </div>
          ))}
        </dl>
      </div>
      <Stats data={defaultStats({ totalAmount, totalServices, period })} />
      <Reports data={services} />
      <Pagination
        currentPage={currentPage}
        totalCount={services.length || 0}
        totalPages={totalPages}
        onPageChange={(page) => changePage(page)}
      />
    </div>
  );
};
