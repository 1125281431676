import { axiosInstance, platform } from '../utils';
import { BenefitsT } from '#interfaces/services';

export function createBenefit(
  benefit: { [key: string]: any },
  budgetServiceid: number = 0,
) {
  const body = {
    Paciente: {
      opcion: 'agregarprestacion',
      todos: benefit,
      BudgetServiceId: budgetServiceid,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/aupaciente', body);
}

export function updateBenefit(benefit: { [key: string]: any }) {
  const body = {
    Paciente: {
      opcion: 'modificarprestacion',
      todos: benefit,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/aupaciente', body);
}

export function deleteBenefit(benefit: { [key: string]: any }) {
  const body = {
    Paciente: {
      opcion: 'eliminarprestacion',
      todos: [
        {
          id_todos: benefit.id_todos,
          id_paciente: benefit.id_paciente,
          fecha: benefit.fecha,
          id_prestacion: benefit.id_prestacion,
          id_prof: benefit.id_prof,
          rowversion: benefit.rowversion,
        },
      ],
    },
    plataforma: platform,
  };

  return axiosInstance.post('/aupaciente', body);
}

export async function getValuesToPay({
  professionalId,
  benefitType,
  valueToChargeFromPatient,
}: BenefitsT) {
  const body = {
    Procesos: {
      opcionProceso: 'ValoresAPagarProf',
      ValoresAPagar: [
        {
          Id: null,
          IdProf: professionalId,
          IdPrestacion: benefitType?.id_prestacion,
          IdOs: -1,
          ValorOsACalcular: benefitType?.imp_os,
          ValorCosegACalcular: valueToChargeFromPatient,
          ValorHaberACalcular: 0,
        },
      ],
    },
    plataforma: platform,
  };

  return axiosInstance.post('/auprocesos', body);
}
