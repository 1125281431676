import React from 'react';
import { Button } from '#components/shadcn';
import { SelectCriteria } from './select-criteria';

import './index.scss';
import { OfficesT } from '#interfaces/general-values';

export const SelectByCriteria: React.FC<{
  onChange: any;
  officeValue: OfficesT;
  specialtyValue: any;
}> = ({ onChange, officeValue, specialtyValue }) => {
  const criteriaFieldName = 'criteria';
  const professionalCriteria = 'by_professional';
  const specialtyCriteria = 'by_specialty';
  const officeCriteria = 'by_office';
  const allOfficesCriteria = 'by_offices';

  return (
    <div className="flex flex-col gap-4">
      {specialtyValue && (
        <div>
          <p className="text-base font-regular text-slate-600 mb-2">
            ¿Querés buscar por profesional o por especialidad?
          </p>
          <div className="grid grid-cols-2 gap-x-4">
            <SelectCriteria
              name={criteriaFieldName}
              criteria={professionalCriteria}
              oppositFieldName={specialtyCriteria}
              label="Por profesional"
            />
            <SelectCriteria
              name={criteriaFieldName}
              criteria={specialtyCriteria}
              oppositFieldName={professionalCriteria}
              label="Por especialidad"
            />
          </div>
        </div>
      )}
      {officeValue && (
        <div>
          <p className="text-base font-regular text-slate-600 mb-2">
            ¿Querés buscar en esta sucursal o en todas?
          </p>
          <div className="grid grid-cols-2 gap-x-4">
            <SelectCriteria
              name={criteriaFieldName}
              criteria={officeCriteria}
              oppositFieldName={allOfficesCriteria}
              label="En esta sucursal"
            />
            <SelectCriteria
              name={criteriaFieldName}
              criteria={allOfficesCriteria}
              oppositFieldName={officeCriteria}
              label="En todas las sucursales"
            />
          </div>
        </div>
      )}
      <Button onClick={onChange} className="px-10 h-10 hover:bg-blue-600">
        Buscar turno
      </Button>
    </div>
  );
};
