import * as React from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import {
  Calendar,
  ButtonScn,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '..';

import { useFieldProps } from '../../hooks';

export const DatePicker: React.FC = () => {
  const [date, setDate] = React.useState(new Date());

  return (
    <Popover>
      <PopoverTrigger className="flex py-2" asChild>
        <ButtonScn
          className={classNames(
            'w-full text-left px-4 !justify-start font-normal bg-white !text-slate-600 border border-slate-300',
            !date && 'text-gray-500',
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? (
            format(date, 'dd/MM/yyyy')
          ) : (
            <span className="text-slate-900">Elige una fecha</span>
          )}
        </ButtonScn>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          month={new Date()}
          mode="single"
          className="p-4"
          selected={date}
          onSelect={setDate}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
};

export function FormDatePicker({
  name,
  disabled,
  className = '',
  minDate,
}: {
  name: string;
  className?: string;
  disabled?: boolean;
  minDate?: Date;
}) {
  const {
    field: { value },
    setFieldValue,
  } = useFieldProps({ name });

  const onSelectHandler = (date: string | Date) => {
    if (date) return setFieldValue(date);
  };

  return (
    <Popover>
      <PopoverTrigger className="flex py-2" asChild>
        <ButtonScn
          disabled={disabled}
          className={classNames(
            'w-full text-left px-4 !justify-start font-normal bg-white !text-slate-600 border border-slate-300',
            !value && 'text-gray-500',
            className,
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {value ? (
            format(value, 'dd/MM/yyyy')
          ) : (
            <span className="text-slate-900">Elige una fecha</span>
          )}
        </ButtonScn>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          className="p-4"
          initialFocus
          selected={value}
          onSelect={onSelectHandler}
          month={new Date(value)}
          min={minDate}
        />
      </PopoverContent>
    </Popover>
  );
}
