import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { Form } from '../../../../../../components';
import { useDemoName } from '../../../../../../hooks';
import { DeleteUserFormT } from '#interfaces/components';

export const DeleteUserForm: React.FC<DeleteUserFormT> = ({
  user,
  userName,
  onChange,
}) => {
  const { isDemo } = useDemoName(userName);

  return (
    <Formik initialValues={{}} onSubmit={useSubmit({ user, isDemo, onChange })}>
      <Form submitTitle="Eliminar" ignoreDirty>
        <p className="mb-4 text-base font-regular text-neutral-500">
          Estás por eliminar este usuario y esta acción es irreversible, el/la
          usuario se eliminará definitivamente.
        </p>
      </Form>
    </Formik>
  );
};
