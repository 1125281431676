import React from 'react';
import { DropdownForm } from '../../../../../components';
import { BanksT } from '#interfaces/general-values';

const BankMethod: React.FC<{ banks: BanksT[] }> = ({ banks }) => {
  return (
    <div className="col-span-12 grid grid-cols-12 gap-6">
      <DropdownForm
        name="id_bank"
        data={banks}
        className="col-span-4"
        label="Bancos"
        keyId="id_banco"
        keyData="banco"
      />
    </div>
  );
};

export default BankMethod;
