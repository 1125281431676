import React from 'react';
import { useDispatch } from 'react-redux';
import {
  startLoading,
  stopLoading,
  setSavePatient,
} from '../../../../redux/slices';
import { SnackbarContext, ModalContext } from '../../../../context';
import { FreemiumForm } from '../../..';
import { FormikHelpers } from 'formik';

import { AppDispatch } from '../../../../redux';

export function useSubmit(refTarget: any) {
  const dispatch = useDispatch();
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);
  const { showModal, hideModal } = React.useContext(ModalContext);

  return async (spec: any, formikActions: FormikHelpers<any>) => {
    try {
      dispatch(startLoading());
      const response = await axiosPromise(spec, dispatch);
      if (setSavePatient.rejected.match(response)) {
        const error = response.error;
        throw new Error(error.message);
      } //TODO: esto agrego por la forma en que se maneja el error, en la migracion esto ya no va, ya que el enfoqu es distinto
      formikActions.resetForm();
      showSuccessSnackbar('El paciente se guardó con éxito');
      refTarget.current && refTarget.current.focus();
    } catch (err: any) {
      err.message.includes('40000')
        ? showModal(
            'Suscribirse a Premium',
            <FreemiumForm onCancel={hideModal} />,
          )
        : showErrorSnackbar(
            `${err.message} Error status code: ${err.statusCode}`,
          );
    } finally {
      formikActions.setSubmitting(false);
      dispatch(stopLoading());
    }
  };
}

function axiosPromise(spec: any, dispatch: AppDispatch) {
  const apiSpec = transformSpec(spec);

  return dispatch(setSavePatient(apiSpec));
}

function transformSpec(spec: any) {
  const specs: {
    id_paciente: number;
    ape_nom: string;
    f_nacimiento: string;
    domicilio: string;
    localidad: string;
    provincia: string;
    tel_part: string;
    tel_off: string;
    tel_celu: string;
    email: string;
    documento: string;
    sexo: string;
    id_plan: string;
    nro_afil: string;
    hepatitis: boolean;
    embarazo: boolean;
    diabetes: boolean;
    alergia: boolean;
    cardiaco: boolean;
    renal: boolean;
    alfa: boolean;
    f_alta: string;
    f_ultima_at: string;
    es_titular: boolean;
    hc_nro: number;
    condicion: any;
    titular_nom: string;
    titular_emp: string;
    obs_varias: string;
    foto: string;
    id_socio_tit: number;
    alerta: string;
    id_usuario: number;
    face: string;
    twitter: string;
    f_recitar: string | null;
    est_civil: string;
    ocupacion: string;
    cuit: string;
    cond_fiscal: any;
    tipo_doc: string;
    opcion: string;
    instagram: string;
    quedatobuscar: string;
    edad: string;
    saldo: number;
    cta_cte: any[];
    motivos: string;
    rowversion?: string;
  } = {
    id_paciente: spec.id_paciente,
    id_plan: spec.medical_insurance_plan,
    id_socio_tit: 0,
    id_usuario: spec.id_user,
    alergia: spec.alergy,
    alerta: spec.alert,
    alfa: spec.alpha,
    ape_nom: spec.patient_name,
    cardiaco: spec.cardiac,
    cond_fiscal: spec.tax_category,
    condicion: spec.condition,
    cuit: spec.cuit,
    diabetes: spec.diabetes,
    documento: spec.document,
    domicilio: spec.address,
    edad: spec.age,
    email: spec.email,
    embarazo: spec.pregnant,
    es_titular: spec.holder,
    est_civil: spec.civil_status,
    f_alta: spec.discharge_date,
    f_nacimiento: spec.birth_date,
    f_recitar: spec.recite_date,
    f_ultima_at: spec.last_care_date,
    face: spec.face,
    foto: '',
    hc_nro: spec.clinic_story_number,
    hepatitis: spec.hepatitis,
    instagram: spec.instagram,
    localidad: spec.location,
    nro_afil: spec.affiliate_number,
    obs_varias: spec.notes,
    ocupacion: spec.occupation,
    opcion: spec.option,
    provincia: spec.district,
    quedatobuscar: spec.data_to_find,
    renal: spec.renal,
    saldo: spec.balance,
    sexo: spec.gender,
    tel_celu: spec.cel_phone,
    tel_off: spec.office_phone,
    tel_part: spec.phone,
    tipo_doc: spec.doc_type,
    titular_emp: spec.company_owner,
    titular_nom: spec.name_owner,
    twitter: spec.twitter,
    cta_cte: spec.checking_account,
    motivos: spec.motive,
  };

  if (spec.id_patient) {
    return {
      ...specs,
      rowversion: spec.rowversion,
      id_paciente: spec.id_patient,
    };
  }
  return specs;
}
