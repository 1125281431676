import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getApiUrl,
  login,
  getTableParams,
  getRegions,
  setLender,
  getProvinces,
} from '../../services';
import {
  userPermissionsMapper,
  setAuthHeader,
  getTranslations,
} from '../../utils';

const initialState = {
  privacy: false,
  remember: false,
  token: null,
  user: null,
  permissions: null,
  managerCode: null,
  apiURL: null,
  medicalInsurances: null,
  assists: null,
  professionals: null,
  offices: null,
  holidays: null,
  specialties: null,
  users: null,
  statistics: null,
  params: {},
  region: null,
  countries: null,
  translations: {},
  taxes: {},
  laboratories: null,
  provinces: [],
  version: null,
};

export const setLogin = createAsyncThunk(
  'login/setLogin',
  async (params: any) => {
    const { apiUrl } = await getApiUrl(params.acceso);
    const { data } = await login(params.acceso);

    if (data.usuario.habilitado) {
      const { usuario } = data;
      const user = Object.assign({}, usuario);
      const token = user.token;
      const remember = params.remember;
      delete user.permisos;
      delete user.token;

      await setAuthHeader('Authorization', token);

      const {
        data: { listaPermisos: systemPermissions },
      } = await getTableParams('PERMI');
      const permissions = userPermissionsMapper(
        usuario.permisos,
        systemPermissions,
      );

      return { permissions, user, token, apiUrl, remember };
    }
  },
);
export const getUserParams = createAsyncThunk(
  'login/getUserParams',
  async () => {
    try {
      const {
        data: {
          listaObraSociales: medicalInsurances,
          listaAsistencias: assists,
          listaProfesionales: professionals,
          listaSucursales: offices,
          listaFeriados: holidays,
          listaEspecialidades: specialties,
          listaUsuarios: users,
          estadisticasDiarias: statistics,
          parametros: params,
          laboratories,
        },
      } = await getTableParams(
        'PARAM, ASIST, OSPLA, PROFE, SUCUR, ESPEC, FERIA, USUAR, LABOR, PERMI',
      );

      const {
        data: { listaRegionesWapp: countries },
      } = await getRegions();

      const region =
        (isNaN(params?.what_region) && params?.what_region.toLowerCase()) ||
        'arg';
      const countryISO = countries?.find(
        (country: any) => country.iso3 === region?.toUpperCase(),
      )?.iso3;
      const {
        data: { countryProvinces },
      } = await getProvinces(countryISO?.toLowerCase());
      const translations = getTranslations(region?.toLowerCase());
      const taxes = [
        {
          id: 1,
          tax: '0',
        },
        {
          id: 2,
          tax: params.iva2,
        },
        {
          id: 3,
          tax: params.iva1,
        },
      ];

      return {
        medicalInsurances,
        assists,
        professionals,
        offices,
        holidays,
        specialties,
        users,
        statistics,
        params,
        taxes,
        region,
        countries,
        translations,
        laboratories,
        countryProvinces,
      };
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const updateMedicalInsurances = createAsyncThunk(
  'login/updateMedicalInsurances',
  async () => {
    try {
      const {
        data: { listaObraSociales: medicalInsurances },
      } = await getTableParams('OSPLA');

      return {
        medicalInsurances,
      };
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const getManagerCode = createAsyncThunk(
  'login/getManagerCode',
  async (params: { [key: string]: string }) => {
    const {
      data: {
        usuario: { manager_code },
      },
    } = await setLender(params);

    return { manager_code };
  },
);

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setAcceptPrivacy: (state) => {
      state.privacy = true;
    },
    setAuthentication: (_state, action) => {
      const { token } = action.payload;
      setAuthHeader('Authorization', token);
    },
    logOut: (state, _action) => {
      state.remember = false;
      state.token = null;
      state.user = null;
      state.permissions = null;
    },
    updateUser: (state, action) => {
      const usuario = action.payload;
      state.user = usuario;
    },
    setVersion: (state, action) => {
      const version = action.payload;
      state.version = version;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setLogin.fulfilled, (state, action) => {
      const { apiUrl, user, token, permissions, remember }: any =
        action.payload;
      state.token = token;
      state.apiURL = apiUrl;
      state.user = user;
      state.permissions = permissions;

      if (remember) {
        state.remember = true;
      }
    });
    builder.addCase(setLogin.rejected, (_state, action) => {
      throw new Error(action.error.message);
    });
    builder.addCase(
      getManagerCode.fulfilled,
      (state: { [key: string]: any }, action) => {
        const { manager_code } = action.payload;

        state.user = { ...state.user, manager_code };
      },
    );
    builder.addCase(getManagerCode.rejected, (_state, action) => {
      throw new Error(action.error.message);
    });
    builder.addCase(getUserParams.fulfilled, (state, action) => {
      const {
        medicalInsurances,
        assists,
        professionals,
        offices,
        holidays,
        specialties,
        users,
        statistics,
        params,
        region,
        countries,
        translations,
        taxes,
        laboratories,
        countryProvinces,
      } = action.payload;

      state.medicalInsurances = medicalInsurances;
      state.assists = assists;
      state.professionals = professionals;
      state.offices = offices;
      state.holidays = holidays;
      state.specialties = specialties;
      state.users = users;
      state.statistics = statistics;
      state.params = params;
      state.region = region;
      state.countries = countries;
      state.translations = translations;
      state.taxes = taxes;
      state.laboratories = laboratories;
      state.provinces = countryProvinces;
    });
    builder.addCase(updateMedicalInsurances.fulfilled, (state: any, action) => {
      const { medicalInsurances } = action.payload;

      state.medicalInsurances = medicalInsurances;
    });
  },
});

export const {
  setAcceptPrivacy,
  setAuthentication,
  logOut,
  updateUser,
  setVersion,
} = loginSlice.actions;

export default loginSlice.reducer;
