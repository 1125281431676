import React from 'react';
import { getWeek, budgetBenefitListTotalValue } from '../../../../utils';
import { quotaData } from '../../../../mocks';
import { BudgetT } from '#interfaces/general-values';

export function useForm({
  budget,
  idUser,
  professionals,
  patientId,
  offices,
  medicalInsurances,
  taxes,
  idPatientMedIns,
  idPatientPlan,
}: {
  budget: BudgetT | null;
  idUser: string;
  professionals: { [key: string]: any }[];
  patientId: number;
  offices: { [key: string]: any }[];
  medicalInsurances: { [key: string]: any }[];
  taxes: { [key: string]: any }[];
  idPatientMedIns: string;
  idPatientPlan: string;
}) {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues({
      budget,
      idUser,
      professionals,
      patientId,
      offices,
      medicalInsurances,
      taxes,
      idPatientMedIns,
      idPatientPlan,
    }),
  );

  React.useEffect(() => {
    setInitialValues(
      getInitialValues({
        budget,
        idUser,
        professionals,
        patientId,
        offices,
        medicalInsurances,
        taxes,
        idPatientMedIns,
        idPatientPlan,
      }),
    );
  }, [
    budget,
    idUser,
    professionals,
    patientId,
    offices,
    medicalInsurances,
    taxes,
    idPatientMedIns,
    idPatientPlan,
  ]);

  const validate = React.useCallback((values: { [key: string]: any }) => {
    let errors: { [key: string]: string | null } | null = {};
    const requiredMsg = 'Campo requerido';
    if (!values['benefits_list'].length) {
      errors['benefits_list'] = requiredMsg;
    }
    if (!budget && !values['benefit_from_list'].description) {
      errors['benefit_from_list'] = requiredMsg;
    }

    return errors;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { initialValues, validate };
}

export function getInitialValues({
  budget,
  idUser,
  professionals,
  patientId,
  offices,
  medicalInsurances,
  taxes,
  idPatientMedIns,
  idPatientPlan,
}: {
  budget: BudgetT | null;
  idUser: string;
  professionals: { [key: string]: any }[];
  patientId: number;
  offices: { [key: string]: any }[];
  medicalInsurances: { [key: string]: any }[];
  taxes: { [key: string]: any }[];
  idPatientMedIns: string;
  idPatientPlan: string;
}) {
  const { today } = getWeek();
  const [firstMedIns] = medicalInsurances.filter(
    (m: { [key: string]: any }) => !m.inhabilitada,
  );
  const [firstProf] = (() => {
    const profs = professionals.filter((p: { [key: string]: any }) => {
      return p.habilitado && !p.no_tiene_agenda;
    });

    return profs;
  })();

  const benefits =
    budget?.prest_presup?.map((b: { [key: string]: any }) => ({
      amount: b.imp_prest,
      benefit_code: b.id_prestacion
        ? b.id_prestacionNavigation.codigo_prest_os
        : null,
      description: b.id_prestacion
        ? b.id_prestacionNavigation.descripcion
        : b.descripcion,
      id_benefit: b.id_prestacion,
      id_budget: b.id_presup,
      id_budget_benefit: b.id_prest_presu,
      is_manual: !b.id_prestacion || false,
      needs_thoot: b.id_prestacion ? b.id_prestacionNavigation.diente : 'NIN',
      quantity: b.cant,
      thoot_face: b.id_prestacion ? b.id_prestacionNavigation.pide_cara : false,
      tooth_section: b.pieza,
      total_amount: b.imp_prest * b.cant,
      services_performed: b.services_performed,
    })) || [];
  // eslint-disable-next-line eqeqeq
  const iva =
    taxes.find((t: { [key: string]: any }) => t.tax === budget?.iva_porc)?.id ||
    1;
  const installments =
    budget && quotaData.find((q) => q?.text === budget?.cuotas);
  const installments_value =
    ((budget?.importe_total || 0) -
      (budget?.importe_total || 1) * ((budget?.pago_inicial || 0) / 100) || 0) /
    (installments?.value || 1);

  const selectedOffice =
    budget?.id_sucursal || (offices && offices[0]?.id_sucursal);

  return {
    affiliate_number: budget?.nro_afil || '',
    benefits_list: benefits,
    benefits_list_total_amount: budgetBenefitListTotalValue(benefits),
    benefit_list_has_change: false,
    benefit_from_list: {
      amount: 0,
      benefit_code: null,
      description: '',
      id_benefit: null,
      id_budget: 0,
      id_budget_benefit: Math.random(),
      is_manual: false,
      needs_thoot: 'ALG',
      quantity: 1,
      thoot_face: false,
      tooth_section: '',
      total_amount: 0,
    },
    budget_balance: budget?.saldo || 0,
    budget_total_amount: budget?.importe_total || 0,
    previous_budget_total_amount: budget?.importe_total || 0,
    date: budget?.fecha || today,
    description: budget?.descripcion || '',
    discount: {
      amount: !!budget?.desc_val || false,
      amount_value: budget?.desc_val || 0,
      percentage: !!budget?.desc_por || true,
      percentage_value: budget?.desc_por || 0,
    },
    estimated_time: budget?.tiempo || '',
    id_office: selectedOffice,
    id_budget: budget?.id_presup || 0,
    id_medical_insurance:
      budget?.id_planNavigation?.id_os || idPatientMedIns || firstMedIns.id_os,
    id_patient: budget?.id_paciente || patientId,
    id_plan:
      budget?.id_plan || idPatientPlan || firstMedIns?.planes?.[0].id_plan,
    id_professional: budget?.id_prof || firstProf.id_prof,
    id_user: idUser,
    interest_rate: budget?.interes_por || 0,
    installments: {
      quantity: installments?.id || '1',
      value: installments_value,
    },
    is_usd: budget?.es_usd ?? false,
    iva: iva,
    initial_payment: {
      amount:
        (budget?.importe_total || 1) * ((budget?.pago_inicial || 0) / 100) || 0,
      percentage: budget?.pago_inicial || 0,
    },
    not_include_office: budget?.id_sucursal === null,
    observation: budget?.obs || '',
    payment_method: budget?.forma || 'Efectivo',
    rowversion: budget?.rowversion || null,
    sessions: budget?.sesiones || '',
  };
}
