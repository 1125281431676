import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { typeLenders } from '../../../mocks';
import { Form, Title, Button } from '../..';
import { Card } from './card';
import { LenderT } from '#interfaces/components';

export const LenderForm: React.FC<LenderT> = ({ codes, open }) => {
  const { initialValues, validate } = useForm({ codes });

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={useSubmit(open)}
      enableReinitialize
    >
      {({ submitForm }) => (
        <div className="max-w-7xl w-full my-0 mx-auto flex flex-col px-10">
          <Title className="text-lg mb-5">Configuración de prestador</Title>
          <Form showActions={false} className="grid grid-cols-6 gap-5">
            <Card data={typeLenders} />
            <div className="pt-4">
              <Button className="bg-blue-500 w-96" onClick={submitForm}>
                Activar suscripción
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};
