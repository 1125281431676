import React from 'react';
import { TranslationsT } from '#interfaces/general-values';
import { Subtitle } from '../../../../components';
import { RadioButtonsByMedIns } from './RadioButtons';

export const GroupByController: React.FC<{ translations: TranslationsT }> = ({
  translations,
}) => {
  const group = [
    {
      text: translations.medical_insurance,
      field_names: { self: 'medical_insurance', opposites: ['professional'] },
    },
    {
      text: 'Profesional',
      field_names: { self: 'professional', opposites: ['medical_insurance'] },
    },
  ];

  return (
    <div className="col-span-6">
      <Subtitle>Agrupar por</Subtitle>
      <div className="grid grid-cols-2 gap-4">
        {group.map((item, i) => (
          <RadioButtonsByMedIns
            key={i}
            text={item.text}
            name="group_by_med_ins"
            selfField={item.field_names.self}
            oppositesFields={item.field_names.opposites}
          />
        ))}
      </div>
    </div>
  );
};
