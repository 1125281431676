import React from 'react';
import { TimeForm } from './TimeForm';
import { DeleteTimeForm } from './DeleteTimeForm';
import { EmptyState, Modal } from '#components/index';
import { SettingsProfSchedule } from '#components/Tables';
import { TimeTableFormT } from '#interfaces/components';
import { useForm, useSetHeight } from '../../../../../hooks';

const TimetableForm: React.FC<TimeTableFormT> = ({
  professionals,
  selectedProf,
  hasSchedule,
  specialties,
  offices,
  onChange,
  openModal,
  closeModal,
  isOpenModal,
}) => {
  const height = useSetHeight(290);
  const [schedule, setSchedule] = React.useState<any>([]);
  const [timeRow, setTimeRow] = React.useState<any>(false);
  const {
    isOpenForm: isOpenDelete,
    closeForm: closeDelete,
    openForm: openDelete,
  } = useForm();
  const currentProfessional = React.useMemo(
    () =>
      professionals?.find((p) => p.id_prof === selectedProf) ||
      professionals?.[0],
    [professionals, selectedProf],
  );

  React.useEffect(
    () => setSchedule(currentProfessional.prof_horarios),
    [currentProfessional],
  );

  const actions = (time: [key: string], action: string) => {
    const events: { [key: string]: any } = {
      delete: openDelete,
      edit: openModal,
    };
    setTimeRow(time);

    return !!events[action] ? events[action]() : setTimeRow({});
  };
  const filteredOffices = offices?.filter((o: any) => o.habilitada === true);

  return (
    <div
      className="overflow-hidden overflow-y-scroll"
      style={{ height: `${height}px` }}
    >
      {schedule.length ? (
        <SettingsProfSchedule
          data={schedule}
          action={actions}
          config={{ offices: filteredOffices, specialties }}
        />
      ) : (
        <EmptyState
          title="Ups!"
          copy="El profesional seleccionado no tiene horarios definidos."
        />
      )}
      <Modal
        title="Eliminar horario"
        isOpenModal={isOpenDelete}
        closeModal={() => {
          setTimeRow({});
          closeDelete();
        }}
        openModal={openDelete}
      >
        <DeleteTimeForm
          time={timeRow}
          selectedProf={currentProfessional}
          onChange={onChange}
        />
      </Modal>
      <Modal
        title={timeRow ? 'Modificar horario' : 'Nuevo horario'}
        isOpenModal={isOpenModal}
        closeModal={() => {
          setTimeRow({});
          closeModal();
        }}
        openModal={openModal}
      >
        <TimeForm
          time={timeRow}
          specialties={specialties}
          selectedProf={{ currentProfessional, hasSchedule }}
          offices={filteredOffices}
          onChange={onChange}
        />
      </Modal>
    </div>
  );
};

export default TimetableForm;
