import React from 'react';

export function useForm(
  group: { [key: string]: any } | null,
  selectedMed: { [key: string]: any },
) {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues(group, selectedMed),
  );

  React.useEffect(() => {
    setInitialValues(getInitialValues(group, selectedMed));
  }, [group, selectedMed]);

  const validate = React.useCallback((values: { [key: string]: any }) => {
    const errors: { [key: string]: any } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['code', 'description'];

    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues(
  group: { [key: string]: any } | null,
  selectedMed: { [key: string]: any },
) {
  const { id, id_os, code, description, rowversion } =
    group && typeof group === 'object'
      ? group
      : {
          id: 0,
          id_os: selectedMed?.id_os,
          code: '',
          description: '',
          rowversion: undefined,
        };

  return {
    id,
    id_os,
    code,
    description,
    rowversion,
  };
}
