import React from 'react';
import { useDispatch } from 'react-redux';
import { FormContext, SnackbarContext } from '../../../../context';
import { startLoading, stopLoading } from '../../../../redux/slices';
import { deleteBudget } from '../../../../services';
import { BudgetT } from '#interfaces/general-values';

export function useSubmit({
  selectedBudget,
  userId,
  refresh,
}: {
  selectedBudget: BudgetT;
  userId: string;
  refresh: (value?: string) => void;
}) {
  const dispatch = useDispatch();
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async () => {
    try {
      dispatch(startLoading());
      await axiosPromise({ selectedBudget, userId });
      await refresh();
      showSuccessSnackbar('Presupuesto eliminado correctamente');
    } catch (err: any) {
      let message;
      err instanceof Error ? (message = err.message) : (message = String(err));
      showErrorSnackbar(message);
    } finally {
      closeForm();
      dispatch(stopLoading());
    }
  };
}
function axiosPromise({
  selectedBudget,
  userId,
}: {
  selectedBudget: BudgetT;
  userId: string;
}) {
  const apiSpec = transformSpec({ selectedBudget, userId });

  return deleteBudget(apiSpec);
}

function transformSpec({
  selectedBudget,
  userId,
}: {
  selectedBudget: BudgetT;
  userId: string;
}) {
  const apiSpec = {
    id_presup: selectedBudget.id_presup,
    id_paciente: selectedBudget.id_paciente,
    fecha: selectedBudget.fecha,
    id_prof: selectedBudget.id_prof,
    id_plan: selectedBudget.id_plan,
    nro_afil: selectedBudget.nro_afil,
    importe_total: selectedBudget.importe_total,
    iva_porc: selectedBudget.iva_porc,
    saldo: selectedBudget.saldo,
    tiempo: selectedBudget.tiempo,
    sesiones: selectedBudget.sesiones,
    pago_inicial: selectedBudget.pago_inicial,
    forma: selectedBudget.forma,
    cuotas: selectedBudget.cuotas,
    desc_por: selectedBudget.desc_por,
    desc_val: selectedBudget.desc_val,
    interes_por: selectedBudget.interes_por,
    obs: selectedBudget.obs,
    descripcion: selectedBudget.descripcion,
    id_usuario: userId,
    id_sucursal: selectedBudget.id_sucursal,
    rowversion: selectedBudget.rowversion,
    es_usd: selectedBudget.es_usd,
  };

  return apiSpec;
}
