import { useCallback, useEffect, useState } from 'react';

export function useForm({
  codes,
}: {
  codes: {
    lenderCode: string;
    managerCode: string;
  };
}) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues(codes),
  );
  useEffect(() => {
    setInitialValues(getInitialValues(codes));
  }, [codes]);

  const validate = useCallback((values: any) => {
    const errors: { [key: string]: string } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['manager_code', 'lender_code'];
    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues(codes: { lenderCode: string; managerCode: string }) {
  return {
    lender_code: codes.lenderCode,
    manager_code: codes.managerCode,
  };
}
