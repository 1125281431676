import * as React from 'react';
import classNames from 'classnames';
import { ColumnsT } from '#interfaces/tables/benefit';
import { Icons, Badge } from '#components/commons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '#components/shadcn';

export const Columns = ({ action, config }: ColumnsT) => {
  const { permissions, isSupervisor, useUsd } = config;
  const columns = [
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        const { canceled } = row.original;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="relative p-2 hover:cursor-pointer flex-none">
                <Icons
                  type="context"
                  className="text-gray-500 hover:text-gray-900 h-4 w-4"
                />
                <span className="sr-only">Abrir menu</span>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="start">
              <DropdownMenuGroup>
                {(isSupervisor ||
                  permissions.hasOwnProperty('Modificar Pago')) && (
                  <DropdownMenuItem
                    className="hover:cursor-pointer"
                    onSelect={() => action(row.original, 'edit')}
                  >
                    <Icons type="edit" className="mr-2 h-4 w-4" />
                    <span>Modificar pago</span>
                  </DropdownMenuItem>
                )}
                {(isSupervisor ||
                  permissions.hasOwnProperty('Anular Pago')) && (
                  <DropdownMenuItem
                    className="hover:cursor-pointer"
                    onSelect={() => action(row.original, 'cancel')}
                  >
                    <Icons
                      type={`${canceled ? 'check' : 'cancel'}`}
                      className="mr-2 h-4 w-4"
                    />
                    <span>{`${canceled ? 'Habilitar' : 'Anular'}`} pago</span>
                  </DropdownMenuItem>
                )}
                {(isSupervisor ||
                  permissions.hasOwnProperty('Eliminar Pago')) && (
                  <DropdownMenuItem
                    className="hover:cursor-pointer text-red-500"
                    onSelect={() => action(row.original, 'delete')}
                  >
                    <Icons type="delete" className="mr-2 h-4 w-4" />
                    <span>Eliminar pago</span>
                  </DropdownMenuItem>
                )}
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: 'date_es',
      header: 'Fecha',
      cell: ({ row }: { row: any }) => {
        const { date_es } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {date_es}
          </p>
        );
      },
    },
    {
      accessorKey: 'status',
      header: 'Estado',
      cell: ({ row }: { row: any }) => {
        const { status } = row.original;

        return (
          <Badge
            className={classNames('w-auto mt-2 mb-1', status.style)}
            text={status.text}
          />
        );
      },
    },
    {
      accessorKey: 'professional',
      header: 'Profesional',
      cell: ({ row }: { row: any }) => {
        const { professional } = row.original;

        return (
          <p className="min-w-[250px] text-base font-semibold leading-6 text-slate-800 my-1">
            {professional}
          </p>
        );
      },
    },
    {
      accessorKey: 'concept',
      header: 'Concepto',
      cell: ({ row }: { row: any }) => {
        const { concept } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{concept}</p>
        );
      },
    },
    {
      accessorKey: 'voucher',
      header: 'Comprobante',
      cell: ({ row }: { row: any }) => {
        const { voucher } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{voucher}</p>
        );
      },
    },
    {
      accessorKey: 'type',
      header: 'Tipo',
      cell: ({ row }: { row: any }) => {
        const { type } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{type}</p>
        );
      },
    },
    {
      accessorKey: 'number',
      header: 'Número',
      cell: ({ row }: { row: any }) => {
        const { number } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{number}</p>
        );
      },
    },
    {
      accessorKey: 'method',
      header: 'Forma',
      cell: ({ row }: { row: any }) => {
        const { method } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{method}</p>
        );
      },
    },
    {
      accessorKey: 'debit',
      header: 'Debe',
      cell: ({ row }: { row: any }) => {
        const { debit } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{debit}</p>
        );
      },
    },
    {
      accessorKey: 'assets',
      header: 'Haber',
      cell: ({ row }: { row: any }) => {
        const { assets } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{assets}</p>
        );
      },
    },
    {
      accessorKey: 'balance',
      header: 'Saldo',
      cell: ({ row }: { row: any }) => {
        const { balance } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{balance}</p>
        );
      },
    },
    {
      accessorKey: 'is_usd',
      header: 'En USD',
      cell: ({ row }: { row: any }) => {
        const { is_usd } = row.original;

        return (
          <Icons
            type={is_usd ? 'check_clean' : 'cancel'}
            className={classNames(
              'w-5 h-5 mx-auto',
              is_usd ? 'text-green-500' : 'text-slate-500',
            )}
          />
        );
      },
      filterFn: (row: any, id: string, value: any) =>
        value.includes(row.getValue(id)),
    },
    {
      accessorKey: 'modified_by',
      header: 'Modificado por',
      cell: ({ row }: { row: any }) => {
        const { modified_by } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {modified_by}
          </p>
        );
      },
    },
  ];

  !useUsd.params && columns.splice(12, 1);

  return columns;
};
