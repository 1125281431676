import React from 'react';
import { updateBenefitsConfig } from '../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../context';
import { benefitFormFields } from '../../../../../../mocks';
import { fromatAge } from '../../../../../../utils';
import { FormikHelpers } from 'formik';

export function useSubmit(
  selectedMed: { [key: string]: any },
  selectedPlan: number,
  onChange: (value: number) => void,
) {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(spec, selectedMed, selectedPlan);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Prestación guardada correctamente');
      onChange(selectedPlan);
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(
  spec: { [key: string]: any },
  selectedMed: { [key: string]: any },
  selectedPlan: number,
) {
  const apiSpec = transformSpec(spec, selectedMed, selectedPlan);

  return updateBenefitsConfig(apiSpec);
}

function transformSpec(
  spec: { [key: string]: any },
  selectedMed: { [key: string]: any },
  selectedPlan: number,
) {
  const opcion = spec.rowversion ? 'modificar' : 'nuevo';
  const plan = selectedMed.planes.find(
    (p: { [key: string]: any }) => p.id_plan === selectedPlan,
  );
  const { unitField } = benefitFormFields;
  const { text: unidad } = (spec.unit &&
    spec.unit !== 'none' &&
    unitField.find((u: any) => u.id === spec.unit)) || { text: '999' };

  let apiSpec: { [key: string]: any } = {
    codigo_os: selectedMed.codigo_os,
    obra_social: selectedMed.obra_social,
    opcion,
    planes: [
      {
        codigo_plan: plan.codigo_plan,
        nombre: plan.nombre,
        prestaciones: [
          {
            id_prestacion: spec.id_benefit,
            id_plan: selectedPlan,
            descripcion: spec.description,
            importe_os: spec.billing_medical_insurance,
            importe_pac: spec.billing_patient,
            dibujo: spec.draw,
            diente: spec.tooth,
            pide_cara: spec.tooth_face,
            interno: spec.intern,
            inhabilitada: !spec.enabled,
            unidad,
            cantidad: spec.unit === 'none' ? null : spec.amount,
            codigo_prest_os: spec.code_medical_insurance,
            codigo_prest_os_liq: spec.code_medical_insurance_bill,
            es_odontograma: spec.catastral,
            es_usd: spec.is_usd,
            edad: fromatAge(spec.age_text, spec.age),
            por_paciente: spec.amount_patient,
            por_pieza_sector: spec.amount_unit,
            id_grupo: spec.id_group,
            id_prestacion_a_realizar: spec.id_benefit_to_do,
            id_especialidad: spec.id_specialty,
          },
        ],
      },
    ],
  };

  if (spec.rowversion) {
    apiSpec.planes[0].prestaciones[0].rowversion = spec.rowversion;
  }

  return apiSpec;
}
