import React from 'react';
import { ActionT } from '#interfaces/components/commons';

export const Action: React.FC<ActionT> = ({ item, onClick }) => {
  const { name, icon, description, action } = item;

  return (
    <div
      key={name}
      onClick={onClick}
      className="flex flex-col justify-between rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50"
    >
      <div className="flex md:h-full lg:flex-col">
        <div className="flex-shrink-0">
          <div className="inline-flex h-10 w-10 items-center justify-center rounded-md bg-slate-700 text-white sm:h-12 sm:w-12">
            {icon}
          </div>
        </div>
        <div className="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4">
          <div>
            <p className="text-base font-medium text-gray-900">{name}</p>
            <p className="mt-1 text-sm text-gray-500">{description}</p>
          </div>
          <p className="mt-2 text-sm flex items-center font-medium text-blue-600 group lg:mt-4 hover:cursor-pointer">
            {action}
            <span
              aria-hidden="true"
              className="ml-2 inline-block group-hover:translate-x-1 group-hover:transition-all group-hover:duration-200 group-hover:ease-in-out"
            >
              &rarr;
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
