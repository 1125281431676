import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';
import { ScheduleResults } from './Results';
import { PatientResults } from '#components/Tables';
import { fetchPatient, fetchPatientsList } from '../../redux/slices/patients';
import { startLoading, stopLoading, setPatientAlert } from '../../redux/slices';
import { useOnClickOutside } from '../../hooks';
import { SearchBarT } from '#interfaces/components';
import { AppDispatch } from '../../redux';
import { Input, Checkbox, Button } from '#components/shadcn';

export const SearchBar: React.FC<SearchBarT> = ({
  searchResults,
  isSchedule,
  translations,
  errSnk,
  focus,
  onClick,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [isContained, setIsContained] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [sorting, setSorting] = useState([]);
  const refWrapper = useRef(null);
  const refList = useRef(null);

  useOnClickOutside({
    ref: refWrapper,
    handler: () => {
      setIsOpen(false);
      setSearchInput('');
    },
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsOpen(false);
    setSearchInput(e.target.value);
    setHasSearched(false);
  };

  const onSubmitHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (searchInput.trim() !== '' && !hasSearched) {
      dispatch(startLoading());
      await dispatch(
        fetchPatientsList({
          input: searchInput,
          isContained,
          errSnk,
        }),
      );
      dispatch(stopLoading());
      setIsOpen(true);
      setHasSearched(true);
    }
  };

  const searchPatientClickHandler = async (id_paciente: number) => {
    dispatch(startLoading());
    await dispatch(fetchPatient({ patientId: id_paciente }));
    dispatch(stopLoading());
    setIsOpen(false);
    setSearchInput('');
    dispatch(setPatientAlert(true));
  };
  const showResults = isOpen && searchResults && searchResults.length >= 1;

  const searchBarContainerCss = classNames(
    'rounded-md will-change-[left] transition-all duration-100 ease-out w-full group',
    isOpen && 'searchbar-container--is_open relative z-10',
  );
  const searchForCss = classNames(
    showResults
      ? 'opacity-100 h-[620px] absolute z-50 rounded-md shadow-md will-change-[left]'
      : 'opacity-0 transition-all duration-100 ease-out',
    isSchedule && 'h-[300px]',
  );

  const height =
    searchResults && searchResults.length < 9
      ? `${searchResults.length * 65 + 48}px`
      : '620px';

  return (
    <form ref={refWrapper} className={searchBarContainerCss}>
      <div className="grid grid-cols-12 gap-x-4 rounded-md">
        <Input
          type="search"
          className="m-0 text-slate-900 py-2 pl-5 h-10 w-full relative rounded-md text-base col-span-7"
          placeholder="Buscar paciente..."
          onChange={(e: any) => onChangeHandler(e)}
          value={searchInput}
          autoFocus
          aria-label="Buscar paciente"
          name="as_word"
          maxLength={120}
          autoCapitalize="off"
          ref={focus}
        />
        <div className="flex items-center space-x-2 col-span-2">
          <Checkbox
            id="contained"
            checked={isContained}
            onClick={() => setIsContained(!isContained)}
          />
          <label
            htmlFor="contained"
            className="text-sm font-medium leading-none text-slate-600 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Que esté contenido
          </label>
        </div>
        <Button
          disabled={!searchInput}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            onSubmitHandler(e)
          }
          type="submit"
          className="w-full col-span-1 bg-transparent !text-gray-800 hover:bg-slate-200/50 border border-slate-200"
        >
          Buscar
        </Button>
        <Button
          onClick={(e) => onClick(e)}
          className="w-full col-span-2 hover:bg-blue-600"
        >
          Paciente nuevo
        </Button>
      </div>
      <div ref={refList} className={searchForCss}>
        {showResults && isSchedule && (
          <ScheduleResults
            results={searchResults}
            onClickHandler={searchPatientClickHandler}
            translations={translations}
          />
        )}
      </div>
      <Transition
        show={showResults}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <PatientResults
          data={searchResults}
          translations={translations}
          sorting={sorting}
          setSorting={setSorting}
          onClick={searchPatientClickHandler}
          height={height}
        />
      </Transition>
    </form>
  );
};
