import React from 'react';
import { Avatar, Logo } from './';
import { NavT } from '#interfaces/components';

export const Nav: React.FC<NavT> = ({
  section,
  userName,
  changeIcon,
  onClickHandler,
}) => {
  return (
    <div className="grid grid-cols-[5em_auto] print:hidden">
      <Avatar userName={userName} />
      <div className="flex justify-between items-center bg-slate-50 border-b border-slate-200">
        <p className="ml-8 text-slate-600 font-semibold text-lg">{section}</p>
        <Logo className="w-20 mr-6" />
      </div>
    </div>
  );
};
