import React from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
} from '@syncfusion/ej2-react-grids';
import { getValue } from '@syncfusion/ej2-base';
import { EmptyState } from '../../../../../components';
import { todoGridMapper } from '../../../../../utils';

import './index.scss';
import { TranslationsT } from '#interfaces/general-values';

const BenefitList: React.FC<{ todos: any; translations: TranslationsT }> = ({
  todos,
  translations,
}) => {
  const rowDataBound = (args: any) => {
    const className = getValue('className', args.data);
    args.row.classList = `e-row e-altrow ${className}`;
  };

  return (
    <section className="benefits-list">
      {todos.length ? (
        <>
          <div className="benefits-list__references">
            <span className="red">Prest. anterior</span>
            <span className="blue">Prest. a realizar</span>
            <span className="green">Prest. Realizada</span>
          </div>
          <GridComponent
            dataSource={todoGridMapper(todos)}
            pageSettings={{ pageSize: 50 }}
            rowDataBound={rowDataBound}
            height="100%"
          >
            <ColumnsDirective>
              <ColumnDirective field="id" visible={false} />
              <ColumnDirective field="className" visible={false} />
              <ColumnDirective field="Fecha" width="80" />
              <ColumnDirective field="Profesional" width="150" />
              <ColumnDirective field="Cod Prest" width="80" />
              <ColumnDirective field="Descripción" width="150" />
              <ColumnDirective field="Diente/Sector" width="100" />
              <ColumnDirective field="Cara" width="60" />
              <ColumnDirective field={translations.amount_mi} width="80" />
              <ColumnDirective field="Imp Pac" width="80" />
            </ColumnsDirective>
          </GridComponent>
        </>
      ) : (
        <EmptyState
          title="Ups!"
          copy="El diente seleccionado no tiene prestaciones para mostrar."
        />
      )}
    </section>
  );
};

export default BenefitList;
