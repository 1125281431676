import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Nav, Menu } from './';
import { LayoutT } from '#interfaces/components';

export const Layout: React.FC<LayoutT> = (props) => {
  const { centered, section, children } = props;
  const {
    login: {
      user: { nombre },
    },
  } = useSelector((state: any) => state);
  const [changeMenu, setChangeMenu] = useState(false);

  const contentCss = classNames(
    'flex flex-col items-center justify-start',
    centered && 'justify-center',
  );

  return (
    <div className="min-h-screen grid grid-rows-[60px_auto] print:flex print:justify-center">
      <Nav
        section={section}
        userName={nombre}
        changeIcon={changeMenu}
        onClickHandler={() => setChangeMenu(!changeMenu)}
      />
      <div className="grid grid-cols-[5em_auto] h-full print:flex">
        <Menu {...props} />
        <div className={contentCss}>{children}</div>
      </div>
    </div>
  );
};
