import React from 'react';
import { DropdownForm } from '../../../commons';
import { useFieldProps } from '../../../../hooks';
import { ProfessionalT } from '#interfaces/general-values';

export const ProfessionalDropdown: React.FC<{
  name: string;
  professionals: ProfessionalT[];
}> = ({ name, professionals }) => {
  const {
    field: { value: professional },
    setFieldValue,
  } = useFieldProps({ name });

  const enabledProfessionals = React.useMemo(
    () => professionals.filter((el) => el.habilitado && !el.no_tiene_agenda),
    [professionals],
  );

  React.useEffect(() => {
    if (
      (professional &&
        !enabledProfessionals.find((el) => el.id_prof === professional)) ||
      (!professional && enabledProfessionals.length)
    ) {
      setFieldValue(enabledProfessionals[0]?.id_prof);
    }
  }, [professional, enabledProfessionals, setFieldValue]);

  return (
    <DropdownForm
      name={name}
      data={enabledProfessionals}
      label="Profesional"
      keyId="id_prof"
      keyData="nombre"
    />
  );
};
