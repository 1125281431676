import React from 'react';
import { FormikHelpers } from 'formik';
import { saveTurn } from '../../../../services';
import {
  SnackbarContext,
  FormContext,
  ModalContext,
} from '../../../../context';
import { FreemiumForm } from '../../../../components';
import { MedicalInsurancesT } from '#interfaces/general-values';

export function useSubmit({
  appointment,
  onChange,
  medicalInsurances,
}: {
  appointment: any;
  onChange: () => void;
  medicalInsurances: MedicalInsurancesT[];
}) {
  const { closeForm } = React.useContext(FormContext);
  const { showSuccessSnackbar, showErrorSnackbar } =
    React.useContext(SnackbarContext);
  const { showModal, hideModal } = React.useContext(ModalContext);

  return async (spec: any, formikActions: FormikHelpers<any>) => {
    try {
      await axiosPromise({ appointment, spec, medicalInsurances });
      showSuccessSnackbar('Turno guardado con éxito');
      onChange();
    } catch (err: any) {
      err.message.includes('40000')
        ? showModal(
            'Suscribirse a Premium',
            <FreemiumForm onCancel={hideModal} />,
          )
        : showErrorSnackbar(
            `${err.message} Error status code: ${err.statusCode}`,
          );
    } finally {
      closeForm();
      formikActions.resetForm();
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise({
  appointment,
  spec,
  medicalInsurances,
}: {
  appointment: any;
  spec: any;
  medicalInsurances: MedicalInsurancesT[];
}) {
  const apiSpec = transformSpec({ appointment, spec, medicalInsurances });

  return saveTurn(apiSpec);
}

function transformSpec({
  appointment,
  spec,
  medicalInsurances,
}: {
  appointment: any;
  spec: any;
  medicalInsurances: MedicalInsurancesT[];
}) {
  const medicalInsurance = medicalInsurances?.find(
    (m) => m.id_os === spec.medical_insurance,
  );
  const medicalInsurancePlan = medicalInsurance?.planes?.find(
    (m: any) => m.id_plan === spec.medical_insurance_plan,
  );
  const apiSpec = {
    hora: spec.hour,
    opcion: 'agregarTurnoPac1Vez',
    es_sobreturno: false,
    id_prof: spec.id_professional,
    id_paciente: null,
    fecha: spec.date,
    no_dar: false,
    obs: spec.notes,
    paciente: spec.patient,
    telefono: spec.phone,
    asistencia: null,
    id_usuario: spec.id_user,
    obra_social: medicalInsurance?.obra_social || spec.medical_insurance,
    plan_nombre: medicalInsurancePlan?.nombre || spec.medical_insurance_plan,
    nro_afil: spec.afiliate_number,
    fecha_dado: new Date().toISOString(),
    hora_llegada: null,
    id_sucursal: spec.office,
  };

  if (appointment.id_appointment) {
    return {
      ...apiSpec,
      id_agenda: appointment.id_appointment,
      rowversion: appointment.rowversion,
    };
  }

  return apiSpec;
}
