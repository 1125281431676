import React from 'react';
import { Formik } from 'formik';
import classNames from 'classnames';
import {
  Button,
  CheckboxForm,
  TextFieldForm,
  NumericField,
  Form,
  Copy,
  Modal,
} from '#components/index';
import { LaboratoryDataFormT, LaboratoryT } from '#interfaces/components/forms';
import { useForm } from './use-form';
import { useSubmit } from './use-submit';
import { DeleteLab } from './DeleteLab';
import { useForm as useFormHook, useSetHeight } from '../../../../../../hooks';

export const LaboratoryDataForm: React.FC<LaboratoryDataFormT> = ({
  refTarget,
  newLabHandler,
  focusElement,
  onChange,
  newLab,
  selectedLab,
  modifying,
  modifyingHandler,
  laboratories,
  planVersion,
  is_usd,
}) => {
  const current: LaboratoryT | undefined = React.useMemo(() => {
    if (selectedLab && !newLab) {
      const foundProf = laboratories?.find(
        (p: { [key: string]: any }) => p.id_labo === selectedLab,
      );
      return foundProf || laboratories?.[0];
    }
  }, [laboratories, selectedLab, newLab]);
  const height = useSetHeight(350);
  const { initialValues, validate } = useForm(current);

  React.useEffect(() => {
    newLabHandler(false);
    modifyingHandler(false);
  }, [newLabHandler, modifyingHandler]);

  async function handleOnSubmit(submitForm: () => void) {
    if (modifying) {
      submitForm();
      return modifyingHandler(false);
    }
    modifyingHandler(true);
    focusElement();
  }
  const { isOpenForm, closeForm, openForm } = useFormHook();
  const formOpen = (modifying: boolean | undefined) => {
    if (!modifying && laboratories) {
      openForm();
    }
    newLabHandler(false);
    modifyingHandler(false);
  };

  return (
    <div className="p-10 overflow-hidden">
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={useSubmit(onChange, current)}
        enableReinitialize
      >
        {({ submitForm, isSubmitting, isValid }) => (
          <>
            <Form
              showActions={false}
              className="relative z-10 flex flex-col justify-between"
              height={height}
            >
              <div
                className={classNames(
                  !modifying &&
                    'pointer-events-none cursor-not-allowed filter grayscale-100 opacity-60',
                )}
              >
                <div className="mb-4 -mt-2 h-0 w-full border-t border-gray-300" />
                <Copy className="!text-slate-900 !font-bold !text-base mb-4">
                  Datos del laboratorio
                </Copy>
                <div className="form-row grid grid-cols-4 gap-10 mb-4">
                  <TextFieldForm
                    multiline
                    label="Laboratorio"
                    name="name"
                    refTarget={refTarget}
                  />
                  <TextFieldForm multiline label="Contacto" name="contact" />
                  <TextFieldForm multiline label="Dirección" name="address" />
                  <TextFieldForm multiline label="Localidad" name="location" />
                  <TextFieldForm multiline label="Provincia" name="city" />
                  <TextFieldForm multiline label="Teléfono/s" name="phone" />
                  <TextFieldForm multiline label="e-Mail" name="email" />
                  <NumericField label="Saldo actual" name="balance" />
                  {is_usd && (
                    <NumericField label="Saldo actual U$D" name="balance_usd" />
                  )}
                  <TextFieldForm multiline label="C.U.I.T" name="cuit" />
                  <TextFieldForm
                    multiline
                    label="Observaciones"
                    name="observations"
                    className="col-span-2"
                  />
                  <div className="mt-6">
                    <CheckboxForm
                      label="Laboratorio habilitado"
                      name="enabled"
                      showError={false}
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-5 relative">
                <Button
                  onClick={() => formOpen(modifying)}
                  modifier="warning"
                  size="large"
                >
                  {modifying ? 'Cancelar' : 'Eliminar'}
                </Button>
                <Button
                  onClick={() => handleOnSubmit(submitForm)}
                  disabled={
                    planVersion === 'freemium' || isSubmitting || !isValid
                  }
                  loading={isSubmitting}
                  size="large"
                >
                  {modifying ? 'Guardar' : 'Editar'}
                </Button>
              </div>
            </Form>
            <Modal
              title="Eliminar Laboratorio"
              isOpenModal={isOpenForm}
              closeModal={closeForm}
              openModal={openForm}
            >
              <DeleteLab laboratory={current} onChange={onChange} />
            </Modal>
          </>
        )}
      </Formik>
    </div>
  );
};
