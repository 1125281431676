import React from 'react';
import { useFieldProps } from '../../../../../../hooks';
import { CheckboxForm } from '../../../../../../components';

export const ControllercheckBox: React.FC<{
  name: string;
  description?: string;
  label: string;
  handleOnChange: (value: any) => any;
}> = ({ name, label, description, handleOnChange }) => {
  const {
    field: { value },
  } = useFieldProps({ name });

  React.useEffect(() => {
    handleOnChange(value);
  }, [value]);

  return (
    <CheckboxForm
      label={label}
      description={description}
      small
      name={name}
      showError={false}
    />
  );
};
