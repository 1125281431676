import React from 'react';
import classNames from 'classnames';
import { GridRowT } from '#interfaces/components';

export const GridRow: React.FC<GridRowT> = ({
  item,
  prop,
  className,
  visible = true,
  template,
}) => {
  const rowCss = classNames(
    'whitespace-nowrap py-2 px-4 text-sm text-gray-500',
    visible ? 'table-cell' : 'hidden',
    className,
  );

  return template && visible ? (
    template(item && item[prop || ''])
  ) : (
    <td className={rowCss}>{item && item[prop || '']}</td>
  );
};
