import React from 'react';
import { useFieldProps } from '../../../../../hooks';
import { RadioButton } from '../../../../../components';

export const RadioButtonsByDate: React.FC<{
  text: string;
  name: string;
  selfField: string;
  oppositesFields: any[];
}> = ({ text, name, selfField, oppositesFields }) => {
  const fieldName = `${name}.${selfField}`;
  const [firstOpposite, secondOpposite] = oppositesFields.map(
    (f, i) => `${name}.${f}`,
  );
  const {
    field: { value },
    setFieldValue,
  } = useFieldProps({ name: fieldName });
  const { setFieldValue: setFirstOpposite } = useFieldProps({
    name: firstOpposite,
  });
  const { setFieldValue: setSecondOpposite } = useFieldProps({
    name: secondOpposite,
  });

  const handleOnChange = (e: any) => {
    e.preventDefault();
    setFieldValue(true);
    setFirstOpposite(false);
    setSecondOpposite(false);
  };

  return (
    <RadioButton
      label={text}
      name={fieldName}
      checked={value}
      onChange={handleOnChange}
    />
  );
};
