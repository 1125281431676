import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Button } from '../../../components';
import { useFieldProps } from '../../../hooks';
import { compareDatesRanges } from '../../../utils';
import { DayOfWeekT } from '#interfaces/components';

const days = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'];

export const DaysOfWeek: React.FC<DayOfWeekT> = ({
  name,
  dateFieldName,
  officeFieldName,
  professionalFieldName,
  specialtyFieldName,
  professionals,
}) => {
  const { field, setFieldValue } = useFieldProps({ name });
  const {
    field: { value: date },
    setFieldValue: setDateFieldValue,
  } = useFieldProps({ name: dateFieldName });
  const {
    field: { value: office },
  } = useFieldProps({ name: officeFieldName });
  const {
    field: { value: specialty },
  } = useFieldProps({ name: specialtyFieldName });
  const {
    field: { value: professional },
  } = useFieldProps({ name: professionalFieldName });
  const {
    field: { value: layout },
  } = useFieldProps({ name: 'layout' });
  const currentProfessional = useMemo(
    () => professionals.find((p) => p.id_prof === professional),
    [professionals, professional],
  );

  const availableOfficeProfessionalDays = useMemo(() => {
    if (currentProfessional?.prof_horarios?.length) {
      //NEED TO REWRITE THIS
      let professionalDays;
      professionalDays = currentProfessional.prof_horarios.filter((s) => {
        // Chequea si el horario definido tiene rango de fecha definido
        if (s.fecha_desde && s.fecha_hasta) {
          // Compara si la fecha actual esta dentro de las fechas del horario definido
          return compareDatesRanges({
            from: s.fecha_desde,
            to: s.fecha_hasta,
            date,
          });
        }
        return currentProfessional.prof_horarios;
      });
      if (specialty) {
        professionalDays = currentProfessional.prof_horarios.filter((s) => {
          if (s.fecha_desde || s.fecha_hasta) {
            return (
              // Chequea si el horario es de la especialidad seleccionada
              // o si no tiene id de la especialidad
              (s.id_especialidad === specialty || !s.id_especialidad) &&
              compareDatesRanges({
                from: s.fecha_desde,
                to: s.fecha_hasta,
                date,
              })
            );
          }
          return s.id_especialidad === specialty || !s.id_especialidad;
        });
      }
      if (office) {
        professionalDays = currentProfessional.prof_horarios.filter((s) => {
          if (s.fecha_desde || s.fecha_hasta) {
            return (
              s.id_sucursal === office &&
              compareDatesRanges({
                from: s.fecha_desde,
                to: s.fecha_hasta,
                date,
              })
            );
          }
          return s.id_sucursal === office;
        });
      }
      if (office && specialty) {
        professionalDays = currentProfessional.prof_horarios.filter((s) => {
          if (s.fecha_desde || s.fecha_hasta) {
            return (
              s.id_sucursal === office &&
              (s.id_especialidad === specialty || !s.id_especialidad) &&
              compareDatesRanges({
                from: s.fecha_desde,
                to: s.fecha_hasta,
                date,
              })
            );
          }
          return (
            s.id_sucursal === office &&
            (s.id_especialidad === specialty || !s.id_especialidad)
          );
        });
        return professionalDays.map((s) => s.dia);
      }
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, office, specialty, professional]);

  useEffect(() => {
    const dayOfWeek = getDayOfWeek(format(date, 'yyyy/MM/dd'));
    if (dayOfWeek !== field.value) {
      setFieldValue(dayOfWeek);
    }
  }, [date, setFieldValue, field.value]);

  const handleClick = (dayOfWeek: number) => () => {
    const diffInDays = dayOfWeek - field.value;
    const newDate = addDaysToDate(format(date, 'yyyy/MM/dd'), diffInDays);
    setDateFieldValue(newDate);
    setFieldValue(dayOfWeek);
  };

  const daysCurrentProf = currentProfessional?.prof_horarios?.map((s) => s.dia);
  const disabledButtons = !professional || layout !== 'buscar';
  return (
    <div className="grid grid-cols-7 bg-slate-200/60 p-2 rounded-md gap-x-4">
      {days.map((day, index) => {
        const buttonCss = classNames(
          '!m-0 !border-none !hover:bg-slate-300 !hover:shadow-none',
          field.value === index + 1
            ? '!bg-white !shadow-md !text-slate-800'
            : !availableOfficeProfessionalDays.includes(index + 1) &&
              !daysCurrentProf?.includes(index + 1)
            ? '!bg-slate-300 !text-slate-800'
            : '!bg-green-200 !text-green-800',
        );

        return (
          <Button
            key={day}
            size="small"
            className={buttonCss}
            onClick={handleClick(index + 1)}
            disabled={disabledButtons}
          >
            {day}
          </Button>
        );
      })}
    </div>
  );
};

export function addDaysToDate(date: string, days: number): Date {
  const newDate: Date = new Date(date);
  newDate.setDate(newDate.getDate() + days);

  return newDate;
}

export function getDate(date: string) {
  const [day, month, year] = date.split('/');
  const parsedDay = parseInt(day, 10);
  const parsedMonth = parseInt(month, 10);
  const parsedYear = parseInt(year, 10);

  return new Date(parsedYear, parsedMonth - 1, parsedDay, 12, 0, 0);
}

export function getDayOfWeek(dateString: string): number {
  const date = new Date(dateString);
  const value = date.getDay();
  const dayOfWeek = value === 0 ? 7 : value;

  return dayOfWeek;
}
