export const methods: { id: string; text: string }[] = [
  { id: 'Efectivo', text: 'Efectivo' },
  { id: 'Tarjeta', text: 'Tarjeta de Crédito' },
  { id: 'TarjetaD', text: 'Tarjeta de Débito' },
  { id: 'Transf.', text: 'Transferencia' },
  { id: 'Cheque', text: 'Cheque' },
  { id: 'Otro', text: 'Otro' },
];

export const quotaData: { id: string; text: string; value: number }[] = [
  { id: '1', text: '01', value: 1 },
  { id: '2', text: '03', value: 3 },
  { id: '3', text: '06', value: 6 },
  { id: '4', text: '12', value: 12 },
  { id: '5', text: '24', value: 24 },
];

type defaultValues = { id: string; text: string }[];

export const invoices: defaultValues = [
  { id: 'Factura', text: 'Factura' },
  { id: 'Recibo', text: 'Recibo' },
  { id: 'Nota Crédito', text: 'Nota Crédito' },
  { id: 'Nota Débito', text: 'Nota Débito' },
  { id: 'Otro', text: 'Otro' },
];

export const invoiceTypes: defaultValues = [
  { id: 'A', text: 'A' },
  { id: 'B', text: 'B' },
  { id: 'C', text: 'C' },
  { id: 'X', text: 'X' },
];
