import { useContext } from 'react';
import { SnackbarContext, LoadingContext, FormContext } from '../../../context';
import {
  createAuditorLiquidation,
  generateAuditorDocument,
  closeAuditorLiquidation,
} from '../../../services';

export function useSubmit({
  onChange,
  currentPage,
  liquidationActions,
  generateDocument,
  closeLiquidation,
}: {
  onChange: (value: any) => void;
  currentPage: number;
  liquidationActions: {
    generate_document: boolean;
    close_liquidation: boolean;
  };
  generateDocument: (bool: boolean) => void;
  closeLiquidation: (bool: boolean) => void;
}) {
  const { closeForm } = useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);
  const { startLoading, stopLoading } = useContext(LoadingContext);

  return async (spec: any) => {
    try {
      startLoading();
      const { data } = await axiosPromise({
        spec,
        currentPage,
        liquidationActions,
        generateDocument,
        closeLiquidation,
      });
      if (data.servicesToAudit) {
        onChange(data.servicesToAudit);
      } else {
        showSuccessSnackbar(data.message);
      }
      closeForm();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
      onChange([]);
    } finally {
      stopLoading();
    }
  };
}

async function axiosPromise({
  spec,
  currentPage,
  liquidationActions,
  generateDocument,
  closeLiquidation,
}: {
  spec: any;
  currentPage: number;
  liquidationActions: {
    generate_document: boolean;
    close_liquidation: boolean;
  };
  generateDocument: (bool: boolean) => void;
  closeLiquidation: (bool: boolean) => void;
}) {
  const apiSpec = transformSpec({ spec, currentPage });
  const { generate_document, close_liquidation } = liquidationActions;

  if (generate_document) {
    generateAuditorDocument(apiSpec);
    generateDocument(false);
    return { data: { message: 'El documento se generó correctamente' } };
  }

  if (close_liquidation) {
    closeAuditorLiquidation(apiSpec);
    closeLiquidation(false);
    return { data: { message: 'La liquidación se cerró correctamente' } };
  }

  return createAuditorLiquidation(apiSpec);
}

function transformSpec({
  spec,
  currentPage,
}: {
  spec: any;
  currentPage: number;
}) {
  const liquidatedValues: {
    id: string;
    is_liquidated: boolean | null;
  }[] = [
    {
      id: 'ALL',
      is_liquidated: null,
    },
    {
      id: 'LIQ',
      is_liquidated: true,
    },
    {
      id: 'NOLIQ',
      is_liquidated: false,
    },
  ];

  const id_prof = spec.professionals_all ? null : spec.professional;
  const id_grupo = spec.groups_all ? null : spec.medical_insurance_group;
  const { is_liquidated } =
    liquidatedValues.find((v) => v.id === spec.benefits_state) || {};

  const apiSpec: {
    professionalId: number;
    groupId: number;
    medicalInsuranceId: number;
    isRebilling: boolean;
    period: Date | string;
    isLiquidated: boolean | null | undefined;
    pageNumber: number;
    useProfesionalCode: boolean;
  } = {
    professionalId: id_prof,
    groupId: id_grupo,
    medicalInsuranceId: spec.medical_insurance,
    isRebilling: spec.rebilling,
    period: spec.period,
    isLiquidated: is_liquidated,
    pageNumber: currentPage,
    useProfesionalCode: spec.include_prof_code,
  };

  return apiSpec;
}
