import React from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Resize,
  ContextMenu,
} from '@syncfusion/ej2-react-grids';
import { DeleteHolidayForm } from './DeleteHoliday';
import { HolidayForm } from './HolidayForm';
import { EmptyState, Button, Modal } from '../../../../../components';
import { useForm } from '../../../../../hooks';
import { gridContextMenu } from '../../../../../mocks';
import { holidaysGridMapper } from '../../../../../utils';
import { HolidaysFormT } from '#interfaces/components';

export const HolidaysForm: React.FC<HolidaysFormT> = ({
  holidays,
  onChange,
  onClose,
}) => {
  const grid = React.useRef<any>(null);
  const [holidayRow, setHolidayRow] = React.useState(false);
  const {
    isOpenForm: isOpenDelete,
    closeForm: closeDelete,
    openForm: openDelete,
  } = useForm();
  const {
    isOpenForm: isOpenEdit,
    closeForm: closeEdit,
    openForm: openEdit,
  } = useForm();

  React.useEffect(() => {
    onChange('holiday');
  }, [onChange]);

  const onChangeHandler = () => {
    onChange('holiday');
    onClose();
  };

  const onCancelHandler = () => {
    setHolidayRow(false);
    closeEdit();
    closeDelete();
  };

  const contextMenuClickHandler = (e: { [key: string]: any }) => {
    const [selectedRow] = grid.current.getSelectedRecords();
    setHolidayRow(selectedRow);
    const events: { [key: string]: any } = {
      delete: openDelete,
      edit: openEdit,
    };
    if (!!events[e.item.id]) {
      return events[e.item.id](e);
    }
  };

  return (
    <section>
      {holidays.length ? (
        <GridComponent
          ref={grid}
          dataSource={holidaysGridMapper(holidays)}
          pageSettings={{ pageSize: 15 }}
          allowResizing={true}
          allowTextWrap={true}
          height="100%"
          contextMenuItems={gridContextMenu}
          textWrapSettings={{ wrapMode: 'Content' }}
          contextMenuClick={(e) => contextMenuClickHandler(e)}
        >
          <Inject services={[Resize, ContextMenu]} />
          <ColumnsDirective>
            <ColumnDirective field="id" visible={false} />
            <ColumnDirective field="id_prof" visible={false} />
            <ColumnDirective field="option" visible={false} />
            <ColumnDirective field="rowversion" visible={false} />
            <ColumnDirective
              field="format_date_from"
              headerText="Fecha desde"
            />
            <ColumnDirective field="format_date_to" headerText="Fecha hasta" />
          </ColumnsDirective>
        </GridComponent>
      ) : (
        <EmptyState title="Ups!" copy="No hay feriados asignados." />
      )}
      <Button className="mt-10" onClick={openEdit}>
        Nuevo feriado
      </Button>
      <Modal
        title="Eliminar feriado"
        isOpenModal={isOpenDelete}
        closeModal={onCancelHandler}
        openModal={openDelete}
      >
        <DeleteHolidayForm holiday={holidayRow} onChange={onChangeHandler} />
      </Modal>
      <Modal
        title={holidayRow ? 'Editar feriado' : 'Nuevo feriado'}
        isOpenModal={isOpenEdit}
        closeModal={onCancelHandler}
        openModal={openEdit}
      >
        <HolidayForm holiday={holidayRow} onChange={onChangeHandler} />
      </Modal>
    </section>
  );
};
