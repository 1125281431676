import React from 'react';
import { Formik } from 'formik';
import { useForm } from './use-form';
import { useSubmit } from './use-submit';
import {
  Card,
  Button,
  Logo,
  CheckboxForm,
  TextFieldForm,
  Form,
} from '../../../components';
import { useSetHeight } from '../../../hooks';
import { LoginFormT } from '#interfaces/components';

export const LoginForm: React.FC<LoginFormT> = ({ onChange }) => {
  const { initialValues } = useForm();
  const height = useSetHeight(80);

  return (
    <Formik initialValues={initialValues} onSubmit={useSubmit(onChange)}>
      {({ submitForm, isSubmitting, isValid }) => (
        <Form ignoreDirty showActions={false} height={`${height}px`}>
          <div className="relative group max-w-[340px] mx-auto ">
            <Card className="relative bg-white w-[340px] m-auto">
              <Logo className="flex w-36 mx-auto mb-8 mt-4 p-0" />
              <p className="text-base font-regular mb-6 text-neutral-500 text-center">
                ¡Hola! Ingresá tu usuario y contraseña para iniciar sesión
              </p>
              <TextFieldForm
                className="mb-4"
                name="webuser"
                placeholder="Usuario Bilog"
              />
              <TextFieldForm
                className="mb-4"
                name="user"
                placeholder="Usuario"
              />
              <TextFieldForm
                className="mb-4"
                name="password"
                placeholder="Contraseña"
                type="password"
              />
              <CheckboxForm
                label="Mantenerme logueado"
                name="remember"
                showError={false}
                className="mb-6"
              />
              <Button
                onClick={submitForm}
                disabled={isSubmitting || !isValid}
                loading={isSubmitting}
                fullWidth
              >
                Continuar
              </Button>
            </Card>
          </div>
          <p className="flex justify-center text-base text-center mt-6 text-white pb-auto">
            <a
              className="block font-bold hover:underline"
              href="https://signup.bilog.com.ar"
              target="_blank"
              rel="noreferrer"
            >
              Registrarme
            </a>
            <span className="mx-2 text-slate-300">|</span>
            <a
              className="block font-bold hover:underline"
              href="https://signup.bilog.com.ar/recovery/password"
              target="_blank"
              rel="noreferrer"
            >
              Olvidé mi contraseña
            </a>
            <span className="mx-2 text-slate-300">|</span>
            <a
              className="block font-bold hover:underline"
              href="https://www.bilog.com.ar/first-steps"
              target="_blank"
              rel="noreferrer"
            >
              Ayuda
            </a>
          </p>
        </Form>
      )}
    </Formik>
  );
};
