import React from 'react';
import { NormalBannerT } from '#interfaces/components';

export const Normal: React.FC<NormalBannerT> = ({ children, show }) => {
  return (
    show && (
      <section className="w-full bg-neutral-800 py-6 flex justify-center absolute bottom-0">
        {children}
      </section>
    )
  );
};
