import { useField, FieldInputProps } from 'formik';
import { useMemo, useRef } from 'react';
import { identity } from '../utils';
import { useUniqueId } from './useUniqueId';
import { useFieldPropsT } from '#interfaces/hooks';

export function useFieldProps({ name, transform = identity }: useFieldPropsT) {
  const id = useUniqueId();
  const [fiprops, meta, helpers] = useField(name || 'name');

  const setFieldTouchedRef = useRef(helpers.setTouched);
  const setFieldValueRef = useRef(helpers.setValue);

  const setFieldTouched = setFieldTouchedRef.current;
  const setFieldValue = setFieldValueRef.current;

  const field: FieldInputProps<any> = useMemo(
    () => ({
      ...fiprops,
      value: isValue(fiprops.value) && transform(fiprops.value),
    }),
    [fiprops, transform],
  );

  return { id, field, meta, setFieldTouched, setFieldValue };
}

function isValue(value: any) {
  return value !== null && value !== undefined;
}
