import React from 'react';
import { FinanciersT, RecipeT } from '#interfaces/components/forms';
import { OfficesT, PatientT, ProfessionalT } from '#interfaces/general-values';
import { SplitNameFormatter, filteredProf } from '../../../../utils';

export const useForm = ({
  recipe,
  patient,
  offices,
  professionals,
  financiers,
  params,
}: {
  recipe: RecipeT;
  patient: PatientT;
  offices: OfficesT[];
  professionals: ProfessionalT[];
  financiers: FinanciersT[];
  params: any;
}) => {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues({
      recipe,
      patient,
      offices,
      professionals,
      financiers,
      params,
    }),
  );

  React.useEffect(() => {
    setInitialValues(
      getInitialValues({
        recipe,
        patient,
        offices,
        professionals,
        financiers,
        params,
      }),
    );
  }, [recipe, patient, offices, professionals, params]);

  return { initialValues };
};

export const getInitialValues = ({
  offices,
  patient,
  recipe,
  professionals,
  financiers,
  params,
}: {
  recipe: RecipeT;
  patient: PatientT;
  offices: OfficesT[];
  professionals: ProfessionalT[];
  financiers: FinanciersT[];
  params: any;
}) => {
  const medicines =
    recipe &&
    JSON.parse(recipe?.medications)?.map((m: any) => {
      return {
        productName: m.desc,
        quantity: m.qty,
        diagnosis: {
          code: m.dx.split('-')[0],
          description: m.dx.split('-')[1],
        },
        prolonged_treatment: !!m.tx,
        dosage: m.obs,
        id_medicine_recipe: Math.random(),
      };
    });
  const namePatientParts = patient?.ape_nom?.split(' ');
  const professionalsEnabled = professionals.filter((p) => p.habilitado);
  const { firstName, lastName } = SplitNameFormatter(namePatientParts);

  return {
    search_medicine: '',
    medicine_from_list: {
      code: null,
      productName: '',
      presentation: '',
      id_medicine_recipe: Math.random(),
      quantity: 1,
      only_generic: false,
      not_replace: false,
      diagnosis: { description: '', code: '' },
      observations: '',
      prolonged_treatment: false,
      dosage: '',
    },
    medicines_list: medicines || [],
    patient: {
      name: firstName,
      last_name: lastName,
      document: patient?.documento,
      document_type: patient?.tipo_doc,
      sexo: patient?.sexo,
      date_birth: patient?.f_nacimiento,
      email: patient?.email,
      phone: patient?.tel_celu,
      country: 'Argentina',
      affiliate_number: patient?.nro_afil,
      province: patient?.provincia,
      locality: patient?.localidad,
      id_patient: patient?.id_paciente,
      medical_insurance_id: patient?.id_planNavigation.id_os,
    },
    id_financier:
      patient?.id_planNavigation?.id_osNavigation?.financierId ||
      financiers?.[0].financierId,
    type_of_plan: patient?.id_planNavigation?.nombre || '',
    office: offices?.[0]?.id_sucursal,
    professional:
      recipe?.professional?.id_prof || professionalsEnabled?.[0]?.id_prof,
    date_recipe: (recipe && new Date(recipe.date)) || new Date(),
    add_diagnosis: false,
    is_diagnosis_manual: false,
    add_diagnosis_general: false,
    is_diagnosis_manual_general: false,
    contactInformation: '',
    clinicAddress: params?.direccion,
    clinicName: params?.nombre,
    diagnosis_general: { description: recipe?.diagnosis, code: '' },
    prescriber: {
      name: params?.nombre,
      taxCode: params?.cuit,
      address: params?.direccion,
      logoLink: '',
      logoBase64: '',
    },
  };
};
