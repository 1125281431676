import React from 'react';
import classNames from 'classnames';
import { ActionCard } from './action';
import { products } from '../../mocks';
import { ProductBannerT } from '#interfaces/components';

export const ProductsBanner: React.FC<ProductBannerT> = ({
  product,
  data,
  suscribed,
  webUser,
}) => {
  const cardCss = classNames(
    'rounded-t h-24 mb-2 flex justify-between items-center px-8',
    product === 'Whatsapp' ? 'bg-green-500' : 'bg-purple-800',
  );
  const fullname = `${data[0].first_name} ${data[0].last_name}`;

  const href: { [key: string]: {} } = {
    Whatsapp: {
      hire: `https://wa.me/+5491128504255?text=Hola%20soy%20${fullname},%20quisiera%20contratar%20el%20servicio%20de%20WhatsApp`,
      modify: `https://wa.me/+5491128504255?text=Hola%20soy%20${fullname},%20quisiera%20modificar%20el%20servicio%20de%20WhatsApp`,
    },
    Cloud: {
      hire: `https://wa.me/+5491128504255?text=Hola%20soy%20${fullname},%20quisiera%20contratar%20el%20servicio%20de%20almacenamiento`,
      modify: `https://wa.me/+5491128504255?text=Hola%20soy%20${fullname},%20quisiera%20modificar%20el%20servicio%20de%20almacenamiento`,
    },
  };

  return (
    <div className="shadow mt-6">
      <div className={cardCss}>
        <h1 className="text-white text-2xl">{products[product].title}</h1>
        {suscribed && (
          <h4 className="bg-blue-200 w-24 h-6 text-center rounded text-blue-700 font-extrabold">
            Contratado
          </h4>
        )}
      </div>
      <div className="text-slate-500 flex justify-between px-8 items-center mt-8">
        <p>{products[product].description}</p>
      </div>
      <div className="flex justify-between px-8 items-center pb-8"></div>
      <ActionCard
        suscribed={suscribed}
        href={href[product]}
        webUser={webUser}
      />
    </div>
  );
};
