import React from 'react';

export const useForm = (article: any, selectedLab: number | undefined) => {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues(article, selectedLab),
  );

  React.useEffect(() => {
    setInitialValues(getInitialValues(article, selectedLab));
  }, [article, selectedLab]);

  const validate = React.useCallback((values: { [key: string]: any }) => {
    const errors: { [key: string]: any } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields: any = [];

    requiredFields.forEach((requiredField: any) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
};

const getInitialValues = (
  article: { [key: string]: any },
  selectedLab: number | undefined,
) => {
  return {
    id_concept: article?.id_concept || 0,
    concept: article?.concept || '',
    id_labo: article?.id_labo || selectedLab,
    code: article?.code || '',
    amount: article?.amount || 0,
    enabled: article?.enabled || true,
    rowversion: article?.rowversion || null,
    is_usd: article?.is_usd || false,
  };
};
