import React from 'react';
import { columns } from './columns';
import { Data } from './data';
import { laboratoriesOrdersGridMapper } from '../../../../utils';

export const Table: React.FC<any> = ({ data }) => {
  return (
    <div className="mx-auto w-full">
      <Data columns={columns} data={laboratoriesOrdersGridMapper(data)} />
    </div>
  );
};
