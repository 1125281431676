import { useEffect, useState } from 'react';
import { getWeek } from '../../../../utils';

export function useForm(current: any) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues(current),
  );
  useEffect(() => {
    setInitialValues(getInitialValues(current));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { initialValues };
}

function getInitialValues(current: any) {
  const { today, sevenDaysAgo } = getWeek();

  return {
    date_from: current?.date_from || sevenDaysAgo,
    date_to: current?.date_to || today,
    group_date_by: current?.group_date_by || {
      day: true,
      month: false,
      year: false,
    },
    group_by_med_ins: current?.group_by_med_ins || {
      medical_insurance: true,
      professional: false,
    },
    id_prof: current?.id_prof || null,
    id_med_ins: current?.id_med_ins || null,
    id_subsidiary: current?.id_subsidiary || null,
    benefit_code: current?.benefit_code || null,
    show_disabled_med_ins: current?.show_disabled_med_ins || false,
    show_disabled_prof: current?.show_disabled_prof || false,
    check_all_prof: current?.check_all_prof || true,
    check_all_med_ins: current?.current || true,
  };
}
